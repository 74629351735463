import { PropsWithChildren, ReactNode } from 'react'
import JsxParser from 'react-jsx-parser'
import { Image, View } from 'react-native'

import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { colors } from '../../../styles/colors'
import { getResponseAssetUrl } from '../../../utils/assets'
import { Link_C } from '../../Base/Link/Link'
import { Text_C } from '../../Base/Text/Text'
import { ResponseAsset, ResponseVideoAsset } from '../../../types/fetch-response-base.type'
import VideoPlayer from '@onpace/onspace-media-react/elements/video_player'
import { getSignedVideoAsset } from '../../../services/data/request-functions/videos-latest-replays-request'

type RVideoPlayerProps = {
  video: ResponseVideoAsset
}

export function RVideoPlayer({ video }: RVideoPlayerProps) {
  const sourcesLoader = async () => {
    if (video.signed_id === undefined || video.signed_id === null) {
      return { sources: video.viewer_sources }
    }

    const { playerSources } = await getSignedVideoAsset({
      signedId: video.signed_id
    })

    return { sources: playerSources }
  }

  return (
    <VideoPlayer
      sourcesLoader={sourcesLoader}
      metadata={{
        title: video.title,
        subtitle: video.description,
        artwork: video.placeholder_image_url
      }}
    />
  )
}

type RHeadingProps = {
  level: number
  children: ReactNode
}

function RHeading({ level, children }: RHeadingProps) {
  const styles = useBreakpointStyles({ styles: headingStyles })
  return (
    <Text_C
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...styles[`h${level}`],
        fontWeight: '700Bold'
      }}
    >
      {children}
    </Text_C>
  )
}

function RBold({ children }: PropsWithChildren) {
  return (
    <Text_C type="articleTextType" style={{ fontWeight: '700Bold' }}>
      {children}
    </Text_C>
  )
}

function RParagraph({ children }: PropsWithChildren) {
  return <Text_C type="articleTextType">{children}</Text_C>
}

function Pre({ children }: PropsWithChildren) {
  return <Text_C type="articleTextType">{children}</Text_C>
}

type HTMLProps = {
  content: string
}
function RHtml({ content }: PropsWithChildren<HTMLProps>) {
  return <RenderRichText content={content} />
}

type RListItemProps = {
  children: ReactNode
}

function RListItem({ children }: RListItemProps) {
  return <Text_C type="articleTextType">{children}</Text_C>
}

type RListProps = {
  children: ReactNode[]
  type: 'ol' | 'ul'
}

function RList({ children, type }: RListProps) {
  const items = children?.length ? children : [children]
  return (
    <View style={{ paddingVertical: 5, marginLeft: 10 }}>
      {items.map((item, idx) => (
        <View key={idx}>
          {type === 'ol' ? (
            <Text_C type="articleTextType">
              <Text_C style={{ fontWeight: '600SemiBold' }}>{idx + 1}.</Text_C> {item}
            </Text_C>
          ) : (
            <Text_C type="articleTextType">
              <Text_C style={{ fontWeight: '600SemiBold' }}>{'\u2022'}</Text_C> {item}
            </Text_C>
          )}
        </View>
      ))}
    </View>
  )
}

function RListOrdered({ children }: RListProps) {
  return <RList type="ol">{children}</RList>
}

function RListUnordered({ children }: RListProps) {
  return <RList type="ul">{children}</RList>
}

function RBlockQuote({ children }: PropsWithChildren) {
  return (
    <Text_C
      type="articleTextType"
      style={{
        marginTop: 10,
        marginLeft: 10,
        paddingLeft: 10,
        borderStyle: 'solid',
        borderColor: colors.gray300,
        borderLeftWidth: 5
      }}
    >
      {children}
    </Text_C>
  )
}

function RCaption({ children }: PropsWithChildren) {
  return (
    <Text_C
      type="articleTextType"
      style={{
        color: colors.gray300,
        fontStyle: 'italic'
      }}
    >
      {children}
    </Text_C>
  )
}

function RItalic({ children }: PropsWithChildren) {
  return (
    <Text_C
      type="articleTextType"
      style={{
        fontStyle: 'italic'
      }}
    >
      {children}
    </Text_C>
  )
}

function RHorizontalRule() {
  return (
    <View
      style={{
        width: '100%',
        borderStyle: 'solid',
        borderColor: colors.gray200,
        borderBottomWidth: 2,
        marginVertical: 10
      }}
    />
  )
}

function RStrikeThrough({ children }: PropsWithChildren) {
  return (
    <Text_C
      type="articleTextType"
      style={{
        textDecorationLine: 'line-through'
      }}
    >
      {children}
    </Text_C>
  )
}

function RLink({ children, href }: PropsWithChildren<{ href: string }>) {
  return (
    <Link_C linkUrl={href} externalLink newTab>
      <Text_C
        type="articleTextType"
        style={{
          color: colors.blue
        }}
      >
        {children}
      </Text_C>
    </Link_C>
  )
}

const RAttachmentType = {
  Image: 'image',
  Video: 'video'
} as const

type AttachmentType = (typeof RAttachmentType)[keyof typeof RAttachmentType]

type RAttachmentProps = {
  type: AttachmentType
  id: string
  caption: string
  metadata: string
}

function RAttachment({ caption, metadata }: RAttachmentProps) {
  const data = JSON.parse(metadata) as ResponseVideoAsset | ResponseAsset

  switch (data.content_type) {
    case RAttachmentType.Video:
      return <RVideoPlayer video={data as ResponseVideoAsset} />
    case RAttachmentType.Image: {
      const imageUrl = getResponseAssetUrl(data)
      return (
        <View style={{ width: '100%' }}>
          <Image
            source={{ uri: imageUrl }}
            style={{
              flex: 1,
              aspectRatio: 1.7889,
              width: '100%'
            }}
            resizeMode="contain"
          />

          <Text_C
            style={{
              marginTop: 10,
              color: colors.gray600,
              textAlign: 'center',
              fontStyle: 'italic'
            }}
          >
            {caption}
          </Text_C>
        </View>
      )
    }
    default:
      return null
  }
}

const SUPPORTED_COMPONENTS = {
  Pre,
  RAttachment,
  RBold,
  RBlockQuote,
  RCaption,
  RHeading,
  RHtml,
  RHorizontalRule,
  RLink,
  RItalic,
  RListOrdered,
  RListUnordered,
  RParagraph,
  RListItem,
  RStrikeThrough,
  View
}

const removeSpacesFromJSX = (input: string) =>
  input.replace(/^\s+|\s+$/gm, '').replace(/>\s+</g, '><')

type RenderContentProps = {
  content: string
}

export function RenderRichText({ content }: RenderContentProps) {
  if (!content) return null

  const parsedContent = removeSpacesFromJSX(content)
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <JsxParser
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: Fix the types for this
      components={SUPPORTED_COMPONENTS}
      autoCloseVoidElements
      renderInWrapper={false}
      jsx={parsedContent}
    />
  )
}

const headingStyles = createStyles({
  h1: {
    base: {
      marginVertical: 5,
      fontSize: 20,
      lineHeight: 20
    },
    large: {
      marginVertical: 10,
      fontSize: 22,
      lineHeight: 22
    }
  },
  h2: {
    base: {
      marginVertical: 5,
      fontSize: 18,
      lineHeight: 18
    },
    large: {
      marginVertical: 10,
      fontSize: 20,
      lineHeight: 20
    }
  },
  h3: {
    base: {
      marginVertical: 5,
      fontSize: 16,
      lineHeight: 16
    },
    large: {
      marginTop: 10,
      fontSize: 18,
      lineHeight: 18
    }
  },
  h4: {
    base: {
      marginTop: 10,
      fontSize: 14,
      lineHeight: 14
    },
    large: {
      fontSize: 16,
      lineHeight: 16
    }
  },
  h5: {
    base: {
      marginTop: 10,
      fontSize: 14,
      lineHeight: 14
    },
    large: {
      fontSize: 16,
      lineHeight: 16
    }
  },
  h6: {
    base: {
      marginTop: 10,
      fontSize: 14,
      lineHeight: 14
    },
    large: {
      fontSize: 16,
      lineHeight: 16
    }
  }
})
