/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { View } from 'react-native'
import {
  LatestRacesResponse,
  getNextToGo
} from '../../../services/data/request-functions/latest-races-request'
import {
  MeetingsResultsResponse,
  getDaysMeetingsResults
} from '../../../services/data/request-functions/meetings-results-request'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { LatestRaces_C } from '../../Partials/LatestRaces/LatestRaces'
import { MeetingListCard_C } from '../../Partials/_meeting-components/MeetingListCard'
import { Metadata } from '../../Partials/Metadata/Metadata'

export const Results_C = (): JSX.Element =>
  withScreenWrapper<'Results', [MeetingsResultsResponse, LatestRacesResponse]>({
    requestsSetup: ({ date }) => {
      return [
        getDaysMeetingsResults({
          date
        }),
        getNextToGo({ limit: 6 })
      ]
    },
    aside: ({ responses }) => {
      if (!responses) return
      const [_, { races: nextRaces }] = responses

      return (
        <View style={{ gap: 24 }}>
          <LatestRaces_C nextRaces={nextRaces} />
        </View>
      )
    },
    view: ({ responses }) => {
      const [resultMeetingsResponse] = responses!
      const { meetings, date, minDate, maxDate } = resultMeetingsResponse

      const selectedDate = new Date(date)

      return (
        <>
          <Metadata
            title="Harness Racing Results"
            description="Get all the latest harness racing results and video race replays from across Australia"
          />

          <MeetingListCard_C
            activeDate={selectedDate}
            type="results"
            meetings={meetings}
            maxDate={maxDate}
            minDate={minDate}
          />
        </>
      )
    }
  })
