import { useContext } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { GlobalContext } from '../../../global-context'
import { createStyles } from '../../../services/styles/breakpoint-styles.service'

export function LoadingSpinnerOverlay_C() {
  const { loadingSpinnerActive } = useContext(GlobalContext)

  if (!loadingSpinnerActive) {
    return null
  }

  return (
    <View style={styles.backgroundOverlay}>
      <ActivityIndicator size="large" color="white" />
    </View>
  )
}

const styles = createStyles({
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000069'
  }
})
