import { Image, ImageStyle, View } from 'react-native'

import { colors } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { Text_C } from '../../../Base/Text/Text'
import {
  useBreakpointStyles,
  createStyles
} from '../../../../services/styles/breakpoint-styles.service'

export function CopyrightText_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  return (
    <View style={styles.container}>
      <Text_C style={styles.text}>
        © Racing Information Services Enterprise {new Date().getFullYear()}
      </Text_C>
      <View style={styles.divider} />
      <Text_C style={styles.text}>Powered by</Text_C>
      <Image
        style={styles.image as ImageStyle}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        source={require('../../../../assets/images/rise-logo.png')}
      />
    </View>
  )
}

const breakpointStyles = createStyles({
  container: {
    base: {
      flex: 1,
      flexDirection: 'row',
      gap: 8,
      justifyContent: 'center',
      marginTop: 20,
      minWidth: 500
    }
  },
  text: {
    base: {
      color: 'white',
      ...textSizes.size1
    }
  },
  image: {
    base: {
      width: 46,
      height: 16
    }
  },
  divider: {
    base: {
      borderRightColor: colors.gray100,
      borderRightWidth: 1,
      height: 16
    }
  }
})
