import { removeLeadingSlash, toCapitalized } from '../../../helper-functions'
import { fetchData } from '../api-fetch-request'
import {
  ResponsePreviousRun,
  RunnerAdditionalDataFetchResponse
} from '../data-types/fetch-response-types/runner-additional-data-response.type'
import { DayPhaseLetter, TrackCondition } from '../data-types/general-data-types.type'
import {
  PreviousRun,
  RunnerAdditionalData
} from '../data-types/local-data-types/meeting-detailed.type'

export type RunnerAdditionalDataResponse = {
  runnerAdditionalData: RunnerAdditionalData
}

type FetchRunnerAdditionalDataParams = {
  date: string
  trackSlug: string
  raceNumber: number
  dayPhaseLetter: DayPhaseLetter
  runnerNumber: number
  trial?: boolean
}

export async function fetchRunnerAdditionalData({
  date,
  trackSlug,
  dayPhaseLetter,
  raceNumber,
  runnerNumber,
  trial
}: FetchRunnerAdditionalDataParams): Promise<RunnerAdditionalDataResponse> {
  const endpoint = `racing/${trackSlug}/${date}/${dayPhaseLetter}/${raceNumber}/runner-form/${runnerNumber}${trial ? `?trial=true` : ''}`
  return fetchData<RunnerAdditionalDataResponse, RunnerAdditionalDataFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(
    runnerAdditionalDataPayload: RunnerAdditionalDataFetchResponse
  ): RunnerAdditionalDataResponse {
    const { last_win, previous_runs, age, bm_sire, breeder, colour, dam, owner, sex, sire } =
      runnerAdditionalDataPayload

    let runnerAdditionalData: RunnerAdditionalData = {
      age,
      colour: toCapitalized(colour),
      sex: toCapitalized(sex),
      sire: sire
        ? {
            name: sire.name,
            slug: !sire.slug ? undefined : removeLeadingSlash(sire.slug)
          }
        : undefined,
      dam: dam
        ? {
            name: dam.name,
            slug: !dam.slug ? undefined : removeLeadingSlash(dam.slug)
          }
        : undefined,
      broodmareSire: bm_sire
        ? {
            name: bm_sire.name,
            slug: !bm_sire.slug ? undefined : removeLeadingSlash(bm_sire.slug)
          }
        : undefined,
      owner: {
        name: owner.display_name,
        shortName: owner.mobile_display_name
      },
      breeder: !breeder
        ? undefined
        : {
            name: breeder.display_name,
            shortName: breeder.mobile_display_name
          },

      lastWin: !last_win ? undefined : convertPreviousRun(last_win),
      previousRuns: !previous_runs ? undefined : previous_runs.map(convertPreviousRun)
    }

    if (runnerAdditionalDataPayload.career_statistics) {
      const {
        lifetime_stats,
        lifetime_stakes,
        best_mile_rate,
        this_season_stats,
        last_season_stats,
        trainer_location,
        last_15,
        track_stats,
        range_dist_best_mr,
        place_percentage,
        win_percentage,
        start_stats, // is mobile start stats, but name is not so good
        range_dist_stats
      } = runnerAdditionalDataPayload.career_statistics

      runnerAdditionalData = {
        ...runnerAdditionalData,
        career: lifetime_stats,
        lifetimeStakes: Number(lifetime_stakes),
        bestMileRate: best_mile_rate ?? undefined,
        thisSeason: this_season_stats,
        lastSeason: last_season_stats,
        trainerLocation: trainer_location,
        last15: last_15,
        mobile: start_stats, // naming confuses me, im 99% sure this is right
        track: track_stats,
        distanceRange: range_dist_stats,
        bmrDistanceRange: range_dist_best_mr,
        winPercent: win_percentage,
        placePercent: place_percentage
      }
    }
    return {
      runnerAdditionalData
    }
  }
}

function convertPreviousRun(r: ResponsePreviousRun): PreviousRun {
  const {
    distance,
    name,
    start_type,
    time,
    time_mile_rate,
    total_prize_money,
    track_condition,
    has_replay
  } = r.race_details

  const previousRun: PreviousRun = {
    totalRunners: r.total_runners ?? undefined, // shouldnt be undefined
    raceNumber: `${r.race_number}`,
    hasReplay: has_replay,
    dayPhaseLetter: r.day_night_twilight,
    meetingDate: r.meeting_date,
    place: r.place,
    raceDistance: distance,
    raceName: name ?? undefined,
    startType: start_type as 'MS' | 'SS',
    totalPrizeMoney: Number(total_prize_money),
    trackCondition: !track_condition
      ? undefined
      : (toCapitalized(track_condition) as TrackCondition),
    trackName: r.track,
    trackSlug: removeLeadingSlash(r.track_slug),
    trackPublished: r.track_published,
    barrier: r.barrier,
    raceClass: r.race_details.class ?? undefined,
    mileRate: time_mile_rate,
    driverName: r.driver_name,
    driverSlug: !r.driver_slug ? undefined : removeLeadingSlash(r.driver_slug),
    marginString: r.margin,
    startingPrice: r.starting_price ?? undefined,
    stewardsComments: r.stewards_comments ?? undefined,

    time: {
      first: time.first_quarter ?? undefined,
      second: time.second_quarter ?? undefined,
      third: time.third_quarter ?? undefined,
      fourth: time.fourth_quarter ?? undefined,
      lastHalf: time.last_half ?? undefined
    },

    otherRunners: !r.other_runners
      ? undefined
      : r.other_runners.map(({ horse_name, horse_slug, barrier, place }) => {
          return {
            horseName: horse_name ? toCapitalized(horse_name) : undefined,
            horseSlug: horse_slug ? removeLeadingSlash(horse_slug) : undefined,
            barrier,
            place
          }
        })
  }
  return previousRun
}
