import { format } from 'date-fns'
import {
  DayPhaseLetter,
  MeetingStatus
} from '../../../services/data/data-types/general-data-types.type'
import { playIcon } from '../../Base/Icon/preset-icon-props'
import { Link_C } from '../../Base/Link/Link'
type Props = {
  date: string
  dayPhaseLetter: DayPhaseLetter
  isTrial: boolean
  trackName: string
  trackSlug: string
  raceNumber?: string
  hasReplay: boolean
  meetingStatus?: MeetingStatus
  newTab?: boolean
}
export function TableCellRaceLink_C({
  date,
  dayPhaseLetter,
  isTrial,
  trackName,
  trackSlug,
  raceNumber,
  hasReplay,
  newTab,
  meetingStatus
}: Props) {
  const linkText = `${trackName}\n${format(new Date(date), 'd/M/yy')}${isTrial ? ' (T)' : ''}`

  const props: any = newTab
    ? {
        newTab,
        linkUrl: `${meetingStatus == 'Scheduled' ? 'meeting-program' : 'racing'}/${date}/${trackSlug}/${dayPhaseLetter}/${isTrial ? 'trial' : 'meeting'}${!raceNumber ? '' : `/${raceNumber}`}`
      }
    : {
        navigateTo: [
          'Meeting',
          {
            trackSlug,
            date,
            raceNumber,
            dayPhaseLetter,
            type: isTrial ? 'trial' : 'meeting'
          }
        ]
      }
  return (
    <Link_C
      style={{
        elem: {
          width: hasReplay ? 170 : 140,
          gap: 8
        },
        text: {
          ...(isTrial && { fontStyle: 'italic' })
        }
      }} // 170 to fit 18 char track name
      {...(hasReplay ? { icon: playIcon, styleType: 'linkWithIcon' } : {})}
      {...props}
    >
      {linkText}
    </Link_C>
  )
}
