import { TrainerFetchResponse } from '../data-types/fetch-response-types/trainer-response.type'

export const trainerCompare: TrainerFetchResponse = {
  meta: {
    status: 200,
    code: 'OK'
  },
  trainer: {
    display_name: 'Bernie Hewitt',
    mobile_display_name: 'B J Hewitt',
    slug: '/bernie-hewitt',
    location: 'NSW',
    age: 62,
    driver: {
      display_name: 'Bernie Hewitt',
      mobile_display_name: 'B J Hewitt',
      slug: '/bernie-hewitt',
      location: 'NSW',
      age: 62
    },
    primary_silk: {
      description: 'PURPLE, RED SPOTS',
      silk_image: {
        filename: '486.png',
        path: '/onspace/media/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBeFRaV0E9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--04694b5a5acfb40bc7c0604940a959cfae553ad8/486.png'
      }
    },
    secondary_silk: {
      description: 'PURPLE, RED SPOTS, PURPLE SLEEVES',
      silk_image: {
        filename: '489.png',
        path: '/onspace/media/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBLzZ6VGc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--314d39008909bb0766f64c70a158164887796627/489.png'
      }
    },
    tertiary_silk: {
      // eslint-disable-next-line quotes
      description: "DARK BLUE WITH MATES4HARNESS FLOOD RELIEF '23 LOGO",
      silk_image: null
    },
    debut_season: '83/84',
    lifetime_summary: {
      starts: 13257,
      wins: 1890,
      seconds: 1791,
      thirds: 1741,
      stakes: 13518259,
      group_1_starts: 141,
      group_1_wins: 9,
      group_1_seconds: 8,
      group_1_thirds: 14,
      group_1_stakes: 1308921,
      group_2_starts: 97,
      group_2_wins: 13,
      group_2_seconds: 10,
      group_2_thirds: 10,
      group_2_stakes: 648335,
      group_3_starts: 135,
      group_3_wins: 9,
      group_3_seconds: 19,
      group_3_thirds: 14,
      group_3_stakes: 390673,
      listed_race_starts: 79,
      listed_race_wins: 7,
      listed_race_seconds: 9,
      listed_race_thirds: 8,
      listed_race_stakes: 241064
    },
    current_season_summary: {
      starts: 831,
      wins: 95,
      seconds: 100,
      thirds: 104,
      stakes: 1290014,
      group_1_starts: 6,
      group_1_wins: 1,
      group_1_seconds: 0,
      group_1_thirds: 1,
      group_1_stakes: 108625,
      group_2_starts: 4,
      group_2_wins: 1,
      group_2_seconds: 1,
      group_2_thirds: 0,
      group_2_stakes: 80000,
      group_3_starts: 6,
      group_3_wins: 0,
      group_3_seconds: 1,
      group_3_thirds: 0,
      group_3_stakes: 15143,
      listed_race_starts: 8,
      listed_race_wins: 1,
      listed_race_seconds: 1,
      listed_race_thirds: 0,
      listed_race_stakes: 48770
    },
    last_season_summary: {
      starts: 821,
      wins: 126,
      seconds: 120,
      thirds: 91,
      stakes: 1695950,
      group_1_starts: 26,
      group_1_wins: 6,
      group_1_seconds: 3,
      group_1_thirds: 1,
      group_1_stakes: 528411,
      group_2_starts: 13,
      group_2_wins: 2,
      group_2_seconds: 1,
      group_2_thirds: 2,
      group_2_stakes: 90120,
      group_3_starts: 16,
      group_3_wins: 0,
      group_3_seconds: 4,
      group_3_thirds: 1,
      group_3_stakes: 29714,
      listed_race_starts: 4,
      listed_race_wins: 0,
      listed_race_seconds: 0,
      listed_race_thirds: 1,
      listed_race_stakes: 4250
    },
    feature_wins: [
      {
        is_trial: true,
        has_replay: true,
        meeting_date: '2022-07-16',
        track: 'Albion Park',
        race: 9,
        horse: 'Jewel Melody',
        barrier: 'Fr2',
        saddlecloth_number: 2,
        prizemoney: 84000.0,
        horse_slug: '/jewel-melody',
        black_type: 'GROUP 1',
        track_slug: '/albion-park',
        day_night_twilight: 'N',
        race_number: 9,
        race_name: '2022 Albion Park Gold  Brisbane Apg Sales Graduates 2yo Final (G1)',
        race_short_name: '2022 Albion Park Gold (G1)'
      }
    ]
  }
}
