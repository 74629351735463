import { fetchData } from '../api-fetch-request'
import {
  GetPreferencesFetchResponse,
  ResponseNotificationTypes,
  SetPreferencesFetchResponse
} from '../data-types/fetch-response-types/notification-preferences-response.type'
import { NotificationPreferences } from '../data-types/local-data-types/blackbook.type'

export type NotificationPreferencesResponse = {
  notificationPreferences: NotificationPreferences
}
type GetPreferencesProps = {
  authToken: string
}
export async function getPreferences({
  authToken
}: GetPreferencesProps): Promise<NotificationPreferencesResponse> {
  return fetchData<NotificationPreferencesResponse, GetPreferencesFetchResponse>({
    endpoint: `member/preferences`,
    convertResponse,
    validationCompareObject: undefined,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })

  function convertResponse(payload: GetPreferencesFetchResponse): NotificationPreferencesResponse {
    const {
      member: { notification_types }
    } = payload
    const response: NotificationPreferencesResponse = {
      notificationPreferences: {
        isEntered: notification_types.includes('horse_declared_run'),
        isScratched: notification_types.includes('horse_non_runner'),
        hasWon: notification_types.includes('horse_won'),
        hasCompleted: notification_types.includes('horse_completes_race')
      }
    }

    return response
  }
}

type SetPreferencesProps = {
  authToken: string
  preferences: NotificationPreferences
}
export async function setPreferences({
  authToken,
  preferences
}: SetPreferencesProps): Promise<any> {
  const notificationTypesArray: ResponseNotificationTypes[] = []

  if (preferences.isEntered) notificationTypesArray.push('horse_declared_run')

  if (preferences.isScratched) notificationTypesArray.push('horse_non_runner')

  if (preferences.hasWon) notificationTypesArray.push('horse_won')

  if (preferences.hasCompleted) notificationTypesArray.push('horse_completes_race')

  const body = JSON.stringify({
    preference: {
      member: {
        notification_types: notificationTypesArray
      }
    }
  })
  return fetchData<any, SetPreferencesFetchResponse>({
    endpoint: `member/preferences`,
    convertResponse,
    validationCompareObject: undefined,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body
  })

  function convertResponse(payload: SetPreferencesFetchResponse) {
    const { message } = payload

    return
  }
}
