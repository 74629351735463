// cspell: word Infront

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { merge } from 'lodash'
import { ReactNode, useState } from 'react'
import { View } from 'react-native'
import { LeftOrRight } from '../../../helper-types'
import { Pagination } from '../../../services/data/data-types/general-data-types.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { BreakpointStyles } from '../../../services/styles/dependencies/breakpoint-style.type'
import { tabletBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { Button_C } from '../../Base/Button/Button'
import { Icon_C } from '../../Base/Icon/Icon'
import { chevronIcon, ellipsisLargeIcon } from '../../Base/Icon/preset-icon-props'
import { pressableStyleTypes } from '../../Base/Pressable/pressable-style-types'
import { Text_C } from '../../Base/Text/Text'

type PaginationProps = Pagination & {
  onPageChange: (pageNumber: number) => void
  options?: {
    infoSide?: LeftOrRight
  }
}

type PaginationLayout = 'small' | 'medium' | 'full'

export function Pagination_C({
  totalPages,
  activePageNumber,
  totalRecords,
  perPage,
  options = {
    infoSide: 'right'
  },
  onPageChange
}: PaginationProps) {
  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })

  const [layout, setLayout] = useState<PaginationLayout>()

  const { previousButton, pageNumberButtons, nextButton, paginationInfo } = getElements()

  function onWidthChange(width: number) {
    if (width < 750 && layout !== 'small') {
      setLayout('small')
    }
    if (width >= 750 && width < 900 && layout !== 'medium') {
      setLayout('medium')
    }
    if (width >= 900 && layout !== 'full') {
      setLayout('full')
    }
  }

  const controlsWidth =
    layout == 'small' ? smallWidth : layout == 'medium' ? mediumWidth : fullWidth

  return totalPages <= 1 ? (
    <></>
  ) : (
    <View
      onLayout={({
        nativeEvent: {
          layout: { width }
        }
      }) => {
        onWidthChange(width)
      }}
    >
      <View style={styles.paginationContainer}>
        <View
          style={{
            ...styles.sideContainer,
            ...(layout == 'full' ? { flex: 1 } : {})
          }}
        >
          {options.infoSide == 'left' && (totalPages > 1 ? paginationInfo : 'no results')}
        </View>
        <View style={{ ...styles.controls, width: controlsWidth }}>
          <>
            {previousButton}
            {pageNumberButtons}
            {nextButton}
          </>
        </View>
        <View
          style={{
            ...styles.sideContainer,
            ...(layout == 'full' ? { flex: 1 } : {})
          }}
        >
          {options.infoSide == 'right' && (totalPages > 1 ? paginationInfo : 'no results')}
        </View>
      </View>
    </View>
  )

  function getElements() {
    const maxAdjacentButtons = layout == 'full' ? maxAdjacentButtonsFull : maxAdjacentButtonsMedium

    const pageNumberButtons = Array.from({ length: totalPages }).reduce(
      (elems: ReactNode[], _, i) => {
        const loopPageNumber = i + 1
        const isActivePage = activePageNumber == loopPageNumber
        if (
          loopPageNumber == 1 ||
          loopPageNumber == totalPages ||
          (loopPageNumber > activePageNumber - maxAdjacentButtons &&
            loopPageNumber <= activePageNumber) ||
          (loopPageNumber >= activePageNumber &&
            loopPageNumber < activePageNumber + maxAdjacentButtons)
        ) {
          return [
            ...elems,
            getPaginationButton({
              pageNumber: loopPageNumber,
              disabled: isActivePage
            })
          ]
        }

        if (loopPageNumber == 2 && activePageNumber >= 2 + maxAdjacentButtons) {
          return [...elems, getPaginationDots({ key: loopPageNumber })]
        }
        if (
          loopPageNumber == activePageNumber + maxAdjacentButtons &&
          loopPageNumber < totalPages
        ) {
          return [...elems, getPaginationDots({ key: loopPageNumber })]
        }

        return elems
      },
      [] as ReactNode[]
    )

    const previousButton = (
      <Button_C
        key="previousButton"
        styleType="paginationButton"
        style={{
          elem: {
            ...styles.arrowButton,
            marginRight: 'auto',
            width: layout == 'small' ? arrowButtonWidthSmall : arrowButtonWidthFull
          }
        }}
        iconInfront
        icon={merge({}, chevronIcon, {
          style: {
            ...styles.arrowIcon,
            transform: [{ rotate: '90deg' }]
          }
        })}
        onPress={() => onPageChange(--activePageNumber)}
        disabled={activePageNumber === 1}
      >
        {layout == 'small' ? '' : 'Previous'}
      </Button_C>
    )

    const nextButton = (
      <Button_C
        key="nextButton"
        styleType="paginationButton"
        style={{
          elem: {
            ...styles.arrowButton,
            marginLeft: 'auto',

            ...(layout == 'small'
              ? { width: arrowButtonWidthSmall, gap: 0 }
              : { width: arrowButtonWidthFull })
          }
        }}
        icon={merge({}, chevronIcon, {
          style: {
            ...styles.arrowIcon,
            transform: [{ rotate: '-90deg' }]
          }
        })}
        onPress={() => onPageChange(++activePageNumber)}
        disabled={activePageNumber === totalPages}
      >
        {layout == 'small' ? '' : 'Next'}
      </Button_C>
    )

    const fromNum = (activePageNumber - 1) * perPage + 1
    let toNum = activePageNumber * perPage
    toNum = toNum < totalRecords ? toNum : totalRecords

    const paginationInfo = (
      <View
        style={{
          ...styles.paginationInfo,
          alignItems: options.infoSide == 'right' ? 'flex-end' : 'flex-start'
        }}
      >
        <Text_C numberOfLines={1}>{`${fromNum} - ${toNum} of ${totalRecords}`}</Text_C>
      </View>
    )
    return {
      previousButton,
      pageNumberButtons,
      nextButton,
      paginationInfo
    }

    function getPaginationButton({
      pageNumber,
      disabled
    }: {
      disabled: boolean
      pageNumber: number
    }) {
      return (
        <Button_C
          key={pageNumber}
          styleType="paginationButton"
          onPress={() => onPageChange(pageNumber)}
          disabled={disabled}
        >
          {pageNumber}
        </Button_C>
      )
    }

    function getPaginationDots({ key }: { key: number }) {
      return <Icon_C key={key} {...merge({}, ellipsisLargeIcon, { style: styles.dots })} />
    }
  }
}

const maxAdjacentButtonsMedium = 2
const maxAdjacentButtonsFull = 3

const pb = pressableStyleTypes.paginationButton.elem as BreakpointStyles

const buttonWidthFull = Number(pb.base.width)
const arrowButtonWidthSmall = 30
const arrowButtonWidthFull = 92
const gapWidth = 10
const dotsWidth = 20

const arrowsAndDotsSmall = (arrowButtonWidthSmall + dotsWidth) * 2
const arrowsAndDotsFull = (arrowButtonWidthFull + dotsWidth) * 2

const pageButtonsMedium = (maxAdjacentButtonsMedium * 2 + 1) * buttonWidthFull
const pageButtonsFull = (maxAdjacentButtonsFull * 2 + 1) * buttonWidthFull
const gapsMobile = (maxAdjacentButtonsMedium * 2 + 4) * gapWidth
const gapsDesktop = (maxAdjacentButtonsFull * 2 + 4) * gapWidth

const smallWidth = arrowsAndDotsSmall + pageButtonsMedium + gapsMobile
const mediumWidth = arrowsAndDotsFull + pageButtonsMedium + gapsMobile
const fullWidth = arrowsAndDotsFull + pageButtonsFull + gapsDesktop

const breakpointStyles = createStyles({
  paginationContainer: {
    base: { alignItems: 'center' },
    [tabletBreakpoint]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10
    }
  },
  sideContainer: {}, //one on each side to keep controls centered
  controls: {
    base: {
      marginVertical: 10,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: gapWidth
    },
    [tabletBreakpoint]: {
      marginVertical: 'unset'
    }
  },
  arrowIcon: { width: 12 },
  arrowButton: {},
  dots: {
    width: dotsWidth
  },
  paginationInfo: {}
})
