import { orderBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { australianStates } from '../../../../constants'
import { Pagination } from '../../../../services/data/data-types/general-data-types.type'
import { Podcast } from '../../../../services/data/data-types/local-data-types/podcast-list.type'
import { getPodcasts } from '../../../../services/data/request-functions/podcast-list-requests'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { textSizes } from '../../../../styles/text-sizes'
import { AustralianState } from '../../../../types/map.type'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Option, Select_C } from '../../../Base/Select/Select'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { GapGrid_C } from '../../../Layout/Grid/GapGrid'
import { Pagination_C } from '../../../Partials/Pagination/Pagination'
import { PerPageOption } from '../../../Partials/Pagination/components/PerPageSelect'
import { PodcastItem_C } from './PodcastItem'

const podcastItemsPerPage = 12 as PerPageOption
type SortType = 'newest' | 'oldest'

export function PodcastList_C() {
  const [loadedPodcasts, setLoadedPodcasts] = useState<Podcast[]>()
  const [pagination, setPagination] = useState<Pagination>()
  const [columns, setColumns] = useState<number>()
  const [sortType, setSortType] = useState<'newest' | 'oldest'>('newest')
  const [selectedState, setSelectedState] = useState<AustralianState | 'National'>()

  const styles = useBreakpointStyles({ styles: breakpointStyles })

  useEffect(() => {
    getPodcasts({
      limit: podcastItemsPerPage,
      state: selectedState,
      sortType
    }).then(({ podcasts, pagination }) => {
      setPagination(pagination)
      const orderedPodcasts = orderBy(podcasts, (x) => x.date, [
        sortType == 'newest' ? 'desc' : 'asc'
      ])
      setLoadedPodcasts(orderedPodcasts)
    })
  }, [selectedState, sortType])

  const { stateSelect, orderSelect, paginationElem, heading } = getElements()

  return (
    <Card_C
      style={styles.mainContainer}
      onLayout={({
        nativeEvent: {
          layout: { width }
        }
      }) => {
        onWidthChange(width)
      }}
    >
      <View style={styles.header}>
        {heading}
        {orderSelect}
        {stateSelect}
      </View>

      {!loadedPodcasts?.length ? (
        <View style={styles.noResultsElem}>
          <Text_C style={styles.noResultsText}>No podcasts available</Text_C>
        </View>
      ) : (
        <>
          {paginationElem}
          <GapGrid_C
            gridBreakpointConfig={{
              base: { columns: columns! }
            }}
            columnGap={16}
            rowGap={32}
            cells={loadedPodcasts.map((podcast, idx) => (
              <PodcastItem_C podcast={podcast} key={idx} />
            ))}
          />
          {paginationElem}
        </>
      )}
    </Card_C>
  )

  function getElements() {
    const heading = (
      <Heading_C styleType="h1" style={styles.heading}>
        Podcasts
      </Heading_C>
    )

    const paginationElem = pagination && pagination.totalPages > 1 && (
      <View style={{ width: '100%', marginVertical: 30 }}>
        <Pagination_C
          activePageNumber={pagination.activePageNumber}
          perPage={podcastItemsPerPage}
          totalPages={pagination.totalPages}
          totalRecords={pagination.totalRecords}
          onPageChange={paginationChange}
        />
      </View>
    )
    const selectOptions: Option<AustralianState | undefined | 'National'>[] = useMemo(
      () => [
        { label: 'All States', value: undefined },
        { label: 'National', value: 'National' },
        ...australianStates.map((state) => ({
          label: state,
          value: state
        }))
      ],
      []
    )

    const stateSelect = (
      <Select_C<AustralianState | undefined | 'National'>
        styleType="blueCaret"
        options={selectOptions}
        initialSelection={
          selectedState
            ? selectOptions.find((option) => option.value == selectedState)?.label
            : selectOptions[0].label
        }
        onSelect={(selected) => setSelectedState(selected.value)}
      />
    )

    const orderSelectOptions: Option<SortType>[] = [
      { label: 'Newest First', value: 'newest' },
      { label: 'Oldest First', value: 'oldest' }
    ]
    const orderSelect = (
      <Select_C<SortType>
        styleType="blueCaret"
        options={orderSelectOptions}
        onSelect={({ value }) => setSortType(value)}
        initialSelection={
          sortType
            ? orderSelectOptions.find((option) => option.value == sortType)?.label
            : orderSelectOptions[0].label
        }
      />
    )

    return { stateSelect, orderSelect, paginationElem, heading }
  }

  function paginationChange(page: number) {
    getPodcasts({
      limit: podcastItemsPerPage,
      page,
      sortType,
      state: selectedState
    }).then(({ podcasts, pagination }) => {
      setPagination(pagination)
      setLoadedPodcasts(podcasts)
    })
  }

  function onWidthChange(width: number) {
    if (width < 750 && columns !== 2) {
      setColumns(2)
    }
    if (width >= 750 && width < 900 && columns !== 3) {
      setColumns(3)
    }
    if (width >= 900 && columns !== 4) {
      setColumns(4)
    }
  }
}
const breakpointStyles = createStyles({
  mainContainer: {
    base: {
      rowGap: 30
    }
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 15,
    zIndex: 1
  },
  heading: { marginRight: 'auto' },
  noResultsElem: { alignSelf: 'center' },
  noResultsText: { ...textSizes.size4, fontStyle: 'italic' }
})
