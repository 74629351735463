import { View } from 'react-native'

import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { colors } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { Link_C } from '../../../Base/Link/Link'
import { PressableStyleExtension } from '../../../Base/Pressable/Pressable'

export function FooterLinks_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  // TODO: these links should be coming from the CMS long term (onspace) rather than being hard coded.
  const routes = [
    {
      name: 'Help',
      href: 'help'
    },
    {
      name: 'Privacy',
      href: 'privacy-policy'
    },
    {
      name: 'Advertise',
      href: 'advertise'
    },
    {
      name: 'Terms Of Use',
      href: 'terms-of-use'
    },
    {
      name: 'Contact Us',
      href: 'contact-us'
    }
  ]

  return (
    <View style={styles.container}>
      {routes.map((route, i) => {
        const notLastLink = routes.length - 1 != i
        return [
          <Link_C navigateTo={['Pages', { pageSlug: route.href }]} key={i} style={footerLinkStyle}>
            {route.name}
          </Link_C>,
          notLastLink && <View key={i + 'divider'} style={styles.divider} />
        ]
      })}
    </View>
  )
}

const footerLinkStyle: PressableStyleExtension = {
  text: {
    base: {
      fontFamily: 'OpenSans',
      fontWeight: '700Bold',
      color: 'white',
      ...textSizes.size1
    }
  }
}

const breakpointStyles = createStyles({
  container: {
    base: {
      flexDirection: 'row',
      gap: 8,
      justifyContent: 'center',
      minWidth: 500
    }
  },
  divider: {
    base: {
      borderRightColor: colors.gray100,
      borderRightWidth: 1,
      height: 16
    }
  }
})
