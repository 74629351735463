import { useNavigation } from '@react-navigation/native'
import { useContext, useState } from 'react'
import { View } from 'react-native'
import { capitalLetterRegex, numberRegex, symbolRegex } from '../../../constants'
import { GlobalContext } from '../../../global-context'
import {
  changePassword,
  resetPassword
} from '../../../services/data/request-functions/user-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { setStorageData } from '../../../utils/storage'
import { Button_C } from '../../Base/Button/Button'
import { Heading_C } from '../../Base/Heading/Heading'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { FormField, FormField_C } from '../../Partials/_user-components/FormField'

const blankForm = {
  currentPassword: {
    label: 'Current Password',
    value: '',
    invalidMessage: 'Password is required'
  },
  newPassword: {
    label: 'New Password',
    value: '',
    invalidMessage: 'Password is required'
  },
  confirmNewPassword: {
    label: 'Confirm New Password',
    value: '',
    invalidMessage: 'Confirm password is required'
  }
}
export function ResetPassword_C(): JSX.Element {
  return withScreenWrapper<'ResetPassword'>({
    view: ({ params }) => {
      const { navigate } = useNavigation()
      const { token } = params
      const resetToken = token == 'change' ? undefined : token
      const { authToken, setAuthToken, addToast } = useContext(GlobalContext)
      if (!token && !authToken) {
        navigate('Home')
      }

      const [formState, setFormState] = useState(blankForm)
      const [submitAttempted, setSubmitAttempted] = useState(false)
      const styles = useBreakpointStyles({ styles: breakpointStyles })

      function setFormField<T extends keyof typeof formState>(fieldKey: T, field: FormField) {
        if (!fieldKey) return
        const invalidMessage: string | undefined =
          fieldKey == 'currentPassword'
            ? !field.value
              ? 'Password is required'
              : undefined
            : fieldKey == 'newPassword'
              ? !field.value
                ? 'Password is required'
                : field.value.length < 6
                  ? 'Password must be at least 6 characters'
                  : !capitalLetterRegex.test(field.value)
                    ? 'Password requires a capital Letter'
                    : !numberRegex.test(field.value)
                      ? 'Password requires a number'
                      : !symbolRegex.test(field.value)
                        ? 'Password requires a symbol'
                        : undefined
              : fieldKey == 'confirmNewPassword'
                ? !field.value
                  ? 'Confirm password is required'
                  : field.value != formState.newPassword.value
                    ? 'Passwords do not match'
                    : undefined
                : undefined

        setFormState((prev) => ({
          ...prev,
          [fieldKey]: { value: field.value, invalidMessage }
        }))
      }

      return (
        <Card_C style={{ gap: 20 }}>
          <Heading_C styleType="h1" style={{}}>
            Reset Password
          </Heading_C>
          <Text_C style={{ fontStyle: 'italic', fontSize: 10 }}>
            Password requires at least 6 characters, a number, capital letter and symbol
          </Text_C>
          <View style={{ maxWidth: 400, gap: 10 }}>
            {!resetToken && (
              <FormField_C
                {...{
                  formField: formState.currentPassword,
                  setFormField: (field) => setFormField('currentPassword', field),
                  submitAttempted
                }}
                isPassword
              />
            )}
            <FormField_C
              {...{
                formField: formState.newPassword,
                setFormField: (field) => setFormField('newPassword', field),
                submitAttempted
              }}
              isPassword
            />
            <FormField_C
              {...{
                formField: formState.confirmNewPassword,
                setFormField: (field) => setFormField('confirmNewPassword', field),
                submitAttempted,
                onEnterPress: resetToken ? handleResetPassword : handleChangePassword
              }}
              isPassword
            />
            <View style={styles.buttonsContainer}>
              <Button_C
                styleType="fillButton"
                onPress={resetToken ? handleResetPassword : handleChangePassword}
              >
                Submit
              </Button_C>
            </View>
          </View>
        </Card_C>
      )

      function resetForm() {
        setFormState(blankForm)
        setSubmitAttempted(false)
      }

      function handleChangePassword() {
        const { currentPassword, newPassword, confirmNewPassword } = formState

        setSubmitAttempted(true)
        if (
          currentPassword.invalidMessage ||
          newPassword.invalidMessage ||
          confirmNewPassword.invalidMessage
        ) {
          return
        }
        changePassword({
          currentPassword: currentPassword.value,
          newPassword: newPassword.value,
          authToken: authToken!
        })
          .then(() => {
            addToast({
              message: 'Password successfully updated!',
              status: 'success'
            })
          })
          .catch(() => {
            addToast({
              message:
                'Failed to update password. Please make sure your details are correct and try again.',
              status: 'fail'
            })
          })
          .finally(resetForm)
      }
      function handleResetPassword() {
        const { newPassword, confirmNewPassword } = formState
        setSubmitAttempted(true)

        if (newPassword.invalidMessage || confirmNewPassword.invalidMessage) {
          return
        }
        resetPassword({
          password: newPassword.value,
          resetToken: resetToken!
        })
          .then(({ authToken }) => {
            setAuthToken(authToken)
            setStorageData('auth-token', authToken)
            addToast({
              message: 'Password successfully reset!',
              status: 'success'
            })
          })
          .catch(() => {
            addToast({
              message:
                'Failed to reset password. Please make sure your details are correct and try again.',
              status: 'fail'
            })
          })
          .finally(resetForm)
      }
    }
  })
}

const breakpointStyles = createStyles({
  buttonsContainer: {
    base: {
      gap: 10,
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  }
})
