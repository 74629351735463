import { useState } from 'react'
import { Image, ImageStyle, View } from 'react-native'
import defaultSilk from '../../../assets/images/default-silk.jpg'
import { environment } from '../../../services/data/api-environment'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'
import { SilkObj } from '../../../services/data/data-types/general-data-types.type'

type Props = {
  silk?: SilkObj
  style?: NamedBreakpointStylesExtension<typeof breakpointStyles>
}

export function SilkImage_C(props: Props) {
  const [hasErrored, setHasErrored] = useState(!props.silk)
  const styles = useBreakpointStyles({
    styles: breakpointStyles,
    additionalStyles: [props.style]
  })

  let d, p
  if (props.silk) {
    d = props.silk.description
    p = props.silk.imagePath
  }
  return (
    <View style={styles.cropContainer}>
      {/* silk image comes with top white-space that throws out the styles, this strategy is to counter to top white-space. if images are updated to not have this white-space, this this fix should be removed */}
      <Image
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        accessibilityLabel={d}
        resizeMode="contain"
        style={styles.silkImage as ImageStyle}
        source={
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          !p || hasErrored
            ? defaultSilk
            : {
                uri: `${environment.apiUrl}/${p}`
              }
        }
        onError={() => {
          setHasErrored(true)
        }}
      />
    </View>
  )
}

const breakpointStyles = createStyles({
  cropContainer: {
    overflow: 'hidden',
    width: 50,
    aspectRatio: 1.1
  },
  silkImage: {
    aspectRatio: 0.838,
    flex: 1,
    width: '100%',
    position: 'absolute',
    top: '-32%'
  }
})
