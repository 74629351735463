import { useContext, useEffect, useState } from 'react'
import { Image, ImageSourcePropType, ImageStyle, View } from 'react-native'
import { GlobalContext } from '../../../../../../global-context'
import { desaturateColor } from '../../../../../../helper-functions'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { Button_C } from '../../../../../Base/Button/Button'
import { cameraIcon } from '../../../../../Base/Icon/preset-icon-props'
import { PressableStyleExtension, Pressable_C } from '../../../../../Base/Pressable/Pressable'
import { Text_C } from '../../../../../Base/Text/Text'

export type PhotoFinishType = 'image' | 'icon'

type Props = {
  imageSource: string
  type: PhotoFinishType
  isFullWidth?: boolean
}

export function PhotoFinish_C({ imageSource, type, isFullWidth }: Props) {
  const { openModal } = useContext(GlobalContext)
  const [imageAspectRatio, setImageAspectRatio] = useState(0)
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  useEffect(() => {
    Image.getSize(imageSource, (width, height) => {
      const aspectRatio = width / height

      setImageAspectRatio(aspectRatio)
    })
  }, [imageSource])

  const image = (
    <Pressable_C
      style={{
        elem: {
          ...styles.photoFinishImageContainer,
          maxWidth: isFullWidth ? '100%' : '72.7%'
        }
      }}
      onPress={showPhotoFinish}
    >
      <Image
        style={
          {
            ...styles.photoFinishImage,
            aspectRatio: imageAspectRatio
          } as ImageStyle
        }
        resizeMode={'cover'}
        source={imageSource as ImageSourcePropType}
        // eslint-disable-next-line no-console
        onError={(err) => console.warn(err)}
      />
    </Pressable_C>
  )
  const modalImage = (
    <View
      style={{
        ...styles.photoFinishImageContainer,
        maxWidth: '100%'
      }}
    >
      <Image
        style={
          {
            ...styles.modalPhotoFinishImage,
            aspectRatio: imageAspectRatio
          } as ImageStyle
        }
        resizeMode={'cover'}
        source={imageSource as ImageSourcePropType}
      />
    </View>
  )

  const icon = (
    <View style={styles.photoButtonContainer}>
      <Button_C
        styleType="iconOnly"
        style={photoButton}
        onPress={() => showPhotoFinish()}
        icon={cameraIcon}
      >
        <Text_C>Photo Finish</Text_C>
      </Button_C>
    </View>
  )

  return type == 'icon' ? icon : image

  function showPhotoFinish(): void {
    openModal(modalImage, {
      modalStyle: {
        modalContent: { paddingHorizontal: 0, paddingVertical: 0 },
        modalInnerContainer: {
          maxWidth: 1400,
          width: '90%'
        }
      }
    })
  }
}

const breakpointStyles = createStyles({
  photoButtonContainer: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center'
  },
  photoFinishImageContainer: {
    overflow: 'hidden',
    borderWidth: 2,
    borderColor: presets.border,
    borderRadius: 4,
    alignItems: 'flex-end'
  },
  photoFinishImage: {
    height: '100%'
  },
  modalPhotoFinishImage: {
    width: '100%'
  }
})

const photoButton: PressableStyleExtension = {
  elem: {
    base: {
      flexDirection: 'row',
      gap: 10,
      alignItems: 'center'
    }
  },
  text: {
    base: {
      ...textSizes.size1,
      textTransform: 'uppercase',
      fontWeight: '600SemiBold',
      color: presets.primary
    },
    hovered: { color: desaturateColor(presets.primary, 0.5) }
  }
}
