/* eslint-disable no-console */
import { DrawerActions, useNavigation } from '@react-navigation/native'
import { useContext } from 'react'
import { View } from 'react-native'

import { GlobalContext } from '../../../../global-context'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../../styles/colors'
import { Button_C } from '../../../Base/Button/Button'

import { desaturateColor } from '../../../../helper-functions'
import { accountIcon, menuIcon, searchIcon } from '../../../Base/Icon/preset-icon-props'
import { PressableStyleExtension } from '../../../Base/Pressable/Pressable'
import { LoginRegister_C } from '../../LoginSignup/LoginSignup'

export function HeaderIcons_C() {
  const { openModal, authToken, featuresToggle } = useContext(GlobalContext)

  const navigation = useNavigation()

  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const isMobileLayout = breakpointBelow('large')

  const handleProfileClick = () => {
    if (!authToken) {
      openModal(<LoginRegister_C />, {
        modalStyle: {
          modalContent: { paddingVertical: 0, paddingHorizontal: 0 }
        }
      })
    } else {
      navigation.navigate('UserProfile', { activeTab: 'general-info' })
    }
  }

  return (
    <View style={styles.container}>
      <Button_C
        styleType="iconOnly"
        onPress={() => navigation.navigate('Search')}
        icon={searchIcon}
        style={iconButton}
      />
      {featuresToggle?.userProfile.active && (
        <Button_C
          styleType="iconOnly"
          active={!!authToken}
          style={iconButton}
          icon={accountIcon}
          onPress={handleProfileClick}
        />
      )}

      {isMobileLayout && (
        <Button_C
          styleType="iconOnly"
          onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
          icon={menuIcon}
          style={iconButton}
        />
      )}
    </View>
  )
}

const breakpointStyles = createStyles({
  container: {
    base: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: 10
    }
  }
})

const iconButton: PressableStyleExtension = {
  text: {
    base: { color: 'white' },
    active: { color: presets.primary },
    hovered: { color: desaturateColor(presets.primary, 0.5) }
  }
}
