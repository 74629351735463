import { merge } from 'lodash'
import { loopObject } from '../../../helper-functions'
import {
  BreakpointName,
  BreakpointStyles,
  GetStyleType,
  StatefulBreakpointStyles,
  StyleType
} from './breakpoint-style.type'
import { breakpointNames } from './style-constants'

export function flattenStyles<StateNames extends string>(
  activeBreakpoint: BreakpointName,
  allStyles: Array<StyleType | Partial<BreakpointStyles> | StatefulBreakpointStyles<StateNames>>,
  state?: Record<StateNames, boolean>
) {
  const flattenedStyles: StyleType = {}

  // flatten bp styles before state styles
  allStyles.forEach((styleObj) => {
    if (isBpStyle(styleObj)) {
      const bps: Partial<BreakpointStyles> | StatefulBreakpointStyles<StateNames> = styleObj

      return loopObject(bps, (key: BreakpointName | StateNames, style) => {
        if (!isStateStyle(key)) {
          const breakpointName = key
          if (
            breakpointNames.indexOf(breakpointName) <= breakpointNames.indexOf(activeBreakpoint)
          ) {
            merge(flattenedStyles, style)
          }
        }
      })
    } else {
      const styles = styleObj as StyleType[]
      merge(flattenedStyles, styles)
    }
  })

  // flatten state styles after bp styles
  allStyles.forEach((styleObj) => {
    if (isBpStyle(styleObj)) {
      const bps: Partial<BreakpointStyles> | StatefulBreakpointStyles<StateNames> = styleObj

      return loopObject(bps, (key: BreakpointName | StateNames, style) => {
        if (isStateStyle(key)) {
          //typeguard infers type of name in if/else block
          const stateName: StateNames = key
          const stateIsActive = state?.[stateName]
          if (stateIsActive) {
            merge(flattenedStyles, style)
          }
        }
      })
    }
  })

  type ThisStyleType = GetStyleType<typeof flattenedStyles>

  return flattenedStyles as ThisStyleType

  function isBpStyle(
    styleObj: StyleType | Partial<BreakpointStyles> | StatefulBreakpointStyles<StateNames>
  ): styleObj is Partial<BreakpointStyles> | StatefulBreakpointStyles<StateNames> {
    /* if any bpname or state name is the key for styleobj or any of the additional styles, then its nota  style type  */

    const stateNames = state && Object.keys(state)

    const bpNames: string[] = [...breakpointNames]

    for (const key in styleObj) {
      if (bpNames.includes(key) || (stateNames && stateNames.includes(key))) {
        return true
      }
    }
    return false
  }

  function isStateStyle(name: BreakpointName | StateNames): name is StateNames {
    return !!state && name in state
  }
}
