import { Asset } from '../../../types/assets.type'
import { BaseFetchResponse, ResponseAsset } from '../../../types/fetch-response-base.type'
import { fetchData } from '../api-fetch-request'
import { ResponseNewsArticle } from '../data-types/fetch-response-types/news-list-response.type'
import { NewsArticle } from '../data-types/local-data-types/news.type'

export type ResponseCarouselItem = ResponseNewsArticle

export type ResponseContentSection = {
  content_type: string
  content_subtype: string
  asset: ResponseAsset
  title: string
  description: string
  action_title: string
  action_url: string
}

export type ContentSection = {
  contentType: string
  contentSubtype: string
  asset?: Asset
  title: string
  description: string
  actionTitle: string
  actionUrl: string
}

export type SitePageFetchResponse = BaseFetchResponse & {
  page: ResponseSitePage
}

export type ResponseSitePage = {
  slug: string
  title: string
  content: string
  meta_title: string
  meta_description: string
  meta_keywords: string
  published: string
  updated: string
  carousel_items: ResponseCarouselItem[] | null
  content_sections: ResponseContentSection[] | null
}

export type SitePageResponse = {
  page: SitePage
}

export type SitePage = {
  slug: string
  title: string
  content: string
  metaTitle: string
  metaDescription: string
  metaKeywords: string
  published: string
  updated: string
  carouselItems: NewsArticle[] | null
  contentSections: ContentSection[] | null
}

type FetchPageParams = { pageSlug: string }

export async function fetchSitePage({ pageSlug }: FetchPageParams) {
  const endpoint = `site/pages?slug=${pageSlug}`
  return fetchData<SitePageResponse, SitePageFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(payload: SitePageFetchResponse): SitePageResponse {
    const { page } = payload
    return {
      page: {
        slug: page.slug,
        title: page.title,
        content: page.content,
        metaTitle: page.meta_title,
        metaDescription: page.meta_description,
        metaKeywords: page.meta_keywords,
        published: page.published,
        updated: page.updated,
        carouselItems: null,
        contentSections: null
      }
    }
  }
}
