import { useNavigation } from '@react-navigation/native'
import { isAfter } from 'date-fns'
import { useContext, useMemo } from 'react'
import { View } from 'react-native'
import { getDateNow } from '../../../../helper-functions'
import { RaceStatus } from '../../../../services/data/data-types/general-data-types.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../../services/styles/dependencies/breakpoint-style.type'
import { colors, presets } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { savePageInHistory } from '../../../../utils/save-history'
import { Button_C } from '../../../Base/Button/Button'
import { Link_C } from '../../../Base/Link/Link'
import { PressableStyleExtension } from '../../../Base/Pressable/Pressable'
import { ScrollHorizontal_C } from '../../../Base/ScrollHorizontal/ScrollHorizontal'
import { Text_C } from '../../../Base/Text/Text'
import { MeetingContext } from '../Meeting'

type Props = {
  style?: NamedBreakpointStylesExtension<typeof breakpointStyles>
}

export function RaceTabs_C(props: Props) {
  const { activeMeeting, params, selectedRaceNumber, activeMeetingBasic } =
    useContext(MeetingContext)

  const styles = useBreakpointStyles({
    styles: breakpointStyles,
    additionalStyles: [props.style]
  })
  const navigation = useNavigation()

  return useMemo(() => {
    return (
      <ScrollHorizontal_C contentContainerStyle={styles.raceTabsContainer} style={{}}>
        <Link_C
          navigateTo={[
            'Meeting',
            {
              trackSlug: params.trackSlug,
              date: params.date,
              dayPhaseLetter: params.dayPhaseLetter,
              type: params.type
            }
          ]}
          style={allRacesLinkTab}
          active={!selectedRaceNumber}
          accessibilityState={{ selected: !selectedRaceNumber }}
        >
          <Text_C selectable={false} style={styles.fieldsText}>
            All Races
          </Text_C>
          <Text_C selectable={false} style={styles.allRacesText}>
            {activeMeetingBasic?.races.length} races
          </Text_C>
        </Link_C>

        <View style={styles.raceTabs}>
          {activeMeetingBasic?.races.map((race) => {
            const isSelected =
              selectedRaceNumber !== undefined && race.raceNumber === selectedRaceNumber

            const raceIsOff = (['Abandoned', 'No Race'] as RaceStatus[]).includes(race.status)

            const startedNoResults = !race.resultPlaces && isAfter(getDateNow(), race.startTime)

            const raceTabStyle: PressableStyleExtension = {
              elem: {
                base: {
                  ...(startedNoResults && !raceIsOff
                    ? { backgroundColor: presets.secondary }
                    : race.resultPlaces || raceIsOff
                      ? {
                          backgroundColor: colors.gray600
                        }
                      : {})
                },
                active: {
                  backgroundColor: 'black'
                }
              },
              text: {
                base: {
                  ...(startedNoResults || race.resultPlaces ? { color: 'white' } : {})
                },
                active: {
                  backgroundColor: 'black'
                }
              }
            }

            function navigateToRace() {
              savePageInHistory()
              navigation.navigate('Meeting', {
                trackSlug: params.trackSlug,
                date: params.date,
                raceNumber: `${race.raceNumber}`,
                dayPhaseLetter: params.dayPhaseLetter,
                type: params.type
              })
            }

            return (
              <View key={race.raceNumber} style={{ gap: 2 }}>
                <Button_C
                  styleType="raceTab"
                  onPress={navigateToRace}
                  active={isSelected}
                  accessibilityState={{ selected: isSelected }}
                  style={raceTabStyle}
                >
                  {`R${race.raceNumber}`}
                </Button_C>
                {race.resultPlaces && (
                  <Text_C
                    style={{
                      fontWeight: '700Bold',
                      letterSpacing: -0.5,
                      alignSelf: 'center'
                    }}
                  >
                    {race.resultPlaces}
                  </Text_C>
                )}
                {raceIsOff && (
                  <Text_C
                    style={{
                      fontSize: 10,
                      fontStyle: 'italic',
                      letterSpacing: -0.5,
                      alignSelf: 'center',
                      fontWeight: '600SemiBold'
                    }}
                  >
                    {race.status == 'Abandoned' ? 'Abnd' : 'No Race'}
                  </Text_C>
                )}
              </View>
            )
          })}
        </View>
      </ScrollHorizontal_C>
    )
  }, [activeMeeting, selectedRaceNumber, styles])
}

const breakpointStyles = createStyles({
  raceTabsContainer: {
    base: {
      flexDirection: 'row',
      columnGap: 18,
      alignItems: 'center'
    },
    medium: {
      marginLeft: 'auto'
    }
  },
  allRaces: {
    base: {}
  },
  allRacesText: {
    base: {
      color: colors.gray500,
      ...textSizes.size1
    }
  },
  fieldsText: {
    base: {
      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      color: colors.blue
    }
  },
  raceTabs: { base: { flexDirection: 'row', columnGap: 18 } }
})

const allRacesLinkTab: PressableStyleExtension = {
  elem: {
    base: {
      borderRadius: 4,
      borderWidth: 1,
      borderColor: colors.gray500,
      paddingHorizontal: 17,
      paddingVertical: 4,
      justifyContent: 'center',
      alignItems: 'center'
    },
    active: {
      borderColor: presets.primary
    }
  }
}
