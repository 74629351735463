import { TextStyle } from 'react-native'

const openSansFont = createFont<'OpenSans', OpenSansFontWeights>('OpenSans', [
  '300Light',
  '400Regular',
  '500Medium',
  '600SemiBold',
  '700Bold',
  '800ExtraBold'
])

export const fontsMap: Record<FontFamilyName, Font<FontFamilyName, FontWeights>> = {
  ...openSansFont
}

type FontStyleTypes = FontStyle<'OpenSans', OpenSansFontWeights>
// only 1 font atm 'OpenSans', if theres another, add it here as a union of the same type

export type TextStyle_T = Omit<TextStyle, 'fontFamily' | 'fontWeight'> & FontStyleTypes

export function convertFontWeightToFontFamily(style: TextStyle_T): TextStyle {
  const { fontFamily, fontWeight } = style
  const fontValue = fontFamily && fontWeight ? fontsMap[fontFamily].weights[fontWeight] : undefined

  return {
    ...style,
    fontFamily: fontValue,
    fontWeight: undefined
  }
}

function createFont<FN extends FontFamilyName, W extends FontWeights>(
  family: FN,
  fontWeightNames: W[]
) {
  return {
    [family as FontFamilyName]: {
      weights: fontWeightNames.reduce(
        (fontWeightObj, fontWeightName: W) => {
          return {
            ...fontWeightObj,
            [fontWeightName]: `${family}_${fontWeightName}`
          }
        },
        {} as FontWeight<FN, W>
      )
    }
  }
}

type FontFamilyName = 'OpenSans'

type FontWeights = string

type OpenSansFontWeights =
  | '300Light'
  | '400Regular'
  | '500Medium'
  | '600SemiBold'
  | '700Bold'
  | '800ExtraBold'

type Font<FN extends FontFamilyName, W extends FontWeights> = {
  weights: FontWeight<FN, W>
}

type FontWeight<FN extends FontFamilyName, W extends FontWeights> = Record<W, `${FN}_${W}`>

type FontStyle<N extends FontFamilyName, W extends FontWeights> = {
  fontFamily?: N
  fontWeight?: W
}
