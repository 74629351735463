import silk1Desktop from './silk-1-desktop.svg'
import silk2Desktop from './silk-2-desktop.svg'
import silk3Desktop from './silk-3-desktop.svg'
import silk4Desktop from './silk-4-desktop.svg'
import silk5Desktop from './silk-5-desktop.svg'
import silk6Desktop from './silk-6-desktop.svg'
import silk7Desktop from './silk-7-desktop.svg'
import silk8Desktop from './silk-8-desktop.svg'
import silk9Desktop from './silk-9-desktop.svg'
import silk10Desktop from './silk-10-desktop.svg'
import silk11Desktop from './silk-11-desktop.svg'
import silk12Desktop from './silk-12-desktop.svg'
import silk13Desktop from './silk-13-desktop.svg'
import silk14Desktop from './silk-14-desktop.svg'
import silk15Desktop from './silk-15-desktop.svg'

export default {
  silk1Desktop,
  silk2Desktop,
  silk3Desktop,
  silk4Desktop,
  silk5Desktop,
  silk6Desktop,
  silk7Desktop,
  silk8Desktop,
  silk9Desktop,
  silk10Desktop,
  silk11Desktop,
  silk12Desktop,
  silk13Desktop,
  silk14Desktop,
  silk15Desktop
}
