import { useNavigation } from '@react-navigation/native'
import { useContext, useMemo, useState } from 'react'
import {
  getUserInfo,
  logoutUser,
  UserInfoResponse
} from '../../../services/data/request-functions/user-request'
import { Button_C } from '../../Base/Button/Button'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { TabConfigItem, TabView_C } from '../../Layout/TabView/TabView'
import { GeneralInfo_C } from './components/GeneralInfo'

import { GlobalContext } from '../../../global-context'
import {
  getNotificationsCount,
  NotificationsCountResponse
} from '../../../services/data/request-functions/notifications-request'
import { savePageInHistory } from '../../../utils/save-history'
import { unsetStorageData } from '../../../utils/storage'
import { BlackBook_C } from './components/BlackBook/BlackBook'
import { NotificationPreferences_C } from './components/NotificationPreferences/NotificationPreferences'
import { Metadata } from '../../Partials/Metadata/Metadata'

export function UserProfile_C(): JSX.Element {
  return withScreenWrapper<'UserProfile', [UserInfoResponse, NotificationsCountResponse]>({
    requiresAuthentication: true,
    requestsSetup: (_params, authToken) => {
      return [
        getUserInfo({ authToken: authToken! }),
        getNotificationsCount({ authToken: authToken! })
      ]
    },
    view: ({ responses, params }) => {
      const [{ user }, { count: initialCount }] = responses!
      const { navigate } = useNavigation()
      const { authToken, setAuthToken } = useContext(GlobalContext)

      const [count] = useState(initialCount)

      // TODO: Have hidden for now by request from Anton. Uncomment when needed.
      // const notifications = useMemo(
      //   () => <Notifications_C onNotifiactionsUpdated={refetchNotificationsCount} />,
      //   []
      // )

      const tabs: TabConfigItem<string>[] = [
        ['General Info', <GeneralInfo_C key="general" {...{ user }} />],
        ['Blackbook', <BlackBook_C key="blackbook" />],
        ['Email Settings', <NotificationPreferences_C key="preferences" />]
      ]

      const tabView = useMemo(() => {
        return (
          <>
            <Metadata
              title={`${user.title} Profile`}
              description={`View and update profile information and email settings on harness.org.au`}
            />
            <TabView_C
              heading="User Profile"
              initialActiveTab={
                params.activeTab == 'blackbook'
                  ? 'Blackbook'
                  : params.activeTab == 'email-settings'
                    ? 'Email Settings'
                    : params.activeTab == 'notifications'
                      ? 'Notifications'
                      : 'General Info'
              }
              onTabSelected={(tabName) => {
                savePageInHistory()
                navigate('UserProfile', {
                  activeTab:
                    tabName == 'Blackbook'
                      ? 'blackbook'
                      : tabName == 'Email Settings'
                        ? 'email-settings'
                        : tabName == 'Notifications'
                          ? 'notifications'
                          : 'general-info'
                })
              }}
              tabs={tabs}
            >
              <Button_C styleType="fillButton" onPress={logout}>
                Logout
              </Button_C>
            </TabView_C>
          </>
        )
      }, [count])

      return tabView

      // async function refetchNotificationsCount() {
      //   getNotificationsCount({ authToken: authToken! }).then(({ count }) => setCount(count))
      // }

      function logout() {
        logoutUser({ authToken: authToken! })
        unsetStorageData('auth-token')
        setAuthToken(null)
        navigate('Home')
      }
    }
  })
}
