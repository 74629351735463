import { useContext, useMemo } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../global-context'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import {
  breakpointSizes,
  tabletBreakpoint
} from '../../../services/styles/dependencies/style-constants'
import { presets, stateColor } from '../../../styles/colors'
import { MainMenu_C } from '../MainMenu/MainMenu'
import { HeaderIcons_C } from './components/HeaderIcons'
import { HeaderLogo_C } from './components/HeaderLogo'
import { NextToGo_C } from './components/NextToGo/NextToGo'

export function Header_C() {
  const isMobileLayout = breakpointBelow(tabletBreakpoint)
  const { selectedState } = useContext(GlobalContext)

  const styles = useBreakpointStyles({ styles: newStyleTest })

  const mobileLayout = (
    <View style={[styles.mobileContainer, styles.container]}>
      <HeaderLogo_C />
      <HeaderIcons_C />
    </View>
  )

  const nextToGo: JSX.Element = useMemo(() => {
    return <NextToGo_C />
  }, [])

  const desktopLayout = (
    <View style={styles.container}>
      <View style={[styles.nextToGoContainer, styles.desktopContainer]}>
        <View style={[styles.desktopContent]}>{nextToGo}</View>
      </View>

      <View
        style={[
          styles.menuContainer,
          selectedState && { backgroundColor: stateColor[selectedState] }
        ]}
      >
        <View style={styles.desktopContent}>
          <HeaderLogo_C />
          <MainMenu_C />
          <HeaderIcons_C />
        </View>
      </View>
    </View>
  )

  return isMobileLayout ? mobileLayout : desktopLayout
}

const newStyleTest = createStyles({
  container: {
    position: 'relative',
    zIndex: 10
  },
  mobileContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 55,
    backgroundColor: presets.headerFooter,
    paddingHorizontal: 20
  },
  desktopContainer: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  desktopContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: breakpointSizes.xxlarge,
    paddingHorizontal: 32
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 65,
    backgroundColor: presets.headerFooter
  },
  nextToGoContainer: {
    height: 75,
    backgroundColor: 'white'
  }
})
