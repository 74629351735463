/* eslint-disable react/jsx-key */
import { View } from 'react-native'
import { formatCurrency } from '../../../../helper-functions'
import { ProgramRace } from '../../../../services/data/data-types/local-data-types/program-search'
import { breakpointBelow } from '../../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../../styles/text-sizes'
import { humanFriendlyDateTime } from '../../../../utils/date-time'
import { TableStyles, Table_C } from '../../../Base/Table/Table'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { TableCellRaceLink_C } from '../../../Partials/_meeting-components/TableCellRaceLink'
type Props = { matchingRaces: ProgramRace[] }
export function ProgramSearchResults_C({ matchingRaces }: Props) {
  const isMobileLayout = breakpointBelow(tabletBreakpoint)

  return (
    <Card_C>
      <Table_C
        styleType="lightHeaderBorders"
        styles={tableStyles}
        headings={[
          { content: 'Meeting', colWidth: { width: 150 } },
          { content: 'Race Code', colWidth: { flex: 4 } },
          { content: 'Start', colWidth: { width: 72 } },
          { content: 'Name', colWidth: { flex: 2 } },
          { content: 'Prize Money', colWidth: { width: 104 } },
          { content: 'Class', colWidth: { width: 108 } },
          { content: 'Gait', colWidth: { width: 80 } },
          { content: 'Noms Close', colWidth: { width: 110 } },
          { content: 'Metres Draw', colWidth: { width: 120 } }
        ]}
        rows={matchingRaces.map((race) => {
          const {
            meeting,
            track,
            raceCode,
            notes,
            startType,
            shortName,
            name,
            totalPrizeMoney,
            distance,
            class: raceClass,
            gait,
            barrierDrawType
          } = race

          const nomsCloseDate =
            meeting.nomsCloseDate && humanFriendlyDateTime(meeting.nomsCloseDate)

          return {
            cells: [
              <TableCellRaceLink_C
                {...{
                  newTab: true,
                  date: meeting.date,
                  dayPhaseLetter: meeting.dayPhaseLetter,
                  hasReplay: false,
                  isTrial: meeting.isTrial,
                  raceNumber: undefined,
                  trackName: track.shortName,
                  trackSlug: track.slug,
                  meetingStatus: meeting.status
                }}
              />,

              <View>
                <Text_C
                  style={{
                    fontWeight: '600SemiBold',
                    marginBottom: 8,
                    ...textSizes.size1
                  }}
                >
                  {raceCode}
                </Text_C>
                <Text_C
                  style={{
                    ...textSizes.size1
                  }}
                >
                  {notes && `Other Conditions: ${notes}`}
                </Text_C>
              </View>,

              `${startType == 'Mobile Start' ? 'Mobile' : 'Standing'}`,

              `${(isMobileLayout && shortName) || name}`,

              `${formatCurrency(totalPrizeMoney)}`,

              `${raceClass}`,

              `${gait}`,

              nomsCloseDate,

              `${distance}\n${barrierDrawType}`
            ]
          }
        })}
      />
    </Card_C>
  )
}

const tableStyles: TableStyles = {
  table: { width: '100%' },
  tableContainer: { width: '100%', minWidth: 980 },
  cell: { justifyContent: 'flex-start', gap: 6 },
  cellText: { lineHeight: 18 }
}
