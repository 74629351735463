import { View, ViewStyle } from 'react-native'

import { createStyles } from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
type Props = {
  percent: number
  styles?: ViewStyle
}
export function ProgressBar_C(props: Props) {
  return (
    <View style={[styles.container, props.styles]}>
      <View style={[styles.progressBar, { width: `${props.percent}%` }]} />
    </View>
  )
}

const styles = createStyles({
  container: {
    backgroundColor: colors.gray200,
    borderRadius: 6,
    overflow: 'hidden',
    flex: 1,
    height: 13
  },
  progressBar: {
    backgroundColor: presets.primary,
    borderRadius: 6,
    height: '100%'
  }
})
