import { differenceInMilliseconds } from 'date-fns'
import { useContext, useEffect, useRef } from 'react'
import { Animated } from 'react-native'
import { GlobalContext } from '../../../global-context'
import { desaturateColor } from '../../../helper-functions'
import { colors } from '../../../styles/colors'
import { Icon_C } from '../Icon/Icon'
import { infoLetterIcon } from '../Icon/preset-icon-props'
import { Text_C } from '../Text/Text'
import { Toast } from './toast.type'

const animationDuration = 300

export function Toast_C(toast: Toast) {
  const { expiryDate, status } = toast
  const { removeToast } = useContext(GlobalContext)

  const toastOpacityValueRef = useRef(new Animated.Value(0))
  const relativeTopValueRef = useRef(new Animated.Value(20))

  const fadeIn = Animated.timing(toastOpacityValueRef.current, {
    toValue: 1,
    duration: animationDuration,
    useNativeDriver: false
  })
  const fadeOut = Animated.timing(toastOpacityValueRef.current, {
    toValue: 0,
    duration: animationDuration,
    useNativeDriver: false
  })

  const riseUp = Animated.timing(relativeTopValueRef.current, {
    toValue: 0,
    duration: animationDuration,
    useNativeDriver: false
  })
  useEffect(() => {
    const millisecondsUntilExpiry = differenceInMilliseconds(expiryDate, new Date())
    fadeIn.start()
    riseUp.start()
    setTimeout(() => {
      fadeOut.start(() => removeToast(toast))
    }, millisecondsUntilExpiry)
  }, [])

  return (
    <Animated.View
      style={{
        borderRadius: 4,
        overflow: 'hidden',
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 8,
        backgroundColor:
          status == 'success'
            ? desaturateColor(colors.green, 3)
            : status == 'fail'
              ? desaturateColor(colors.red, 1.5)
              : 'white',
        width: 300,
        padding: 12,
        opacity: toastOpacityValueRef.current,
        top: relativeTopValueRef.current,
        flexDirection: 'row',
        gap: 12,
        alignItems: 'flex-start'
      }}
    >
      <Icon_C {...infoLetterIcon} />
      <Text_C>{toast.message}</Text_C>
    </Animated.View>
  )
}
