import { removeLeadingSlash } from '../../../helper-functions'
import { fetchData } from '../api-fetch-request'
import { MeetingProgramFetchResponse } from '../data-types/fetch-response-types/meeting-program-response.type'
import {
  DayPhaseLetter,
  meetingStatusMap,
  raceStatusMap
} from '../data-types/general-data-types.type'
import { MeetingProgram } from '../data-types/local-data-types/meeting-program.type'
import { meetingProgramCompare } from '../validation-compare-objects/meeting-program-compare'

export type MeetingProgramResponse = {
  meetingProgram: MeetingProgram
}

type GetMeetingProgramParams = {
  trackSlug: string
  date: string
  dayPhaseLetter: DayPhaseLetter
  trial?: boolean
}
export async function getMeetingProgramData({
  trackSlug,
  date,
  dayPhaseLetter,
  trial
}: GetMeetingProgramParams): Promise<MeetingProgramResponse> {
  const endpoint = `racing/calendar/meeting-program/${trackSlug}/${date}/${dayPhaseLetter}${trial ? `?trial=true` : ''}`

  return fetchData<MeetingProgramResponse, MeetingProgramFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: meetingProgramCompare
  })

  function convertResponse(
    meetingProgramPayload: MeetingProgramFetchResponse
  ): MeetingProgramResponse {
    const {
      meeting: {
        date,
        day_night_twilight,
        meeting_status,
        acceptances_time,
        fields_available_time,
        nominations_close_time
      },
      track: { desktop_display_name, mobile_display_name, slug },
      races
    } = meetingProgramPayload

    const meetingProgramResponse: MeetingProgramResponse = {
      meetingProgram: {
        meeting: {
          date: new Date(date),
          dayPhaseLetter: day_night_twilight,
          status: meetingStatusMap[meeting_status],
          acceptancesDate: new Date(acceptances_time),
          fieldsAvailableDate: new Date(fields_available_time),
          nomsCloseDate: new Date(nominations_close_time)
        },
        track: {
          name: desktop_display_name,
          shortName: mobile_display_name,
          slug: removeLeadingSlash(slug)
        },
        races: races.map((r) => {
          const {
            distance,
            name,
            race_barrier_draw_type,
            race_class,
            race_notes,
            short_name,
            start_type,
            total_prize_money,
            rise_race_code,
            race_status
          } = r
          return {
            name: name ?? undefined,
            startType: start_type,
            shortName: short_name,
            raceCode: rise_race_code,
            totalPrizeMoney: total_prize_money,
            class: race_class,
            distance,
            barrierDrawType: race_barrier_draw_type,
            notes: race_notes ?? undefined,
            status: raceStatusMap[race_status]
          }
        })
      }
    }

    return meetingProgramResponse
  }
}
