/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { RouteProp, useRoute } from '@react-navigation/native'
import { RefObject, useCallback, useContext, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { GlobalContext } from '../../../global-context'
import { desaturateColor } from '../../../helper-functions'
import { ParamList, ScreenName } from '../../../services/routes/screens'
import { colors, presets } from '../../../styles/colors'
import { Button_C } from '../../Base/Button/Button'

export type CalendarPicker = {
  parentElemRef: RefObject<any>
  screen: ScreenName
  maxDate?: Date
  minDate?: Date
  onDateSelected(selectedDate: Date): void
  selectedDate: Date
}

type Props = {
  maxDate?: Date
  minDate?: Date
  onDateSelected(selectedDate: Date): void
  selectedDate: Date
}

export function CalendarPickerIcon_C({ selectedDate, onDateSelected, maxDate, minDate }: Props) {
  const { activeCalendar, setActiveCalendar } = useContext(GlobalContext)

  const calendarPickerIconRef = useRef<any>(null)
  const route = useRoute<RouteProp<ParamList>>()

  const buttonIsActive = activeCalendar?.parentElemRef.current == calendarPickerIconRef.current

  const onPress = useCallback(toggleDatePicker, [activeCalendar, buttonIsActive])

  return (
    <View style={styles.container} ref={calendarPickerIconRef}>
      <Button_C
        active={buttonIsActive}
        onPress={onPress}
        styleType="iconOnly"
        style={{
          text: {
            base: { color: presets.primary },
            active: { color: desaturateColor(presets.primary, 1) },
            hovered: { color: desaturateColor(presets.primary, 0.5) }
          }
        }}
        icon={{
          name: 'calendar2',
          style: { height: 30 },
          useParentTextColor: true
        }}
      />
    </View>
  )
  function toggleDatePicker() {
    if (activeCalendar) {
      setActiveCalendar(undefined)
    } else {
      setActiveCalendar({
        parentElemRef: calendarPickerIconRef,
        selectedDate,
        maxDate,
        minDate,
        onDateSelected,
        screen: route.name
      })
    }
  }
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    position: 'relative'
  },
  calendar: {
    boxShadow: `5px 5px 10px ${colors.gray300}`,
    borderRadius: 5,
    position: 'absolute',
    backgroundColor: 'white',
    right: 0,
    top: 4,
    width: 320,
    padding: 6
  }
})
