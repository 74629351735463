/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-unused-vars */
import { ReactNode } from 'react'
import { Pressable, ScrollView, View } from 'react-native'
import { Cell, Table, TableWrapper } from 'react-native-table-component'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { colors, presets } from '../../../styles/colors'
import { convertFontWeightToFontFamily } from '../../../styles/fonts-map'
import { textSizes } from '../../../styles/text-sizes'
import { Text_C } from '../Text/Text'
import { TableCell, TableHeading, TableRow } from './table.type'

/*
  - column widths can not be auto to the content, they are flex 1 unless specified in headings
*/

type Props = {
  headings: TableHeading[]
  styleType?: StyleTypes
  rows: TableRow[]
  onRowSelected?(rowIndex: number): void
  expandedIndex?: number
  styles?: TableStyles
}

export function Table_C(props: Props) {
  const styles = useBreakpointStyles({
    styles: baseStyles,
    additionalStyles: [props.styleType && styleTypes[props.styleType], props.styles]
  })

  const tableHeadRow = (
    <TableWrapper style={styles.header}>
      {props.headings.map((heading, cellIndex) => {
        const headingIsObject = heading && typeof heading == 'object' && 'content' in heading
        const { headingContent, headingObj } = headingIsObject
          ? {
              headingContent: heading.content,
              headingObj: heading
            }
          : { headingContent: heading, headingObj: undefined }

        const isEmptyFirstHeading = cellIndex == 0 && !headingContent

        const colWidth = headingObj?.colWidth
        return (
          <Cell
            key={cellIndex}
            data={headingContent}
            textStyle={convertFontWeightToFontFamily({
              ...styles.headingText,
              ...headingObj?.textStyle
            })}
            style={{
              ...(isEmptyFirstHeading
                ? {}
                : {
                    ...styles.cell,
                    ...styles.heading
                  }),
              ...headingObj?.style,
              ...(colWidth?.width
                ? { width: colWidth.width, flex: 0, flexBasis: 'auto' }
                : colWidth?.flex
                  ? { flex: colWidth.flex }
                  : {})
            }}
          />
        )
      })}
    </TableWrapper>
  )

  const tableRows = props.rows.flatMap((row, rowIndex) => {
    const rowContent = (
      <>
        <View style={styles.row}>
          {row.cells.map((cell, cellIndex) => {
            let cellContent: ReactNode
            let cellObj: TableCell | null = null

            if (cell && typeof cell == 'object' && 'content' in cell) {
              cellObj = cell
              cellContent = cell.content
            } else cellContent = cell as ReactNode

            const cellIsObject = !!cell && typeof cell == 'object' && 'content' in cell
            cellIsObject
              ? { cellContent: cell.content, cellObj: cell }
              : { cellContent: cell, cellObj: undefined }

            const cellHeading = props.headings[cellIndex]

            const headingIsObject =
              cellHeading && typeof cellHeading == 'object' && 'content' in cellHeading
            const colWidth =
              headingIsObject && typeof cellHeading != 'string' ? cellHeading.colWidth : undefined

            return (
              <Cell
                key={cellIndex}
                data={cellContent}
                style={{
                  ...styles.cell,
                  ...cellObj?.style,
                  ...(colWidth?.width
                    ? {
                        width: colWidth.width,
                        flex: 0,
                        flexBasis: 'auto'
                      }
                    : colWidth?.flex
                      ? { flex: colWidth.flex }
                      : {})
                }}
                textStyle={convertFontWeightToFontFamily({
                  ...styles.cellText,
                  ...cellObj?.textStyle
                })}
              />
            )
          })}
        </View>
        {row.underRowContent && (
          <View style={{ ...styles.underRow }}>
            <Cell
              data={row.underRowContent}
              textStyle={convertFontWeightToFontFamily(styles.cellText)}
              style={{ flex: 1 }}
            />
          </View>
        )}
      </>
    )
    const rowElem = row.onSelected ? (
      <Pressable key={rowIndex} onPress={row.onSelected}>
        {rowContent}
      </Pressable>
    ) : (
      <View key={rowIndex}>{rowContent}</View>
    )

    return rowElem
  })

  return (
    /* propsValidation() ?? */ <ScrollView horizontal contentContainerStyle={styles.tableContainer}>
      <Table style={styles.table} borderStyle={styles.tableBorder}>
        {tableHeadRow}
        {tableRows}
      </Table>
    </ScrollView>
  )
}

type StyleTypes = 'darkHeader' | 'lightHeaderBorders'
export type TableStyles = NamedBreakpointStylesExtension<typeof baseStyles>

const baseStyles = createStyles({
  tableContainer: { minWidth: '100%' },
  table: {
    minWidth: '100%'
  },
  tableBorder: {
    base: {}
  },
  header: {
    flexDirection: 'row'
  },
  row: {
    flexDirection: 'row'
  },
  heading: {
    base: {}
  },
  headingText: {
    base: {
      ...textSizes.size1,

      fontFamily: 'OpenSans',
      fontWeight: '700Bold'
    },
    [desktopBreakpoint]: {
      ...textSizes.size2
    }
  },
  cell: {
    minWidth: 50,
    flex: 1,
    alignSelf: 'stretch',
    paddingHorizontal: 10,
    paddingVertical: 8
  },
  cellText: {
    ...textSizes.size1,
    fontFamily: 'OpenSans',
    fontWeight: '400Regular'
  },
  underRow: {
    width: '100%'
  }
})

export const tableStyles = baseStyles

export const darkHeader: TableStyles = createStyles({
  heading: {
    base: {
      backgroundColor: presets.headerFooter
    }
  },
  headingText: {
    base: {
      color: colors.white
    }
  },
  row: {
    base: {
      borderTopColor: colors.gray300,
      borderTopWidth: 2
    }
  }
})

const lightHeaderBorders: TableStyles = createStyles({
  header: {
    base: {
      borderTopWidth: 1,
      borderTopColor: presets.border,
      borderRightWidth: 1,
      borderRightColor: presets.border
    }
  },
  row: {
    base: {
      borderRightWidth: 1,
      borderRightColor: presets.border
    }
  },
  cell: {
    base: {
      borderBottomWidth: 1,
      borderBottomColor: presets.border,
      borderLeftWidth: 1,
      borderLeftColor: presets.border
    }
  },
  expandedContent: {
    base: {
      borderBottomWidth: 1,
      borderBottomColor: presets.border
    }
  }
})

const styleTypes: {
  [styleType in StyleTypes]: TableStyles
} = {
  darkHeader,
  lightHeaderBorders
}
