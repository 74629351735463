import { useNavigation } from '@react-navigation/native'
import { orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import { View } from 'react-native'
import { australianStates } from '../../../../constants'
import { removeLeadingSlash } from '../../../../helper-functions'
import { VideosListResponse } from '../../../../services/data/request-functions/videos-list-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { colors } from '../../../../styles/colors'
import { AustralianState } from '../../../../types/map.type'
import {
  humanFriendlyDate,
  humanFriendlyLongDate,
  humanFriendlyTime,
  shortDate
} from '../../../../utils/date-time'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Link_C } from '../../../Base/Link/Link'
import { ScrollHorizontal_C } from '../../../Base/ScrollHorizontal/ScrollHorizontal'
import { Select_C } from '../../../Base/Select/Select'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { VideoItem_C } from '../../../Partials/_video-components/VideoItem'
import { CalendarPickerIcon_C } from '../../../Partials/CalendarPicker/CalendarPickerIcon'

type Props = {
  videosListResponse: VideosListResponse
}

export function StewardsReplays_C({ videosListResponse }: Props) {
  const [selectedState, setSelectedState] = useState<AustralianState>()

  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const { navigate } = useNavigation()

  const { date, maxMeetingDate, meetings, minMeetingDate } = videosListResponse
  const { headerTools } = getElements()

  const filteredMeetings = meetings.filter((meeting) => {
    const replayMeetings = meeting.races.filter((race) => {
      return race.hasStewardsReplay
    })
    return replayMeetings.length > 0
  })

  return (
    <Card_C>
      <View style={styles.videoContainer}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 15,
            zIndex: 1
          }}
        >
          <View style={{ rowGap: 5, marginRight: 'auto' }}>
            <Heading_C styleType="h1">Stewards Replays</Heading_C>
            <Heading_C styleType="h2" style={{ textTransform: 'capitalize' }}>
              {humanFriendlyLongDate(date)}
            </Heading_C>
          </View>
          {headerTools}
        </View>

        {date && filteredMeetings.length === 0 && (
          <View style={{ paddingVertical: 40 }}>
            <Text_C style={{ textAlign: 'center' }}>
              There are no Stewards replays on {humanFriendlyDate(date)}
            </Text_C>
          </View>
        )}

        {filteredMeetings.length > 0 &&
          filteredMeetings
            .filter(({ track }) => !selectedState || track.state == selectedState)
            .map((meeting, idx) => {
              const races = meeting.races.filter((race) => race.hasStewardsReplay)
              const orderedRaces = orderBy(races, (race) => race.startTime, 'desc')

              return (
                <View key={idx} style={{ marginTop: 10 }}>
                  <Link_C
                    navigateTo={[
                      'Meeting',
                      {
                        date: shortDate(date),
                        trackSlug: removeLeadingSlash(meeting.track.slug),
                        dayPhaseLetter: meeting.dayPhaseLetter,
                        type: meeting.isTrial ? 'trial' : 'meeting'
                      }
                    ]}
                  >
                    <View style={{ flexDirection: 'row', columnGap: 8 }}>
                      <Heading_C styleType="h2">{meeting.track.name}</Heading_C>
                      <Heading_C styleType="h2" style={{ color: colors.gray800 }}>
                        {meeting.track.state}
                      </Heading_C>
                    </View>
                  </Link_C>

                  <ScrollHorizontal_C contentContainerStyle={styles.scroll} scrollIntervalMs={1}>
                    {orderedRaces.map((race) => {
                      return race.stewardsReplays.map((replay) => (
                        <VideoItem_C
                          key={idx}
                          startTime={humanFriendlyTime(race.startTime)}
                          video={replay}
                          date={shortDate(date)}
                          trackSlug={removeLeadingSlash(meeting.track.slug)}
                          raceNumber={race.raceNumber}
                          trial={meeting.isTrial}
                          dayPhaseLetter={meeting.dayPhaseLetter}
                          style={{ mainContainer: { width: 250 } }}
                        />
                      ))
                    })}
                  </ScrollHorizontal_C>
                </View>
              )
            })}
      </View>
    </Card_C>
  )
  function getElements() {
    const selectOptions = useMemo(
      () => [
        { label: 'All States', value: null },
        ...australianStates.map((state) => ({
          label: state,
          value: state
        }))
      ],
      []
    )

    const headerTools = (
      <>
        <Select_C<AustralianState | null>
          styleType="blueCaret"
          options={selectOptions}
          initialSelection={
            selectedState
              ? selectOptions.find((option) => option.value == selectedState)?.label
              : selectOptions[0].label
          }
          onSelect={(selected) => setSelectedState(selected.value ?? undefined)}
        />
        <CalendarPickerIcon_C
          maxDate={maxMeetingDate}
          minDate={minMeetingDate}
          selectedDate={date}
          onDateSelected={(date) =>
            navigate('Videos', {
              categorySlug: 'stewards-replays',
              date: shortDate(date)
            })
          }
        />
      </>
    )
    return { headerTools }
  }
}

const breakpointStyles = createStyles({
  videoContainer: {
    base: {
      gap: 20
    }
  },
  featuredNews: {
    base: {
      flex: 1,
      marginBottom: 20
    },
    xlarge: {
      marginBottom: 0
    }
  },
  scroll: {
    marginTop: 10,
    gap: 20
  },
  smallVideoContainer: {
    base: {
      flexDirection: 'row',
      gap: 20,
      flexWrap: 'wrap'
    },
    xlarge: {
      flexDirection: 'column',
      gap: 25,
      width: 325
    }
  }
})
