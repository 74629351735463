import { useContext, useMemo } from 'react'
import { View } from 'react-native'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../../../services/styles/dependencies/style-constants'
import { Card_C } from '../../../../Layout/Card/Card'
import { MeetingContext } from '../../Meeting'
import { RaceHeader_C } from '../../components/RaceHeader'
import { FieldTable_C } from '../FieldAndForm/components/FieldTable'
import { ResultsInfo_C } from './components/ResultsInfo'
import { ResultScratchings_C } from './components/ResultsScratchings'
import { ResultsTable_C } from './components/ResultsTable'

export function Results_C() {
  const meetingContext = useContext(MeetingContext)
  const { activeMeeting, selectedRaceNumber, activeTab } = meetingContext

  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const isMobileLayout = breakpointBelow(tabletBreakpoint)

  return useMemo(() => {
    return (
      <View style={styles.container}>
        {activeMeeting?.races
          .filter((race) => !selectedRaceNumber || selectedRaceNumber == race.raceNumber)
          .map((race) => {
            const hasScratchings = race.runners.some((r) => r.scratched)
            return (
              <Card_C key={race.raceNumber} style={styles.card}>
                <RaceHeader_C race={race} />
                {race.status == 'Fields Drawn' ? (
                  <FieldTable_C race={race} />
                ) : (
                  <>
                    <ResultsTable_C
                      runners={race.runners}
                      raceNumber={race.raceNumber}
                      isTrial={activeMeeting.isTrial}
                    />
                    {hasScratchings && <ResultScratchings_C race={race} />}
                    <ResultsInfo_C resultsInfo={race.resultsInfo} />
                  </>
                )}
              </Card_C>
            )
          })}
      </View>
    )
  }, [selectedRaceNumber, activeMeeting, styles, isMobileLayout, activeTab])
}

const breakpointStyles = createStyles({
  container: {
    base: {
      rowGap: 24
    }
  },
  card: {
    base: { rowGap: 20 },
    [desktopBreakpoint]: {
      rowGap: 30
    }
  }
})
