import { ReactNode, useContext, useMemo } from 'react'
import { Image, ImageSourcePropType, View } from 'react-native'
import { NamedObj } from '../../../../helper-types'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../services/styles/dependencies/style-constants'
import { colors } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { humanFriendlyDate } from '../../../../utils/date-time'
import { Icon_C } from '../../../Base/Icon/Icon'
import { Select_C } from '../../../Base/Select/Select'
import { Separator_C } from '../../../Base/Separator/Separator'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { InfoButton_C } from '../../../Partials/InfoButton/InfoButton'

import { MeetingPuntersCorner } from '../../../../services/data/request-functions/punters-corner-request'
import { PuntersCornerTrackContext } from '../PuntersCornerTrack'

export function PuntersCornerTrackHeader_C() {
  const { activeMeeting, date, todaysMeetings } = useContext(PuntersCornerTrackContext)

  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { track } = activeMeeting

  const { trackSelect, meetingDate, trackInfo, trackInfoIcon, image } = getElements()

  return (
    <Card_C>
      <View style={styles.mainContainer}>
        <View style={styles.topContainer}>
          <View style={styles.leftContainer}>
            {trackSelect}
            {meetingDate}
            <View style={styles.weatherContainer}>
              {/*  todo: bring back when have data
          {weather}
              {trackCondition} */}
              {trackInfoIcon}
            </View>
          </View>
          <View style={styles.middleContainer}>
            {image}
            {trackInfo}
          </View>
        </View>
        <Separator_C style={styles.separator} />
      </View>
    </Card_C>
  )

  function getElements(): NamedObj<ReactNode> {
    const trackSelect = useMemo(() => {
      const trackSelectOptions = todaysMeetings.map((meeting) => {
        return {
          label: (
            <View style={styles.trackSelectOption}>
              <Text_C>{meeting.track.name}</Text_C>
              <Text_C style={styles.headingState}>{meeting.track.state}</Text_C>
            </View>
          ),
          value: meeting
        }
      })

      return trackSelectOptions.length > 1 ? (
        <Select_C
          options={trackSelectOptions}
          onSelect={(option) => onMeetingSelected(option.value)}
          initialSelection={
            trackSelectOptions.find(({ value }) => value.track.name == track.name)?.label
          }
          styles={selectStyles}
        />
      ) : (
        <Text_C>{trackSelectOptions[0].label}</Text_C>
      )
    }, [todaysMeetings])

    // TODO: Should do something when meeting is selected
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    function onMeetingSelected(meeting: MeetingPuntersCorner) {}

    const { trackDetails } = track

    let trackInfo: JSX.Element | undefined
    if (trackDetails) {
      const {
        circumference,
        homeStraightLength,
        maxFrontRowMobile,
        maxFrontRowStanding,
        sprintLane
      } = trackDetails
      trackInfo = (
        <View>
          {circumference && (
            <Text_C style={styles.trackInfoText}>Circumference: {circumference}m</Text_C>
          )}
          {homeStraightLength && (
            <Text_C style={styles.trackInfoText}>Straight: {homeStraightLength}m</Text_C>
          )}

          {maxFrontRowMobile && (
            <Text_C style={styles.trackInfoText}>Front Line (Mobile): {maxFrontRowMobile}</Text_C>
          )}

          {maxFrontRowStanding && (
            <Text_C style={styles.trackInfoText}>
              Front Line (Standing): {maxFrontRowStanding}
            </Text_C>
          )}
          {sprintLane && <Text_C style={styles.trackInfoText}>Sprint Lane in Operation</Text_C>}
        </View>
      )
    }

    const trackInfoIcon = <InfoButton_C tooltipContent={'' /* todo: */} />

    const weatherData = {
      temperature: '16°C',
      trackCondition: 'Fast'
    }
    const weather = (
      <View style={styles.weather}>
        <Icon_C
          name="weather"
          color={colors.gray600}
          colorFill={true}
          colorStroke={false}
          style={{ width: 30, height: 25.4 }}
        />
        <Text_C>{weatherData.temperature}</Text_C>
      </View>
    )
    const trackCondition = (
      <View style={styles.trackCondition}>
        <Icon_C
          name="wind"
          color={colors.gray600}
          colorFill={true}
          colorStroke={false}
          style={{ width: 28.1, height: 19.5 }}
        />
        <Text_C>{weatherData.trackCondition}</Text_C>
      </View>
    )

    const imageSource = activeMeeting.track.trackMapImage
      ?.placeholderImageUrl as ImageSourcePropType

    const image = imageSource ? (
      <Image
        source={imageSource}
        style={{ aspectRatio: 1.685, height: 106 }}
        resizeMode="contain"
      />
    ) : null

    const meetingDate = useMemo(
      () => <Text_C style={styles.meetingDate}>{humanFriendlyDate(new Date(date))}</Text_C>,
      [date]
    )
    return {
      trackSelect,
      meetingDate,
      trackInfo,
      trackInfoIcon,
      weather,
      trackCondition,
      image
    }
  }
}

const selectStyles = createStyles({
  head: {
    base: {
      width: 'auto',
      minWidth: 200,
      alignSelf: 'flex-start',
      paddingHorizontal: 0,
      height: 'auto',
      borderWidth: 0,
      marginBottom: 6
    }
  },
  headText: {
    base: {
      marginRight: 10,
      ...textSizes.size7
    }
  }
})

const breakpointStyles = createStyles({
  selectHeadText: { base: { textAlign: 'left', marginLeft: 0 } },
  trackSelectOption: {
    base: {
      flexDirection: 'row',
      columnGap: 8,
      alignItems: 'baseline',
      flex: 1
    }
  },
  trackHeading: { base: { marginBottom: 8 } },
  trackInfoText: { base: { fontWeight: '600SemiBold', ...textSizes.size1 } },
  headingState: { base: { color: colors.gray500, ...textSizes.size2 } },
  raceTabsContainer: {
    base: {
      flexDirection: 'row',
      columnGap: 18,
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%'
    }
  },
  meetingDate: {
    base: {
      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      marginBottom: 10,
      textTransform: 'uppercase'
    },
    [desktopBreakpoint]: {
      ...textSizes.size5,
      marginBottom: 16
    }
  },

  weatherContainer: {
    base: {
      gap: 12,
      alignItems: 'flex-end'
    },
    [desktopBreakpoint]: {
      flexDirection: 'row',
      gap: 20
    }
  },

  weather: {
    base: {
      flexDirection: 'row',
      columnGap: 2,
      alignItems: 'center'
    }
  },

  trackCondition: {
    base: {
      flexDirection: 'row',
      columnGap: 5
    }
  },
  separator: {
    base: {
      marginVertical: 12
    }
  },
  mainContainer: { base: {} },
  topContainer: {
    base: {
      flexDirection: 'row',
      gap: 40,
      justifyContent: 'space-between',
      marginBottom: 8,
      alignItems: 'stretch'
    },
    [desktopBreakpoint]: { marginBottom: 0 }
  },
  leftContainer: { base: {} },
  middleContainer: {
    base: {
      flex: 1,

      flexDirection: 'row',
      gap: 10,
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  rightContainer: { base: { flex: 2 } }
})
