import { createStyles } from '../services/styles/breakpoint-styles.service'
import { textSizes } from './text-sizes'

export const raceNumber = createStyles({
  container: {
    width: 39,
    height: 39,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100
  },
  text: {
    fontWeight: '600SemiBold',
    ...textSizes.size4
  }
})
