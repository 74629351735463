import { View } from 'react-native'
import { MeetingDetailedRace } from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { Link_C } from '../../../../../Base/Link/Link'
import { Text_C } from '../../../../../Base/Text/Text'

type Props = { race: MeetingDetailedRace }

export function ResultScratchings_C({ race }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const scratchings = race.runners.filter((runner) => runner.scratched)
  return (
    <View style={{ flexDirection: 'row' }}>
      <Text_C style={styles.scratchingsLabel}>Scratchings: </Text_C>
      {scratchings.map((runner, i) => {
        const text = `${runner.runnerNumber}. ${runner.horse.name}${
          runner.isLateScratching ? '(L)' : ''
        }${i !== scratchings.length - 1 ? ', ' : ''}`
        return !runner.horse.slug ? (
          <Text_C key={i}>{text}</Text_C>
        ) : (
          <Link_C key={i} navigateTo={['Horse', { horseSlug: runner.horse.slug }]}>
            {text}
          </Link_C>
        )
      })}
    </View>
  )
}

const breakpointStyles = createStyles({
  scratchingsLabel: {
    fontWeight: '700Bold'
  }
})
