import { merge } from 'lodash'
import { useEffect, useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import { desaturateColor } from '../../../helper-functions'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { convertFontWeightToFontFamily } from '../../../styles/fonts-map'
import { textSizes } from '../../../styles/text-sizes'
import { Button_C } from '../Button/Button'
import { crossIcon, editIcon, tickIcon } from '../Icon/preset-icon-props'
import { PressableStyleExtension } from '../Pressable/Pressable'
import { Option, Select_C } from '../Select/Select'
import { Text_C } from '../Text/Text'

type Props<T extends string> = {
  // eslint-disable-next-line no-unused-vars
  onChange(newValue: T): void
  value: T
  selectOptions?: Option<T>[]
  numberOfLines?: number
  required?: boolean
}

export function EditField_C<T extends string>({
  value: initialValue,
  onChange,
  selectOptions,
  numberOfLines = 1,
  required
}: Props<T>) {
  const [editMode, setEditMode] = useState<boolean>()
  const [value, setValue] = useState<T>(initialValue)

  const styles = useBreakpointStyles({ styles: breakpointStyles })

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <View style={styles.valueContainer}>
      {editMode ? (
        selectOptions ? (
          <View>
            <Select_C
              initialSelection={value}
              styleType="greyChevron"
              options={selectOptions}
              onSelect={({ value }) => setValue(value)}
            />
          </View>
        ) : (
          <View
            style={{
              borderWidth: 1,
              borderRadius: 3,
              borderColor: presets.border,
              flex: 1
            }}
          >
            <TextInput
              value={value}
              onChangeText={(text) => setValue(text as T)}
              numberOfLines={numberOfLines}
              multiline
              style={{
                minWidth: 'auto',
                width: '100%',
                borderWidth: 1,
                borderColor: presets.border,
                borderRadius: 4,
                padding: 6,
                flex: 1,
                ...textSizes.size3,
                ...(convertFontWeightToFontFamily({
                  fontFamily: 'OpenSans'
                }) as ViewStyle)
              }}
            />
          </View>
        )
      ) : (
        <Text_C numberOfLines={numberOfLines} ellipsizeMode="tail">
          {value}
        </Text_C>
      )}
      <View style={styles.buttonsContainer}>
        {editMode ? (
          <>
            <Button_C
              disabled={(required && !value) || initialValue == value}
              styleType="iconOnly"
              icon={merge(tickIcon, { style: { height: 18 } })}
              onPress={() => {
                onChange(value)
                setEditMode(false)
              }}
              style={tickButton}
            />
            <Button_C
              styleType="iconOnly"
              icon={merge(crossIcon, { style: { height: 20 } })}
              onPress={() => {
                setValue(initialValue)
                setEditMode(false)
              }}
              style={crossButton}
            />
          </>
        ) : (
          <Button_C styleType="iconOnly" icon={editIcon} onPress={() => setEditMode(true)} />
        )}
      </View>
    </View>
  )
}

const breakpointStyles = createStyles({
  valueContainer: {
    minWidth: 260,
    columnGap: 20,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  buttonsContainer: {
    width: 34,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginLeft: 'auto'
  }
})

const tickButton: PressableStyleExtension = {
  text: {
    base: {
      color: colors.green
    },
    hovered: { color: desaturateColor(colors.green, 1) },
    disabled: { color: presets.disabledText }
  }
}

const crossButton: PressableStyleExtension = {
  text: {
    base: {
      color: colors.red
    },
    hovered: { color: desaturateColor(colors.red, 1) }
  }
}
