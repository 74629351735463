import { useContext } from 'react'
import { GlobalContext } from '../../global-context'
import { desaturateColor } from '../../helper-functions'
import { updateBlackBookItem } from '../../services/data/request-functions/black-book-request'
import { colors } from '../../styles/colors'
import { Button_C } from '../Base/Button/Button'
import { LoginRegister_C } from './LoginSignup/LoginSignup'
type Props = {
  horseId: number
  horseName: string
  onHorseAdded?: () => void
  isAlreadyInBlackbook: boolean
}
export function BlackBookButton_C({
  horseId,
  horseName,
  onHorseAdded,
  isAlreadyInBlackbook
}: Props) {
  const { featuresToggle, addToast, authToken, openModal } = useContext(GlobalContext)
  if (!featuresToggle?.userProfile.active) {
    return <></>
  }
  return (
    <Button_C
      styleType="fillButton"
      onPress={isAlreadyInBlackbook ? undefined : addHorseToBlackbook}
      icon={{
        name: 'blackBookRibbon',
        style: { height: 22, position: 'absolute', top: 10, right: 14 },
        color: 'white',
        colorFill: true,
        colorStroke: false
      }}
      active={isAlreadyInBlackbook}
      style={{
        elem: {
          base: {
            alignSelf: 'center'
          },
          active: {
            backgroundColor: desaturateColor(colors.green, 2)
          }
        },
        text: {
          marginRight: 26
        }
      }}
    >
      {isAlreadyInBlackbook ? 'Horse in Blackbook' : 'Add To Blackbook'}
    </Button_C>
  )
  function addHorseToBlackbook(): void {
    if (!authToken) {
      openModal(<LoginRegister_C />, {
        modalStyle: {
          modalContent: { paddingVertical: 0, paddingHorizontal: 0 }
        }
      })
    } else {
      updateBlackBookItem({
        authToken: authToken,
        comment: '',
        useNotifications: true,
        blackbookItemId: horseId
      })
        .then(() => {
          addToast({
            message: `${horseName} added to Blackbook`,
            status: 'success'
          })
          onHorseAdded?.()
        })
        .catch(() => {
          addToast({
            message: `An error occurred while trying to add ${horseName} to Blackbook`,
            status: 'fail'
          })
        })
    }
  }
}
