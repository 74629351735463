import { ReactNode, useEffect, useRef, useState } from 'react'
import { ScrollView, View } from 'react-native'
type Props = {
  columns: number
  gap: number
  minHeight: number
  items: ReactNode[]
}
export function ScrollColumns_C(props: Props) {
  const [scrollHeight, setScrollHeight] = useState<number | undefined>()

  useEffect(() => {
    setScrollHeight(undefined)
  }, [props.columns, props.items])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const itemsContainerRef = useRef<any>(null)

  useEffect(() => {
    if (scrollHeight == undefined) {
      const itemContainerElem = itemsContainerRef.current as HTMLDivElement
      let height = Math.ceil((itemContainerElem.offsetHeight / props.columns) * 1.2)
      if (height < props.minHeight) {
        height = props.minHeight
      }
      setScrollHeight(height)
    }
  }, [scrollHeight])

  return (
    <ScrollView
      nestedScrollEnabled
      contentContainerStyle={{
        height: props.minHeight
      }}
    >
      <View
        style={[
          {
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignContent: 'flex-start'
          },
          scrollHeight ? { height: scrollHeight } : {},
          { marginRight: -props.gap, marginBottom: -props.gap }
        ]}
        ref={itemsContainerRef}
      >
        {props.items.map((item, i) => (
          <View
            key={i}
            style={{
              width: `${100 / props.columns}%`,
              paddingRight: props.gap,
              paddingBottom: props.gap
            }}
          >
            {item}
          </View>
        ))}
      </View>
    </ScrollView>
  )
}
