import { AToZ, australiaLatLng, australiaZoom, stateBreakpointZoom } from '../../../../constants'

import { ViewStyle } from 'react-native'
import { wordStartsWithLetter } from '../../../../helper-functions'
import { useActiveBreakpoint } from '../../../../services/styles/breakpoint-styles.service'
import { AustralianState, MapPin, NativeAndWebZoom } from '../../../../types/map.type'
import { Map_C } from '../../../Base/Map/Map'

type Props = {
  markerLocations: MapPin[]
  selectedState: AustralianState | null
  aToZFilter: AToZ | null
  style?: ViewStyle
}

export function TrackMap_C(props: Props) {
  const activeBreakpoint = useActiveBreakpoint()
  const { zoom, nativeZoom }: NativeAndWebZoom = props.selectedState
    ? stateBreakpointZoom[props.selectedState].breakpointZoom[activeBreakpoint]
    : australiaZoom[activeBreakpoint]
  const filteredMarkerLocations: MapPin[] = props.markerLocations.filter((marker) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      (props.aToZFilter == null || wordStartsWithLetter(marker.locationName, props.aToZFilter)) &&
      (props.selectedState == null || marker.state == props.selectedState)
    )
  })

  return (
    <Map_C
      defaultMapCoords={australiaLatLng}
      mapCoords={
        props.selectedState ? stateBreakpointZoom[props.selectedState].coords : australiaLatLng
      }
      zoom={zoom}
      nativeZoom={nativeZoom}
      markerLocations={filteredMarkerLocations}
      style={props.style}
    />
  )
}
