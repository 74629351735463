/* eslint-disable no-unused-vars */
import { useLinkTo, useNavigation } from '@react-navigation/native'
import { GestureResponderEvent, Linking, Platform } from 'react-native'
import { NavigateTo } from '../../../helper-types'
import { environment } from '../../../services/data/api-environment'
import { ScreenName } from '../../../services/routes/screens'
import { PressableCProps, Pressable_C } from '../Pressable/Pressable'

/* internal navigate with TS */
type Props1 = PressableCProps & {
  navigateTo: NavigateTo<ScreenName>
}
/* or string url with  new tab option, no TS*/
type Props2 = PressableCProps & {
  newTab?: boolean
  externalLink?: boolean // if internal, no need for base path
  linkUrl: string
}

export type LinkProps = Props1 | Props2
//export type LinkProps = Props1 | Props2;

export function Link_C(props: Props1): JSX.Element
export function Link_C(props: Props2): JSX.Element
export function Link_C(props: Partial<Props1> & Partial<Props2>) {
  const navigation = useNavigation()
  const linkTo = useLinkTo()

  const { externalLink, linkUrl, navigateTo, newTab } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handlePress(e: GestureResponderEvent) {
    if (linkUrl) {
      let url = externalLink ? linkUrl : `${environment.baseUrl}/${linkUrl}`
      if (externalLink) {
        url = fixExternalUrl(url)
      }
      if (Platform.OS === 'web') {
        if (newTab) {
          window.open(url, '_blank')
        } else {
          linkTo(linkUrl)
        }
      } else {
        if (newTab) {
          Linking.openURL(url)
        } else {
          //you cant open an external link in the same tab in react native
          Linking.openURL(url)
        }
      }
    } else if (navigateTo) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigation.navigate(...navigateTo)
    }
  }

  return <Pressable_C onPress={handlePress} styleType="linkBase" {...props} />

  function fixExternalUrl(url: string) {
    const hasProtocol = url.startsWith('http://') || url.startsWith('https://')

    if (!hasProtocol) {
      // URL doesn't have a protocol, prepend "http://" by default
      return 'http://' + url
    }

    // URL already has a protocol, return it as is
    return url
  }
}
