import { View } from 'react-native'
import {
  LatestRacesResponse,
  getNextToGo
} from '../../../services/data/request-functions/latest-races-request'
import {
  MeetingsFieldsResponse,
  getDaysMeetingsFields
} from '../../../services/data/request-functions/meetings-fields-request'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { LatestRaces_C } from '../../Partials/LatestRaces/LatestRaces'
import { MeetingListCard_C } from '../../Partials/_meeting-components/MeetingListCard'
import { Metadata } from '../../Partials/Metadata/Metadata'

export const Fields_C = (): JSX.Element =>
  withScreenWrapper<'Fields', [MeetingsFieldsResponse, LatestRacesResponse]>({
    requestsSetup: ({ date }) => {
      return [getDaysMeetingsFields({ date }), getNextToGo({ limit: 6 })]
    },
    aside: ({ responses }) => {
      const nextRaces = responses![1].races
      return (
        <View style={{ gap: 24 }}>
          <LatestRaces_C nextRaces={nextRaces} />
        </View>
      )
    },
    view: ({ responses }) => {
      const [racingMeetingsResponse] = responses!
      const { meetings, date, maxDate } = racingMeetingsResponse
      const selectedDate = new Date(date)
      return (
        <>
          <Metadata
            title="Harness Racing Fields"
            description="View all the upcoming Harness Racing for every meet across Australia on harness.org.au"
          />

          <MeetingListCard_C
            activeDate={selectedDate}
            type="fields"
            meetings={meetings}
            maxDate={maxDate}
            minDate={new Date()}
          />
        </>
      )
    }
  })
