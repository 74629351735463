import { merge } from 'lodash'
import { useContext } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../../global-context'
import { User, nameTitle } from '../../../../services/data/data-types/local-data-types/user.type'
import { updateUserInfo } from '../../../../services/data/request-functions/user-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../../styles/colors'
import { EditField_C } from '../../../Base/EditField/EditField'
import { Link_C } from '../../../Base/Link/Link'
import { LabelValueTable_C } from '../../../Base/Table/LabelValueTable'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'

type Props = {
  user: User
}
export function GeneralInfo_C({ user }: Props): JSX.Element {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const { authToken, addToast } = useContext(GlobalContext)
  const { email, firstName, lastName, title } = user

  return (
    <Card_C style={{ alignItems: 'flex-start' }}>
      <LabelValueTable_C
        style={{ tableRow: { height: 34, alignItems: 'center' } }}
        data={[
          {
            label: 'Title',
            value: (
              <EditField_C
                selectOptions={nameTitle.map((title) => ({ value: title }))}
                value={title}
                onChange={(val) => updateUser({ title: val })}
              />
            )
          },
          {
            label: 'First Name',
            value: (
              <EditField_C
                value={firstName}
                required
                onChange={(val) => updateUser({ firstName: val })}
              />
            )
          },
          {
            label: 'Last Name',
            value: (
              <EditField_C
                value={lastName}
                required
                onChange={(val) => updateUser({ lastName: val })}
              />
            )
          },
          {
            label: 'Email',
            value: (
              <View style={styles.valueContainer}>
                <Text_C>{email}</Text_C>
              </View>
            )
          },
          {
            label: 'Password',
            value: (
              <Link_C styleType="linkBase" navigateTo={['ResetPassword', { token: 'change' }]}>
                Reset Password
              </Link_C>
            )
          }
        ]}
      />
    </Card_C>
  )

  function updateUser<T extends keyof User>(updatedPropObj: {
    [key in T]: User[key]
  }) {
    const [value] = Object.values(updatedPropObj)
    if (!value) {
      return
    }
    const updatedUser: User = merge(user, updatedPropObj)
    updateUserInfo({ user: updatedUser, authToken: authToken! })
      .then(() =>
        addToast({
          message: 'Update successful',
          status: 'success'
        })
      )
      .catch(() =>
        addToast({
          message: 'Update failed',
          status: 'fail'
        })
      )
  }
}

const breakpointStyles = createStyles({
  buttonsContainer: {
    base: {
      gap: 10,
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  valueContainer: {
    columnGap: 20,
    flexDirection: 'row',
    alignItems: 'center'
  },
  editButton: {
    marginLeft: 'auto'
  },
  inputField: {
    base: {
      borderWidth: 1,
      borderColor: presets.border,
      borderRadius: 4,
      padding: 6
    }
  }
})
