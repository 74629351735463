import { merge } from 'lodash'
import { desaturateColor } from '../../../helper-functions'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { colors, presets } from '../../../styles/colors'
import { textSizes } from './../../../styles/text-sizes'
import { PressableStyleExtension } from './Pressable'

export type StyleTypes =
  | 'fillButton'
  | 'tabButton'
  | 'iconOnly'
  | 'colorIconOnly'
  | 'buttonWithIcon'
  | 'buttonWithIconColor'
  | 'raceTab'
  | 'linkBase'
  | 'buttonBase'
  | 'linkWithIcon'
  | 'formButton'
  | 'paginationButton'

const buttonBase: PressableStyleExtension = {
  elem: {
    base: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 4,
      maxWidth: 260,
      paddingVertical: 8,
      paddingHorizontal: 14,
      gap: 12
    }
  },
  text: {
    base: {
      fontFamily: 'OpenSans',
      fontWeight: '400Regular',
      ...textSizes.size3
    },
    disabled: {}
  }
}

export const linkBase: PressableStyleExtension = {
  text: {
    base: {
      color: presets.primary,
      fontWeight: '600SemiBold',
      ...textSizes.size2
    },
    hovered: { color: desaturateColor(presets.primary, 0.5) },
    disabled: {
      color: presets.disabled
    }
  }
}

const fillButton = merge({}, buttonBase, {
  elem: {
    base: {
      backgroundColor: presets.primary,
      paddingVertical: 12,
      paddingHorizontal: 20
    },

    hovered: { backgroundColor: desaturateColor(presets.primary, 0.5) },
    disabled: {
      backgroundColor: presets.disabled
    }
  },
  text: {
    base: {
      color: 'white'
    },
    disabled: {
      color: colors.gray700
    }
  }
} as PressableStyleExtension)

const tabButton = merge({}, buttonBase, {
  elem: {
    base: {},

    large: {
      paddingHorizontal: 20
    },
    active: {
      backgroundColor: colors.blue
    },
    disabled: {
      backgroundColor: presets.disabled
    },
    hovered: {
      backgroundColor: colors.gray300
    }
  },
  text: {
    base: {
      color: colors.gray600
    },
    large: {
      ...textSizes.size3
    },
    active: {
      color: 'white'
    },
    disabled: {
      color: colors.gray700
    },
    hovered: {
      color: colors.gray800
    }
  }
})

const buttonWithIcon = merge({}, buttonBase, {
  elem: {
    base: {
      borderWidth: 1,
      borderColor: colors.gray700
    },
    hovered: { backgroundColor: colors.gray200 },
    active: {
      backgroundColor: colors.gray200
    }
  },
  text: {
    base: {
      color: colors.gray700
    },
    hovered: { color: colors.gray800 },
    disabled: { color: presets.disabled }
  }
} as PressableStyleExtension)

const buttonWithIconColor = merge({}, buttonBase, {
  elem: {
    base: {
      borderWidth: 1,
      borderColor: colors.blue
    },
    hovered: { backgroundColor: colors.blue },
    active: {
      backgroundColor: colors.blue
    }
  },
  text: {
    base: {
      color: colors.blue
    },
    hovered: { color: colors.white },
    disabled: { color: presets.disabled }
  }
} as PressableStyleExtension)

const iconOnly: PressableStyleExtension = {
  elem: {
    base: {
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  text: {
    base: {
      color: colors.gray600
    },
    hovered: { color: colors.gray800 },
    disabled: { color: presets.disabled },
    active: { color: presets.primary }
  }
}

const colorIconOnly: PressableStyleExtension = {
  ...iconOnly,
  text: {
    base: {
      color: colors.blue
    },
    hovered: { color: colors.darkBlue },
    disabled: { color: presets.disabled },
    active: { color: presets.primary }
  }
}

const raceTab: PressableStyleExtension = {
  elem: {
    base: {
      borderRadius: 100,
      borderWidth: 1,
      borderColor: colors.gray500,
      width: 39,
      height: 39,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 0,
      gap: 10
    },
    active: {
      backgroundColor: 'black'
    }
  },

  text: {
    base: {
      color: colors.gray600
    },
    [desktopBreakpoint]: {
      ...textSizes.size3
    },
    active: {
      color: 'white'
    }
  }
}

const linkWithIcon = merge({}, linkBase, {
  elem: {
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center'
  },
  text: {
    base: {},
    hovered: {},
    disabled: {}
  }
} as PressableStyleExtension)

const formButton = merge({}, buttonBase, {
  elem: {
    base: {
      borderWidth: 2,
      borderColor: colors.gray400,
      backgroundColor: colors.gray300,
      marginLeft: 'auto',
      paddingVertical: 6,
      paddingHorizontal: 10
    },
    hovered: {
      backgroundColor: colors.gray200
    }
  },
  text: {
    base: {
      fontWeight: '600SemiBold'
    },
    hovered: {},
    disabled: {}
  }
} as PressableStyleExtension)

const paginationButton: PressableStyleExtension = {
  elem: {
    base: {
      borderWidth: 1,
      borderColor: colors.gray600,
      backgroundColor: 'white',
      paddingVertical: 4,
      paddingHorizontal: 8,
      borderRadius: 4,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      width: 34,
      height: 26
    },
    disabled: {
      backgroundColor: presets.disabled
    },
    hovered: { backgroundColor: presets.primary }
  },
  text: {
    base: {
      ...textSizes.size2,
      fontWeight: '600SemiBold',
      color: colors.gray800
    },
    disabled: { color: colors.gray600 },
    hovered: { color: 'white' }
  }
}

export const pressableStyleTypes: Record<StyleTypes, PressableStyleExtension> = {
  buttonBase,
  tabButton,
  fillButton,
  buttonWithIcon,
  buttonWithIconColor,
  iconOnly,
  colorIconOnly,
  raceTab,
  linkBase,
  linkWithIcon,
  formButton,
  paginationButton
}
