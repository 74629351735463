/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useNavigation } from '@react-navigation/native'
import { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { capitalLetterRegex, emailRegex, numberRegex, symbolRegex } from '../../../constants'
import { GlobalContext } from '../../../global-context'
import { NameTitle, nameTitle } from '../../../services/data/data-types/local-data-types/user.type'
import { loginUser, signupUser } from '../../../services/data/request-functions/user-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { colors, presets } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { setStorageData } from '../../../utils/storage'
import { Button_C } from '../../Base/Button/Button'
import { Text_C } from '../../Base/Text/Text'
import { TabView_C } from '../../Layout/TabView/TabView'
import { FormField, FormField_C } from '../_user-components/FormField'
import { ForgotPassword_C } from './components/ForgotPassword'

const blankForm: SignupLoginForm = {
  title: {
    label: 'Title',
    value: '',
    invalidMessage: 'Title is required'
  },
  firstName: {
    label: 'First Name',
    value: '',
    invalidMessage: 'First name is required'
  },
  lastName: {
    label: 'Last Name',
    value: '',
    invalidMessage: 'Last name is required'
  },
  email: {
    label: 'Email',
    value: '',
    invalidMessage: 'Email is required'
  },
  password: {
    label: 'Password',
    value: '',
    invalidMessage: 'Password is required'
  }
}

type SignupLoginForm = {
  title: {
    label: string
    value: '' | NameTitle
    invalidMessage?: string
  }
  firstName: {
    label: string
    value: '' | string
    invalidMessage?: string
  }
  lastName: {
    label: string
    value: '' | string
    invalidMessage?: string
  }
  email: {
    label: string
    value: '' | string
    invalidMessage?: string
  }
  password: {
    label: string
    value: '' | string
    invalidMessage?: string
  }
}

// NOTE: Recaptcha has been removed as per client request

export function LoginRegister_C() {
  const { setAuthToken, openModal, closeModal, addToast } = useContext(GlobalContext)

  const [submitAttempted, setSubmitAttempted] = useState(false)

  const [activeTab, setActiveTab] = useState<'Login' | 'Register'>('Login')

  const [formState, setFormState] = useState(blankForm)

  type FieldKey = keyof typeof formState

  useEffect(() => {
    resetFormState()
  }, [activeTab])

  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const { navigate } = useNavigation()

  const { loginForm, registerForm } = getElems()

  return (
    <View style={styles.mainContainer}>
      <TabView_C
        heading="Login / Register"
        separateCards={false}
        tabs={[
          ['Login', loginForm],
          ['Register', registerForm]
        ]}
        onTabSelected={(tab) => setActiveTab(tab)}
      />
    </View>
  )

  function setFormField<T extends FieldKey>(fieldKey: T, field: FormField) {
    const invalidMessage: string | undefined =
      fieldKey == 'title'
        ? !field.value
          ? 'Title is required'
          : undefined
        : fieldKey == 'firstName'
          ? field.value == ''
            ? 'First name is required'
            : undefined
          : fieldKey == 'lastName'
            ? field.value == ''
              ? 'Last name is required'
              : undefined
            : fieldKey == 'password'
              ? !field.value
                ? 'Password is required'
                : field.value.length < 6
                  ? 'Password must be at least 6 characters'
                  : !capitalLetterRegex.test(field.value)
                    ? 'Password requires a capital letter'
                    : !numberRegex.test(field.value)
                      ? 'Password requires a number'
                      : !symbolRegex.test(field.value)
                        ? 'Password requires a symbol'
                        : undefined
              : fieldKey == 'email'
                ? field.value == ''
                  ? 'Email is required'
                  : field.value && !emailRegex.test(field.value)
                    ? 'Invalid email format'
                    : undefined
                : undefined

    if (fieldKey) {
      setFormState((prev) => ({
        ...prev,
        [fieldKey]: { ...prev[fieldKey], value: field.value, invalidMessage }
      }))
    }
  }

  function getElems() {
    const titleField = (
      <FormField_C
        {...{
          formField: formState.title,
          setFormField: (field) => setFormField('title', field),
          submitAttempted,
          options: nameTitle.map((value) => ({ value }))
        }}
      />
    )
    const firstNameField = (
      <FormField_C
        {...{
          formField: formState.firstName,
          setFormField: (field) => setFormField('firstName', field),
          submitAttempted,
          textContentType: 'givenName'
        }}
      />
    )

    const lastNameField = (
      <FormField_C
        {...{
          formField: formState.lastName,
          setFormField: (field) => setFormField('lastName', field),
          submitAttempted,
          textContentType: 'familyName'
        }}
      />
    )

    const emailField = (
      <FormField_C
        {...{
          formField: formState.email,
          setFormField: (field) => setFormField('email', field),
          submitAttempted,
          textContentType: 'emailAddress',
          inputMode: 'email'
        }}
      />
    )

    const passwordField = (submitAction: () => void) => (
      <FormField_C
        {...{
          isPassword: true,
          formField: formState.password,
          setFormField: (field) => setFormField('password', field),
          submitAttempted,
          onEnterPress: submitAction
        }}
      />
    )

    const loginForm = (
      <View style={styles.form}>
        {emailField}
        {passwordField(submitLogin)}
        <View style={styles.buttonsContainer}>
          <Button_C styleType="linkBase" onPress={onForgotPasswordSelected}>
            Forgot Password?
          </Button_C>
          <Button_C styleType="fillButton" onPress={submitLogin}>
            Submit
          </Button_C>
        </View>
      </View>
    )
    const registerForm = (
      <View style={styles.form}>
        {titleField}
        {firstNameField}
        {lastNameField}
        {emailField}
        <Text_C style={{ fontStyle: 'italic', fontSize: 10 }}>
          Password requires at least 6 characters, a number, capital letter and symbol
        </Text_C>
        {passwordField(submitSignup)}
        <View style={styles.buttonsContainer}>
          <Button_C styleType="fillButton" onPress={submitSignup}>
            Submit
          </Button_C>
        </View>
      </View>
    )
    return { loginForm, registerForm, emailField }
  }

  function onForgotPasswordSelected() {
    resetFormState()
    openModal(<ForgotPassword_C />)
  }

  function submitLogin(): void {
    const { email, password } = formState
    if (email.invalidMessage || password.invalidMessage) {
      setSubmitAttempted(true)
      return
    }

    loginUser({
      email: email.value,
      password: password.value
    })
      .then(({ authToken }) => onLoginSuccessful(authToken))
      .catch(() => {
        addToast({
          message: 'Failed to Login. Please make sure your details are correct and try again.',
          status: 'fail'
        })
      })
  }
  function submitSignup(): void {
    const { email, password, firstName, lastName, title } = formState
    if (
      email.invalidMessage ||
      password.invalidMessage ||
      firstName.invalidMessage ||
      lastName.invalidMessage ||
      title.invalidMessage
    ) {
      setSubmitAttempted(true)
      return
    }

    signupUser({
      email: email.value,
      password: password.value,
      firstName: firstName.value,
      lastName: lastName.value,
      title: title.value as NameTitle
    })
      .then(({ authToken }) => onLoginSuccessful(authToken))
      .catch(({ response }) => {
        if (response.error.message == 'Email already exists') {
          addToast({
            message: 'Registration failed: Email already in use.',
            status: 'fail'
          })
        } else {
          addToast({
            message: `Failed to Signup. Please make sure your details are correct and try again.${!response?.error?.message ? '' : ` ${response.error.message}`}`,
            status: 'fail'
          })
        }
      })
  }

  function onLoginSuccessful(authToken: string) {
    setAuthToken(authToken)
    setStorageData('auth-token', authToken)
    closeModal()
    navigate('UserProfile', { activeTab: 'general-info' })
  }

  function resetFormState() {
    const { email, password, firstName, lastName, title } = blankForm
    setFormState({
      title: {
        label: title.label,
        value: title.value,
        invalidMessage: title.invalidMessage
      },
      email: {
        label: email.label,
        value: email.value,
        invalidMessage: email.invalidMessage
      },
      password: {
        label: password.label,
        value: password.value,
        invalidMessage: password.invalidMessage
      },
      firstName: {
        label: firstName.label,
        value: firstName.value,
        invalidMessage: firstName.invalidMessage
      },
      lastName: {
        label: lastName.label,
        value: lastName.value,
        invalidMessage: lastName.invalidMessage
      }
    })
    setSubmitAttempted(false)
  }
}
const breakpointStyles = createStyles({
  mainContainer: {
    base: {
      gap: 20,
      backgroundColor: presets.background
    },
    [desktopBreakpoint]: { gap: 30 }
  },
  form: {
    gap: 10
  },
  buttonsContainer: {
    base: {
      gap: 10,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  },
  inputField: {
    base: {
      borderWidth: 1,
      borderColor: presets.border,
      borderRadius: 4,
      padding: 6,
      flex: 1
    }
  },
  inputError: {
    borderColor: colors.red
  },
  errorText: {
    color: colors.red,
    ...textSizes.size1
  },
  formField: {
    gap: 4
  }
})
