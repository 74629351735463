/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-key */
import { useContext, useState } from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { formatCurrency } from '../../../../helper-functions'
import { environment } from '../../../../services/data/api-environment'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../../styles/colors'
import { raceNumber } from '../../../../styles/preset-styles'
import { humanFriendlyTime } from '../../../../utils/date-time'
import { Button_C } from '../../../Base/Button/Button'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Icon_C } from '../../../Base/Icon/Icon'
import { eyeIcon, playIcon, printIcon } from '../../../Base/Icon/preset-icon-props'
import { Link_C } from '../../../Base/Link/Link'
import { TableStyles, Table_C } from '../../../Base/Table/Table'
import { TableRow } from '../../../Base/Table/table.type'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { ExpandContainer_C } from '../../../Layout/ExpandContainer/ExpandContainer'
import { SimpleContainer_C } from '../../../Layout/SimpleContainer/SimpleContainer'
import { RaceCountdownText_C } from '../../../Partials/RaceCountdownText/RaceCountdownText'
import { Analysis_C } from '../../../Partials/_meeting-components/Analysis'
import { GearChanges_C } from '../../../Partials/_meeting-components/GearChanges'
import { Scratchings_C } from '../../../Partials/_meeting-components/Scratchings'
import { Tips_C } from '../../../Partials/_meeting-components/Tips'
import { ExpandedContentTypes } from '../../PuntersCorner/punters-corner-ui.type'
import { PuntersCornerTrackContext } from '../PuntersCornerTrack'
type ExpandedRow = {
  raceNumber: number
  contentType: ExpandedContentTypes
}

export function PuntersCornerMeeting_C() {
  const {
    activeMeetingPuntersCorner,
    params: { date, trackSlug, dayPhaseLetter }
  } = useContext(PuntersCornerTrackContext)

  const [expandedRow, setExpandedRow] = useState<ExpandedRow | undefined>()
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { races, analysis, tips, isTrial } = activeMeetingPuntersCorner
  return (
    <Card_C>
      <Table_C
        styleType="lightHeaderBorders"
        styles={tableStyles}
        headings={[
          { content: 'Race', colWidth: { flex: 4 } },
          { content: 'Fields', colWidth: { flex: 2 } },
          { content: 'Form', colWidth: { flex: 3 } },
          { content: 'Tips', colWidth: { width: 90 } },
          { content: 'Analysis', colWidth: { width: 90 } },
          { content: 'Scratched', colWidth: { width: 90 } },
          { content: 'Gear', colWidth: { width: 90 } },
          { content: 'Info', colWidth: { width: 90 } },
          { content: 'Fav/Odds', colWidth: { flex: 5 } }
        ]}
        rows={races.map((race) => {
          const { raceNumber, distance, startTime, runners } = race

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const favorite: any = undefined //todo:

          const isThisRace = expandedRow?.raceNumber == raceNumber

          const scratchingsCount = runners?.filter((r) => r.isScratched).length
          const gearChangesCount = runners?.filter((r) => r.gearAdded || r.gearRemoved).length

          const tipsExpanded = isThisRace && expandedRow?.contentType == 'tips'

          const analysisExpanded = isThisRace && expandedRow?.contentType == 'analysis'

          const scratchingsExpanded = isThisRace && expandedRow?.contentType == 'scratchings'

          const gearChangeExpanded = isThisRace && expandedRow?.contentType == 'gear'

          const infoExpanded = isThisRace && expandedRow?.contentType == 'info'

          return {
            underRowContent: (
              <ExpandContainer_C
                isExpanded={expandedRow?.raceNumber == raceNumber}
                styles={{ expandContainer: styles.expandContainer }}
              >
                {tipsExpanded && (
                  <View style={styles.expandContentContainer}>
                    <Heading_C style={styles.expandHeading} styleType="h3">
                      Tips
                    </Heading_C>
                    <SimpleContainer_C>
                      {tips ? <Tips_C tips={tips} /> : <Text_C>No tips</Text_C>}
                    </SimpleContainer_C>
                  </View>
                )}

                {analysisExpanded && (
                  <View style={styles.expandContentContainer}>
                    <Heading_C style={styles.expandHeading} styleType="h3">
                      Analysis
                    </Heading_C>
                    <SimpleContainer_C>
                      {analysis ? <Analysis_C analysis={analysis} /> : <Text_C>No analysis</Text_C>}
                    </SimpleContainer_C>
                  </View>
                )}
                {scratchingsExpanded && (
                  <View style={styles.expandContentContainer}>
                    <Heading_C style={styles.expandHeading} styleType="h3">
                      Scratched
                    </Heading_C>

                    <SimpleContainer_C>
                      <Scratchings_C races={[race]} />
                    </SimpleContainer_C>
                  </View>
                )}

                {gearChangeExpanded && (
                  <View style={styles.expandContentContainer}>
                    <Heading_C style={styles.expandHeading} styleType="h3">
                      Gear
                    </Heading_C>

                    <SimpleContainer_C>
                      <GearChanges_C races={[race]} />
                    </SimpleContainer_C>
                  </View>
                )}
                {infoExpanded && (
                  <View style={styles.expandContentContainer}>
                    <Heading_C style={styles.expandHeading} styleType="h3">
                      Info
                    </Heading_C>
                    <View style={styles.contentContainer}>
                      <Text_C>no info</Text_C>
                    </View>
                  </View>
                )}
              </ExpandContainer_C>
            ),
            cells: [
              {
                content: (
                  <Link_C
                    navigateTo={[
                      'Meeting',
                      {
                        date,
                        trackSlug,
                        dayPhaseLetter,
                        raceNumber: `${raceNumber}`,
                        type: isTrial ? 'trial' : 'meeting'
                      }
                    ]}
                    style={{ elem: breakpointStyles.raceLinkContainer }}
                  >
                    <View style={styles.raceNumber}>
                      <Text_C style={styles.raceNumberText}>R{raceNumber}</Text_C>
                    </View>
                    <View style={styles.timeContainer}>
                      <Text_C>{humanFriendlyTime(startTime)}</Text_C>
                      <RaceCountdownText_C startTime={startTime} />
                    </View>
                    <View>
                      <Text_C>{distance}m</Text_C>
                      <Text_C>{runners?.length} Runners</Text_C>
                    </View>
                  </Link_C>
                )
              },
              {
                //Fields
                style: breakpointStyles.cellContainer,
                content: (
                  <>
                    <Link_C
                      styleType="iconOnly"
                      icon={eyeIcon}
                      navigateTo={[
                        'Meeting',
                        {
                          date,
                          trackSlug,
                          dayPhaseLetter,
                          raceNumber: `${raceNumber}`,
                          type: isTrial ? 'trial' : 'meeting'
                        }
                      ]}
                    />
                    <Button_C styleType="iconOnly" icon={printIcon} />
                  </>
                )
              },
              {
                //Form
                style: breakpointStyles.cellContainer,
                content: (
                  <>
                    <Link_C
                      styleType="iconOnly"
                      icon={eyeIcon}
                      navigateTo={[
                        'Meeting',
                        {
                          date,
                          trackSlug,
                          dayPhaseLetter,
                          raceNumber: `${raceNumber}`,
                          type: isTrial ? 'trial' : 'meeting'
                        }
                      ]}
                    />
                    <Button_C styleType="iconOnly" icon={playIcon} />
                    <Button_C styleType="iconOnly" icon={printIcon} />
                  </>
                )
              },
              {
                //Tips
                style: breakpointStyles.cellContainer,
                content: (
                  <Button_C
                    styleType="iconOnly"
                    icon={eyeIcon}
                    onPress={() =>
                      setExpandedRow(
                        tipsExpanded
                          ? undefined
                          : {
                              raceNumber: raceNumber,
                              contentType: 'tips'
                            }
                      )
                    }
                  />
                )
              },
              <Button_C
                styleType="iconOnly"
                icon={{
                  ...eyeIcon,
                  ...(analysisExpanded && { color: presets.primary })
                }}
                disabled={!analysis}
                onPress={() =>
                  setExpandedRow(
                    analysisExpanded
                      ? undefined
                      : {
                          raceNumber,
                          contentType: 'analysis'
                        }
                  )
                }
              />,
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: 10
                }}
              >
                <Button_C
                  styleType="iconOnly"
                  disabled={!scratchingsCount}
                  icon={{
                    ...eyeIcon,
                    ...(scratchingsExpanded && {
                      color: presets.primary
                    })
                  }}
                  onPress={() =>
                    setExpandedRow(
                      scratchingsExpanded
                        ? undefined
                        : {
                            raceNumber,
                            contentType: 'scratchings'
                          }
                    )
                  }
                />
                {scratchingsCount && <Text_C>{`(${scratchingsCount})`}</Text_C>}
              </View>,
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: 10
                }}
              >
                <Button_C
                  styleType="iconOnly"
                  disabled={!gearChangesCount}
                  icon={{
                    ...eyeIcon,
                    ...(gearChangeExpanded && {
                      color: presets.primary
                    })
                  }}
                  onPress={() =>
                    setExpandedRow(
                      gearChangeExpanded
                        ? undefined
                        : {
                            raceNumber,
                            contentType: 'gear'
                          }
                    )
                  }
                />
                {gearChangesCount && <Text_C>{`(${gearChangesCount})`}</Text_C>}
              </View>,
              {
                //Info
                content: (
                  <Button_C
                    styleType="iconOnly"
                    icon={eyeIcon}
                    onPress={() =>
                      setExpandedRow(
                        infoExpanded
                          ? undefined
                          : {
                              raceNumber: raceNumber,
                              contentType: 'info'
                            }
                      )
                    }
                  />
                )
              },
              {
                //Favorite/Odds
                style: breakpointStyles.cellContainer,
                content: !favorite ? (
                  <></>
                ) : (
                  <>
                    <Image
                      resizeMode="contain"
                      style={styles.silkImage as ImageStyle}
                      source={{
                        uri: `${environment.apiUrl}/${favorite.silk}`
                      }}
                    />
                    {favorite.horse.slug ? (
                      <Link_C navigateTo={['Horse', { horseSlug: favorite.horse.slug }]}>
                        {favorite.horse.name}
                      </Link_C>
                    ) : (
                      <Text_C>{favorite.horse.name}</Text_C>
                    )}
                    <View style={styles.oddsContainer}>
                      <Text_C style={{}}>{formatCurrency(favorite.odds)}</Text_C>
                    </View>
                    <Icon_C
                      name="chevron"
                      color={colors.gray600}
                      style={styles.expandIcon}
                      strokeWidth={1.5}
                    />
                  </>
                )
              }
            ]
          } as TableRow
        })}
      />
    </Card_C>
  )
}

const tableStyles: TableStyles = {
  tableContainer: { width: '100%' },
  table: { width: '100%', minWidth: 1230 },
  cell: {
    paddingHorizontal: 12,
    paddingVertical: 14
  }
}

const breakpointStyles = createStyles({
  raceLinkContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  raceNumber: {
    ...raceNumber.container,
    backgroundColor: colors.darkBlue,
    marginRight: 10
  },
  raceNumberText: { ...raceNumber.text, color: 'white' },
  timeContainer: { marginRight: 20 },
  expandIcon: {
    alignSelf: 'center',
    width: 20,
    height: 10,
    transform: [{ rotate: '-90deg' }]
  },
  expandContainer: {
    borderColor: presets.border,
    borderWidth: 1,
    borderTopWidth: 0
  },
  expandContentContainer: {
    padding: 30,
    backgroundColor: colors.gray50
  },
  expandHeading: { marginBottom: 20 },
  contentContainer: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: presets.border,
    padding: 30,
    marginRight: 'auto',
    gap: 20
  },
  oddsContainer: {
    backgroundColor: colors.gray200,
    width: 76,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginLeft: 'auto'
  },
  silkImage: { aspectRatio: 0.838, marginTop: -8, width: 50 },
  cellContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20
  }
})
