import {
  SitePageResponse,
  fetchSitePage
} from '../../../services/data/request-functions/page-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { Heading_C } from '../../Base/Heading/Heading'
import { Card_C } from '../../Layout/Card/Card'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { Metadata } from '../../Partials/Metadata/Metadata'
import { RenderRichText } from '../../Partials/RichText/RichText'

export function Page_C(): JSX.Element {
  return withScreenWrapper<'Pages', [SitePageResponse]>({
    requestsSetup: ({ pageSlug }) => [fetchSitePage({ pageSlug })],

    view: ({ responses }) => {
      const [{ page }] = responses!
      const styles = useBreakpointStyles({ styles: breakpointStyles })
      return (
        <>
          <Metadata title={page.metaTitle || page.title} description={page.metaDescription} />

          <Card_C style={styles.newsCard}>
            <Heading_C style={{ base: styles.heading }} styleType="h1">
              {page.title}
            </Heading_C>
            <RenderRichText content={page.content} />
          </Card_C>
        </>
      )
    }
  })
}

const breakpointStyles = createStyles({
  heading: {
    base: {
      marginBottom: 0
    }
  },
  newsCard: {
    base: {
      gap: 10
    },
    large: {
      width: '80%',
      alignSelf: 'center'
    }
  },
  image: {
    base: {
      width: '100%',
      flex: 1,
      aspectRatio: 1.7889
    }
  }
})
