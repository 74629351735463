import { useEffect, useMemo, useState } from 'react'

import { useNavigation } from '@react-navigation/native'
import { isSameDay } from 'date-fns'
import { australianStates } from '../../../constants'
import {
  LatestRacesResponse,
  getNextToGo
} from '../../../services/data/request-functions/latest-races-request'
import {
  NewsListResponse,
  getNewsList
} from '../../../services/data/request-functions/news-list-request'
import { AustralianState } from '../../../types/map.type'
import { savePageInHistory } from '../../../utils/save-history'
import { Option, Select_C } from '../../Base/Select/Select'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { TabConfigItem, TabView_C } from '../../Layout/TabView/TabView'
import { CalendarPickerIcon_C } from '../../Partials/CalendarPicker/CalendarPickerIcon'
import { CategoryNewsLayout_C } from './CategoryNewsLayout'
import { LatestNewsLayout_C } from './LatestNewsLayout'
import { FEATURE_ARTICLE_LIMIT, GRID_ARTICLES_PER_PAGE } from './news-constants'
import { Metadata } from '../../Partials/Metadata/Metadata'

export type TabsResponse = {
  tabName: string
  selected: boolean
  priority?: number
  slug?: string
}

const useMock = false

export function NewsIndex_C(): JSX.Element {
  return withScreenWrapper<'News', [NewsListResponse, LatestRacesResponse]>({
    requestsSetup: ({ categorySlug }) => [
      getNewsList({
        limit: categorySlug ? GRID_ARTICLES_PER_PAGE : FEATURE_ARTICLE_LIMIT,
        categorySlug,
        useMock
      }),
      getNextToGo({ limit: 6 })
    ],

    view: ({ responses, params }) => {
      const [routeResponse] = responses!
      const [newsListResponse, setNewsListResponse] = useState(routeResponse)
      const [selectedDate, setSelectedDate] = useState<Date>()
      const [selectedState, setSelectedState] = useState<AustralianState | 'Others'>()

      useEffect(() => {
        setNewsListResponse(routeResponse)
      }, [routeResponse])

      const { articles, categories, pagination } = newsListResponse
      const activeCategory = categories.find((c) => c.slug == params.categorySlug)

      const { navigate } = useNavigation()

      const { headerTools } = getElements()
      const { activeTabName, tabConfig } = getTabVars()

      return (
        <>
          <Metadata
            title={`${activeTabName}`}
            description="Read all the harness racing latest news, industry news and more on harness.org.au"
          />
          <TabView_C
            initialActiveTab={activeTabName}
            onTabSelected={(tabName) =>
              handleTabSelected(categories.find(({ title }) => title == tabName)?.slug)
            }
            tabs={tabConfig}
            heading={activeTabName}
          >
            {activeCategory && headerTools}
          </TabView_C>
        </>
      )
      function getElements() {
        const selectOptions: Option<AustralianState | 'Others' | undefined>[] = useMemo(
          () => [
            { label: 'All States', value: undefined },
            { label: 'HRA', value: 'Others' },
            ...australianStates.map((state) => ({
              label: state,
              value: state
            }))
          ],
          []
        )
        const headerTools = (
          <>
            <Select_C<AustralianState | 'Others' | undefined>
              styleType="blueCaret"
              options={selectOptions}
              initialSelection={
                selectedState
                  ? selectOptions.find((option) => option.value == selectedState)?.label
                  : selectOptions[0].label
              }
              onSelect={(selected) => handleStateSelected(selected.value ?? undefined)}
            />

            <CalendarPickerIcon_C
              maxDate={new Date()}
              selectedDate={selectedDate ?? new Date()}
              onDateSelected={(date) => {
                handleDateSelected(date)
              }}
            />
          </>
        )
        return { headerTools }
      }

      function getTabVars(): {
        activeTabName: string
        tabsAndPriority: TabsResponse[]
        tabConfig: TabConfigItem[]
      } {
        const tabs: TabsResponse[] = [
          {
            tabName: 'Latest News',
            selected: !activeCategory,
            priority: 0
          },
          ...categories.map((c) => {
            return {
              tabName: c.title,
              selected: activeCategory?.slug == c.slug,
              priority: c.priority
            }
          })
        ]

        const activeTabName = tabs.find(({ selected }) => selected)?.tabName

        if (!activeTabName) {
          // eslint-disable-next-line no-console
          console.error('should have found active tab name')
        }

        const view = !activeCategory ? (
          <LatestNewsLayout_C articles={articles} />
        ) : (
          <CategoryNewsLayout_C
            {...{
              articles,
              pagination,
              activeCategory,
              selectedDate,
              selectedState
            }}
          />
        )
        const tabConfig: TabConfigItem[] = sortByPriority(tabs)?.map(({ tabName, selected }) => [
          tabName,
          selected ? view : <></>,
          {}
        ])

        return {
          activeTabName: activeTabName!,
          tabsAndPriority: tabs,
          tabConfig
        }

        function sortByPriority(tabs: TabsResponse[]): TabsResponse[] {
          return tabs.sort((a, b) => {
            const ap = a.priority
            const bp = b.priority
            if (ap && bp) {
              return ap > bp ? 1 : -1
            }
            if (ap && !bp) {
              return 1
            }
            if (!ap && bp) {
              return -1
            }
            return 0
          })
        }
      }

      function handleTabSelected(categorySlug: string | undefined) {
        savePageInHistory()
        navigate('News', { categorySlug: categorySlug ?? '' })
      }

      function handleDateSelected(date: Date) {
        const updatedDate = selectedDate && isSameDay(selectedDate, date) ? undefined : date
        setSelectedDate(updatedDate)
        getNewsList({
          limit: GRID_ARTICLES_PER_PAGE,
          categorySlug: activeCategory?.slug,
          state: selectedState,
          date: updatedDate
        }).then(setNewsListResponse)
      }
      function handleStateSelected(state?: AustralianState | 'Others') {
        setSelectedState(state)
        getNewsList({
          limit: GRID_ARTICLES_PER_PAGE,
          categorySlug: activeCategory?.slug,
          state: state,
          date: selectedDate
        }).then(setNewsListResponse)
      }
    }
  })
}
