/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { toUpper } from 'lodash'
import { removeLeadingSlash } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { convertAsset } from '../../../utils/assets'
import { fetchData } from '../api-fetch-request'
import { VideosListFetchResponse } from '../data-types/fetch-response-types/videos-list-response.type'
import { VideoReplayMeeting } from '../data-types/local-data-types/video-replay.type'

export type VideosListResponse = {
  date: Date
  minMeetingDate: Date
  maxMeetingDate: Date
  meetings: VideoReplayMeeting[]
}

export const getAllVideoReplays = ({ date }: { date?: string }): Promise<VideosListResponse> => {
  const endpoint = 'videos/replays/list?' + new URLSearchParams({ date: `${date ?? ''}` })

  return fetchData<VideosListResponse, VideosListFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(payload: VideosListFetchResponse) {
    const { date, max_meeting_date, min_meeting_date, meetings } = payload
    const converted: VideosListResponse = {
      date: new Date(date),
      maxMeetingDate: new Date(max_meeting_date),
      minMeetingDate: new Date(min_meeting_date),
      meetings: meetings.map((m) => {
        const { date, day_night_twilight, is_trial, track, races } = m

        const meeting: VideoReplayMeeting = {
          date: new Date(date),
          dayPhaseLetter: day_night_twilight,
          isTrial: is_trial,
          track: {
            name: track.desktop_display_name,
            shortName: track.mobile_display_name,
            slug: removeLeadingSlash(track.slug),
            state: toUpper(track.state) as AustralianState
          },
          races: races.map((r) => {
            return {
              raceNumber: r.race_number,
              distance: r.distance,
              name: r.name,
              replay: convertAsset(r.replay_video),
              startTime: new Date(r.scheduled_start_time),
              hasStewardsReplay: r.has_stewards_replay,
              stewardsReplays: r.stewards_replay_videos?.map((v) => convertAsset(v, true))
            }
          })
        }
        return meeting
      })
    }

    return converted
  }
}
