import { Slider } from '@miblanchard/react-native-slider'
import { format } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'

import { australianStates } from '../../../../constants'
import { TrackBasic } from '../../../../services/data/data-types/local-data-types/track-basic.type'
import { getTracksBasicData } from '../../../../services/data/request-functions/tracks-basic-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { Select_C } from '../../../Base/Select/Select'
import { Text_C } from '../../../Base/Text/Text'
import { FiltersContainer_C } from '../../../Partials/FiltersContainer/FiltersContainer'
import { CalendarPickerIcon_C } from '../../../Partials/CalendarPicker/CalendarPickerIcon'
import {
  ProgramSearchFilter,
  ageSelectOptions,
  distanceOptions,
  gaitSelectOptions,
  prizeMoneySelectOptions,
  sexSelectOptions,
  startTypeSelectOptions
} from '../program-search'

import { colors } from '../../../../styles/colors'
import { getFirstDayOfMonth } from '../../../../utils/date-time'

type Props = {
  // eslint-disable-next-line no-unused-vars
  onSearch(filter: ProgramSearchFilter): void
  filter: ProgramSearchFilter
  setFilter: React.Dispatch<React.SetStateAction<ProgramSearchFilter>>
}

export function ProgramSearchFilters_C({ onSearch, filter, setFilter }: Props) {
  const [trackOptions, setTrackOptions] = useState<TrackBasic[]>()
  const [ratingValues, setRatingValues] = useState([30, 120])

  useEffect(() => {
    getTracksBasicData({ useDesignMock: false }).then(({ tracksBasic }) =>
      setTrackOptions(tracksBasic)
    )
  }, [])

  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })

  const {
    fromDatePicker,
    toDatePicker,
    stateSelect,
    ratingSlider,
    trackSelect,
    ageSelect,
    sexSelect,
    distanceSelect,
    gaitSelect,
    prizeMoneySelect,
    startTypeSelect,
    includeTrialSelect
  } = getElements()

  return (
    <View style={styles.container}>
      <FiltersContainer_C
        isExpanded
        dateFilters={[
          { label: 'From', filter: fromDatePicker },
          { label: 'To', filter: toDatePicker }
        ]}
        ratingSlider={{ label: 'Rating', filter: ratingSlider }}
        filters={[
          { label: 'State', filter: stateSelect },
          ...(trackSelect ? [{ label: 'Track', filter: trackSelect }] : []),
          { label: 'Distance', filter: distanceSelect },
          { label: 'Age', filter: ageSelect },
          { label: 'Sex', filter: sexSelect },
          { label: 'Gait', filter: gaitSelect },
          { label: 'Prize Money', filter: prizeMoneySelect },
          { label: 'Start Type', filter: startTypeSelect },
          { label: 'Include Trials', filter: includeTrialSelect }
        ]}
        useSubmitButton={() => {
          onSearch(filter)
        }}
      />
    </View>
  )

  function getElements() {
    const trackSelect = useMemo(
      () =>
        !trackOptions?.length ? null : (
          <Select_C<TrackBasic | undefined>
            styleType="greyChevron"
            options={[
              { label: 'Track', value: undefined },
              ...trackOptions
                .filter((t) => !filter.state || t.state == filter.state)
                .map((t) => ({ label: t.name, value: t }))
            ]}
            initialSelection={filter.track?.name}
            styles={{ head: { flex: 1 } }}
            onSelect={({ value: track }) =>
              setFilter({
                ...filter,
                track: track
              })
            }
          />
        ),
      [trackOptions, filter]
    )

    const stateSelect = useMemo(
      () => (
        <Select_C
          styleType="greyChevron"
          options={[
            { label: 'State', value: undefined },
            ...australianStates.map((s) => ({ label: s, value: s }))
          ]}
          initialSelection={filter.state}
          styles={{ head: { flex: 1 } }}
          onSelect={({ value: state }) =>
            setFilter({
              ...filter,
              track: filter.track?.state == state ? filter.track : undefined,
              state
            })
          }
        />
      ),
      [filter]
    )

    const fromDatePicker = (
      <View
        style={{
          gap: 10,
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          zIndex: 1
        }}
      >
        <CalendarPickerIcon_C
          selectedDate={filter.fromDate}
          onDateSelected={(selected) => {
            setFilter((prev) => ({ ...prev, fromDate: selected }))
          }}
          minDate={getFirstDayOfMonth()}
        />
        <Text_C>{format(filter.fromDate, 'do MMM')}</Text_C>
      </View>
    )
    const toDatePicker = (
      <View style={{ gap: 10, flexDirection: 'row', alignItems: 'center', flex: 1 }}>
        <CalendarPickerIcon_C
          selectedDate={filter.toDate}
          onDateSelected={(selected) => {
            setFilter((prev) => ({ ...prev, toDate: selected }))
          }}
        />
        <Text_C>{format(filter.toDate, 'do MMM')}</Text_C>
      </View>
    )

    const includeTrialSelect = useMemo(
      () => (
        <Select_C
          styleType="greyChevron"
          options={[
            { label: 'Include Trials', value: false },
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
          styles={{ head: { flex: 1 } }}
          onSelect={({ value: bool }) =>
            setFilter({
              ...filter,
              includeTrial: bool
            })
          }
        />
      ),
      [filter]
    )

    const distanceSelect = useMemo(() => {
      return (
        <Select_C
          styleType="greyChevron"
          options={distanceOptions}
          initialSelection={filter.distance}
          styles={{ head: { flex: 1 } }}
          onSelect={({ value: distance }) =>
            setFilter({
              ...filter,
              distance
            })
          }
        />
      )
    }, [filter])

    const ageSelect = useMemo(
      () => (
        <Select_C
          styleType="greyChevron"
          options={ageSelectOptions}
          initialSelection={filter.age}
          styles={{ head: { flex: 1 } }}
          onSelect={({ value: age }) =>
            setFilter({
              ...filter,
              age
            })
          }
        />
      ),
      [filter]
    )

    const gaitSelect = useMemo(
      () => (
        <Select_C
          styleType="greyChevron"
          options={gaitSelectOptions}
          initialSelection={filter.gait}
          styles={{ head: { flex: 1 } }}
          onSelect={({ value: gait }) =>
            setFilter({
              ...filter,
              gait
            })
          }
        />
      ),
      [filter]
    )

    const sexSelect = useMemo(
      () => (
        <Select_C
          styleType="greyChevron"
          options={sexSelectOptions}
          initialSelection={filter.gait}
          styles={{ head: { flex: 1 } }}
          onSelect={({ value: sex }) =>
            setFilter({
              ...filter,
              sex
            })
          }
        />
      ),
      [filter]
    )

    const ratingSlider = (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          gap: 20
        }}
      >
        <Text_C style={{ width: 30 }}>{ratingValues[0]}</Text_C>
        <View style={{ flex: 1 }}>
          <Slider
            maximumValue={120}
            minimumValue={30}
            thumbTintColor={colors.blue}
            minimumTrackTintColor={colors.blue}
            value={ratingValues}
            onValueChange={(values) => {
              setRatingValues(values.map(Math.floor))
            }}
            onSlidingComplete={(values) => {
              setFilter({
                ...filter,
                rating: values.map(Math.floor)
              })
            }}
          />
        </View>

        <Text_C style={{ width: 30 }}>{ratingValues[1]}</Text_C>
      </View>
    )

    const prizeMoneySelect = useMemo(
      () => (
        <Select_C
          styleType="greyChevron"
          options={prizeMoneySelectOptions}
          initialSelection={filter.gait}
          styles={{ head: { flex: 1 } }}
          onSelect={({ value: prizeMoney }) =>
            setFilter({
              ...filter,
              prizeMoney
            })
          }
        />
      ),
      [filter]
    )

    const startTypeSelect = useMemo(
      () => (
        <Select_C
          styleType="greyChevron"
          options={startTypeSelectOptions}
          initialSelection={filter.gait}
          styles={{ head: { flex: 1 } }}
          onSelect={({ value: startType }) =>
            setFilter({
              ...filter,
              startType
            })
          }
        />
      ),
      [filter]
    )

    return {
      fromDatePicker,
      toDatePicker,
      ratingSlider,
      stateSelect,
      trackSelect,
      ageSelect,
      sexSelect,
      distanceSelect,
      gaitSelect,
      prizeMoneySelect,
      startTypeSelect,
      includeTrialSelect
    }
  }
}

const breakpointStyles = createStyles({
  container: { alignItems: 'flex-end', gap: 20 }
})
