import { upperCase } from 'lodash'
import { australianStates } from '../../../constants'
import { removeLeadingSlash } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { fetchData } from '../api-fetch-request'
import { TracksBasicFetchResponse } from '../data-types/fetch-response-types/tracks-basic-response.type'
import { TrackBasic } from '../data-types/local-data-types/track-basic.type'

export type TracksBasicResponse = {
  tracksBasic: TrackBasic[]
}

type FetchTracksBasicParams = {
  useDesignMock?: boolean
}

export async function getTracksBasicData({
  useDesignMock
}: FetchTracksBasicParams): Promise<TracksBasicResponse> {
  if (useDesignMock) {
    throw 'no design mock'
  }

  const endpoint = 'racing/tracks'
  return fetchData<TracksBasicResponse, TracksBasicFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(tracksBasicPayload: TracksBasicFetchResponse): TracksBasicResponse {
    // We need to filter out any tracks that are not Australian state tracks
    const tracks = tracksBasicPayload.tracks.filter((track) => {
      const state = upperCase(track.state) as AustralianState
      return australianStates.includes(state)
    })

    const tracksBasic = tracks.map((payloadTrack) => {
      const {
        map_latitude,
        map_longitude,
        desktop_display_name,
        mobile_display_name,
        slug,
        state
      } = payloadTrack

      const basicTrack: TrackBasic = {
        name: desktop_display_name,
        shortName: mobile_display_name,
        slug: removeLeadingSlash(slug),
        state: state?.toUpperCase() as AustralianState,
        lat: map_latitude,
        lng: map_longitude
      }

      return basicTrack
    })
    return { tracksBasic }
  }
}
