import { PerPageOption } from '../../../components/Partials/Pagination/components/PerPageSelect'
import { mapSilkObj, removeLeadingSlash, toCapitalCase } from '../../../helper-functions'
import { BaseFetchResponse } from '../../../types/fetch-response-base.type'
import { fetchData } from '../api-fetch-request'
import {
  NotificationsCountFetchResponse,
  NotificationsFetchResponse
} from '../data-types/fetch-response-types/notifications-response.type'
import { meetingStatusMap, Pagination } from '../data-types/general-data-types.type'
import {
  BlackbookNotification,
  NotificationRunnerRace
} from '../data-types/local-data-types/notifications.type'

export type NotificationsResponse = {
  notifications: BlackbookNotification[]
  pagination: Pagination
}
type NotificationsProps = {
  authToken: string
  activePageNumber?: number
  perPage?: PerPageOption
}

export async function getNotifications({
  authToken,
  activePageNumber,
  perPage
}: NotificationsProps): Promise<NotificationsResponse> {
  return fetchData<NotificationsResponse, NotificationsFetchResponse>({
    endpoint:
      `member/notifications?` +
      new URLSearchParams({
        'pagination[limit]': `${perPage ?? 10}`,
        'pagination[page]': `${activePageNumber ?? 1}`
      }),
    convertResponse,
    validationCompareObject: undefined,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })

  function convertResponse(payload: NotificationsFetchResponse): NotificationsResponse {
    const { notifications, pagination } = payload
    const response: NotificationsResponse = {
      pagination: {
        activePageNumber: pagination.page,
        totalPages: pagination.pages,
        perPage: pagination.limit as PerPageOption,
        totalRecords: pagination.total
      },
      notifications: notifications.map(({ subject, created_at, races, serial }) => ({
        id: serial,
        message: subject,
        date: new Date(created_at),
        runnerRaces: races?.map(
          ({
            race_number,
            scheduled_start_time,
            meeting,
            distance,
            runner: { horse, saddlecloth_number, forecast_odds, silk, woo_average_odds }
          }) => {
            const race: NotificationRunnerRace = {
              startTime: new Date(scheduled_start_time),
              raceNumber: race_number,
              distance,
              runner: {
                runnerNumber: saddlecloth_number ?? undefined,
                silk: silk ? mapSilkObj(silk) : undefined,

                odds:
                  !woo_average_odds && !forecast_odds
                    ? undefined
                    : woo_average_odds || `${forecast_odds}*`,
                horse: {
                  name: horse.name ?? 'unnamed',
                  slug: horse.slug ? removeLeadingSlash(horse.slug) : undefined
                } // payload needs runner name and slug
              },
              meeting: {
                isTrial: meeting.is_trial,
                date: new Date(meeting.date),
                dayPhaseLetter: meeting.day_night_twilight,
                meetingStatus: meetingStatusMap[meeting.meeting_status]
              },
              track: {
                name: toCapitalCase(meeting.track.desktop_display_name),
                shortName: toCapitalCase(meeting.track.mobile_display_name),
                slug: removeLeadingSlash(meeting.track.slug)
              }
            }
            return race
          }
        )
      }))
    }

    return response
  }
}

type ClearNotificationProps = {
  authToken: string
  id: string
}

export async function clearNotification({ authToken, id }: ClearNotificationProps): Promise<any> {
  return fetchData<any, BaseFetchResponse>({
    endpoint: `member/notifications/${id}/delete`,
    convertResponse: () => {},
    validationCompareObject: undefined,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
}

type ClearAllNotificationsProps = {
  authToken: string
}
export async function clearAllNotifications({
  authToken
}: ClearAllNotificationsProps): Promise<any> {
  return fetchData<any, BaseFetchResponse>({
    endpoint: `member/notifications/all/delete`,
    convertResponse: () => {},
    validationCompareObject: undefined,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
}

export type NotificationsCountResponse = {
  count: number
}
type NotificationsCountProps = {
  authToken: string
}

export async function getNotificationsCount({
  authToken
}: NotificationsCountProps): Promise<NotificationsCountResponse> {
  return fetchData<NotificationsCountResponse, NotificationsCountFetchResponse>({
    endpoint: `member/notifications/count`,
    convertResponse: (payload: NotificationsCountFetchResponse) => {
      return {
        count: payload.unread_count
      }
    },
    validationCompareObject: undefined,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
}
