import { HorsePerformanceFetchResponse } from '../data-types/fetch-response-types/horse-response.type'

export const horsePerformanceCompare: HorsePerformanceFetchResponse = {
  meta: {
    status: 200,
    code: 'OK'
  },
  pagination: {
    limit: 10,
    page: 1,
    total: 15,
    pages: 2
  },
  performance_records: [
    {
      place: '7',
      formatted_handicap: 'Fr2',
      prizemoney: 60.0,
      margin: '22.3m',
      starting_price: '71.00',
      barrier: 'Fr2',
      meeting: {
        date: '2024-01-11',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 2,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '1:59.9',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    },
    {
      place: '9',
      formatted_handicap: 'Fr2',
      prizemoney: 60.0,
      margin: '31.2m',
      starting_price: '101.00',
      barrier: 'Fr2',
      meeting: {
        date: '2024-01-04',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 5,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '2:00.6',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Adam Richardson',
        mobile_display_name: 'A P Richardson',
        slug: '/adam-richardson'
      }
    },
    {
      place: '7',
      formatted_handicap: 'Fr3',
      prizemoney: 60.0,
      margin: '12.5m',
      starting_price: '51.00',
      barrier: 'Fr3',
      meeting: {
        date: '2023-12-28',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 5,
        distance: 2040,
        start_type: 'MS',
        time_mile_rate: '2:02.6',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    },
    {
      place: '10',
      formatted_handicap: 'Fr2',
      prizemoney: 60.0,
      margin: '46.7m',
      starting_price: '21.00',
      barrier: 'Fr2',
      meeting: {
        date: '2023-12-14',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 8,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '2:00.3',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Angus Garrard',
        mobile_display_name: 'A R Garrard',
        slug: '/angus-garrard'
      }
    },
    {
      place: '2',
      formatted_handicap: 'Fr1',
      prizemoney: 677.0,
      margin: '3.6m',
      starting_price: '8.00',
      barrier: 'Fr1',
      meeting: {
        date: '2023-12-10',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Marburg',
        mobile_display_name: 'Marburg',
        slug: '/marburg',
        published: true
      },
      race: {
        number: 5,
        distance: 1850,
        start_type: 'MS',
        time_mile_rate: '2:02.6',
        has_replay: true
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    },
    {
      place: '7',
      formatted_handicap: 'Fr3',
      prizemoney: 60.0,
      margin: '41.0m',
      starting_price: '101.00',
      barrier: 'Fr3',
      meeting: {
        date: '2023-11-30',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 4,
        distance: 2040,
        start_type: 'MS',
        time_mile_rate: '2:02.1',
        has_replay: true
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Paige Bevan',
        mobile_display_name: 'P M Bevan',
        slug: '/paige-bevan'
      }
    },
    {
      place: '7',
      formatted_handicap: 'Fr3',
      prizemoney: 60.0,
      margin: '19.0m',
      starting_price: '26.00',
      barrier: 'Fr3',
      meeting: {
        date: '2023-11-27',
        day_night_twilight: 'N',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 3,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '2:01.8',
        has_replay: true
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Paige Bevan',
        mobile_display_name: 'P M Bevan',
        slug: '/paige-bevan'
      }
    },
    {
      place: '6',
      formatted_handicap: 'Fr2',
      prizemoney: 60.0,
      margin: '30.0m',
      starting_price: '21.00',
      barrier: 'Fr2',
      meeting: {
        date: '2023-11-19',
        day_night_twilight: 'T',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 6,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '1:59.6',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Chloe Butler',
        mobile_display_name: 'C J Butler',
        slug: '/chloe-butler'
      }
    },
    {
      place: '4',
      formatted_handicap: 'Fr1',
      prizemoney: 226.0,
      margin: '17.7m',
      starting_price: '34.00',
      barrier: 'Fr1',
      meeting: {
        date: '2023-11-12',
        day_night_twilight: 'N',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Redcliffe',
        mobile_display_name: 'Redcliffe',
        slug: '/redcliffe',
        published: true
      },
      race: {
        number: 7,
        distance: 1780,
        start_type: 'MS',
        time_mile_rate: '2:00.6',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Leonard Cain',
        mobile_display_name: 'L T Cain',
        slug: '/leonard-cain'
      }
    },
    {
      place: '8',
      formatted_handicap: 'Fr4',
      prizemoney: 60.0,
      margin: '33.9m',
      starting_price: '31.00',
      barrier: 'Fr4',
      meeting: {
        date: '2023-11-05',
        day_night_twilight: 'D',
        is_trial: false
      },
      track: {
        desktop_display_name: 'Marburg',
        mobile_display_name: 'Marburg',
        slug: '/marburg',
        published: true
      },
      race: {
        number: 2,
        distance: 1850,
        start_type: 'MS',
        time_mile_rate: '2:02.2',
        has_replay: false
      },
      trainer: {
        display_name: 'Lynne Collins',
        mobile_display_name: 'L A Collins',
        slug: '/lynne-collins'
      },
      driver: {
        display_name: 'Alanah Richardson',
        mobile_display_name: 'A K Richardson',
        slug: '/alanah-richardson'
      }
    }
  ]
}
