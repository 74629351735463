/* eslint-disable react/jsx-key */
import { View } from 'react-native'

import { formatCurrency } from '../../../helper-functions'
import { FeatureWin } from '../../../services/data/data-types/general-data-types.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../services/styles/dependencies/style-constants'
import { Heading_C } from '../../Base/Heading/Heading'
import { Link_C } from '../../Base/Link/Link'
import { TableStyles, Table_C } from '../../Base/Table/Table'
import { TableHeading, TableRow } from '../../Base/Table/table.type'
import { Card_C } from '../../Layout/Card/Card'
import { TableCellRaceLink_C } from '../_meeting-components/TableCellRaceLink'

type Props = { featureWins: FeatureWin[] }

export function FeatureRaceWins_C({ featureWins }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const isMobileLayout = breakpointBelow(tabletBreakpoint)
  const performanceHeadings: TableHeading[] = [
    { content: 'Date', colWidth: { width: 190 } },
    { content: 'Horse', colWidth: { width: 170 } },
    { content: 'Race Name', colWidth: { flex: 2 } },
    'Race Class',
    'Race Stakes'
  ]

  const featureWinsRows: TableRow[] = featureWins?.map((r) => {
    const { meetingDate, dayPhaseLetter, raceNumber, hasReplay, trackSlug, track, isTrial } = r
    return {
      cells: [
        <TableCellRaceLink_C
          {...{
            date: meetingDate,
            dayPhaseLetter,
            isTrial,
            trackName: track,
            trackSlug,
            hasReplay,
            raceNumber: `${raceNumber}`
          }}
        />,
        <Link_C navigateTo={['Horse', { horseSlug: r.horseSlug }]}>{r.horse}</Link_C>,
        (isMobileLayout && r.raceShortName) || r.raceName,
        r.blackType,
        formatCurrency(r.prizemoney)
      ]
    }
  })
  return (
    <>
      <Card_C style={styles.racesCard}>
        <View style={styles.raceGroup}>
          <Heading_C styleType="h4" style={breakpointStyles.raceHeading}>
            Feature Race Wins
          </Heading_C>

          <Table_C
            styleType="lightHeaderBorders"
            headings={performanceHeadings}
            rows={featureWinsRows}
            styles={tableStyle}
          />
        </View>
      </Card_C>
    </>
  )
}

const breakpointStyles = createStyles({
  racesCard: {
    base: {
      gap: 40
    }
  },
  raceGroup: {
    base: {}
  },
  raceHeading: {
    base: { marginBottom: 16 },
    [tabletBreakpoint]: { marginBottom: 22 },
    [desktopBreakpoint]: { marginBottom: 30 }
  }
})

const tableStyle: TableStyles = {
  tableContainer: { base: { minWidth: 'auto', flex: 1, maxWidth: 1000 } },
  table: { width: '100%', minWidth: 700 }
}
