import { useEffect, useRef, useState } from 'react'

import { View } from 'react-native'
import { filterExpiredRaces, getExpiryTime } from '../../../../../helper-functions'
import {
  LatestRace,
  getNextToGo
} from '../../../../../services/data/request-functions/latest-races-request'
import { createStyles } from '../../../../../services/styles/breakpoint-styles.service'
import { textSizes } from '../../../../../styles/text-sizes'
import { ScrollHorizontal_C } from '../../../../Base/ScrollHorizontal/ScrollHorizontal'
import { Text_C } from '../../../../Base/Text/Text'
import { NextToGoRace_C } from './NextToGoRace'

/*
  - max items variable (18)
  - if state selected only that state
  - as they click the next arrow, animates in next items, variable amount(1)
  - number of items displayed relative to available retails pace
*/

const maxRaces = 18
const minAmountForRefetch = 8
const expireMinsAfterRaceStart = 5

export function NextToGo_C() {
  const [nextRaces, setNextRaces] = useState<LatestRace[]>([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const raceExpireTimers = useRef<any[]>([])

  useEffect(() => {
    let latestRaces: LatestRace[]

    getAndSetLatestRaces()

    function getAndSetLatestRaces() {
      getNextToGo({ limit: maxRaces }).then(({ races }) => {
        clearAllTimers()
        latestRaces = filterExpiredRaces({
          races,
          extraMins: expireMinsAfterRaceStart
        })
        setNextRaces(latestRaces)
        removeAfterStart(latestRaces)
      })
    }

    function removeAfterStart(races: LatestRace[]) {
      races.forEach((race) => {
        const expireTime = getExpiryTime({
          date: race.startTime,
          extraMins: expireMinsAfterRaceStart
        })
        raceExpireTimers.current.push(setTimeout(onRaceExpire, expireTime))

        function onRaceExpire() {
          latestRaces = filterExpiredRaces({
            races: latestRaces,
            extraMins: expireMinsAfterRaceStart
          })
          if (latestRaces.length > minAmountForRefetch) {
            setNextRaces(latestRaces)
          } else {
            getAndSetLatestRaces()
          }
        }
      })
    }

    function clearAllTimers() {
      raceExpireTimers.current.forEach(clearTimeout)
    }
    return clearAllTimers
  }, [])

  return (
    <View style={styles.nextToGoContainer}>
      <Text_C style={styles.nextToGoText}>Next To Go:</Text_C>
      <ScrollHorizontal_C contentContainerStyle={{ columnGap: 18 }}>
        {!nextRaces.length && <Text_C>Loading...</Text_C>}
        {nextRaces?.map((race) => (
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          <NextToGoRace_C key={race.trackName + race.startTime} race={race} />
        ))}
      </ScrollHorizontal_C>
    </View>
  )
}

const styles = createStyles({
  nextToGoContainer: {
    flex: 1,
    flexDirection: 'row',
    gap: 10
  },
  nextToGoText: { alignSelf: 'center' },

  colorDot: {
    width: 12,
    height: 12,
    borderRadius: 100
  },
  infoContainer: {},
  trackName: {
    ...textSizes.size1,
    fontWeight: '700Bold'
  },
  raceDetails: {
    flexDirection: 'row',
    gap: 10
  }
})
