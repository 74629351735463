import { View } from 'react-native'

import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { Heading_C } from '../../Base/Heading/Heading'
import { Card_C } from '../../Layout/Card/Card'
import { GridAndGraph_C } from '../GridAndGraph/GridAndGraph'
import { PerformanceStats } from '../../../services/data/data-types/general-data-types.type'

type Props = {
  lifetimePerformance: PerformanceStats
  lastSeasonPerformance: PerformanceStats
  thisSeasonPerformance: PerformanceStats
}

export function TrainerDriverStats_C({
  lifetimePerformance,
  lastSeasonPerformance,
  thisSeasonPerformance
}: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const { lifetime, thisSeason, lastSeason } = getElements()

  return (
    <Card_C style={styles.statsCard}>
      {lifetime}
      {thisSeason}
      {lastSeason}
    </Card_C>
  )

  function getElements() {
    const lifetime = (
      <View style={styles.statsGroup}>
        <Heading_C styleType="h4" style={breakpointStyles.statsHeading}>
          Lifetime
        </Heading_C>
        <GridAndGraph_C {...{ stats: lifetimePerformance }} />
      </View>
    )

    const thisSeason = (
      <View style={styles.statsGroup}>
        <Heading_C styleType="h4" style={breakpointStyles.statsHeading}>
          This Season
        </Heading_C>
        <GridAndGraph_C {...{ stats: thisSeasonPerformance }} />
      </View>
    )

    const lastSeason = (
      <View style={styles.statsGroup}>
        <Heading_C styleType="h3" style={breakpointStyles.statsHeading}>
          Last Season
        </Heading_C>
        <GridAndGraph_C {...{ stats: lastSeasonPerformance }} />
      </View>
    )
    return {
      lifetime,
      thisSeason,
      lastSeason
    }
  }
}

const breakpointStyles = createStyles({
  statsCard: {
    base: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      columnGap: 80,
      rowGap: 40
    }
  },
  statsGroup: {
    base: { alignItems: 'flex-start' }
  },
  statsHeading: {
    base: { marginBottom: 16 }
  },
  statsGrid: { base: { width: 138, flex: 0, flexBasis: 'auto' } },
  labelText: {
    base: { fontFamily: 'OpenSans', fontWeight: '700Bold' }
  }
})
