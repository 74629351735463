import { useEffect, useState } from 'react'
import { Image, ImageSourcePropType, ImageStyle, View } from 'react-native'
import { decodeHtmlEntities } from '../../../../helper-functions'
import { NewsArticle } from '../../../../services/data/data-types/local-data-types/news.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../../services/styles/dependencies/breakpoint-style.type'
import { ImageDimensions } from '../../../../types/miscellaneous-types.type'
import { getAssetUrl } from '../../../../utils/assets'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Link_C } from '../../../Base/Link/Link'
import { Text_C } from '../../../Base/Text/Text'
import { CategoryAndDate_C } from './CategoryAndDate'
import { baseNewsStyles } from './news-styles'

type Props = {
  article: NewsArticle
  style?: NamedBreakpointStylesExtension<typeof breakpointStyles>
}

export function NewsItemLarge_C({ article, style }: Props) {
  const heroImage = getAssetUrl(article.hero)
  const styles = useBreakpointStyles({
    styles: breakpointStyles,
    additionalStyles: [style]
  })
  const [logoDimensions, setLogoDimensions] = useState<ImageDimensions>()

  const stateLogoUrl = getAssetUrl(article.stateLogo)

  useEffect(() => {
    if (stateLogoUrl) {
      Image.getSize(stateLogoUrl, (width, height) => {
        setLogoDimensions({
          width,
          height,
          apsectRatio: width / height
        })
      })
    }
  }, [])

  return (
    <Link_C
      navigateTo={[
        'NewsArticle',
        {
          newsId: `${article.id}`,
          newsSlug: article.slug
        }
      ]}
      style={{
        elem: {
          base: { ...style, ...styles.mainContainer }
        }
      }}
    >
      <View style={styles.imageContainer as ImageStyle}>
        {heroImage ? (
          <Image
            style={styles.image as ImageStyle}
            source={(heroImage ?? '') as ImageSourcePropType}
            resizeMethod="auto"
            resizeMode="contain"
          />
        ) : (
          !!stateLogoUrl &&
          !!logoDimensions && (
            <Image
              resizeMethod="auto"
              resizeMode="contain"
              style={{
                ...(styles.fallbackImage as ImageStyle),
                maxWidth: '90%',
                maxHeight: '90%',
                height: logoDimensions.height,
                width: logoDimensions.width,
                aspectRatio: logoDimensions.apsectRatio
              }}
              source={stateLogoUrl as ImageSourcePropType}
            />
          )
        )}
      </View>

      <View style={{ gap: 6 }}>
        <CategoryAndDate_C article={article} />
        <Heading_C styleType="h3">{decodeHtmlEntities(article.headline)}</Heading_C>
        <Text_C style={styles.content} numberOfLines={3} ellipsizeMode="head">
          {article.content}
        </Text_C>
      </View>
    </Link_C>
  )
}

const breakpointStyles = createStyles({
  heading: {}, // set by home page
  mainContainer: {
    rowGap: 20
  },
  content: {
    marginTop: 3,
    fontWeight: '500Medium'
  },
  categoryAndDateContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: 10,
    gap: 7
  },
  ...baseNewsStyles
})
