import { format } from 'date-fns'
import { orderBy } from 'lodash'
import { useContext } from 'react'
import { View } from 'react-native'
import { Records } from '../../../../../../services/data/data-types/local-data-types/track-detailed.type'
import {
  breakpointIs,
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { Heading_C } from '../../../../../Base/Heading/Heading'
import { Link_C } from '../../../../../Base/Link/Link'
import { Table_C } from '../../../../../Base/Table/Table'
import { TableCell, TableHeading, TableRow } from '../../../../../Base/Table/table.type'
import { TrackContext } from '../../../Track'

export function RecordTables_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { activeTrack } = useContext(TrackContext)

  const { slug, barrierStats, trotterRecords, pacerRecords } = activeTrack

  const recordsHeadings: TableHeading[] = [
    { colWidth: { width: 85 }, content: 'Distance' },
    'Horse',
    'Date',
    { colWidth: { width: 93 }, content: 'Mile Rate' }
  ]

  const mobileBarrierHeadings = ['Barrier', 'Starts', 'Wins'] as const

  return (
    <View style={styles.section}>
      <View style={styles.recordsContainer}>
        {!!pacerRecords.length && (
          <View style={styles.recordsTableContainer}>
            <Heading_C styleType="h2">Pacers Track Records</Heading_C>
            <Table_C
              styleType="lightHeaderBorders"
              headings={recordsHeadings}
              rows={makeRecordsRows(pacerRecords)}
              styles={{
                tableContainer: tableBreakpointStyles.tableContainer
              }}
            />
          </View>
        )}

        {!!trotterRecords.length && (
          <View style={styles.recordsTableContainer}>
            <Heading_C styleType="h2">Trotters Track Records</Heading_C>
            <Table_C
              styleType="lightHeaderBorders"
              headings={recordsHeadings}
              rows={makeRecordsRows(trotterRecords)}
              styles={{
                tableContainer: tableBreakpointStyles.tableContainer
              }}
            />
          </View>
        )}
        {!!barrierStats.length && (
          <View style={[styles.recordsTableContainer, styles.mobileBarrierTable]}>
            <Heading_C styleType="h2">Mobile Barrier Stats</Heading_C>
            <Table_C
              styleType="lightHeaderBorders"
              {...getMobileBarrierHeadingsAndRows()}
              styles={{
                tableContainer: tableBreakpointStyles.tableContainer
              }}
            />
          </View>
        )}
      </View>
    </View>
  )

  function makeRecordsRows(records: Records[]): TableRow[] {
    return records.map((record): TableRow => {
      const { distance, horse, date, timeMileRate, startType, raceNumber, dayPhaseLetter } = record
      const dateString = format(new Date(date), 'dd MMM yyyy')
      const dateLink = (
        <Link_C
          navigateTo={[
            'Meeting',
            {
              trackSlug: slug,
              date: date,
              raceNumber: `${raceNumber}`,
              dayPhaseLetter: dayPhaseLetter,
              type: 'meeting' // todo: needs isTrial to build correct link
            }
          ]}
        >
          {dateString}
        </Link_C>
      )

      const horseLink = (
        <Link_C linkUrl={`horse/${horse.slug}`} newTab>
          {horse.name}
        </Link_C>
      )

      return {
        cells: [`${distance}m ${startType}`, horseLink, dateLink, timeMileRate]
      }
    })
  }

  function getMobileBarrierHeadingsAndRows(): {
    headings: TableHeading[]
    rows: TableRow[]
  } {
    return breakpointIs(['base', 'small', 'large'])
      ? {
          headings: mobileBarrierHeadings.map((h, i) => ({
            content: h,
            textStyle: { textAlign: i == 0 ? 'left' : 'center' }
          })),
          rows: orderBy(barrierStats, 'barrier')
            .map(({ starts, wins, barrier }) => [barrier, starts, wins])
            .map((row) => ({
              cells: row.map((c, i) => ({
                content: c,
                textStyle: { textAlign: i == 0 ? 'left' : 'center' }
              }))
            }))
        }
      : {
          headings: [
            mobileBarrierHeadings[0],
            ...orderBy(barrierStats, 'barrier').map(({ barrier }) => barrier)
          ].map((content, i) => ({
            content,
            textStyle: { textAlign: i == 0 ? 'left' : 'center' },
            ...(i == 0 ? { colWidth: { width: 90 } } : {})
          })),

          rows: mobileBarrierHeadings
            .slice(1)
            .map((rowHeading) => [
              rowHeading,
              ...orderBy(barrierStats, 'barrier').map(({ starts, wins }): string | number =>
                rowHeading == 'Starts' ? starts : rowHeading == 'Wins' ? wins : ''
              )
            ])
            .map((row) => ({
              cells: row.map(
                (content, i): TableCell => ({
                  content,
                  textStyle: { textAlign: i == 0 ? 'left' : 'center' }
                })
              )
            }))
        }
  }
}

const breakpointStyles = createStyles({
  section: {
    base: { marginHorizontal: 14 },
    large: { marginHorizontal: 0 }
  },
  recordsContainer: {
    base: {
      gap: 20
    },
    large: {
      gap: 30
    },
    xxlarge: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 40
    }
  },
  mobileBarrierTable: {
    base: {},
    xxlarge: { flexBasis: '100%' }
  },
  recordsTableContainer: {
    base: {
      gap: 10
    },
    xxlarge: {
      flex: 1
    }
  }
})

const tableBreakpointStyles = createStyles({
  tableContainer: { base: { minWidth: '100%' } }
})
