import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Pagination } from '../../../services/data/data-types/general-data-types.type'
import {
  NewsArticle,
  NewsCategory
} from '../../../services/data/data-types/local-data-types/news.type'
import { getNewsList } from '../../../services/data/request-functions/news-list-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../styles/text-sizes'
import { AustralianState } from '../../../types/map.type'
import { Button_C } from '../../Base/Button/Button'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { GapGrid_C } from '../../Layout/Grid/GapGrid'
import { NewsItemMedium_C } from './components/NewsItemMedium'
import { GRID_ARTICLES_PER_PAGE } from './news-constants'

type Props = {
  articles: NewsArticle[]
  pagination: Pagination
  selectedDate?: Date
  selectedState?: AustralianState | 'Others'
  activeCategory: NewsCategory
}

export function CategoryNewsLayout_C({
  articles,
  pagination: initialPagination,
  selectedState,
  selectedDate,
  activeCategory
}: Props) {
  const [pagination, setPagination] = useState(initialPagination)
  const [allArticles, setAllArticles] = useState(articles)

  const styles = useBreakpointStyles({ styles: breakpointStyles })

  useEffect(() => {
    setAllArticles(articles)
  }, [articles])

  useEffect(() => {
    setPagination(initialPagination)
  }, [initialPagination])

  function handleLoadMorePress() {
    getNewsList({
      page: pagination.activePageNumber + 1,
      categorySlug: activeCategory?.slug,
      state: selectedState,
      date: selectedDate,
      limit: GRID_ARTICLES_PER_PAGE
    }).then(({ articles, pagination }) => {
      setAllArticles((prev) => [...prev, ...articles])
      setPagination(pagination)
    })
  }

  return (
    <Card_C>
      {!articles.length ? (
        <View style={styles.noResultsElem}>
          <Text_C style={styles.noResultsText}>
            No current news articles for {activeCategory.title}
          </Text_C>
        </View>
      ) : (
        <GapGrid_C
          gridBreakpointConfig={{
            base: { columns: 2 },
            medium: { columns: 3 },
            xlarge: { columns: 4 }
          }}
          columnGap={16}
          rowGap={32}
          cells={allArticles.map((article, idx) => (
            <NewsItemMedium_C key={idx} article={article} />
          ))}
        />
      )}
      {pagination.activePageNumber < pagination.totalPages && (
        <Button_C
          styleType="fillButton"
          style={{
            elem: styles.loadMoreButton
          }}
          onPress={handleLoadMorePress}
        >
          Load More
        </Button_C>
      )}
    </Card_C>
  )
}
const breakpointStyles = createStyles({
  loadMoreButton: {
    base: {
      marginHorizontal: 'auto',
      marginTop: 40
    },
    [desktopBreakpoint]: {
      marginTop: 60
    }
  },
  noResultsElem: { alignSelf: 'center' },
  noResultsText: { ...textSizes.size4, fontStyle: 'italic' }
})
