// cspell:word Hasnt
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useNavigation } from '@react-navigation/native'
import { isBefore, subMinutes } from 'date-fns'
import { useMemo } from 'react'
import { View } from 'react-native'
import { getDateNow } from '../../../helper-functions'
import { RaceStatus } from '../../../services/data/data-types/general-data-types.type'
import { MeetingBasicParital } from '../../../services/data/data-types/local-data-types/meeting-basic.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { colors, presets, stateColor } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { humanFriendlyTime } from '../../../utils/date-time'
import { Heading_C } from '../../Base/Heading/Heading'
import { Icon_C } from '../../Base/Icon/Icon'
import { playIcon } from '../../Base/Icon/preset-icon-props'
import { Link_C } from '../../Base/Link/Link'
import { TableStyles, Table_C } from '../../Base/Table/Table'
import { TableCell, TableHeading, TableRow } from '../../Base/Table/table.type'
import { Text_C } from '../../Base/Text/Text'
import { RaceCountdownText_C } from '../RaceCountdownText/RaceCountdownText'

type Props = {
  meetings: MeetingBasicParital[]
  date: string
}

export function MeetingListTable_C(props: Props) {
  const navigation = useNavigation()
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const mostRacesNum = props.meetings.reduce((mostRacesNum, meeting) => {
    const { length } = meeting.races
    return length > mostRacesNum ? length : mostRacesNum
  }, 0)

  const tableHeadings: TableHeading[] = [
    {
      content: undefined,
      colWidth: { width: 210 }
    },
    ...[...Array(mostRacesNum)].map(
      (_, i): TableHeading => ({
        content: `R${i + 1}`
      })
    )
  ]

  const rows: TableRow[] = props.meetings.map((meeting) => {
    return {
      onSelected: () =>
        navigation.navigate('Meeting', {
          date: meeting.date,
          trackSlug: meeting.track.slug,
          dayPhaseLetter: meeting.dayPhaseLetter,
          type: meeting.isTrial ? 'trial' : 'meeting'
        }),
      cells: [
        {
          style: { paddingLeft: 0 },
          content: (
            <Link_C
              navigateTo={[
                'Meeting',
                {
                  date: props.date,
                  trackSlug: meeting.track.slug,
                  dayPhaseLetter: meeting.dayPhaseLetter,
                  type: meeting.isTrial ? 'trial' : 'meeting'
                }
              ]}
              style={{
                elem: breakpointStyles.raceLinkElem,
                text: breakpointStyles.raceLinkText
              }}
            >
              <View
                style={[styles.stateColor, { backgroundColor: stateColor[meeting.track.state!] }]}
              />
              <View style={styles.trackNameContainer}>
                <Text_C style={styles.trackNameText}>
                  {meeting.track.shortName || meeting.track.name}{' '}
                  {meeting.isTrial && (
                    <Text_C style={{ letterSpacing: 1.1, fontStyle: 'italic' }}>(T)</Text_C>
                  )}
                </Text_C>
                <Text_C style={styles.trackStateText}>{meeting.track.state}</Text_C>
              </View>
            </Link_C>
          )
        },
        ...(meeting.status == 'Scheduled'
          ? [
              <View style={{ gap: 4 }}>
                <Heading_C styleType="h4" style={{ ...textSizes.size2 }}>
                  Scheduled
                </Heading_C>
                <Text_C style={{ ...textSizes.size1 }}>Fields have not yet been drawn</Text_C>
              </View>
            ]
          : [...Array(mostRacesNum)].map((_, i) => {
              const race = meeting.races[i]

              if (race) {
                const { resultPlaces, status, startTime, hasReplay } = race

                const hasResults = !!resultPlaces
                let content

                const raceHasntStartedYet = startTime
                  ? isBefore(getDateNow(), startTime)
                  : undefined

                const raceIsOff = (['Abandoned', 'No Race'] as RaceStatus[]).includes(status)

                // eslint-disable-next-line prefer-const
                content = (
                  <Link_C
                    key={i}
                    navigateTo={[
                      'Meeting',
                      {
                        date: props.date,
                        trackSlug: meeting.track.slug,
                        dayPhaseLetter: meeting.dayPhaseLetter,
                        raceNumber: `${race.raceNumber}`,
                        type: meeting.isTrial ? 'trial' : 'meeting'
                      }
                    ]}
                    style={{
                      elem: {
                        base: breakpointStyles.raceLinkElem,
                        hovered: {
                          backgroundColor: hasResults ? colors.gray300 : colors.gray100
                        }
                      },
                      text: breakpointStyles.raceLinkText
                    }}
                  >
                    {(raceHasntStartedYet || !hasResults) &&
                      startTime &&
                      !raceIsOff &&
                      (isBefore(getDateNow(), subMinutes(startTime, 30)) ? (
                        <Text_C numberOfLines={1} style={styles.raceTime}>
                          {humanFriendlyTime(startTime)}
                        </Text_C>
                      ) : (
                        <RaceCountdownText_C
                          startTime={startTime}
                          textStyle={styles.raceTime}
                          fallbackContent={
                            <Text_C numberOfLines={1} style={styles.raceTime}>
                              {humanFriendlyTime(startTime)}
                            </Text_C>
                          }
                        />
                      ))}
                    {hasResults && (
                      <View style={styles.resultsContainer}>
                        <Text_C
                          numberOfLines={1}
                          style={{
                            ...breakpointStyles.raceTime,
                            fontWeight: '700Bold',
                            ...styles.placingsText
                          }}
                        >
                          {race.resultPlaces}
                        </Text_C>
                        {hasReplay && (
                          <Icon_C
                            {...{
                              ...playIcon,
                              style: {
                                width: 16
                              }
                            }}
                          />
                        )}
                      </View>
                    )}
                    {raceIsOff && (
                      <Text_C style={{ ...textSizes.size1, fontStyle: 'italic' }}>{status}</Text_C>
                    )}
                  </Link_C>
                )

                const cell: TableCell = {
                  content,
                  style: {
                    ...styles.tableCell,
                    backgroundColor: hasResults ? colors.gray200 : 'none'
                  }
                }
                return cell
              } else
                return {
                  style: styles.tableCell,
                  content: <></>
                }
            }))
      ]
    }
  })

  return useMemo(() => {
    return (
      <Table_C
        styleType="darkHeader"
        headings={tableHeadings}
        rows={rows}
        styles={tableBreakpointStyles}
        onRowSelected={handleRowSelected}
      />
    )
  }, [props])

  function handleRowSelected(rowIndex: number) {
    const meeting: MeetingBasicParital = props.meetings[rowIndex]
    navigation.navigate('Meeting', {
      date: props.date,
      trackSlug: meeting.track.slug,
      dayPhaseLetter: meeting.dayPhaseLetter,
      type: meeting.isTrial ? 'trial' : 'meeting'
    })
  }
}

export const tableBreakpointStyles: TableStyles = {
  heading: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
}
const breakpointStyles = createStyles({
  raceLinkElem: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  raceLinkText: {
    color: colors.gray600
  },
  trackContainer: {
    flexDirection: 'row'
  },
  trackNameContainer: {
    flex: 1,
    justifyContent: 'center',
    rowGap: 5,
    marginTop: 2,
    marginLeft: 12
  },
  stateColor: { base: { width: 9, height: 35 } },
  trackNameText: {
    ...textSizes.size3,
    textTransform: 'capitalize',
    fontFamily: 'OpenSans',
    fontWeight: '700Bold',
    color: colors.gray700
  },
  trackStateText: {
    ...textSizes.size1,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold',
    lineHeight: 12,
    marginTop: 2,
    color: colors.gray700
  },
  tableCell: {
    paddingHorizontal: 0,
    paddingVertical: 0
  },
  raceTime: {
    ...textSizes.size1,
    textAlign: 'center'
  },
  waitingOnResults: {
    fontSize: 10,
    lineHeight: 12,
    fontStyle: 'italic',
    textAlign: 'center',
    color: colors.gray700,
    paddingHorizontal: 4
  },
  liveRace: {
    backgroundColor: presets.secondary,
    borderRadius: 10,
    paddingVertical: 2,
    paddingHorizontal: 8
  },
  liveRaceText: {
    fontSize: 8,
    color: colors.white,
    textTransform: 'uppercase',
    fontWeight: '600SemiBold',
    letterSpacing: 1.01
  },
  placingsText: {
    letterSpacing: -0.8
  },
  resultsContainer: {
    base: { gap: 4, alignItems: 'center', justifyContent: 'flex-start' },
    [tabletBreakpoint]: { gap: 6 }
  }
})
