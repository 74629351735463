import { format } from 'date-fns'
import { orderBy } from 'lodash'
import { View } from 'react-native'
import { formatCurrency } from '../../../helper-functions'
import { DayPhaseLetter } from '../../../services/data/data-types/general-data-types.type'
import {
  MeetingProgramResponse,
  getMeetingProgramData
} from '../../../services/data/request-functions/meeting-program-request'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../styles/text-sizes'
import { humanFriendlyDateTime } from '../../../utils/date-time'
import { Heading_C } from '../../Base/Heading/Heading'
import { LabelValueTable_C } from '../../Base/Table/LabelValueTable'
import { TableStyles, Table_C, tableStyles } from '../../Base/Table/Table'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'

export const MeetingProgram_C = (): JSX.Element =>
  withScreenWrapper<'MeetingProgram', [MeetingProgramResponse]>({
    requestsSetup: ({ date, trackSlug, dayPhaseLetter, type }) => [
      getMeetingProgramData({
        trackSlug,
        date,
        dayPhaseLetter: dayPhaseLetter as DayPhaseLetter,
        trial: type == 'trial'
      })
    ],

    view: ({ responses }) => {
      const [
        {
          meetingProgram: { meeting, races, track }
        }
      ] = responses!
      const { acceptancesDate, fieldsAvailableDate, nomsCloseDate, dayPhaseLetter } = meeting
      const styles = useBreakpointStyles({
        styles: breakpointStyles
      })
      const isMobileLayout = breakpointBelow(tabletBreakpoint)

      const orderedRaces = orderBy(races, (r) => r.raceCode, 'asc')

      return (
        <Card_C>
          <View style={styles.header}>
            <View style={styles.leftContainer}>
              <Heading_C style={styles.heading} styleType="h1">
                Meeting Program
              </Heading_C>
              <Heading_C styleType="h3">
                {`${
                  (isMobileLayout && track.shortName) || track.name
                } (${dayPhaseLetter}) - ${format(meeting.date, 'EEEE dd MMMM yyyy')}`}
              </Heading_C>
            </View>
            <LabelValueTable_C
              style={{
                label: { width: 150, textAlign: 'right' },
                container: { marginLeft: 'auto', rowGap: 10 },
                valueText: { marginLeft: 10 }
              }}
              data={[
                {
                  label: 'Nominations Close:',
                  value: humanFriendlyDateTime(nomsCloseDate)
                },
                {
                  label: 'Acceptances:',
                  value: humanFriendlyDateTime(acceptancesDate)
                },
                {
                  label: 'Race fields available:',
                  value: humanFriendlyDateTime(fieldsAvailableDate)
                }
              ]}
            />
          </View>
          {!orderedRaces.length ? (
            <Text_C style={{}}>Races have not yet been allocated to this meeting</Text_C>
          ) : (
            <Table_C
              styleType="lightHeaderBorders"
              styles={raceTableStyles}
              headings={[
                'Race Code',
                'Start Type',
                { content: 'Name', colWidth: { flex: 2 } },
                'Prize Money',
                'Class',
                'Metres Draw',
                { content: 'Other conditions', colWidth: { flex: 3 } }
              ]}
              rows={orderedRaces.map((r, idx) => {
                return {
                  cells: [
                    <Text_C
                      key={idx}
                      style={{
                        ...textSizes.size1,
                        fontWeight: '600SemiBold'
                      }}
                    >
                      {r.raceCode}
                    </Text_C>,

                    `${r.startType == 'MS' ? 'Mobile' : 'Standing'}`,

                    `${(isMobileLayout && r.shortName) || r.name || '---'}`,

                    `${formatCurrency(r.totalPrizeMoney)}`,

                    `${r.class}`,

                    `${r.distance}\n${r.barrierDrawType}`,

                    `${r.notes ?? ''}`
                  ]
                }
              })}
            />
          )}
          <Text_C style={styles.gstInfo}>
            Stakes shown exclude GST. GST will be paid to owners who are registered for GST
            purposes.
          </Text_C>
        </Card_C>
      )
    }
  })

const raceTableStyles: TableStyles = {
  table: { width: '100%' },
  tableContainer: { width: '100%', minWidth: 1000 },
  cell: { ...tableStyles.cellText, justifyContent: 'flex-start', gap: 6 },
  cellText: { lineHeight: 18 }
}

const breakpointStyles = createStyles({
  header: {
    base: { marginBottom: 30, gap: 30 },
    medium: { flexDirection: 'row' },
    [tabletBreakpoint]: { marginBottom: 36 },
    [desktopBreakpoint]: { marginBottom: 40 }
  },
  leftContainer: {},
  rightContainer: {},
  heading: {
    base: { marginBottom: 20 },
    [tabletBreakpoint]: { marginBottom: 26 },
    [desktopBreakpoint]: { marginBottom: 30 }
  },

  gstInfo: {
    marginTop: 20
  }
})
