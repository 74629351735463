/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { merge } from 'lodash'
import { PropsWithChildren, useRef, useState } from 'react'
import { ScrollView, ScrollViewProps, StyleSheet, View } from 'react-native'
import { LeftOrRight } from '../../../helper-types'
import { colors } from '../../../styles/colors'
import { Button_C } from '../Button/Button'
import { chevronIcon } from '../Icon/preset-icon-props'
import { PressableStyleExtension } from '../Pressable/Pressable'

type Props = {
  scrollIntervalMs?: number
}

export function ScrollHorizontal_C(props: PropsWithChildren<Props & ScrollViewProps>) {
  const { children, scrollIntervalMs = 4, ...rest } = props
  const scrollViewProps = rest
  const scrollViewRef = useRef<any>(null)

  const intervalRef = useRef<NodeJS.Timer | null>(null)

  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [isActive, setIsActive] = useState<boolean>(false)
  const [, setIsArrowPressActive] = useState<boolean>(false)

  const moveAmount = 2

  // if scroll is at either end or not
  let atEnd: LeftOrRight | null = null
  if (scrollViewRef.current) {
    const { offsetWidth, scrollWidth }: HTMLDivElement = scrollViewRef.current

    const scrollEnd = scrollWidth
    atEnd =
      scrollPosition <= 0 ? 'left' : scrollPosition + offsetWidth >= scrollEnd ? 'right' : null
  }

  return (
    <View style={styles.container}>
      {isActive && atEnd !== 'left' && (
        <View style={styles.arrowContainer}>
          <Button_C
            testID="left-arrow-button"
            style={arrowButtonStyle}
            onPressIn={() => onHoldScrollArrow('left')}
            onPressOut={onReleaseArrow}
            styleType="iconOnly"
            icon={merge({}, chevronIcon, {
              style: { ...styles.arrowIcon, ...styles.arrowIconLeft }
            })}
          />
        </View>
      )}
      <ScrollView
        testID="scroll-view"
        ref={scrollViewRef}
        horizontal
        showsHorizontalScrollIndicator={false}
        {...scrollViewProps}
        scrollEventThrottle={scrollIntervalMs}
        contentContainerStyle={[styles.contentContainer, scrollViewProps.contentContainerStyle]}
        style={[styles.scrollView, scrollViewProps.style]}
        onLayout={handleLayoutChange}
      >
        {children}
      </ScrollView>

      {isActive && atEnd !== 'right' && (
        <View style={[styles.arrowContainer, { right: 0 }]}>
          <Button_C
            testID="right-arrow-button"
            style={arrowButtonStyle}
            onPressIn={() => onHoldScrollArrow('right')}
            onPressOut={onReleaseArrow}
            styleType="iconOnly"
            icon={merge({}, chevronIcon, {
              style: { ...styles.arrowIcon, ...styles.arrowIconRight }
            })}
          />
        </View>
      )}
    </View>
  )

  function onHoldScrollArrow(direction: LeftOrRight) {
    setIsArrowPressActive(true)

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(arrowHoldScrollLoop, scrollIntervalMs, direction)

    function arrowHoldScrollLoop(direction: LeftOrRight) {
      if (!scrollViewRef.current) {
        return
      }

      let position = (scrollViewRef.current as HTMLDivElement).scrollLeft
      switch (direction) {
        case 'left':
          if (position <= 0) {
            clearInterval(intervalRef.current!)
            return
          }

          position -= moveAmount
          break
        case 'right': {
          const scrollMax = scrollViewRef.current.scrollWidth - scrollViewRef.current.offsetWidth
          if (position >= scrollMax) {
            clearInterval(intervalRef.current!)
            return
          }

          position += moveAmount
        }
      }

      scrollViewRef.current.scrollLeft = position
      setScrollPosition(position)
    }
  }

  function onReleaseArrow() {
    setIsArrowPressActive(false)
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  function handleLayoutChange() {
    if (scrollViewRef.current) {
      const { scrollWidth, offsetWidth }: HTMLDivElement = scrollViewRef.current
      setIsActive(offsetWidth < scrollWidth)
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  scrollView: {
    flex: 1
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  arrowContainer: {
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 100
  },
  arrowIcon: {
    width: 18
  },
  arrowIconLeft: {
    transform: [{ rotate: '90deg' }]
  },
  arrowIconRight: {
    transform: [{ rotate: '-90deg' }]
  }
})

const arrowButtonStyle: PressableStyleExtension = {
  elem: {
    base: {
      width: 20,
      height: 40,
      backgroundColor: colors.gray200,
      opacity: 0.5
    },
    hovered: {
      opacity: 1
    }
  }
}
