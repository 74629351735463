import { useContext } from 'react'
import { Pressable, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { GlobalContext } from '../../../global-context'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'
import { presets } from '../../../styles/colors'
import { Button_C } from '../Button/Button'
import { crossIcon } from '../Icon/preset-icon-props'
import { PressableStyleExtension } from '../Pressable/Pressable'

export function Modal_C() {
  const { modalVisible, modalContent, closeModal, modalStyle } = useContext(GlobalContext)
  const styles = useBreakpointStyles({
    styles: modalBaseStyles,
    additionalStyles: [standardStyles, modalStyle]
  })

  if (!modalVisible) {
    return null
  }

  return (
    <View style={styles.modalOuterContainer}>
      <View style={styles.backgroundOverlay}>
        <Pressable /* sibling pressable instead of parent, so clicks in content don't trigger close */
          style={styles.backgroundPressable}
          onPress={closeModal}
        ></Pressable>
        <View style={styles.modalInnerContainer}>
          <Button_C
            styleType="iconOnly"
            style={closeButtonStyle}
            onPress={closeModal}
            icon={crossIcon}
          />
          <ScrollView contentContainerStyle={styles.contentContainer} style={styles.modalContent}>
            {modalContent}
          </ScrollView>
        </View>
      </View>
    </View>
  )
}

const modalBaseStyles = createStyles({
  modalOuterContainer: {
    base: {
      zIndex: 10, //Above header
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  },
  backgroundOverlay: {
    base: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#00000069'
    }
  },
  modalInnerContainer: {
    base: {
      position: 'relative',
      elevation: 5,
      marginHorizontal: 'auto',
      minWidth: 350,
      zIndex: 1
    }
  },
  modalContent: {},
  contentContainer: {},
  backgroundPressable: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
})

export type ModalStyle = NamedBreakpointStylesExtension<typeof modalBaseStyles>

const standardStyles: ModalStyle = {
  modalOuterContainer: {},
  modalInnerContainer: {
    backgroundColor: 'white',
    maxWidth: 500,
    borderRadius: 4,
    overflow: 'hidden',
    shadowColor: 'black',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 8,
    width: '80%',
    maxHeight: '80%'
  },
  modalContent: {
    base: {
      width: '100%',
      paddingHorizontal: 30,
      paddingVertical: 20
    },
    small: {
      paddingHorizontal: 40,
      paddingVertical: 30
    }
  }
}

const closeButtonStyle: PressableStyleExtension = {
  elem: {
    base: {
      position: 'absolute',
      top: 5,
      right: 5,
      zIndex: 10,
      height: 24,
      backgroundColor: 'white'
    },
    hovered: {
      backgroundColor: presets.primary,
      borderRadius: 100,
      borderWidth: 1,
      borderColor: presets.primary
    }
  },
  text: {
    base: {},
    hovered: {
      color: 'white'
    }
  }
}
