import { MutableRefObject, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { Dimensions, EmitterSubscription, ScrollView } from 'react-native'
import { GlobalContext } from '../../../global-context'
import { createStyles } from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { Coords } from '../../../types/miscellaneous-types.type'
import { Text_C } from '../Text/Text'

const POSITIONS = ['left', 'top', 'right', 'bottom'] as const
type Postion = (typeof POSITIONS)[number]

export type Tooltip = {
  styleType?: 'map'
  parentElemRef: React.RefObject<any>
  content: ReactNode
  position: Postion
}

const tooltipWidth = 140
const tooltipMaxHeight = 120
const tooltipGap = 4

export function Tooltip_C({ scrollViewRef }: { scrollViewRef: MutableRefObject<any> }) {
  const { activeTooltip, setActiveTooltip } = useContext(GlobalContext)

  const [coords, setCoords] = useState<Coords | undefined>()
  const [contentHeight, setContentHeight] = useState<number | undefined>()
  const tooltipContainer = useRef<any>(null)

  useEffect(() => {
    let subscription: EmitterSubscription | undefined

    const parent = activeTooltip?.parentElemRef.current

    if (parent && contentHeight) {
      setCoords(getTooltipCoords(parent, activeTooltip.position))
    }

    const handleOutsideClick = (event: any) => {
      const clickTargetElem = event.target as HTMLDivElement
      const tooltipContainerElem = tooltipContainer.current as HTMLDivElement

      const parentElem = activeTooltip?.parentElemRef.current as HTMLDivElement
      // needs parent elem to check if click is on the toggle button

      if (tooltipContainerElem) {
        const clickIsOutside = !tooltipContainerElem.contains(clickTargetElem)
        const clickOnParent = parentElem.contains(clickTargetElem)

        if (clickIsOutside && !clickOnParent) {
          setCoords(undefined)
          setActiveTooltip(undefined)
        }
      }
    }

    if (parent && contentHeight) {
      subscription = Dimensions.addEventListener('change', () => {
        setCoords(getTooltipCoords(parent, activeTooltip.position))
      })
      document.addEventListener('mousedown', handleOutsideClick)
    }
    if (!parent) {
      subscription?.remove()
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      subscription?.remove()
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [activeTooltip, contentHeight])

  useEffect(() => {
    const parent = activeTooltip?.parentElemRef.current
    if (parent && contentHeight && activeTooltip) {
      setCoords(getTooltipCoords(parent, activeTooltip.position))
    }
  }, [contentHeight])

  if (!activeTooltip) {
    return null
  }

  function onTooltipRender() {
    const clickTargetElem = tooltipContainer.current as HTMLDivElement
    const contentHeight = clickTargetElem.offsetHeight
    setContentHeight(contentHeight)
  }

  const { content } = activeTooltip

  const styles = activeTooltip.styleType == 'map' ? mapStyles : defaultStyles

  return (
    <ScrollView
      onLayout={onTooltipRender}
      ref={tooltipContainer}
      style={[styles.elem, coords, { opacity: contentHeight ? 1 : 0 }]}
    >
      {typeof content == 'string' ? (
        <Text_C ellipsizeMode="clip" style={styles.text}>
          {content}
        </Text_C>
      ) : (
        content
      )}
    </ScrollView>
  )

  function getTooltipCoords(parentElem: HTMLDivElement, position: Postion): Coords | undefined {
    if (!contentHeight) {
      return
    }
    const appScrollContainer = scrollViewRef.current as HTMLElement

    const { clientWidth, clientHeight, scrollTop } = appScrollContainer

    const parentElemRect: DOMRect = parentElem.getBoundingClientRect()

    const { left, top, width, height } = parentElemRect

    const xCenter = left + width / 2
    const yCenter = top + height / 2

    return getPositionCoords(position, contentHeight)

    /* recursive function looks to reposition */
    function getPositionCoords(
      tooltipPosition: Tooltip['position'],
      contentHeight: number,
      loopCount = 1
    ): Coords {
      let coords: Coords

      const noGoodOption = loopCount > 3

      if (noGoodOption) {
        tooltipPosition = 'left'
      }
      if (tooltipPosition == 'top') {
        coords = {
          left: xCenter - tooltipWidth / 2,
          top: top - (contentHeight + tooltipGap) + (scrollTop ?? 0)
        }
      } else if (tooltipPosition == 'bottom') {
        coords = {
          left: xCenter - tooltipWidth / 2,
          top: top + height + tooltipGap + (scrollTop ?? 0)
        }
      } else if (tooltipPosition == 'left') {
        coords = {
          left: left - (tooltipWidth + tooltipGap),
          top: yCenter - contentHeight / 2 + (scrollTop ?? 0)
        }
      } else {
        coords = {
          left: left + (width + tooltipGap),
          top: yCenter - contentHeight / 2 + (scrollTop ?? 0)
        }
      }

      if (noGoodOption) {
        return coords
      }

      if (tooCloseTo('right')) {
        return getPositionCoords('left', contentHeight, loopCount + 1)
      } else if (tooCloseTo('left')) {
        return getPositionCoords('right', contentHeight, loopCount + 1)
      } else if (tooCloseTo('top')) {
        return getPositionCoords('bottom', contentHeight, loopCount + 1)
      } else if (tooCloseTo('bottom')) {
        return getPositionCoords('top', contentHeight, loopCount + 1)
      }

      return coords

      function tooCloseTo(testPosition: Tooltip['position']): boolean {
        if (testPosition == 'right') {
          return coords.left + tooltipWidth > clientWidth
        } else if (testPosition == 'left') {
          return coords.left < 0
        } else if (testPosition == 'top') {
          return coords.top - scrollTop < 0
        } else {
          return coords.top + contentHeight > clientHeight + scrollTop
        }
      }
    }
  }
}

const defaultStyles = createStyles({
  elem: {
    backgroundColor: colors.gray700,
    padding: 6,
    borderColor: 'black',
    borderWidth: 1,
    width: tooltipWidth,

    maxHeight: tooltipMaxHeight,
    position: 'absolute',
    zIndex: 1
  },
  text: { color: 'white', fontSize: 10 }
})

const mapStyles = createStyles({
  elem: {
    backgroundColor: 'white',
    padding: 6,
    borderColor: 'black',
    borderWidth: 1,
    width: tooltipWidth,

    maxHeight: tooltipMaxHeight,
    position: 'absolute',
    zIndex: 1
  },
  text: { color: presets.primary, fontSize: 10 }
})
