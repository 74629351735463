import { merge } from 'lodash'
import { useContext } from 'react'
import { View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { colors, presets, stateColor } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { humanFriendlyDate } from '../../../../../../utils/date-time'
import { Heading_C } from '../../../../../Base/Heading/Heading'
import { Icon_C } from '../../../../../Base/Icon/Icon'
import { chevronIcon } from '../../../../../Base/Icon/preset-icon-props'
import { Link_C } from '../../../../../Base/Link/Link'
import { Text_C } from '../../../../../Base/Text/Text'
import { TrackContext } from '../../../Track'

export function LatestResults_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { activeTrack } = useContext(TrackContext)
  const { last5Meetings, state, slug } = activeTrack

  return (
    <View style={styles.container}>
      <View style={styles.topHeader}>
        <Heading_C styleType="h2" style={breakpointStyles.heading}>
          Latest Results
        </Heading_C>
      </View>

      {last5Meetings?.length ? (
        last5Meetings.map((m, i) => {
          return (
            <Link_C
              navigateTo={[
                'Meeting',
                {
                  trackSlug: slug,
                  date: m.date,
                  dayPhaseLetter: m.dayPhaseLetter,
                  type: m.isTrial ? 'trial' : 'meeting'
                }
              ]}
              style={{
                elem: {
                  base: styles.resultRow,
                  hovered: { backgroundColor: presets.background }
                }
              }}
              key={i}
            >
              <View
                style={{
                  ...styles.stateColor,
                  backgroundColor: state ? stateColor[state] : colors.gray500
                }}
              />
              <View style={styles.dateAndRaces}>
                <Text_C style={styles.meetingDate}>{humanFriendlyDate(new Date(m.date))}</Text_C>
                <Text_C style={styles.meetingRaces}>{m.racesCount} Races</Text_C>
              </View>
              <Icon_C
                {...merge({}, chevronIcon, { style: styles.rowArrow })}
                color={colors.gray300}
              />
            </Link_C>
          )
        })
      ) : (
        <Text_C style={styles.noResultsMessage}>No resulted races </Text_C>
      )}
    </View>
  )
}

const breakpointStyles = createStyles({
  container: {
    marginTop: 25
  },
  topHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
    position: 'relative',
    zIndex: 1
  },
  heading: {},
  resultRow: {
    borderTopWidth: 1,
    borderTopColor: presets.border,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    paddingVertical: 18,
    paddingHorizontal: 14
  },
  noResultsMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 20,
    fontStyle: 'italic'
  },
  rowArrow: {
    width: 20,
    transform: [{ rotate: '-90deg' }],
    marginLeft: 'auto'
  },
  stateColor: {
    width: 9,
    height: 35
  },
  dateAndRaces: {
    gap: 2
  },
  meetingDate: {
    ...textSizes.size4,
    fontWeight: '600SemiBold'
  },
  meetingRaces: {}
})
