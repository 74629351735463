import { orderBy, toUpper } from 'lodash'
import { reduceResultPlaces, removeLeadingSlash, toCapitalCase } from '../../../helper-functions'
import { MeetingStatusResponse } from '../../../types/fetch-response-base.type'
import { AustralianState } from '../../../types/map.type'
import { convertAsset } from '../../../utils/assets'
import { fetchData } from '../api-fetch-request'
import { MeetingBasicFetchResponse as MeetingsBasicFetchResponse } from '../data-types/fetch-response-types/meetings-basic-response.type'
import { meetingStatusMap, raceStatusMap } from '../data-types/general-data-types.type'
import { MeetingBasic, RaceBasic } from '../data-types/local-data-types/meeting-basic.type'

export type MeetingsBasicResponse = {
  maxMeetingDate: Date
  date: Date
  meetings: MeetingBasic[]
}

type FetchMeetingsBasicParams = {
  date: string
  useDesignMock?: boolean
}

export async function getMeetingsBasic({
  date,
  useDesignMock
}: FetchMeetingsBasicParams): Promise<MeetingsBasicResponse> {
  if (useDesignMock) {
    throw 'no design mock'
  }

  const endpoint = `racing/list/${date}`

  return fetchData<MeetingsBasicResponse, MeetingsBasicFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined /* basicMeetingCompare */
    /* throws too many warning because so many props are optional */
  })

  function convertResponse(meetingsPayload: MeetingsBasicFetchResponse): MeetingsBasicResponse {
    return {
      date: new Date(meetingsPayload.meeting_date),
      maxMeetingDate: new Date(meetingsPayload.max_meeting_date),
      meetings: meetingsPayload.meetings
        .filter((meeting) => {
          return !(['abandoned', 'cancelled', 'transferred'] as MeetingStatusResponse[]).includes(
            meeting.meeting_status
          )
        })
        .map((meeting) => {
          const {
            track,
            meeting_status,
            date,
            races,
            track_condition,
            weather,
            is_trial,
            day_night_twilight
          } = meeting
          const { desktop_display_name, mobile_display_name, slug, state, track_image } = track

          const basicMeeting: MeetingBasic = {
            date: date,
            isTrial: is_trial,
            dayPhaseLetter: day_night_twilight,
            status: meetingStatusMap[meeting_status],
            trackCondition: track_condition ?? undefined,
            weather: weather ?? undefined,
            track: {
              name: toCapitalCase(desktop_display_name),
              slug: removeLeadingSlash(slug),
              state: toUpper(state) as AustralianState,
              shortName: mobile_display_name ? toCapitalCase(mobile_display_name) : undefined,
              trackMapImage: !track_image ? undefined : convertAsset(track_image)
            },
            races: orderBy(races, 'race_number').map((race): RaceBasic => {
              const {
                race_number,
                distance,
                race_status,
                result_places,
                scheduled_start_time,
                has_replay
              } = race

              return {
                raceNumber: race_number,
                status: raceStatusMap[race_status],
                replayUrl: 'stub data',
                resultPlaces: result_places ? reduceResultPlaces(result_places) : undefined,
                startTime: new Date(scheduled_start_time),
                distance: distance,
                hasReplay: has_replay
              }
            })
          }
          return basicMeeting
        })
    }
  }
}
