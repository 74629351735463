import { BreakpointName, BreakpointSizes } from './breakpoint-style.type'

export const breakpointNames = ['base', 'small', 'medium', 'large', 'xlarge', 'xxlarge'] as const

export const breakpointSizes: BreakpointSizes = {
  base: 0,
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
  xxlarge: 1500
}

export const contentMaxWidth = breakpointSizes.xxlarge

export const tabletBreakpoint: BreakpointName = 'large'
export const desktopBreakpoint: BreakpointName = 'xlarge'
