/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import {
  addDays,
  differenceInMilliseconds,
  format,
  isAfter,
  isBefore,
  isSameDay,
  minutesToMilliseconds,
  subDays
} from 'date-fns'
import { capitalize, toLower } from 'lodash'
import { Image } from 'react-native'
import tinycolor from 'tinycolor2'
import { HexColor, NamedObj } from './helper-types'
import {
  ResponseSilkObj,
  ResponseTrainerDiverPerformanceStats
} from './services/data/data-types/fetch-response-types/trainer-response.type'
import {
  BlackTypePerformance,
  PlaceStats,
  ResultPlaces,
  SilkObj
} from './services/data/data-types/general-data-types.type'
import { Ad, AdType, AdsObj } from './services/data/request-functions/advertisement-request'
import {
  LatestRace,
  LatestResultPlace
} from './services/data/request-functions/latest-races-request'
import { getAssetUrl } from './utils/assets'

export const timer = <T>(ms: number) => new Promise<T>((r) => setTimeout(r, ms))

export function sortByAscendingDate<T extends { date: Date }>(objA: T, objB: T): -1 | 0 | 1 {
  return objA.date < objB.date ? -1 : objA.date > objB.date ? 1 : 0
}

export const isArrayOfStrings = (testType: unknown): testType is string[] => {
  return Array.isArray(testType) && testType.every((item) => typeof item === 'string')
}

export function wordStartsWithLetter(word: string, letter: string) {
  return word.charAt(0).toUpperCase() == letter.toUpperCase()
}

export function createNamedObj<T extends NamedObj<T> | NamedObj<any>>(obj: T): T {
  return obj
}

export function removeLeadingSlash(string: string) {
  return string.replace(/^\//, '')
}

export function toCapitalCase(str: string) {
  // Split the string into an array of words
  const words = str.toLowerCase().split(' ')

  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  // Join the words back into a single string
  const capitalCaseStr = words.join(' ')

  return capitalCaseStr
}

export function loopObject<T extends Record<string, unknown>, R>(
  obj: T,
  fn?: (key: keyof T, value: T[keyof T]) => R
): R[] {
  return Object.entries(obj).map(
    ([key, value]) => fn?.(key as keyof T, value as T[keyof T]) ?? (value as R)
  )
}

export function mapObject<
  O extends { [key in keyof O]: O[keyof O] },
  Keys extends keyof O,
  Fr extends { [k: string]: any } | undefined,
  R extends { [k in keyof Fr]: Fr[k] }
>(obj: O, fn: (key: Keys, value: O[Keys]) => Fr): R {
  return (Object.keys(obj) as Keys[]).reduce((mapped, key: Keys) => {
    const res = fn(key, obj[key])
    return { ...mapped, ...res }
  }, {} as R)
}

export function reduceObject<T extends { [key in keyof T]: T[key] }, R>(
  obj: T,
  fn: (accumulator: R, key: keyof T, value: T[keyof T]) => R,
  initial?: R
): R {
  return Object.entries(obj).reduce((accumulator, [key, value]) => {
    return fn(accumulator, key as keyof T, value as T[keyof T])
  }, initial as R)
}

/**
 *
 * @description the ony purpose of this is to enable testing. by making the date an external dependency, it can be mocked, or have the value changed globally for a quick test, but should be left as new Date()
 */
export function getDateNow() {
  return new Date()
}

export function desaturateColor(hexColor: HexColor, amount: number) {
  const color = tinycolor(hexColor)
  const desaturatedColor = color
    .lighten(amount * 20)
    .desaturate(amount * 20)
    .toString()
  return desaturatedColor
}

export function numberToOrdinal(num: number): string {
  if (num === 0) {
    return '0th'
  }

  const lastTwoDigits = num % 100
  const lastDigit = num % 10

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return num + 'th'
  }

  switch (lastDigit) {
    case 1:
      return num + 'st'
    case 2:
      return num + 'nd'
    case 3:
      return num + 'rd'
    default:
      return num + 'th'
  }
}

export function toCapitalized(uppercaseString: string) {
  const lowercaseString = toLower(uppercaseString)
  const words = lowercaseString.split(' ')
  const capitalizedWords = words.map((word) => capitalize(word))
  const capitalizedSentence = capitalizedWords.join(' ')

  return capitalizedSentence
}

export function formatCurrency(number: number, noDecimals?: boolean) {
  // If noDecimals is true and is greater or equal to 10, then remove the decimal
  const roundedNumber = noDecimals ? Math.round(number) : number

  const currencyFormatter = new Intl.NumberFormat('en-au', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: noDecimals ? 0 : 2
  })

  let formatted = currencyFormatter.format(roundedNumber)

  if (Number.isInteger(roundedNumber) && roundedNumber >= 10) {
    formatted = formatted.replace('.00', '')
  }

  return formatted
}

export function reduceResultPlaces(responseResultPlaces: ResultPlaces): string {
  const values = Object.values(responseResultPlaces)
  const resultString = values
    .map((arr) => (arr.length === 1 ? arr[0] : arr))
    .slice(0, 3)
    .map((arr) => (Array.isArray(arr) ? arr.join('+') : arr))
    .join(', ')
  return resultString
}

export function reduceLatestResultPlaces(responseResultPlaces: LatestResultPlace[]): string {
  const resultString = responseResultPlaces
    .filter(({ place }) => ['1', '2', '3'].includes(place))
    .map(({ runners }) => runners.map(({ clothNumber }) => clothNumber).join('+'))
    .join(', ')
  return resultString
}

/**
  @param number eg if return value is 50% then number was 0.5
  @param decimals eg if number is 0.12345 and decimals is 2, then return is 12.35%
*/
export function toPercent(number: number, decimals = 0) {
  const result = Math.round(number * Math.pow(1000, decimals)) / Math.pow(10, decimals)

  return result
}

export function roundToDecimals(number: number, decimals: number = 0) {
  const result = parseFloat(number.toFixed(decimals))

  return result
}

export function isNumeric(testVal: any): boolean {
  return !isNaN(testVal)
}

export type FieldDateTabParams = {
  type: 'fields'
  daysRange: number //number of days from today to display
  maxDate: Date
  activeDate: Date
}
export type ResultsDateTabParams = {
  type: 'results'
  daysRange: number //number of days from today to display
  maxDate: Date
  activeDate: Date
}
export type DateTabsResponse = {
  tabName: string
  date: Date
  selected: boolean
}

export function makeDateTabs(params: FieldDateTabParams): DateTabsResponse[]
export function makeDateTabs(params: ResultsDateTabParams): DateTabsResponse[]
export function makeDateTabs(
  params: FieldDateTabParams | ResultsDateTabParams
): DateTabsResponse[] {
  const today = new Date()

  const tabNameAndDate: DateTabsResponse[] = [
    {
      tabName: `Today ${format(today, 'd/L')}`,
      date: today,
      selected: isSameDay(params.activeDate, today)
    }
  ]

  if (params.type == 'fields') {
    const { maxDate, activeDate: selectedDate, daysRange } = params

    const rangeMaxDay = addDays(today, daysRange)

    const maxDay = compareDays(maxDate, 'before', rangeMaxDay) ? maxDate : rangeMaxDay

    loop(daysRange, (number) => {
      const thisDate = addDays(today, number)
      const dayAfterMax = compareDays(thisDate, 'after', maxDay)

      //don't add tab if after max day
      if (!dayAfterMax) {
        const selected = isSameDay(selectedDate, thisDate)

        const tabName =
          number === 1 ? `Tomorrow ${format(thisDate, 'd/L')}` : format(thisDate, 'eeee d/L')

        tabNameAndDate.push({
          tabName,
          date: thisDate,
          selected
        })
      }
    })

    const selectedOutsideRange = compareDays(selectedDate, 'after', maxDay)

    // if selected is outside of range, add tab
    if (selectedOutsideRange) {
      tabNameAndDate.push({
        tabName: format(selectedDate, 'eeee d/L'),
        date: selectedDate,
        selected: true
      })
    }
  }
  if (params.type == 'results') {
    const { activeDate: selectedDate, daysRange } = params

    const rangeMaxDay = subDays(today, daysRange)
    loop(daysRange, (number) => {
      const thisDate = subDays(today, number)
      const selected = isSameDay(selectedDate, thisDate)
      const tabName =
        number === 1 ? `Yesterday ${format(thisDate, 'd/L')}` : format(thisDate, 'eeee d/L')

      tabNameAndDate.push({
        tabName,
        date: thisDate,
        selected
      })
    })

    const selectedOutsideRange = compareDays(selectedDate, 'before', rangeMaxDay)

    // if selected is outside of range, add tab
    if (selectedOutsideRange) {
      tabNameAndDate.push({
        tabName: format(selectedDate, 'eeee d/L'),
        date: selectedDate,
        selected: true
      })
    }
  }
  return tabNameAndDate
}

function compareDays(testDay: Date, compareType: 'before' | 'after', dayToCompare: Date) {
  if (isSameDay(testDay, dayToCompare)) {
    return false
  } else {
    if (compareType == 'before') {
      return isBefore(testDay, dayToCompare)
    }
    if (compareType == 'after') {
      return isAfter(testDay, dayToCompare)
    }
  }
}
/**
 * provide a number and function, runs the function with incrementing number starting from 1
 */
export function loop<T extends any | undefined>(
  number: number,
  func?: (loopNum: number) => T
): Array<T | undefined> {
  return Array.from({ length: number }).map((_, i): T | undefined => {
    return func ? func(i + 1) : undefined
  })
}

export function filterExpiredRaces({
  races,
  extraMins
}: {
  races: LatestRace[]
  extraMins: number
}) {
  return races.filter(
    (race) =>
      getExpiryTime({
        date: race.startTime,
        extraMins
      }) > 0
  )
}
export function getExpiryTime({ date, extraMins }: { date: Date; extraMins: number }): number {
  const timeDifference = differenceInMilliseconds(date, getDateNow())
  const raceExpireMs = timeDifference + minutesToMilliseconds(extraMins)
  return raceExpireMs
}
type PercentParams = {
  starts: number
  wins: number
  places: number
}
export function getPlacementPercents(stats: PercentParams): PlaceStats & { places: number } {
  const { starts, wins, places } = stats
  const baseReturnObj: PlaceStats & { places: number } = {
    ...stats,
    places: 0,
    winPercent: 0,
    placePercent: 0,
    unplacedPercent: 0
  }
  if (!starts) {
    return baseReturnObj
  }

  return {
    ...baseReturnObj,
    places,
    winPercent: !wins ? 0 : toPercent(wins / starts, 1),
    placePercent: !places ? 0 : toPercent(places / starts, 1),
    unplacedPercent: toPercent((starts - (wins + places)) / starts, 1)
  }
}

const propPrefixes = ['group_1', 'group_2', 'group_3', 'listed_race'] as const

const propSuffixes = ['starts', 'wins', 'seconds', 'thirds', 'stakes'] as const

type PropSuffix = (typeof propSuffixes)[number]

export function getBlackTypePerformance(
  stats: ResponseTrainerDiverPerformanceStats
): BlackTypePerformance[] {
  return propPrefixes.map((propPrefix): BlackTypePerformance => {
    const { starts, wins, seconds, thirds, stakes } = propSuffixes.reduce(
      (acc, propSuffix) => {
        const prop = `${propPrefix}_${propSuffix}` as const
        acc[propSuffix] = stats[prop] ?? 0
        return acc
      },
      {} as Record<PropSuffix, number>
    )

    const blackTypeClass = (
      {
        group_1: 'Group 1',
        group_2: 'Group 2',
        group_3: 'Group 3',
        listed_race: 'Listed'
      } as const
    )[propPrefix]

    const blackType: BlackTypePerformance = {
      class: blackTypeClass,
      ...getPlacementPercents({ starts, wins, places: seconds + thirds }),
      stakes: formatCurrency(stakes)
    }
    return blackType
  })
}

export async function getImageSizes(url: string) {
  return await new Promise<{
    width: number
    height: number
    aspectRatio: number
  }>((r) => {
    Image.getSize(url, (w, h) => {
      r({
        width: w,
        height: h,
        aspectRatio: w / h
      })
    })
  })
}

export async function getAdSizes(baseAdsObj: AdsObj) {
  const adsObj: AdsObj = {}
  for (const t in baseAdsObj) {
    const adType = t as AdType
    const ad: Ad | undefined = baseAdsObj[adType]
    const url = getAssetUrl(ad?.asset)
    if (ad && url) {
      const sizes = await getImageSizes(url)
      const adWithSizes: Ad = { ...ad, ...sizes, imageUrl: url }
      adsObj[adType] = adWithSizes
    } else {
      adsObj[adType] = undefined
    }
  }
  return adsObj
}

export function decodeHtmlEntities(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.documentElement.textContent
}

export function mapSilkObj(silk: ResponseSilkObj): SilkObj {
  return {
    description: silk.description ? toCapitalized(silk.description) : undefined,
    imagePath: silk.silk_image?.path
  }
}
