import { View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../services/styles/breakpoint-styles.service'
import { IconProps } from '../../../../Base/Icon/Icon'
import { Text_C } from '../../../../Base/Text/Text'
import { OptionToggle_C } from '../../../../Partials/OptionToggle/OptionToggle'

type Props<OptionName extends string> = {
  label: string
  options: { name: OptionName; icon?: IconProps }[]
  initial: OptionName
  onOptionSelected: (_val: boolean) => void
}
export function PreferenceRow_C<OptionName extends string>({
  initial,
  options,
  label,
  onOptionSelected
}: Props<OptionName>) {
  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })
  return (
    <View style={styles.optionRow}>
      <Text_C style={styles.optionLabel}>{label}</Text_C>
      <OptionToggle_C
        options={options}
        onOptionSelected={(selected) => {
          onOptionSelected(selected == 'Yes')
        }}
        initialSelectedOption={initial}
      />
    </View>
  )
}

const breakpointStyles = createStyles({
  heading: { marginBottom: 20 },
  optionRow: { flexDirection: 'row', alignItems: 'center', marginBottom: 20, gap: 25 },
  optionLabel: { textAlign: 'left', paddingRight: 10 }
})
