/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createDrawerNavigator, DrawerScreenProps } from '@react-navigation/drawer'
import { LinkingOptions } from '@react-navigation/native'
import { FunctionComponent } from 'react'
import { CalendarScreen_C } from '../../components/Screens/Calendar/CalendarScreen'
import { Driver_C } from '../../components/Screens/Driver/Driver'
import { FeatureToggleScreen_C } from '../../components/Screens/FeatureToggleScreen/FeatureToggleScreen'
import { Fields_C } from '../../components/Screens/Fields/Fields'
import { Home_C } from '../../components/Screens/Home/Home'
import { Horse_C } from '../../components/Screens/Horse/Horse'
import { MeetingScreen_C } from '../../components/Screens/Meeting/Meeting'
import { MeetingProgram_C } from '../../components/Screens/MeetingProgram/MeetingProgram'
import { NewsArticle_C } from '../../components/Screens/NewsArticle/NewsArticle'
import { NewsIndex_C } from '../../components/Screens/NewsIndex/NewsIndex'
import { Page_C } from '../../components/Screens/Page/Pages'
import { ProgramSearch_C } from '../../components/Screens/ProgramSearch/ProgramSearch'
import { PuntersCorner_C } from '../../components/Screens/PuntersCorner/PuntersCorner'
import { PuntersCornerTrack_C } from '../../components/Screens/PuntersCornerTrack/PuntersCornerTrack'
import { ResetPassword_C } from '../../components/Screens/ResetPassword/ResetPassword'
import { Results_C } from '../../components/Screens/Results/Results'
import Search_C from '../../components/Screens/Search/Search'
import { Track_C } from '../../components/Screens/Track/Track'
import { TrackIndex_C } from '../../components/Screens/TrackIndex/TrackIndex'
import { Trainer_C } from '../../components/Screens/Trainer/Trainer'
import { UserProfile_C } from '../../components/Screens/UserProfile/UserProfile'
import { Videos_C } from '../../components/Screens/Videos/Videos'
import { mapObject } from '../../helper-functions'
import { shortDate } from '../../utils/date-time'

export const screens = {
  Home: createScreen(Home_C, 'home', 'Home'),
  Fields: createScreen(Fields_C, 'fields/:date?', 'Racing Fields'),
  Results: createScreen(Results_C, 'results/:date?', 'Racing Results'),
  Tracks: createScreen(TrackIndex_C, 'tracks', 'Racing Tracks'),
  Track: createScreen(Track_C, 'tracks/:trackSlug', 'Track'),
  Meeting: createScreen(
    MeetingScreen_C,
    'racing/:date/:trackSlug/:dayPhaseLetter/:type/:raceNumber?',
    'Meeting'
  ),
  Horse: createScreen(Horse_C, 'horse/:horseSlug', 'Horse Profile'),
  Trainer: createScreen(Trainer_C, 'trainer/:trainerSlug', 'Trainer Profile'),
  Driver: createScreen(Driver_C, 'driver/:driverSlug', 'Driver Profile'),
  Videos: createScreen(Videos_C, 'videos/:categorySlug/:date?', 'Videos'),
  News: createScreen(NewsIndex_C, 'news/:categorySlug?', 'News'),
  NewsArticle: createScreen(NewsArticle_C, 'news/:newsId/:newsSlug', 'News Article'),
  PuntersCorner: createScreen(PuntersCorner_C, 'punters-corner/:date', 'Punters Corner', {
    date: shortDate(new Date())
  }),
  PuntersCornerTrack: createScreen(
    PuntersCornerTrack_C,
    'punters-corner/:date/:trackSlug/:dayPhaseLetter/',
    'Punters Corner Track'
  ),
  CalendarScreen: createScreen(CalendarScreen_C, 'calendar/:date', 'Harness Racing Calendar', {
    date: shortDate(new Date())
  }),
  Pages: createScreen(Page_C, '/:pageSlug/', 'Page'), //todo: test typescript and ads
  MeetingProgram: createScreen(
    MeetingProgram_C,
    'meeting-program/:date/:trackSlug/:dayPhaseLetter/:type',
    // type should be either 'meeting' or 'trial'
    'Meeting Program'
  ),
  ProgramSearch: createScreen(ProgramSearch_C, 'program-search/', 'Program Search'),
  Search: createScreen(Search_C, 'search', 'Search'),
  UserProfile: createScreen(UserProfile_C, 'user/:activeTab?', 'User Profile'),
  ResetPassword: createScreen(ResetPassword_C, 'reset-password/:token', 'Reset Password'),
  FeatureToggleScreen: createScreen(FeatureToggleScreen_C, 'features', 'Feature Toggle')
}

function createScreen<Path extends string>(
  component: FunctionComponent,
  path: Path,
  title: string,
  params?: Partial<PathToParams_M<Path>> & Record<string, any>
) {
  const basePath = path.split('/').shift() as PathToBasePath_M<Path>
  const initialParams = {
    ...params
  } as PathToInitialParams_M<Path>
  const screen: PathToScreen_M<Path> = {
    component,
    title,
    initialParams,
    path,
    basePath
  }
  return screen
}

export const screenToBaseMap = mapObject(screens, (name, obj) => {
  return { [name]: obj.basePath }
})

export type Screens = typeof screens
export type ScreenName = keyof Screens

type PathToParams_M<
  Path extends string,
  IsStart extends boolean = true
> = Path extends `${infer _BasePath}:${infer Param}/${infer Rest}`
  ? Param extends `${infer ParamName}?`
    ? { [K in ParamName]?: string } & PathToParams_M<Rest, false>
    : { [K in Param]: string } & PathToParams_M<Rest, false>
  : Path extends `${infer _BasePath}:${infer Param}`
    ? Param extends `${infer ParamName}?`
      ? { [K in ParamName]?: string }
      : { [K in Param]: string }
    : IsStart extends true
      ? undefined
      : object

type PathToScreen_M<Path extends string> = {
  component: FunctionComponent // for screen component
  initialParams: PathToInitialParams_M<Path>
  title: string // for screen title
  path: Path // for linking options config, and mapped to params,
  basePath?: PathToBasePath_M<Path>
}

type PathToBasePath_M<P extends string> = P extends `${infer B}/${string}` ? B : P

type PathToInitialParams_M<Path extends string> = PathToParams_M<Path>

type ScreensToParamList_M<Screens extends Record<string, PathToScreen_M<any>>> = {
  [ScreenName in keyof Screens]: PathToParams_M<Screens[ScreenName]['path']>
}

export type ParamList = {
  [screen in keyof ScreensToParamList_M<Screens>]: ScreensToParamList_M<Screens>[screen]
}

export type DrawerProps = DrawerScreenProps<ParamList>

export const MainNavigator = createDrawerNavigator<ParamList>()

export const linkingOptions: LinkingOptions<ParamList> = {
  prefixes: [],
  config: {
    screens: mapObject(screens, (name, { path }) => {
      return {
        [name]: {
          path
        }
      }
    })
  }
}
