import { isSameDay } from 'date-fns'
import { orderBy } from 'lodash'
import { View } from 'react-native'
import { BlackbookItem } from '../../../../../../services/data/data-types/local-data-types/blackbook.type'
import { BlackBookUpcomingResponse } from '../../../../../../services/data/request-functions/black-book-request'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../../../services/styles/dependencies/style-constants'
import { presets } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { humanFriendlyDate, humanFriendlyTime, shortDate } from '../../../../../../utils/date-time'
import { Heading_C } from '../../../../../Base/Heading/Heading'
import { Link_C } from '../../../../../Base/Link/Link'
import { Table_C } from '../../../../../Base/Table/Table'
import { TableHeading, TableRow } from '../../../../../Base/Table/table.type'
import { Text_C } from '../../../../../Base/Text/Text'
import { TableCellRaceLink_C } from '../../../../../Partials/_meeting-components/TableCellRaceLink'
import { LinkOrText_C } from '../../../../../Partials/LinkOrText'
import {
  PerPageOption,
  PerPageSelect
} from '../../../../../Partials/Pagination/components/PerPageSelect'
import { Pagination_C } from '../../../../../Partials/Pagination/Pagination'
import { SilkImage_C } from '../../../../../Partials/SilkImage/SilkImage'

type UpcomingGroupedByDay = {
  day: Date
  races: BlackbookItem[]
}

type Props = {
  upcomingResponse: BlackBookUpcomingResponse
  onUpcomingPageChange: (params: { pageNumber?: number; perPage?: PerPageOption }) => void
}
export function UpcomingTable_C({ upcomingResponse, onUpcomingPageChange }: Props) {
  const { upcoming, pagination } = upcomingResponse
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const isMobileLayout = breakpointBelow(desktopBreakpoint)

  const upcommingGroupedByDay: UpcomingGroupedByDay[] = getUpcommingGroupedByDay()

  const tableHeadings: TableHeading[] = [
    { content: 'Date', colWidth: { width: 160 } },
    { content: 'Colours', colWidth: { width: 76 } },
    'Horse / Driver / Trainer',
    {
      content: 'Race Number / Distance / Start Time',
      colWidth: { width: 260 }
    }
  ]

  return (
    <View>
      {upcommingGroupedByDay.length ? (
        <View style={{ gap: 30 }}>
          <PerPageSelect
            initialSelection={pagination?.perPage}
            onSelect={(perPage) => onUpcomingPageChange({ perPage })}
          />
          {pagination && (
            <Pagination_C
              options={{
                infoSide: 'left'
              }}
              {...pagination}
              onPageChange={(pageNumber) =>
                onUpcomingPageChange({
                  pageNumber,
                  perPage: pagination.perPage
                })
              }
            />
          )}
          {upcommingGroupedByDay.map(({ day, races }) => {
            const rows: TableRow[] = races
              .filter((r) => r.nextRace)
              .map((item, idx) => {
                const { nextRace } = item
                if (!nextRace) {
                  console.warn('there should be next race prop')
                  return { cells: [] }
                }
                const { meeting, race, track, horse, trainer, driver } = nextRace
                const driverName = (isMobileLayout && driver?.shortName) || driver?.name || ''
                const trainerName = (isMobileLayout && trainer?.shortName) || trainer?.name || ''
                return {
                  cells: [
                    <TableCellRaceLink_C
                      key={idx}
                      {...{
                        date: shortDate(meeting.date),
                        dayPhaseLetter: meeting.dayPhaseLetter,
                        trackName: track.name,
                        trackSlug: track.slug,
                        hasReplay: false,
                        raceNumber: `${race.raceNumber}`,
                        isTrial: meeting.isTrial
                      }}
                    />,
                    <SilkImage_C key={idx} silk={horse.silk} />,
                    <View key={idx} style={styles.horseTrainerDriver}>
                      <LinkOrText_C
                        name={horse.name}
                        newTab
                        linkUrl={!horse.slug ? undefined : `horse/${horse.slug}`}
                        linkStyle={{
                          text: {
                            base: styles.runner
                          }
                        }}
                      />
                      <View style={{ flexDirection: 'row', gap: 6 }}>
                        {driver.slug && (
                          <Text_C>
                            D:{' '}
                            <LinkOrText_C
                              newTab
                              name={driverName}
                              linkUrl={!driver.slug ? undefined : `driver/${driver.slug}`}
                            />
                          </Text_C>
                        )}
                        {trainer.slug && (
                          <Text_C>
                            T:{' '}
                            <LinkOrText_C
                              newTab
                              name={trainerName}
                              linkUrl={!trainer.slug ? undefined : `trainer/${trainer.slug}`}
                            />
                          </Text_C>
                        )}
                      </View>
                    </View>,
                    <Link_C
                      style={{ elem: styles.raceDetails, text: { color: 'blue' } }}
                      navigateTo={[
                        'Meeting',
                        {
                          trackSlug: track.slug,
                          date: shortDate(meeting.date),
                          raceNumber: `${race.raceNumber}`,
                          dayPhaseLetter: meeting.dayPhaseLetter,
                          type: meeting.isTrial ? 'trial' : 'meeting'
                        }
                      ]}
                    >
                      <Text_C style={styles.linkText}>R{race.raceNumber}</Text_C>
                      <View style={styles.horizontalDivider} />
                      <Text_C style={styles.linkText}>{race.distance}m</Text_C>
                      <View style={styles.horizontalDivider} />
                      <Text_C style={styles.linkText}>{humanFriendlyTime(race.startTime)}</Text_C>
                    </Link_C>
                  ]
                } as TableRow
              })

            return (
              <View key={day.getTime()} style={{ gap: 10 }}>
                <Heading_C styleType="h3">{humanFriendlyDate(day)}</Heading_C>
                <Table_C
                  styleType="lightHeaderBorders"
                  headings={tableHeadings}
                  rows={rows}
                  styles={{ table: { minWidth: 700, width: '100%' } }}
                />
              </View>
            )
          })}
          {pagination && (
            <Pagination_C
              options={{
                infoSide: 'left'
              }}
              {...pagination}
              onPageChange={(pageNumber) =>
                onUpcomingPageChange({
                  pageNumber,
                  perPage: pagination.perPage
                })
              }
            />
          )}
        </View>
      ) : (
        <Text_C style={{ fontStyle: 'italic' }}>
          There are currently no upcoming races that include your Blackbook horses
        </Text_C>
      )}
    </View>
  )

  function getUpcommingGroupedByDay(): UpcomingGroupedByDay[] {
    return orderBy(
      upcoming.reduce((dayGroups, upcomingRace) => {
        if (!upcomingRace.nextRace) {
          console.error('should not have upcomming race with no race')
          return dayGroups
        }
        const { date } = upcomingRace.nextRace.meeting

        const matchingDayGroup = dayGroups.find(({ day }) => isSameDay(day, date))
        if (matchingDayGroup) {
          matchingDayGroup.races.push(upcomingRace)
          return dayGroups
        } else {
          dayGroups.push({
            day: date,
            races: [upcomingRace]
          })
          return dayGroups
        }
      }, [] as UpcomingGroupedByDay[]),
      (group) => group.day,
      'asc'
    )
  }
}

const breakpointStyles = createStyles({
  heading: { marginBottom: 20 },
  horseTrainerDriver: {
    gap: 6
  },
  meetingDetails: {
    gap: 6
  },
  trackName: {
    fontWeight: '600SemiBold',
    ...textSizes.size3
  },
  trackState: {
    fontWeight: '600SemiBold',
    ...textSizes.size1
  },
  trackNameRow: { flexDirection: 'row', alignItems: 'baseline', gap: 8 },
  raceDetails: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  horizontalDivider: {
    height: 10,
    width: 1,
    backgroundColor: presets.border,
    marginHorizontal: 6
  },
  runner: {
    base: {
      ...textSizes.size4,
      fontWeight: '600SemiBold',
      marginRight: 4
    }
  },
  linkText: {
    color: presets.primary,
    fontWeight: '600SemiBold',
    ...textSizes.size2
  }
})
