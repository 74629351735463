import {
  API_ENV,
  DEV_API_KEY,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_API_KEY_RISE,
  PRODUCTION_API_KEY,
  RECAPTCHA_KEY_DANIEL,
  RECAPTCHA_KEY_PROD,
  STAGING_API_KEY
} from '@env'

const environmentNames = ['development', 'stagingDev', 'staging', 'production'] as const

type EnvironmentName = (typeof environmentNames)[number]

type Environment = {
  apiUrl: string
  baseUrl: string
  apiKey: string
  mapApiKey: string
  recaptchaKey: string
}

const environments: Record<EnvironmentName, Environment> = {
  development: {
    apiUrl: 'http://localhost:4100',
    baseUrl: 'http://localhost:4100',
    apiKey: DEV_API_KEY,
    mapApiKey: GOOGLE_MAPS_API_KEY,
    recaptchaKey: RECAPTCHA_KEY_PROD || RECAPTCHA_KEY_DANIEL
  },
  stagingDev: {
    apiUrl: 'https://api.staging.harness.au',
    baseUrl: 'http://localhost:19006',
    apiKey: STAGING_API_KEY,
    mapApiKey: GOOGLE_MAPS_API_KEY,
    recaptchaKey: RECAPTCHA_KEY_PROD || RECAPTCHA_KEY_DANIEL
  },
  staging: {
    apiUrl: 'https://api.staging.harness.au',
    baseUrl: 'https://staging.harness.au',
    apiKey: STAGING_API_KEY,
    mapApiKey: GOOGLE_MAPS_API_KEY_RISE,
    recaptchaKey: RECAPTCHA_KEY_PROD || RECAPTCHA_KEY_DANIEL
  },
  production: {
    apiUrl: 'https://api.harness.au',
    baseUrl: 'https://harness.au',
    apiKey: PRODUCTION_API_KEY,
    mapApiKey: GOOGLE_MAPS_API_KEY_RISE,
    recaptchaKey: RECAPTCHA_KEY_PROD || RECAPTCHA_KEY_DANIEL
  }
}

const currentEnvironmentName: EnvironmentName = API_ENV || 'staging'

if (!environmentNames.includes(currentEnvironmentName)) {
  throw `Environment name invalid: ${currentEnvironmentName}`
}

export const environment: Environment = environments[currentEnvironmentName]
