import { Fragment, useEffect, useState } from 'react'
import { View } from 'react-native'

import { NewsArticle } from '../../../../services/data/data-types/local-data-types/news.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../../styles/colors'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Link_C } from '../../../Base/Link/Link'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { NewsItemSmall_C } from '../../NewsIndex/components/NewsItemSmall'
import { getNewsList } from '../../../../services/data/request-functions/news-list-request'

export function LatestNews_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const [articles, setArticles] = useState<NewsArticle[]>([])

  useEffect(() => {
    getNewsList({ limit: 5 }).then(({ articles }) => {
      const newsItems = articles.slice(0, 5)
      setArticles(newsItems)
    })
  }, [])

  const rowDivider = <View style={styles.rowDivider} />

  return (
    <Card_C>
      <View style={styles.topContainer}>
        <Heading_C styleType="h2" style={styles.heading}>
          LATEST NEWS
        </Heading_C>
      </View>

      <View style={{ rowGap: 14 }}>
        {articles.length > 0 &&
          articles.map((article, idx) => (
            <Fragment key={idx}>
              {rowDivider}
              <NewsItemSmall_C key={article.id} article={article} />
            </Fragment>
          ))}
      </View>

      {!articles.length && <Text_C style={styles.noArticles}>No articles available</Text_C>}

      <Link_C
        style={{
          elem: styles.allArticlesLink,
          text: styles.allArticlesLinkText
        }}
        navigateTo={['News', {}]}
      >
        View All Articles
      </Link_C>
    </Card_C>
  )
}

const breakpointStyles = createStyles({
  allArticlesLink: { alignSelf: 'flex-end', marginTop: 16 },
  allArticlesLinkText: { fontWeight: '600SemiBold' },
  heading: {
    base: {
      textTransform: 'uppercase',
      marginRight: 'auto'
    }
  },
  topContainer: {
    base: {
      flexDirection: 'row',
      marginBottom: 20,
      columnGap: 20,
      zIndex: 1,
      alignItems: 'center'
    }
  },
  noArticles: {
    base: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingVertical: 20,
      fontStyle: 'italic'
    }
  },
  rowDivider: {
    base: {
      height: 1.2,
      backgroundColor: presets.border,
      width: '100%'
    }
  }
})
