import { useCallback, useEffect, useMemo, useState } from 'react'
import { Dimensions, ScaledSize } from 'react-native'
import { BreakpointName } from './breakpoint-style.type'
import { breakpointNames, breakpointSizes } from './style-constants'

export function useActiveBreakpoint() {
  const [activeBreakpoint, setActiveBreakpoint] = useState<BreakpointName>(
    getCurrentBreakpoint(Dimensions.get('window').width)
  )

  const onWidthChange = useCallback(
    (width: number) => {
      const breakpoint = getCurrentBreakpoint(width)
      if (breakpoint !== activeBreakpoint) {
        setActiveBreakpoint(breakpoint)
      }
    },
    [activeBreakpoint] //ensures latest value of activeBreakpoint
  )

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window: { width } }: { window: ScaledSize }) => onWidthChange(width)
    )

    return () => {
      subscription.remove()
    }
  }, [onWidthChange])

  function getCurrentBreakpoint(width: number) {
    return breakpointNames.reduce((previous: BreakpointName, current) => {
      const breakpointSize = breakpointSizes[current]
      if (breakpointSize <= width) {
        if (breakpointSize > breakpointSizes[previous]) {
          return current
        }
      }
      return previous
    }, 'base')
  }

  return useMemo(() => {
    return activeBreakpoint
  }, [activeBreakpoint])
}
