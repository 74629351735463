import { useNavigation } from '@react-navigation/native'

import { Image, ImageStyle, Pressable, View } from 'react-native'

import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../../services/styles/dependencies/style-constants'
import { Heading_C } from '../../../Base/Heading/Heading'

export function FooterTitle_C() {
  const navigation = useNavigation()
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  return (
    <View style={styles.titleContainer}>
      <Pressable onPress={() => navigation.navigate('Home')}>
        <Image
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          source={require('../../../../assets/images/logo-light.png')}
          style={styles.logo as ImageStyle}
          resizeMode="contain"
        />
      </Pressable>
      <Heading_C styleType="h3" style={breakpointStyles.footerText}>
        Harness.org.au
      </Heading_C>
    </View>
  )
}
const breakpointStyles = createStyles({
  titleContainer: {
    base: {
      flexDirection: 'row',
      gap: 16,
      marginBottom: 14,
      alignItems: 'center'
    },
    [tabletBreakpoint]: {
      marginBottom: 30
    }
  },
  footerText: {
    base: {
      color: 'white'
    }
  },
  logo: {
    base: {
      aspectRatio: 1.867,
      height: 60
    }
  }
})
