import { useNavigation } from '@react-navigation/native'
import {
  VideosListResponse,
  getAllVideoReplays
} from '../../../services/data/request-functions/videos-list-request'
import { savePageInHistory } from '../../../utils/save-history'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { TabConfigItem, TabView_C } from '../../Layout/TabView/TabView'
import { LatestNews_C } from './components/LatestNews'
import { PodcastList_C } from './components/PodcastList'
import { RaceReplays_C } from './components/RaceReplays'
import { StewardsReplays_C } from './components/StewardsReplay'
import { Metadata } from '../../Partials/Metadata/Metadata'

export type ResponseDateObj = {
  date: Date
  minDate: Date
  maxDate: Date
}

export function Videos_C(): JSX.Element {
  return withScreenWrapper<'Videos', [VideosListResponse]>({
    requestsSetup: ({ date }) => [getAllVideoReplays({ date })],
    aside: () => <LatestNews_C />,
    view: ({ responses, params }) => {
      const { navigate } = useNavigation()
      const [videosListResponse] = responses!

      const videosTabs = [
        {
          title: 'Race Replays',
          slug: 'replays',
          component: <RaceReplays_C videosListResponse={videosListResponse} />
        },
        {
          title: 'Stewards Replays',
          slug: 'stewards-replays',
          component: <StewardsReplays_C videosListResponse={videosListResponse} />
        },
        {
          title: 'Podcasts',
          slug: 'podcasts',
          component: <PodcastList_C />
        }
      ]

      const tabs: TabConfigItem[] = videosTabs.map((tab) => [tab.title, tab.component])

      const activeTab = videosTabs.find((tab) => tab.slug === params.categorySlug)

      function handleTabSelected(tabName?: string, date?: string) {
        const tab = videosTabs.find((tab) => tab.title === tabName)
        const dateSlug = date ?? ''
        let params = {
          categorySlug: 'replays',
          date: dateSlug
        }

        if (tab) {
          params = { categorySlug: tab.slug, date: dateSlug }
        }

        navigate('Videos', params)
        savePageInHistory()
      }

      return (
        <>
          <Metadata
            title={activeTab?.title ?? videosTabs[0].title}
            description="Watch harness racing replays & podcasts all from harness.org.au."
          />
          <TabView_C
            heading="Videos"
            tabs={tabs}
            initialActiveTab={activeTab?.title ?? videosTabs[0].title}
            onTabSelected={(tabName) => handleTabSelected(tabName, params.date)}
          />
        </>
      )
    }
  })
}
