/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createContext } from 'react'
import { DayPhaseLetter } from '../../../services/data/data-types/general-data-types.type'
import { MeetingDetailed } from '../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  MeetingDetailedResponse,
  getMeetingDetailed
} from '../../../services/data/request-functions/meeting-detailed-request'
import {
  MeetingPuntersCorner,
  PuntersCornerResponse,
  getPuntersCornerData
} from '../../../services/data/request-functions/punters-corner-request'
import { ParamList } from '../../../services/routes/screens'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { PuntersCornerMeeting_C } from './components/PuntersCornerMeeting'
import { PuntersCornerTrackHeader_C } from './components/PuntersCornerTrackHeader'

export function PuntersCornerTrack_C(): JSX.Element {
  return withScreenWrapper<'PuntersCornerTrack', [PuntersCornerResponse, MeetingDetailedResponse]>({
    requestsSetup: ({ date, trackSlug, dayPhaseLetter }) => [
      getPuntersCornerData({ date }),
      getMeetingDetailed({
        date,
        trackSlug,
        dayPhaseLetter: dayPhaseLetter as DayPhaseLetter,
        raceNumber: 'all',
        trial: false
      })
    ],

    view: ({ responses, params }) => {
      const [meetingsResponse, detailedMeetingResponse] = responses!
      const { meetings: todaysMeetings } = meetingsResponse
      const { meeting: activeMeeting } = detailedMeetingResponse

      const context: PuntersCornerTrackContext = {
        params: params,
        date: params.date,
        selectedRaceNumber: undefined,
        activeMeeting,
        todaysMeetings,
        activeMeetingPuntersCorner: todaysMeetings.find(
          (m) =>
            m.track.name == activeMeeting.track.name && m.dayPhaseLetter == params.dayPhaseLetter
        )!
      }

      return (
        <PuntersCornerTrackContext.Provider value={context}>
          <PuntersCornerTrackHeader_C />
          <PuntersCornerMeeting_C />
        </PuntersCornerTrackContext.Provider>
      )
    }
  })
}

export const PuntersCornerTrackContext = createContext<PuntersCornerTrackContext>(
  {} as PuntersCornerTrackContext
)

export type PuntersCornerTrackContext = {
  params: ParamList['PuntersCornerTrack'] & { date: string }
  date: string
  todaysMeetings: MeetingPuntersCorner[]
  activeMeeting: MeetingDetailed
  selectedRaceNumber?: number | undefined
  activeMeetingPuntersCorner: MeetingPuntersCorner
}
