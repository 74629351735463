import { useContext } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../global-context'
import { loopObject } from '../../../helper-functions'
import { Button_C } from '../../Base/Button/Button'
import { Heading_C } from '../../Base/Heading/Heading'
import { crossIcon, tickIcon } from '../../Base/Icon/preset-icon-props'
import { Link_C } from '../../Base/Link/Link'
import { Text_C } from '../../Base/Text/Text'

export function FeatureToggleScreen_C() {
  const { featuresToggle, setFeaturesToggle } = useContext(GlobalContext)
  return (
    <View style={{ padding: 20, gap: 20 }}>
      <Heading_C styleType="h1">Feature Toggle List</Heading_C>
      <Link_C navigateTo={['Home']}>{'<< back'}</Link_C>

      {featuresToggle &&
        loopObject(featuresToggle, (key, { label, active }) => {
          return (
            <View style={{ gap: 10, flexDirection: 'row' }} key={key}>
              <Button_C
                {...{
                  styleType: 'iconOnly',
                  onPress: () => {
                    setFeaturesToggle({
                      ...featuresToggle,
                      [key]: { active: !active, label }
                    })
                  },
                  ...(active ? { icon: tickIcon, active: true } : { icon: crossIcon }),
                  style: {
                    text: {
                      base: { color: 'red' },
                      active: { color: 'green' },
                      hovered: { color: active ? 'green' : 'red' }
                    }
                  }
                }}
              />
              <Text_C>{label}</Text_C>
            </View>
          )
        })}
    </View>
  )
}
