import { Pressable, View } from 'react-native'

import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'
import { colors } from '../../../styles/colors'
import { IconProps, Icon_C } from '../../Base/Icon/Icon'
import { Text_C } from '../../Base/Text/Text'

type Props<OptionName extends string> = {
  // eslint-disable-next-line no-unused-vars
  onOptionSelected: (option: OptionName) => void
  options: { name: OptionName; icon?: IconProps }[]
  initialSelectedOption: OptionName
  style?: NamedBreakpointStylesExtension<typeof breakpointStyles>
}

export function OptionToggle_C<OptionName extends string>(props: Props<OptionName>) {
  const { initialSelectedOption, onOptionSelected, options, style } = props

  const styles = useBreakpointStyles({
    styles: breakpointStyles,
    additionalStyles: [style]
  })

  return (
    <View style={styles.toggleButtonStyle}>
      {options.map((option) => (
        <Pressable
          key={option.name}
          onPress={() => onOptionSelected(option.name)}
          style={[styles.optionIdle, initialSelectedOption == option.name && styles.optionSelected]}
        >
          <Text_C
            style={{
              ...styles.optionIdleText,
              ...(initialSelectedOption == option.name && styles.optionSelectedText)
            }}
          >
            {option.name}
          </Text_C>
          {option.icon ? (
            <Icon_C
              {...option.icon}
              color={initialSelectedOption == option.name ? colors.gray200 : colors.gray700}
            />
          ) : (
            <></>
          )}
        </Pressable>
      ))}
    </View>
  )
}

const breakpointStyles = createStyles({
  toggleButtonStyle: {
    base: {
      flexDirection: 'row',
      borderRadius: 4,
      height: 30,
      overflow: 'hidden'
    }
  },
  optionIdle: {
    base: {
      backgroundColor: colors.gray200,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      paddingHorizontal: 14
    }
  },
  optionIdleText: {
    base: {
      color: colors.gray700
    }
  },
  optionSelected: {
    base: {
      backgroundColor: colors.gray700
    }
  },
  optionSelectedText: {
    base: {
      color: colors.gray200
    }
  }
})

/* {
  toggleButtonStyle: {
    base: {
      flexDirection: "row",
      borderRadius: 4,
      height: 30,
      overflow: "hidden",
    },
  },
  optionIdle: {
    base: {
      backgroundColor: colors.gray200,
      flexDirection: "row",
      width: 75,
      paddingLeft: 11,
      alignItems: "center",
      gap: 5,
    },
  },
  optionIdleText: {
    base: {
      color: colors.gray700,
    },
  },
  optionSelected: {
    base: {
      backgroundColor: colors.gray700,
    },
  },
  optionSelectedText: {
    base: {
      color: colors.gray200,
    },
  },
} */
