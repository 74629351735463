import { differenceInMilliseconds, isBefore, milliseconds } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { getDateNow } from '../../../helper-functions'
import { Text_C } from '../Text/Text'

type Props = {
  timeBasedContent: TimeBasedContent[]
  fallbackContent: JSX.Element | string
}

type ContentOptions = JSX.Element | string | (() => JSX.Element | string)

export type TimeBasedContent = {
  ifBeforeDate: Date
  content: ContentOptions
}

/**
 *
 *
 * @description
 *  - when the component loads it sets a timeout for how long till it expires, when it expires, its checks the array to find the next active option
 *  - If the content is a function, the function will fire each time there is a 1 second interval, in your component use this with the current date to get a countdown
 * @example
 * // on 1 sec interval, update content, value of diff changes based on start Date changing
 *  content: () => {
      const diff = intervalToDuration({
        start: new Date(),
        end: race.startTime,
      });
      return (
        <Text_C style={styles.countdownInRed}>
          {`${diff.minutes}:${diff.seconds}m`}
        </Text_C>
      );
    }
 */
export function CountdownContent_C({ timeBasedContent, fallbackContent = '' }: Props): JSX.Element {
  const [shouldRun, setShouldRun] = useState(false)

  const intervalInstance = useRef<NodeJS.Timer>()

  const [activeOption, setActiveOption] = useState<TimeBasedContent | undefined>(getActiveOption())

  const [, rerenderTrigger] = useState(false)

  useEffect(() => {
    clearInterval(intervalInstance.current)

    if (shouldRun) {
      intervalInstance.current = setInterval(
        () => {
          rerenderTrigger((prev) => !prev)
        },
        milliseconds({ seconds: 1 })
      )
    }
    return () => {
      clearInterval(intervalInstance.current)
    }
  }, [shouldRun])

  /* when an option is set, set a timeout for when it expires, refresh and pick the next option */
  useEffect(() => {
    let stateChangeTimeout: NodeJS.Timer | null = null
    if (!activeOption) {
      clearInterval(intervalInstance.current)
    } else {
      setShouldRun(typeof activeOption.content == 'function')

      const now = getDateNow()

      const timeDiffMs = differenceInMilliseconds(activeOption.ifBeforeDate, now)

      stateChangeTimeout = setTimeout(() => {
        const option = getActiveOption()
        setActiveOption(option)
      }, timeDiffMs)
    }

    return () => {
      if (stateChangeTimeout) clearInterval(stateChangeTimeout)
    }
  }, [activeOption])

  let contentToRender = activeOption?.content ?? fallbackContent

  if (typeof contentToRender == 'function') {
    contentToRender = contentToRender()
  }

  if (typeof contentToRender == 'string') {
    contentToRender = <Text_C>{contentToRender}</Text_C>
  }

  return contentToRender

  function getActiveOption(): TimeBasedContent | undefined {
    return timeBasedContent.find(({ ifBeforeDate }) => isBefore(getDateNow(), ifBeforeDate))
  }
}
