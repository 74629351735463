/* eslint-disable no-unused-vars */
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import {
  BreakpointStyles,
  GeneralNamedStyles,
  NamedBreakpointStyles,
  NamedBreakpointStylesExtension,
  NamedStatefulBreakpointStyles,
  NamedStatefulBreakpointStylesExtension,
  NamedStyleExtension,
  NamedStyles,
  StyleType
} from './breakpoint-style.type'
import { processStyles } from './process-styles'
import { useActiveBreakpoint } from './use-active-breakpoint'

/*
  - 2 types of style with 2 sub types
    - non-named
      - style
      - bps
      ( no need for non-named sbps yet)
    - named
      - style
      - bps
      - sbps


/* non-named */
export function useBreakpointStyles(obj: {
  styles: StyleType
  additionalStyles?: Array<StyleType | undefined>
}): StyleType

/* non-named bps */
export function useBreakpointStyles(obj: {
  styles: StyleType | BreakpointStyles
  additionalStyles?: Array<StyleType | BreakpointStyles | undefined>
}): StyleType

/* named */
export function useBreakpointStyles<T extends GeneralNamedStyles<T>>(
  obj: {
    styles: T
    additionalStyles?: Array<NamedStyleExtension<T> | NamedBreakpointStylesExtension<T> | undefined>
  },
  test?: { screenName: string }
): NamedStyles<T>

/* named stateful */
export function useBreakpointStyles<
  T extends GeneralNamedStyles<T, StateNames>,
  StateNames extends string
>(obj: {
  styles: T
  additionalStyles?: Array<NamedStatefulBreakpointStylesExtension<T, StateNames> | undefined>
  state?: Record<StateNames, boolean>
}): NamedStyles<T>

export function useBreakpointStyles<
  T extends
    | NamedStyles<T>
    | NamedBreakpointStyles<T>
    | NamedStatefulBreakpointStyles<T, StateNames>,
  StateNames extends string
>(
  obj: {
    styles: T | StyleType | BreakpointStyles
    additionalStyles?: Array<
      | StyleType
      | NamedStyleExtension<T>
      | NamedBreakpointStylesExtension<T>
      | NamedStatefulBreakpointStylesExtension<T, StateNames>
      | undefined
    >
    state?: Record<StateNames, boolean>
  },
  test?: { screenName: string }
) {
  const activeBreakpoint = useActiveBreakpoint()
  const initialState = { ...obj, activeBreakpoint }
  const [state, setState] = useState(initialState)

  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const [processedStyles, setProcessedStyles] = useState(processStyles(state))

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    } else {
      setProcessedStyles(processStyles(state))
    }
  }, [state])

  useEffect(() => {
    if (!isEqual(state.activeBreakpoint, activeBreakpoint)) {
      setState((prev) => ({ ...prev, activeBreakpoint }))
    }
  }, [activeBreakpoint])

  useEffect(() => {
    if (!isEqual(state.additionalStyles, obj.additionalStyles)) {
      setState((prev) => ({
        ...prev,
        additionalStyles: obj.additionalStyles
      }))
    }
  }, [obj.additionalStyles])

  useEffect(() => {
    if (!isEqual(state.state, obj.state)) {
      setState((prev) => ({ ...prev, state: obj.state }))
    }
  }, [obj.state])

  useEffect(() => {
    if (!isEqual(state.styles, obj.styles)) {
      setState((prev) => ({ ...prev, styles: obj.styles }))
    }
  }, [obj.styles])

  return useMemo(() => {
    /* keep this test code to help verify style code execution */
    if (test?.screenName) {
      // eslint-disable-next-line no-console
      // console.log("return styles", test?.screenName)
    }
    return processedStyles
  }, [processedStyles])
}
