import { AccessibilityProps, ColorValue, View, ViewStyle } from 'react-native'
import svgs from './svgs'

export type SvgName = keyof typeof svgs

export type IconProps = AccessibilityProps & {
  name: SvgName
  color?: ColorValue
  colorFill?: boolean
  colorStroke?: boolean
  style?: ViewStyle
  strokeWidth?: number
  useParentTextColor?: boolean
}

export function Icon_C({
  name,
  color,
  colorFill,
  colorStroke = true,
  style,
  strokeWidth,
  accessibilityLabel
}: IconProps) {
  const SVG = svgs[name]
  return (
    <View style={style} {...{ accessibilityLabel }}>
      <SVG
        {...(strokeWidth && {
          strokeWidth
        })}
        fill={colorFill ? color : 'transparent'}
        stroke={colorStroke ? color : 'transparent'}
        height={'100%'}
      />
    </View>
  )
}
