import ordinal from 'ordinal'
import { Dispatch, SetStateAction, useContext } from 'react'
import { View } from 'react-native'
import { formatCurrency, isNumeric, roundToDecimals } from '../../../../../../helper-functions'
import { RunnerBasic } from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import { createStyles } from '../../../../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { Icon_C } from '../../../../../Base/Icon/Icon'
import { blackbookRibbon } from '../../../../../Base/Icon/preset-icon-props'
import { Link_C } from '../../../../../Base/Link/Link'
import { Table_C, tableStyles } from '../../../../../Base/Table/Table'
import { TableHeading, TableRow } from '../../../../../Base/Table/table.type'
import { Text_C } from '../../../../../Base/Text/Text'
import { ExpandContainer_C } from '../../../../../Layout/ExpandContainer/ExpandContainer'
import { InfoButton_C } from '../../../../../Partials/InfoButton/InfoButton'
import { LinkOrText_C } from '../../../../../Partials/LinkOrText'
import { SilkImage_C } from '../../../../../Partials/SilkImage/SilkImage'
import { MeetingContext } from '../../../Meeting'
import { ResultsExpandedDesktop_C } from './ResultsExpandedDesktop'

type Props = {
  runners: RunnerBasic[]
  raceNumber: number
  expandedRunners: RunnerBasic[]
  isTrial: boolean
  setExpandedRunners: Dispatch<SetStateAction<RunnerBasic[]>>
}
export function ResultTableDesktop_C(props: Props) {
  const { blackbookHorseIds } = useContext(MeetingContext)
  const hasProtest = props.runners.some((r) => r.pastThePostPlace !== r.place)

  const desktopHeadings: (string | TableHeading)[] = [
    { content: 'Place', colWidth: { width: 80 } },
    ...(hasProtest ? [{ content: 'Past the Post', colWidth: { width: 110 } }] : []),
    { content: 'Number and Horse', colWidth: { flex: 9 } },
    ...(props.isTrial ? [] : [{ content: 'Prize', colWidth: { width: 80 } }]),
    { content: 'BR', colWidth: { width: 80 } },
    { content: 'Margin', colWidth: { width: 80 } },
    ...(props.isTrial ? [] : [{ content: 'SP', colWidth: { width: 80 } }]),
    { content: 'Comments', colWidth: { flex: 6 } }
  ]
  const desktopRows: TableRow[] = props.runners
    .filter((r) => !r.scratched)
    .map((runner) => {
      const {
        trainer,
        driver,
        horse,
        place,
        runnerNumber,
        barrier,
        margin,
        startingPrice,
        claimingPrice,
        stewardsCommentShort,
        stewardsCommentLong,
        silk,
        prizemoney,
        pastThePostPlace,
        odStatus
      } = runner
      const isExpanded = props.expandedRunners.some((r) => r.runnerNumber == runnerNumber)

      const placeNumber = isNumeric(place) ? Number(place) : undefined

      const ordinalPlace = placeNumber ? ordinal(placeNumber) : undefined

      const isTop3 = placeNumber && placeNumber >= 1 && placeNumber <= 3

      const ptpPlaceNumber = isNumeric(pastThePostPlace) ? Number(pastThePostPlace) : undefined

      const ptpOrdinalPlace = ptpPlaceNumber ? ordinal(ptpPlaceNumber) : undefined

      const runnerRows: TableRow = {
        underRowContent: (
          <ExpandContainer_C isExpanded={isExpanded}>
            <ResultsExpandedDesktop_C meetingRunner={runner} raceNumber={props.raceNumber} />
          </ExpandContainer_C>
        ),
        onSelected: () =>
          props.setExpandedRunners((prev) =>
            prev.some((r) => r.runnerNumber == runnerNumber)
              ? prev.filter((r) => r.runnerNumber != runnerNumber)
              : [...prev, runner]
          ),
        cells: [
          {
            style: {
              ...styles.runnerPlaceStyle,
              ...(isTop3 && styles.top3Place)
            },
            textStyle: {
              ...(isTop3 && styles.top3PlaceText)
            },
            content: ordinalPlace ?? place
          },
          ...(hasProtest
            ? [
                {
                  style: {
                    ...styles.runnerPlaceStyle
                  },
                  content: ptpOrdinalPlace ?? ptpPlaceNumber
                }
              ]
            : []),
          {
            content: (
              <>
                <View style={{ flexDirection: 'row', gap: 16, alignItems: 'center' }}>
                  <SilkImage_C silk={silk} />
                  <View style={{ gap: 4 }}>
                    <LinkOrText_C
                      name={`${runnerNumber}. ${horse.name}${
                        claimingPrice ? ` ($${claimingPrice})` : ''
                      }`}
                      newTab
                      linkUrl={!horse.slug ? undefined : `horse/${horse.slug}`}
                      linkStyle={{ text: styles.runner }}
                    />

                    <View style={{ flexDirection: 'row' }}>
                      <Text_C style={textSizes.size2}>
                        {driver && (
                          <>
                            D:{' '}
                            {!driver.slug ? (
                              <Text_C>{driver.name ?? driver.shortName}</Text_C>
                            ) : (
                              <Link_C newTab linkUrl={`driver/${driver.slug}`}>
                                {driver.name ?? driver.shortName}
                              </Link_C>
                            )}
                          </>
                        )}
                        {driver && trainer && ' | '}
                        {trainer && (
                          <>
                            T:{' '}
                            {!trainer.slug ? (
                              <Text_C>{trainer.name ?? trainer.shortName}</Text_C>
                            ) : (
                              <Link_C newTab linkUrl={`trainer/${trainer.slug}`}>
                                {trainer.name ?? trainer.shortName}
                              </Link_C>
                            )}
                          </>
                        )}
                      </Text_C>
                    </View>
                  </View>
                </View>
                {isRunnerInBlackBook(runner) && <Icon_C {...blackbookRibbon} />}
              </>
            )
          },
          ...(props.isTrial
            ? []
            : [
                {
                  content: prizemoney && formatCurrency(prizemoney)
                }
              ]),

          `${barrier}${odStatus ? `\n${odStatus}` : ''}`,

          margin ? roundToDecimals(margin, 1) : '---',

          //starting price
          ...(props.isTrial ? [] : [{ content: startingPrice }]),
          {
            content: (
              <View
                style={{
                  flexDirection: 'row',
                  gap: 6,
                  alignItems: 'center'
                }}
              >
                {stewardsCommentLong && <InfoButton_C tooltipContent={stewardsCommentLong} />}
                <Text_C>{stewardsCommentShort}</Text_C>
              </View>
            )
          }
        ]
      }
      return runnerRows
    })

  return (
    <Table_C
      styleType="lightHeaderBorders"
      headings={desktopHeadings}
      rows={desktopRows}
      styles={{
        tableContainer: { base: { width: '100%' } },
        table: { base: { minWidth: 900, width: '100%' } }
      }}
    />
  )
  function isRunnerInBlackBook(runner: RunnerBasic): boolean {
    return blackbookHorseIds?.includes(runner.horse.id) ?? false
  }
}

const styles = createStyles({
  expandSwitch: {
    flexDirection: 'row',
    gap: 10
  },
  silkSvg: {
    width: 37.2
  },
  runnerPlaceStyle: {
    ...tableStyles.cell,
    ...textSizes.size3,
    justifyContent: 'center',
    alignItems: 'center'
  },
  top3Place: {
    backgroundColor: presets.primary
  },
  top3PlaceText: {
    fontWeight: '600SemiBold',
    color: 'white'
  },
  silkImage: {
    aspectRatio: 0.838,
    width: 42,
    marginTop: -8
  },
  runner: {
    ...textSizes.size4,
    fontWeight: '600SemiBold'
  }
})
