import { Image, ImageSourcePropType, ImageStyle, View } from 'react-native'
import { Fragment, useEffect, useState } from 'react'
import {
  NewsArticleResponse,
  getNewsArticle
} from '../../../services/data/request-functions/news-article-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { ImageDimensions } from '../../../types/miscellaneous-types.type'
import { getAssetUrl, getPdfUrl } from '../../../utils/assets'
import { IFrame_C } from '../../Base/IFrame/IFrame'
import { Link_C } from '../../Base/Link/Link'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { RenderRichText } from '../../Partials/RichText/RichText'
import { CategoryAndDate_C } from '../NewsIndex/components/CategoryAndDate'
import { RelatedArticles_C } from './components/RelatedArticles'
import { Metadata } from '../../Partials/Metadata/Metadata'

export function NewsArticle_C(): JSX.Element {
  return withScreenWrapper<'NewsArticle', [NewsArticleResponse]>({
    requestsSetup: ({ newsId }) => [getNewsArticle({ newsId })],
    aside: ({ responses }) => {
      if (!responses) return
      const [{ article }] = responses
      return <RelatedArticles_C articles={article.similarArticles} />
    },
    view: ({ responses }) => {
      const [{ article }] = responses!
      const styles = useBreakpointStyles({ styles: breakpointStyles })
      const [logoDimensions, setLogoDimensions] = useState<ImageDimensions>()

      const heroImageUrl = getAssetUrl(article.hero)

      const pdfUrl = getPdfUrl(article?.pdf?.path)

      const stateLogoUrl = getAssetUrl(article.stateLogo)

      useEffect(() => {
        if (stateLogoUrl) {
          Image.getSize(stateLogoUrl, (width, height) => {
            setLogoDimensions({
              width,
              height,
              apsectRatio: width / height
            })
          })
        }
      }, [])

      return (
        <Fragment>
          <Metadata
            title={article.metaTitle ?? article.headline}
            description={article.metaDescription ?? article.headline}
          />

          <Card_C style={styles.newsCard}>
            {!!heroImageUrl && (
              <Image
                style={styles.image as ImageStyle}
                source={{ uri: heroImageUrl }}
                resizeMethod="auto"
                resizeMode="contain"
              />
            )}

            <View style={styles.newsContent}>
              <View style={{ flexDirection: 'row', gap: 20, marginBottom: 6 }}>
                <View style={{ rowGap: 6, flex: 1 }}>
                  <View
                    style={{
                      flexDirection: !heroImageUrl ? 'column-reverse' : 'column',
                      rowGap: 6
                    }}
                  >
                    <CategoryAndDate_C article={article} />
                    <Text_C style={styles.heading}>{article.headline}</Text_C>
                  </View>

                  <Text_C type="articleTextType">
                    {!!article.author && `By ${article.author}`}
                  </Text_C>
                </View>

                {stateLogoUrl && logoDimensions && (
                  <Image
                    resizeMode="contain"
                    style={{
                      height: 80,
                      aspectRatio: logoDimensions.apsectRatio,
                      marginLeft: 'auto',
                      maxWidth: 100
                    }}
                    source={stateLogoUrl as ImageSourcePropType}
                  />
                )}
              </View>

              {!!article.content && <RenderRichText content={article.content} />}

              {!!pdfUrl && (
                <Link_C
                  linkUrl={pdfUrl}
                  newTab
                  externalLink
                  style={{
                    elem: { alignSelf: 'flex-start', overflow: 'hidden', marginVertical: 8 }
                  }}
                >
                  <IFrame_C
                    uri={pdfUrl}
                    style={{
                      pointerEvents: 'none',
                      width: 120,
                      aspectRatio: 0.8,
                      border: 0
                    }}
                  />
                </Link_C>
              )}

              {!!article.trotsUrl && (
                <View style={{ marginTop: 10 }}>
                  <Link_C
                    linkUrl={article.trotsUrl}
                    externalLink
                    newTab
                    style={{ elem: { base: { flexDirection: 'row' } } }}
                  >
                    <Text_C
                      style={{
                        fontWeight: '700Bold',
                        ...textSizes.size3
                      }}
                    >
                      See the full article at{' '}
                    </Text_C>
                    <Text_C
                      style={{
                        color: presets.primary,
                        fontWeight: '700Bold',
                        ...textSizes.size3
                      }}
                    >
                      thetrots.com.au
                    </Text_C>
                  </Link_C>
                </View>
              )}
            </View>
          </Card_C>
        </Fragment>
      )
    }
  })
}

const breakpointStyles = createStyles({
  heading: {
    base: {
      marginBottom: 0,
      fontSize: 20,
      lineHeight: 24,
      fontWeight: '700Bold'
    },
    large: {
      fontSize: 24
    }
  },
  newsCard: {
    base: {
      width: '100%',
      // Overriding Card component padding
      paddingHorizontal: 0,
      paddingVertical: 0,
      paddingBottom: 16,
      overflow: 'hidden'
    },
    large: {
      alignSelf: 'center'
    }
  },

  newsContent: {
    base: {
      paddingVertical: 14,
      paddingHorizontal: 10
    },
    large: {
      paddingVertical: 20,
      paddingHorizontal: 16
    }
  },
  image: {
    base: {
      width: '100%',
      flex: 1,
      aspectRatio: 16 / 9,
      backgroundColor: presets.background,
      marginBottom: 6
    }
  },
  categoryLink: {
    ...textSizes.size1,
    textTransform: 'uppercase',
    fontWeight: '600SemiBold',
    color: presets.primary,
    marginBottom: 5
  }
})
