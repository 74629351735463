import { useNavigation } from '@react-navigation/native'

import { shortDate } from '../../../utils/date-time'
import { savePageInHistory } from '../../../utils/save-history'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { MeetingsCalendar_C } from '../../Partials/MeetingCalendar/MeetingsCalendar'
import { Metadata } from '../../Partials/Metadata/Metadata'

export function CalendarScreen_C(): JSX.Element {
  return withScreenWrapper<'CalendarScreen'>({
    view: ({ params }) => {
      const { navigate } = useNavigation()
      return (
        <>
          <Metadata
            title="Harness Racing Calendar"
            description="View all the upcoming harness racing across Australia"
          />

          <MeetingsCalendar_C
            date={new Date(params.date)}
            onDateChange={(date) => {
              savePageInHistory()
              navigate('CalendarScreen', { date: shortDate(date) })
            }}
          />
        </>
      )
    }
  })
}
