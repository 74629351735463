import { View, ViewProps } from 'react-native'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { colors } from '../../../styles/colors'

type CardProps = ViewProps & {
  noMobileHorizontalPadding?: boolean
}

export function Card_C(props: CardProps) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const isMobileLayout = breakpointBelow('small')
  const noMobilePaddingStyle = props.noMobileHorizontalPadding &&
    isMobileLayout && {
      paddingHorizontal: 0
    }

  return <View {...props} style={[styles.card, props.style, noMobilePaddingStyle]} />
}

const breakpointStyles = createStyles({
  card: {
    base: {
      width: '100%',
      paddingHorizontal: 16,
      paddingVertical: 16,
      backgroundColor: 'white',
      shadowColor: colors.gray500,
      shadowOffset: { width: 4, height: 4 },
      shadowOpacity: 0.6,
      justifyContent: 'flex-start',
      shadowRadius: 12
    },
    small: {
      borderRadius: 6
    },
    [desktopBreakpoint]: {
      paddingHorizontal: 20,
      paddingVertical: 20
    }
  }
})
