// cspell:word SHWRY

import { Weather } from '../../../services/data/data-types/general-data-types.type'
import { Icon_C } from '../../Base/Icon/Icon'
import {
  lightShowersIcon,
  partlyCloudyIcon,
  rainIcon,
  sunnyIcon
} from '../../Base/Icon/preset-icon-props'
type Props = {
  weather: Weather
}
export function WeatherIcon_C({ weather }: Props) {
  return (
    <Icon_C
      accessibilityLabel={weather}
      {...(weather == 'RAIN'
        ? rainIcon
        : weather == 'FINE'
          ? partlyCloudyIcon
          : weather == 'HOT'
            ? sunnyIcon
            : weather == 'SHWRY'
              ? lightShowersIcon
              : partlyCloudyIcon)}
    />
  )
}
