/* eslint-disable no-console */
import { Fragment, useEffect, useState } from 'react'
import { View } from 'react-native'

import { isAfter, isSameDay } from 'date-fns'
import { filter } from 'lodash'
import { australianStates } from '../../../constants'
import {
  VideosLatestRacesResponse,
  getLatestRacesReplays
} from '../../../services/data/request-functions/videos-latest-replays-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { AustralianState } from '../../../types/map.type'
import { humanFriendlyDate, shortDate } from '../../../utils/date-time'
import { Heading_C } from '../../Base/Heading/Heading'
import { Link_C } from '../../Base/Link/Link'
import { Select_C } from '../../Base/Select/Select'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { CalendarPickerIcon_C } from '../CalendarPicker/CalendarPickerIcon'
import { ReplayRow_C } from './ReplayRow'

const replayRacesFetchAmount = 12
const replayRacesShowAmount = 6

/* 6 replays, and only show day before's when there's not 6 in the current day yet */
export function LatestReplays_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const [response, setResponse] = useState<VideosLatestRacesResponse | undefined>()
  const [selectedState, setSelectedState] = useState<AustralianState | null>(null)

  useEffect(() => {
    getAndSetLatestReplays()
  }, [])

  if (response === undefined) {
    return (
      <Card_C>
        <Text_C>Loading...</Text_C>
      </Card_C>
    )
  } else {
    const { date, maxMeetingDate, minMeetingDate, races } = response
    const { heading, stateSelect, calendarPicker } = getElements()

    const rowDivider = <View style={styles.rowDivider} />

    if (!races.every((r) => r.hasReplay)) {
      console.warn('every race returned should have hasReplay prop', races)
    }

    const filteredRaces = filter(races, (race) => {
      return race.meeting.track.state === selectedState || selectedState === null
    })
      .sort((a, b) => {
        return isAfter(a.startTime, b.startTime) ? -1 : 1
      })
      .slice(0, replayRacesShowAmount)

    return (
      <Card_C>
        <View style={styles.topContainer}>
          {heading}
          {stateSelect}
          {calendarPicker}
        </View>

        {!filteredRaces?.length && (
          <Text_C style={styles.noMeetingMessage}>No replays available</Text_C>
        )}

        {filteredRaces.length > 0 && (
          <>
            <Text_C style={styles.dateHeading}>{humanFriendlyDate(date)}</Text_C>
            {rowDivider}
            {filteredRaces.map((race, idx) => (
              <Fragment key={idx}>
                <ReplayRow_C race={race} />
                {rowDivider}
              </Fragment>
            ))}
          </>
        )}

        <Link_C
          style={{
            elem: breakpointStyles.allReplaysLink,
            text: breakpointStyles.allReplaysLinkText
          }}
          navigateTo={['Videos', { categorySlug: 'replays', date: shortDate(date) }]}
        >
          View All Replays
        </Link_C>
      </Card_C>
    )

    function getElements() {
      const heading = (
        <Heading_C styleType="h2" style={breakpointStyles.heading}>
          Latest Replays
        </Heading_C>
      )

      const selectOptions = [
        { label: 'All States', value: null },
        ...australianStates.map((state) => ({ label: state, value: state }))
      ]
      const stateSelect = (
        <Select_C<AustralianState | null>
          styleType="blueCaret"
          options={selectOptions}
          initialSelection={
            selectedState
              ? selectOptions.find((option) => option.value == selectedState)?.label
              : selectOptions[0].label
          }
          onSelect={(selected) => setSelectedState(selected.value)}
        />
      )
      const calendarPicker = (
        <CalendarPickerIcon_C
          minDate={minMeetingDate}
          maxDate={maxMeetingDate}
          selectedDate={date}
          onDateSelected={onDateSelected}
        />
      )
      return { heading, stateSelect, calendarPicker }
    }

    function onDateSelected(selectedDate: Date) {
      if (!isSameDay(selectedDate, date)) {
        getAndSetLatestReplays(selectedDate)
      }
    }
  }
  function getAndSetLatestReplays(date?: Date) {
    const dateString = date ? shortDate(date) : undefined
    getLatestRacesReplays({
      date: dateString,
      limit: replayRacesFetchAmount
    }).then(setResponse)
  }
}

const breakpointStyles = createStyles({
  allReplaysLink: { alignSelf: 'flex-end', marginTop: 16 },
  allReplaysLinkText: { fontWeight: '600SemiBold' },
  heading: {
    base: {
      textTransform: 'uppercase',
      marginRight: 'auto'
    }
  },
  topContainer: {
    base: {
      flexDirection: 'row',
      marginBottom: 20,
      columnGap: 20,
      zIndex: 1,
      alignItems: 'center'
    }
  },
  noMeetingMessage: {
    base: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingVertical: 20,
      fontStyle: 'italic'
    }
  },
  dateHeading: {
    base: {
      color: presets.primary,
      marginBottom: 16,
      fontWeight: '600SemiBold',
      ...textSizes.size1
    }
  },
  rowDivider: {
    base: {
      height: 1,
      backgroundColor: presets.border,
      width: '100%'
    }
  }
})
