import { orderBy } from 'lodash'

import { View } from 'react-native'
import { RacePuntersCorner } from '../../../services/data/request-functions/punters-corner-request'
import { LabelValuePair, LabelValueTable_C } from '../../Base/Table/LabelValueTable'
import { Text_C } from '../../Base/Text/Text'

type Props = { races: RacePuntersCorner[] }
export function Scratchings_C({ races }: Props) {
  const scratchingsTableData: LabelValuePair[] = orderBy(races, (r) => r.raceNumber).reduce(
    (acc, race) => {
      const scratchedRunners = race.runners.filter((runner) => runner.isScratched)

      if (scratchedRunners.length) {
        return [
          ...acc,
          {
            label: `Race ${race.raceNumber}`,
            value: (
              <View style={{ gap: 4 }}>
                {scratchedRunners.map((r, i) => (
                  <Text_C key={i}>
                    {r.runnerNumber}.{r.isLateScratching && '(L)'} {r.horseName}
                  </Text_C>
                ))}
              </View>
            )
          }
        ]
      }
      return acc
    },
    [] as LabelValuePair[]
  )

  return (
    <LabelValueTable_C
      data={scratchingsTableData}
      style={{ container: { rowGap: 26 }, label: { width: 60 } }}
    />
  )
}
