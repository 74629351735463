/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { orderBy, toUpper } from 'lodash'
import { reduceResultPlaces, removeLeadingSlash, toCapitalCase } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { fetchData } from '../api-fetch-request'
import { MeetingsFieldsFetchResponse } from '../data-types/fetch-response-types/meeting-fields-response.type'
import { meetingStatusMap, raceStatusMap } from '../data-types/general-data-types.type'
import { MeetingBasic } from '../data-types/local-data-types/meeting-basic.type'

export type MeetingsFieldsResponse = {
  date: Date
  maxDate: Date
  meetings: MeetingBasic[]
}

type FetchMeetingsBasicParams = {
  date?: string
  useDesignMock?: boolean
}

export async function getDaysMeetingsFields({
  date,
  useDesignMock
}: FetchMeetingsBasicParams): Promise<MeetingsFieldsResponse> {
  if (useDesignMock) {
    throw 'no design mock'
  }

  const endpoint =
    'racing/fields?' +
    new URLSearchParams({
      date: `${date ?? ''}`
    })

  return fetchData<MeetingsFieldsResponse, MeetingsFieldsFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(meetingsPayload: MeetingsFieldsFetchResponse): MeetingsFieldsResponse {
    return {
      date: new Date(meetingsPayload.date),
      maxDate: new Date(meetingsPayload.max_meeting_date),
      meetings: meetingsPayload.meetings.map((meeting) => {
        const { track, races, is_trial, day_night_twilight, meeting_status } = meeting

        const basicMeeting: MeetingBasic = {
          isTrial: is_trial,
          date: meetingsPayload.date,
          dayPhaseLetter: day_night_twilight,
          status: meetingStatusMap[meeting_status],
          track: {
            name: toCapitalCase(track.desktop_display_name),
            slug: removeLeadingSlash(track.slug),
            state: toUpper(track.state) as AustralianState,
            shortName: track.mobile_display_name
          },
          races: orderBy(races, 'race_number').map((race) => {
            const {
              race_number,
              scheduled_start_time,
              race_status,
              result_places,
              has_replay,
              has_stewards_replay
            } = race
            return {
              raceNumber: race_number,
              startTime: new Date(scheduled_start_time),
              status: raceStatusMap[race_status],
              resultPlaces: result_places ? reduceResultPlaces(result_places) : undefined,
              hasReplay: has_replay,
              hasStewardsReplay: has_stewards_replay
            }
          })
        }
        return basicMeeting
      })
    }
  }
}
