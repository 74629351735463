import { Fragment } from 'react'
import { View } from 'react-native'

import { NewsArticle } from '../../../../services/data/data-types/local-data-types/news.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../../styles/colors'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Link_C } from '../../../Base/Link/Link'
import { Text_C } from '../../../Base/Text/Text'
import { Card_C } from '../../../Layout/Card/Card'
import { NewsItemSmall_C } from '../../NewsIndex/components/NewsItemSmall'

type RelatedArticlesProps = {
  articles?: NewsArticle[]
}

export function RelatedArticles_C({ articles }: RelatedArticlesProps) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const rowDivider = <View style={styles.rowDivider} />

  return (
    <Card_C>
      <View style={styles.topContainer}>
        <Heading_C styleType="h2" style={styles.heading}>
          Related News
        </Heading_C>
      </View>

      <View style={{ rowGap: 12 }}>
        {!!articles &&
          articles.length > 0 &&
          articles.map((article, idx) => (
            <Fragment key={idx}>
              {idx != 0 && rowDivider}
              <NewsItemSmall_C key={article.id} article={article} />
            </Fragment>
          ))}
      </View>

      {(!articles || !articles.length) && (
        <Text_C style={styles.noArticles}>No articles available</Text_C>
      )}

      <Link_C
        style={{
          elem: styles.allArticlesLink,
          text: styles.allArticlesLinkText
        }}
        linkUrl="/news"
      >
        View All Articles
      </Link_C>
    </Card_C>
  )
}

const breakpointStyles = createStyles({
  allArticlesLink: { alignSelf: 'flex-end', marginTop: 16 },
  allArticlesLinkText: { fontWeight: '600SemiBold' },
  heading: {
    base: {
      textTransform: 'uppercase',
      marginRight: 'auto'
    }
  },
  topContainer: {
    base: {
      flexDirection: 'row',
      marginBottom: 12,
      columnGap: 20,
      zIndex: 1,
      alignItems: 'center'
    }
  },
  noArticles: {
    base: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingVertical: 20,
      fontStyle: 'italic'
    }
  },
  rowDivider: {
    base: {
      height: 1.5,
      backgroundColor: presets.border,
      width: '100%'
    }
  }
})
