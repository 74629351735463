import { View } from 'react-native'
import { aToZ } from '../../../../constants'
import { wordStartsWithLetter } from '../../../../helper-functions'
import { TrackBasic } from '../../../../services/data/data-types/local-data-types/track-basic.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { StyleType } from '../../../../services/styles/dependencies/breakpoint-style.type'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../../services/styles/dependencies/style-constants'
import { colors } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { Link_C } from '../../../Base/Link/Link'
import { Text_C } from '../../../Base/Text/Text'
import { ScrollColumns_C } from '../../../Partials/ScrollColumns/ScrollColumns'

type Props = {
  stateFilteredTracks: TrackBasic[]
  selectedState: string | null
  aToZFilter: string | null
  style?: StyleType
  isListView: boolean
}

export function TrackList_C(props: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const isMobileLayout = breakpointBelow('medium')
  const isTabletLayout = breakpointBelow(tabletBreakpoint)
  const isDesktopLayout = breakpointBelow(desktopBreakpoint)

  const filteredTracks = props.stateFilteredTracks.filter(
    (track) => props.aToZFilter == null || wordStartsWithLetter(track.name, props.aToZFilter)
  )

  const trackListItems = aToZ
    .filter((letter) => filteredTracks.some((track) => wordStartsWithLetter(track.name, letter)))
    .map((letter) => (
      <View key={letter}>
        <Text_C style={styles.letter}>{letter}</Text_C>
        {filteredTracks
          .filter(
            (track) =>
              wordStartsWithLetter(track.name, letter) &&
              (props.selectedState == null || track.state == props.selectedState)
          )
          .map((track, idx) => (
            <Link_C key={idx} navigateTo={['Track', { trackSlug: track.slug }]}>
              <Text_C style={styles.trackLink}>
                {track.name}
                {!!track.state && (
                  <Text_C style={styles.trackLinkState}>{`, ${track.state}`}</Text_C>
                )}
              </Text_C>
            </Link_C>
          ))}
      </View>
    ))

  const scrollColumnsProps = breakpointBelow('medium')
    ? props.isListView
      ? { columns: 3 }
      : { columns: 1 }
    : isTabletLayout
      ? props.isListView
        ? { columns: 4 }
        : { columns: 2 }
      : isDesktopLayout
        ? props.isListView
          ? { columns: 5 }
          : { columns: 3 }
        : props.isListView
          ? { columns: 6 }
          : { columns: 4 }

  return (
    <View style={[styles.container, props.style]}>
      {isMobileLayout ? (
        trackListItems
      ) : (
        <ScrollColumns_C
          columns={scrollColumnsProps.columns}
          minHeight={500}
          gap={20}
          items={trackListItems}
        />
      )}
    </View>
  )
}

const breakpointStyles = createStyles({
  container: {
    base: { gap: 26, flex: 1 },
    large: {}
  },
  trackLink: {
    base: {
      lineHeight: 26
    }
  },
  trackLinkState: {
    base: {
      color: colors.gray500
    }
  },
  letter: {
    base: {
      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      ...textSizes.size5,
      borderBottomWidth: 1,
      borderBottomColor: colors.gray300,
      paddingBottom: 6,
      marginBottom: 6,
      width: '100%',
      gap: 6
    }
  }
})
