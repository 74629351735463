import { useNavigation } from '@react-navigation/native'
import { orderBy } from 'lodash'

import { View } from 'react-native'
import SelectDropdown from 'react-native-select-dropdown'
import { MeetingStatus } from '../../../services/data/data-types/general-data-types.type'
import { MeetingBasic } from '../../../services/data/data-types/local-data-types/meeting-basic.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { colors } from '../../../styles/colors'
import { convertFontWeightToFontFamily } from '../../../styles/fonts-map'
import { textSizes } from '../../../styles/text-sizes'
import { savePageInHistory } from '../../../utils/save-history'
import { Icon_C } from '../../Base/Icon/Icon'
import { Text_C } from '../../Base/Text/Text'
//https://www.npmjs.com/package/react-native-select-dropdown

type Props = {
  meetings: MeetingBasic[]
  activeMeeting: MeetingBasic
}

export function MeetingSelect_C({ meetings, activeMeeting }: Props) {
  const { navigate } = useNavigation()
  const styles = useBreakpointStyles({
    styles: beakpointStyles
  })
  const {
    dropdown: dropdownStyle,
    head: buttonStyle,
    option: rowStyle,
    headText,
    optionText
  } = styles

  const filteredMeetings = meetings.filter((m) =>
    (['Fields Drawn', 'Results'] as MeetingStatus[]).includes(m.status)
  )

  const trackSelectOptions: MeetingBasic[] = [
    activeMeeting,
    ...orderBy(filteredMeetings.filter(isNotSameMeeting), (m) => m.track.name, ['desc'])
  ]

  function getHeadingText(meeting: MeetingBasic) {
    return (
      <View style={styles.trackSelectOption}>
        <Text_C style={{ fontWeight: '600SemiBold', ...textSizes.size7 }}>
          {meeting.track.name}
        </Text_C>
        <Text_C style={styles.headingState}>{meeting.track.state}</Text_C>
      </View>
    )
  }

  function getOptionText(meeting: MeetingBasic) {
    return (
      <View style={styles.trackSelectOption}>
        <Text_C>{`${meeting.track.shortName}${meeting.isTrial ? ' (T)' : ''}`}</Text_C>
        <Text_C style={styles.headingState}>{meeting.track.state}</Text_C>
      </View>
    )
  }

  return meetings.length == 1 ? (
    getHeadingText(activeMeeting)
  ) : (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <SelectDropdown
      data={trackSelectOptions}
      {...{
        dropdownStyle,
        buttonStyle,
        rowStyle,
        buttonTextStyle: convertFontWeightToFontFamily(headText),
        rowTextStyle: convertFontWeightToFontFamily(optionText)
      }}
      defaultValue={activeMeeting}
      renderDropdownIcon={() => (
        <Icon_C name="chevron" style={styles.chevronIcon} color={colors.gray600} />
      )}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      onSelect={onMeetingSelected} //@ts-ignore
      buttonTextAfterSelection={getHeadingText}
      rowTextForSelection={getOptionText}
      dropdownOverlayColor={'transparent'}
      selectedRowStyle={{ backgroundColor: colors.gray300 }}
    />
  )
  function isNotSameMeeting(m: MeetingBasic) {
    return !(
      m.track.name == activeMeeting.track.name &&
      m.dayPhaseLetter == activeMeeting.dayPhaseLetter &&
      m.isTrial == activeMeeting.isTrial
    )
  }
  function onMeetingSelected(meeting: MeetingBasic) {
    savePageInHistory()
    navigate('Meeting', {
      date: meeting.date,
      trackSlug: meeting.track.slug,
      dayPhaseLetter: meeting.dayPhaseLetter,
      type: meeting.isTrial ? 'trial' : 'meeting'
    })
  }
}

const beakpointStyles = createStyles({
  dropdown: {
    base: {
      height: 'auto',
      flex: 1,
      marginBottom: 0,
      minWidth: 150,
      borderRadius: 4,
      overflow: 'hidden',
      maxHeight: 300
    }
  },
  head: {
    base: {
      backgroundColor: 'white',
      height: 'auto',
      margin: 0,
      width: 'auto',
      minWidth: 240, // wide so that the dropdown is wide to include wide track names
      alignSelf: 'flex-start',
      paddingHorizontal: 0,
      borderWidth: 0
    }
  },
  headText: {
    base: {
      textAlign: 'left',
      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      marginHorizontal: 0,
      marginRight: 10,
      ...textSizes.size7
    }
  },
  option: {
    base: {
      backgroundColor: '#eee',
      paddingVertical: 8,
      paddingHorizontal: 12
    }
  },
  optionText: {
    base: {
      ...textSizes.size2,

      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      textAlign: 'left',
      marginHorizontal: 0
    }
  },
  chevronIcon: {
    base: { width: 22 }
  },
  selectHead: {
    base: {
      width: 'auto',
      minWidth: 200, // wide so that the dropdown is wide to include wide track names
      alignSelf: 'flex-start',
      paddingHorizontal: 0,
      height: 'auto',
      borderWidth: 0
    }
  },
  selectHeadText: {
    base: {
      marginRight: 10
    }
  },

  trackSelectOption: {
    base: {
      flexDirection: 'row',
      columnGap: 8,
      alignItems: 'baseline',
      flex: 1
    }
  },
  headingState: { base: { color: colors.gray500, ...textSizes.size2 } }
})
