import { View } from 'react-native'
import { Select_C } from '../../../Base/Select/Select'
import { Text_C } from '../../../Base/Text/Text'

const perPageOptions = [10, 25, 50, 100] as const
export type PerPageOption = (typeof perPageOptions)[number]
type Props = {
  // eslint-disable-next-line no-unused-vars
  onSelect: (number: PerPageOption) => void
  initialSelection?: PerPageOption
}
export function PerPageSelect({ onSelect, initialSelection }: Props) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end'
      }}
    >
      <Text_C>Per Page: </Text_C>
      <Select_C
        styleType="greyChevron"
        initialSelection={initialSelection}
        options={perPageOptions.map((num) => ({
          label: `${num}`,
          value: num
        }))}
        onSelect={({ value }) => onSelect(value)}
      />
    </View>
  )
}
