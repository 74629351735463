import { useMemo, useState } from 'react'
import { View } from 'react-native'
import { RunnerBasic } from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import { presets, sectionalsColors } from '../../../../../../styles/colors'
import { HorseSilkIcon_C, HorseSilkSvgName } from '../../../../../Base/Icon/horse-silk-icon'
import { Text_C } from '../../../../../Base/Text/Text'
import {
  SectionalStats,
  arrowWidth,
  getFinishScaled,
  horseWidth,
  valueWidth
} from './sectionals-utils'
type Props = {
  runner: RunnerBasic
  runners: RunnerBasic[]
  sectionalStats: SectionalStats
  highlighted?: boolean
}
export function FinishGainedGraphic_C({ runners, runner, highlighted, sectionalStats }: Props) {
  const [elemWidth, setElemWidth] = useState<number>()

  const scaledGraphicElem = useMemo(getScaledGraphicElem, [elemWidth])

  return (
    <View
      onLayout={({
        nativeEvent: {
          layout: { width }
        }
      }) => {
        setElemWidth(width)
      }}
    >
      {scaledGraphicElem}
    </View>
  )

  function getScaledGraphicElem() {
    const finishScaled = getFinishScaled(runners, elemWidth!)
    const runnerFinishScaled = finishScaled.find((x) => x.name == runner.horse.name)
    if (!runnerFinishScaled) return <></>
    const { marginPercent, metresGained, gainPercent } = runnerFinishScaled

    const { valueText, horse, gain, arrow } = getElems()

    return (
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: highlighted ? `${presets.primary}11` : 'transparent'
        }}
      >
        <View
          style={{
            marginLeft: `${marginPercent}%`
          }}
        ></View>
        {metresGained < 0 && (
          <>
            {valueText}
            {arrow}
            {gain}
          </>
        )}
        {horse}
        {metresGained >= 0 && (
          <>
            {gain}
            {arrow}
            {valueText}
          </>
        )}
      </View>
    )

    function getElems() {
      const valueText = (
        <View
          style={{
            width: valueWidth,
            justifyContent: 'center',
            backgroundColor:
              metresGained == sectionalStats.metresGained800Finish?.min
                ? sectionalsColors.redHighlight
                : metresGained == sectionalStats.metresGained800Finish?.max
                  ? sectionalsColors.greenHighlight
                  : 'transparent',

            ...(metresGained < 0 ? { paddingRight: 4 } : { paddingLeft: 4 })
          }}
        >
          <Text_C
            numberOfLines={1}
            ellipsizeMode="tail"
            style={{
              textAlign: metresGained < 0 ? 'right' : 'left'
            }}
          >
            {`${metresGained >= 0 ? '+' : ''}${metresGained.toFixed(2)}`}
          </Text_C>
        </View>
      )

      const silkName = `silk${runner.place}Desktop` as HorseSilkSvgName
      const horse = <HorseSilkIcon_C name={silkName} style={{ width: horseWidth, height: 60 }} />
      const gain = (
        <View
          style={{
            height: '100%',
            width: `${gainPercent}%`,
            backgroundColor: metresGained < 0 ? sectionalsColors.red : sectionalsColors.green
          }}
        ></View>
      )

      const arrow = (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor:
              metresGained == sectionalStats.metresGained800Finish?.min
                ? sectionalsColors.redHighlight
                : metresGained == sectionalStats.metresGained800Finish?.max
                  ? sectionalsColors.greenHighlight
                  : 'transparent'
          }}
        >
          {metresGained >= 0 && (
            <View
              style={{
                width: 1,
                height: '100%',
                backgroundColor: sectionalsColors.green
              }}
            />
          )}
          <View
            style={{
              alignSelf: 'center',
              flexDirection: 'row',
              borderTopWidth: 3,
              borderBottomWidth: 3,
              borderTopColor: 'transparent',
              borderBottomColor: 'transparent',
              borderStyle: 'solid',
              ...(metresGained < 0
                ? {
                    borderColor: sectionalsColors.red,
                    borderRightWidth: arrowWidth - 1
                  }
                : {
                    borderColor: sectionalsColors.green,
                    borderLeftWidth: arrowWidth - 1
                  })
            }}
          />
          {metresGained < 0 && (
            <View
              style={{
                width: 1,
                height: '100%',
                backgroundColor: sectionalsColors.red
              }}
            />
          )}
        </View>
      )
      return { valueText, horse, gain, arrow }
    }
  }
}
