/* eslint-disable @typescript-eslint/unbound-method */
import { subDays } from 'date-fns'
import type { Dayjs } from 'dayjs'
import { debounce } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import DateTimePicker from 'react-native-ui-datepicker'
import { GlobalContext } from '../../../global-context'
import { ScreenName } from '../../../services/routes/screens'
import { colors, presets } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { Coords } from '../../../types/miscellaneous-types.type'
import { Icon_C } from '../../Base/Icon/Icon'

type Props = {
  screenName: ScreenName
}

export function CalendarPicker_C({ screenName }: Props) {
  const { activeCalendar, setActiveCalendar } = useContext(GlobalContext)

  const [coords, setCoords] = useState<Coords | undefined>()
  const [resizing, setResizing] = useState<boolean>(false)
  const calendarContainer = useRef<any>()

  useEffect(() => {
    const teardown = setup()

    return teardown

    /*  */

    function setup() {
      if (!activeCalendar || screenName !== activeCalendar.screen) {
        // it creates a picker for each screen, so they all react, inactive screens blocked
        return () => {}
      } else {
        setCoords(getCalendarCoords())
        const screenResizeSubscription = Dimensions.addEventListener(
          'change',
          debounceRepositionCalendar
        )
        document.addEventListener('mousedown', handleOutsideClick)
        return teardown

        function teardown() {
          screenResizeSubscription?.remove()
          document.removeEventListener('mousedown', handleOutsideClick)
        }

        function debounceRepositionCalendar() {
          setResizing(true)
          debounce(() => {
            setCoords(getCalendarCoords())
            setResizing(false)
          }, 300)()
        }
        function handleOutsideClick({ target }: MouseEvent) {
          const clickTargetElem = target as HTMLElement
          const calendarContainerElem = calendarContainer.current
          if (!calendarContainerElem || !clickTargetElem) {
            return
          }
          const clickIsOutside = !calendarContainerElem.contains(clickTargetElem)

          const clickOnButton = (activeCalendar?.parentElemRef.current as HTMLElement).contains(
            clickTargetElem
          )

          if (clickIsOutside && !clickOnButton) {
            setActiveCalendar(undefined)
          }
        }
      }
    }
  }, [activeCalendar])

  if (!activeCalendar) {
    return <></>
  }
  const { onDateSelected, selectedDate, maxDate, minDate, parentElemRef } = activeCalendar
  return (
    <View
      style={[
        {
          ...styles.calendar,
          ...(coords ? { left: coords.left, top: coords.top } : { opacity: 0 }),
          ...(resizing ? { opacity: 0 } : {})
        }
      ]}
      ref={calendarContainer}
    >
      <DateTimePicker
        mode="single"
        date={selectedDate}
        onChange={({ date }) => handleDateChange((date as Dayjs).toDate())}
        maxDate={maxDate}
        minDate={minDate ? subDays(minDate, 1) : undefined}
        calendarTextStyle={{
          fontFamily: 'OpenSans_500Medium',
          ...textSizes.size3,
          color: presets.primary
        }}
        selectedTextStyle={{
          color: 'white'
        }}
        headerTextContainerStyle={{
          borderWidth: 1,
          borderColor: presets.primary,
          borderRadius: 4,
          paddingHorizontal: 10,
          marginHorizontal: 5
        }}
        selectedItemColor={presets.primary}
        buttonPrevIcon={
          <Icon_C
            name="chevron"
            color={presets.primary}
            style={{
              width: 15,
              height: 15,
              transform: [{ rotate: '90deg' }]
            }}
          />
        }
        buttonNextIcon={
          <Icon_C
            name="chevron"
            color={presets.primary}
            style={{
              width: 15,
              height: 15,
              transform: [{ rotate: '-90deg' }]
            }}
          />
        }
      />
    </View>
  )

  function handleDateChange(date: Date) {
    onDateSelected(date)

    setActiveCalendar(undefined)
  }
  function getCalendarCoords(): Coords | undefined {
    const iconElem: DOMRect | undefined = (
      parentElemRef?.current as HTMLElement
    )?.getBoundingClientRect()

    const calendarElem: DOMRect | undefined = (
      calendarContainer?.current as HTMLElement
    )?.getBoundingClientRect()

    if (!iconElem || !calendarElem) return

    let left = iconElem.left + iconElem.width - calendarElem.width
    if (left < 0) {
      left = 0
    }

    const top = iconElem.top + iconElem.height
    return { left, top }
  }
}

const styles = StyleSheet.create({
  calendar: {
    boxShadow: `5px 5px 10px ${colors.gray300}`,
    borderRadius: 5,
    position: 'absolute',
    backgroundColor: 'white',
    width: 320,
    padding: 6,
    zIndex: 20,
    margin: 6
  }
})
