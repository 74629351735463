import { useMemo } from 'react'
import { View } from 'react-native'
import { LatestRace } from '../../../../../services/data/request-functions/latest-races-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../services/styles/breakpoint-styles.service'
import { colors, stateColor } from '../../../../../styles/colors'
import { humanFriendlyTime, shortDate } from '../../../../../utils/date-time'
import { Link_C } from '../../../../Base/Link/Link'
import { Text_C } from '../../../../Base/Text/Text'
import { RaceCountdownText_C } from '../../../RaceCountdownText/RaceCountdownText'

export const nextToGoItemWidth = 180

type Props = {
  race: LatestRace
}

export function NextToGoRace_C({ race }: Props) {
  const { startTime, raceNumber, trackSlug, dayPhaseLetter, state, trackNameShort } = race

  const stylesObj = useMemo(() => {
    return {
      styles: breakpointStyles
    }
  }, [])
  const styles = useBreakpointStyles(stylesObj)
  const date = shortDate(startTime)

  const textDivider = <View style={styles.textDivider} />
  return (
    <Link_C
      navigateTo={[
        'Meeting',
        {
          raceNumber: `${raceNumber}`,
          trackSlug,
          date,
          dayPhaseLetter,
          type: 'meeting'
        }
      ]}
      style={{
        elem: {
          base: {
            ...styles.nextToGoRace,
            width: nextToGoItemWidth
          },
          hovered: { backgroundColor: colors.gray100 }
        }
      }}
    >
      <View style={styles.colorContainer}>
        <View
          style={{
            ...styles.colorDot,
            backgroundColor: stateColor[state]
          }}
        />
      </View>

      <View style={styles.infoContainer}>
        <Text_C style={styles.trackName}>{trackNameShort}</Text_C>

        <View style={styles.raceDetails}>
          <Text_C>R{raceNumber}</Text_C>
          {textDivider}
          <Text_C>{humanFriendlyTime(new Date(startTime))}</Text_C>
          {textDivider}
          <RaceCountdownText_C startTime={new Date(startTime)} fallbackContent="---" />
        </View>
      </View>
    </Link_C>
  )
}

const breakpointStyles = createStyles({
  nextToGoRace: {
    padding: 10,
    flexDirection: 'row',
    gap: 12,
    alignItems: 'baseline'
  },
  colorContainer: {},
  colorDot: {
    width: 12,
    height: 12,
    borderRadius: 100
  },
  infoContainer: { gap: 4 },
  trackName: {
    fontWeight: '700Bold'
  },
  raceDetails: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  textDivider: {
    marginHorizontal: 8,
    width: 1,
    backgroundColor: colors.gray700,
    height: 12
  }
})
