/*
  for the sake of easier to reimplement the common component emerged from the site,
    - text is red when under 30 mins
    - text of days hours mins secs displayed appropriately

*/

import { add, intervalToDuration, sub } from 'date-fns'
import { View } from 'react-native'
import { getDateNow } from '../../../helper-functions'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { TextStyle_T } from '../../../styles/fonts-map'
import { CountdownContent_C } from '../../Base/CountdownContent/CountdownContent'
import { Text_C } from '../../Base/Text/Text'
type Props = {
  startTime: Date
  fallbackContent?: JSX.Element | string
  textStyle?: TextStyle
}
export function RaceCountdownText_C({ startTime, fallbackContent, textStyle }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  return (
    <CountdownContent_C
      fallbackContent={fallbackContent ?? '--'}
      timeBasedContent={[
        {
          ifBeforeDate: sub(startTime, { minutes: 15 }),
          content: getText
        },
        //if after 15 minds before, red text
        {
          ifBeforeDate: startTime,
          content: () => getText({ color: 'red' })
        },
        // if after start time, live now,
        {
          ifBeforeDate: add(startTime, { minutes: 30 }),
          content: (
            <View style={styles.liveRace}>
              <Text_C style={styles.liveRaceText}>Now</Text_C>
            </View>
          )
        }
      ]}
    />
  )

  function getText(style?: TextStyle_T) {
    const { days, hours, minutes, seconds } = intervalToDuration({
      start: getDateNow(),
      end: startTime
    })

    return (
      <Text_C style={{ ...style, ...textStyle }}>
        {days && days > 1
          ? `${days}d`
          : days! > 0
            ? `${days}d ${hours}h`
            : hours != undefined && hours > 1
              ? `${hours}h`
              : hours && hours > 0
                ? `${hours}h ${minutes}m`
                : minutes && minutes > 5
                  ? `${minutes}m`
                  : minutes && minutes > 0
                    ? `${minutes}m ${seconds}s`
                    : `${seconds}s`}
      </Text_C>
    )
  }
}

const breakpointStyles = createStyles({
  liveRace: {
    backgroundColor: presets.secondary,
    borderRadius: 8,
    paddingHorizontal: 5,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  liveRaceText: {
    fontSize: 9,
    lineHeight: 17,
    color: colors.white,
    textTransform: 'uppercase',
    fontWeight: '700Bold',
    letterSpacing: 0.7
  }
})
