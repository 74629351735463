import { useContext, useRef } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../global-context'
import { colors } from '../../../styles/colors'
import { Button_C } from '../../Base/Button/Button'
import { PressableStyleExtension } from '../../Base/Pressable/Pressable'

type Props = {
  tooltipContent: string
}

export function InfoButton_C(props: Props) {
  const { activeTooltip, setActiveTooltip } = useContext(GlobalContext)

  const infoButtonRef = useRef<any>(null)

  const isActive = infoButtonRef.current === activeTooltip?.parentElemRef.current

  function showTooltip() {
    setActiveTooltip({
      content: props.tooltipContent,
      parentElemRef: infoButtonRef,
      position: 'top'
    })
  }

  return (
    <View ref={infoButtonRef}>
      <Button_C
        active={isActive}
        accessibilityState={{ selected: isActive }}
        styleType="iconOnly"
        icon={{
          color: 'white',
          name: 'info',
          colorFill: true,
          colorStroke: false,
          style: { width: '100%' },
          useParentTextColor: false
        }}
        style={buttonStyles}
        onPress={() => {
          if (activeTooltip?.content == props.tooltipContent) {
            setActiveTooltip(undefined)
          } else {
            showTooltip()
          }
        }}
      />
    </View>
  )
}

const buttonStyles: PressableStyleExtension = {
  elem: {
    base: {
      backgroundColor: colors.gray500,
      width: 24,
      height: 24,
      borderRadius: 100
    },
    active: { backgroundColor: colors.blue }
  },
  text: { base: { color: 'white' } }
}
