/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { TextInput, TextInputProps, View, ViewStyle } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { convertFontWeightToFontFamily } from '../../../styles/fonts-map'
import { textSizes } from '../../../styles/text-sizes'
import { Button_C } from '../../Base/Button/Button'
import { eyeIcon } from '../../Base/Icon/preset-icon-props'
import { Option, Select_C } from '../../Base/Select/Select'
import { Text_C, textBaseStyle } from '../../Base/Text/Text'

export type FormField = {
  label: string
  value?: string
  invalidMessage?: string
}
export type FormState<T extends string = any> = Record<T, FormField>

type InputMode = NonNullable<TextInputProps['inputMode']>
type TextContentType = NonNullable<TextInputProps['textContentType']>

type Props = {
  formField: FormField
  submitAttempted?: boolean
  setFormField(field: FormField): void
  isPassword?: boolean
  isEmail?: boolean
  options?: Option<string | undefined>[]
  inputMode?: InputMode
  textContentType?: TextContentType
  onEnterPress?: () => void
}

export function FormField_C({
  inputMode = 'text',
  textContentType = 'none',
  formField,
  submitAttempted,
  setFormField,
  isPassword,
  options,
  onEnterPress
}: Props) {
  const [showPassword, setShowPassword] = useState(false)

  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const showError = !!formField.invalidMessage && !!submitAttempted

  return (
    <View style={styles.formField}>
      {options?.length ? (
        <View style={{ ...styles.labelValueContainer, zIndex: 10 }}>
          <Text_C>{formField.label}: </Text_C>
          <Select_C
            aria-label={formField.label}
            styleType="greyChevron"
            options={[{ label: 'Select an option', value: undefined }, ...options]}
            onSelect={({ value }) => {
              const field = { ...formField, value }
              setFormField(field)
            }}
            initialSelection={formField.value}
          />
        </View>
      ) : (
        <View style={{ flexDirection: 'row', gap: 12 }}>
          <TextInput
            onChangeText={(value) => setFormField({ ...formField, value })}
            secureTextEntry={isPassword && !showPassword}
            textContentType={isPassword ? 'password' : textContentType}
            inputMode={inputMode}
            placeholder={formField.label}
            value={formField.value}
            aria-label={formField.label}
            style={[
              styles.inputField as ViewStyle,
              convertFontWeightToFontFamily(textBaseStyle),
              (showError ? styles.inputError : {}) as ViewStyle
            ]}
            onKeyPress={(x) => {
              const isEnter = x.nativeEvent.key == 'Enter'
              if (isEnter) onEnterPress?.()
            }}
          />
          {isPassword && (
            <Button_C
              styleType="iconOnly"
              active={showPassword}
              icon={eyeIcon}
              onPress={() => setShowPassword((prev) => !prev)}
            />
          )}
        </View>
      )}
      <Text_C style={styles.errorText}>
        {(submitAttempted && formField.invalidMessage) || ' '}
      </Text_C>
    </View>
  )
}

const breakpointStyles = createStyles({
  inputField: {
    base: {
      borderWidth: 1,
      borderColor: presets.border,
      borderRadius: 4,
      padding: 6,
      flex: 1
    }
  },
  inputError: {
    borderColor: colors.red
  },
  errorText: {
    color: colors.red,
    ...textSizes.size1
  },
  formField: {
    gap: 4
  },
  labelValueContainer: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center'
  }
})
