import { useContext, useMemo } from 'react'
import { View } from 'react-native'
import {
  RunnerBasic,
  RunnerDetailed
} from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  RunnerAdditionalDataResponse,
  fetchRunnerAdditionalData
} from '../../../../../../services/data/request-functions/runner-additional-data-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../../../../styles/colors'
import { Text_C } from '../../../../../Base/Text/Text'
import { ComponentWithFetch_C } from '../../../../../HigherOrderComponents/ComponentWithFetch'
import { BorderGrid_C } from '../../../../../Layout/Grid/BorderGrid'
import { GridCell } from '../../../../../Layout/Grid/grid.type'
import { BlackBookButton_C } from '../../../../../Partials/BlackBookButton'
import { LinkOrText_C } from '../../../../../Partials/LinkOrText'
import { MeetingContext } from '../../../Meeting'
type Props = {
  meetingRunner: RunnerBasic
  raceNumber: number
}

export function ResultsExpandedDesktop_C(props: Props) {
  const meetingContext = useContext(MeetingContext)

  const {
    params: { trackSlug, type },
    date,
    activeMeeting,
    blackbookHorseIds,
    fetchBlackbookHorseIds
  } = meetingContext
  const { meetingRunner, raceNumber } = props
  const { horse } = meetingRunner

  return useMemo(() => {
    return (
      <ComponentWithFetch_C<[RunnerAdditionalDataResponse]>
        fetchRequests={[
          fetchRunnerAdditionalData({
            date,
            trackSlug,
            dayPhaseLetter: activeMeeting.dayPhaseLetter,
            raceNumber,
            runnerNumber: meetingRunner.runnerNumber,
            trial: type == 'trial'
          })
        ]}
        showLoading={false}
        view={({ responses }) => {
          const [runnerAdditionalDataResponse] = responses
          const { runnerAdditionalData } = runnerAdditionalDataResponse
          const detailedRunner: RunnerDetailed = {
            ...meetingRunner,
            ...runnerAdditionalData
          }

          const { age, colour, sex, sire, dam, owner, breeder } = detailedRunner

          const styles = useBreakpointStyles({ styles: breakpointStyles })

          const gridCells: GridCell[] = [
            {
              content: (
                <View style={{ gap: 4 }}>
                  <Text_C style={styles.labelText}>Age / Colour / Sex</Text_C>
                  <View style={styles.flexRow}>
                    <Text_C>{age}</Text_C>
                    <Text_C>{colour}</Text_C>
                    <Text_C>{sex}</Text_C>
                  </View>
                </View>
              )
            },
            {
              breakpointConfig: { base: { colspan: 2 } },
              content: (
                <View style={{ gap: 4 }}>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Sire: </Text_C>
                    {sire ? (
                      <LinkOrText_C
                        name={sire.name}
                        newTab
                        linkUrl={!sire.slug ? undefined : `horse/${sire.slug}`}
                      />
                    ) : (
                      <Text_C>N/A</Text_C>
                    )}
                  </View>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Dam: </Text_C>
                    {dam ? (
                      <LinkOrText_C
                        name={dam.name}
                        newTab
                        linkUrl={!dam.slug ? undefined : `horse/${dam.slug}`}
                      />
                    ) : (
                      <Text_C>N/A</Text_C>
                    )}
                  </View>
                </View>
              )
            },
            {
              breakpointConfig: {
                base: { colspan: 2 }
              },
              content: (
                <View style={{ gap: 4 }}>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Owner:</Text_C>
                    <Text_C>{owner.name}</Text_C>
                  </View>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Breeder:</Text_C>
                    <Text_C>{breeder?.name ?? '---'}</Text_C>
                  </View>
                </View>
              )
            }
          ]

          return (
            <View style={styles.container}>
              <BorderGrid_C
                gridBreakpointConfig={{
                  base: { columns: 5 }
                }}
                cells={gridCells}
              />
              <BlackBookButton_C
                onHorseAdded={fetchBlackbookHorseIds}
                horseId={horse.id}
                horseName={horse.name}
                isAlreadyInBlackbook={blackbookHorseIds?.includes(horse.id) ?? false}
              />
            </View>
          )
        }}
      />
    )
  }, [blackbookHorseIds])
}

const breakpointStyles = createStyles({
  container: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    backgroundColor: colors.gray50,
    gap: 20,
    borderBottomWidth: 1,
    borderBottomColor: presets.border
  },
  labelText: {
    fontFamily: 'OpenSans',
    fontWeight: '700Bold'
  },
  flexRow: { flexDirection: 'row', gap: 10 }
})
