import { useContext, useMemo } from 'react'
import { View } from 'react-native'
import {
  breakpointAbove,
  createStyles,
  useBreakpointStyles
} from '../../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../../services/styles/dependencies/style-constants'
import { Card_C } from '../../../../Layout/Card/Card'
import { MeetingContext } from '../../Meeting'
import { RaceHeader_C } from '../../components/RaceHeader'
import { ResultsInfo_C } from '../Results/components/ResultsInfo'
import { ResultScratchings_C } from '../Results/components/ResultsScratchings'
import { SectionalsTable_C } from './components/SectionalsTable'

export function SectionalsTab_C() {
  const meetingContext = useContext(MeetingContext)
  const { activeMeeting, selectedRaceNumber } = meetingContext
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const isDesktopLayout = breakpointAbove('xxlarge')

  return useMemo(() => {
    return (
      <View style={styles.container}>
        {activeMeeting?.races
          .filter((race) =>
            selectedRaceNumber
              ? selectedRaceNumber == race.raceNumber
              : race.status != 'Fields Drawn'
          )
          .map((race) => {
            const { runners } = race
            const hasScratchings = runners.some((r) => r.scratched)
            const orderedRunners = runners.filter((r) => !r.scratched)
            return (
              <Card_C key={race.raceNumber} style={styles.card}>
                <RaceHeader_C race={race} photoFinishType="icon" />
                <SectionalsTable_C runners={orderedRunners} />
                {hasScratchings && <ResultScratchings_C race={race} />}
                <ResultsInfo_C resultsInfo={race.resultsInfo} />
              </Card_C>
            )
          })}
      </View>
    )
  }, [selectedRaceNumber, activeMeeting, styles, isDesktopLayout])
}

const breakpointStyles = createStyles({
  container: {
    base: {
      rowGap: 24
    }
  },
  card: {
    base: { rowGap: 20 },
    [desktopBreakpoint]: {
      rowGap: 30
    }
  }
})
