import { upperCase } from 'lodash'
import { removeLeadingSlash } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { fetchData } from '../api-fetch-request'
import { VideoLatestRacesFetchResponse } from '../data-types/fetch-response-types/video-replays-response.type'
import { LatestReplayRace } from '../data-types/local-data-types/videos-latest-races.type'
import { VideoSourceFetchResponse } from '../data-types/fetch-response-types/video-source-response.type'

export type VideoSourceResponse = {
  playerSources: {
    url: string
  }[]
}

export type VideosLatestRacesResponse = {
  date: Date
  minMeetingDate: Date
  maxMeetingDate: Date
  races: LatestReplayRace[]
}

type Params = {
  date?: string
  limit?: number
  useDesignMock?: boolean
}

export const getLatestRacesReplays = ({
  date,
  limit = 18
}: Params): Promise<VideosLatestRacesResponse> => {
  const selectedDate = date ? `&date=${date}` : ''
  const endpoint = `videos/replays/latest?limit=${limit}${selectedDate}`

  return fetchData<VideosLatestRacesResponse, VideoLatestRacesFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(payload: VideoLatestRacesFetchResponse): VideosLatestRacesResponse {
    const { races, date, max_meeting_date, min_meeting_date } = payload
    const videosLatestRacesResponse: VideosLatestRacesResponse = {
      date: new Date(date),
      minMeetingDate: new Date(min_meeting_date),
      maxMeetingDate: new Date(max_meeting_date),
      races: races.map((race) => {
        const {
          distance,
          meeting,
          name,
          race_number,
          scheduled_start_time,
          has_replay,
          result_places
        } = race
        const { date, day_night_twilight, is_trial, track } = meeting
        const latestReplayRace: LatestReplayRace = {
          meeting: {
            date: new Date(date),
            isTrial: is_trial,
            dayPhaseLetter: day_night_twilight,
            track: {
              name: track.desktop_display_name,
              shortName: track.mobile_display_name,
              state: upperCase(track.state) as AustralianState,
              slug: removeLeadingSlash(track.slug)
            }
          },
          name: name,
          raceNumber: race_number,
          distance: distance,
          startTime: new Date(scheduled_start_time),
          hasReplay: has_replay,
          resultPlaces: result_places?.map(({ place, runners }) => {
            return {
              place,
              runners: runners.map((r) => {
                return {
                  runnerNumber: r.saddlecloth_number,
                  silk: !r.silk_image
                    ? undefined
                    : {
                        imagePath: r.silk_image?.path
                      }
                }
              })
            }
          })
        }
        return latestReplayRace
      })
    }
    return videosLatestRacesResponse
  }
}

///////// Watch Video ////////////

type GetSignedVideoAssetValues = {
  signedId?: string
}

export function getSignedVideoAsset({ signedId }: GetSignedVideoAssetValues) {
  const endpoint = `videos/watch/assets/${signedId}`
  return fetchData<VideoSourceResponse, VideoSourceFetchResponse>({
    endpoint,
    convertResponse: (payload: VideoSourceFetchResponse): VideoSourceResponse => {
      const { sources } = payload.player
      return {
        playerSources: sources.map((source) => ({
          url: source.url
        }))
      }
    }
  })
}
