import { orderBy, upperCase } from 'lodash'
import { removeLeadingSlash } from '../../../helper-functions'
import { BaseFetchResponse } from '../../../types/fetch-response-base.type'
import { AustralianState } from '../../../types/map.type'
import { fetchData } from '../api-fetch-request'
import { DayPhaseLetter, SilkObj } from '../data-types/general-data-types.type'

/////// Next to go ///////

export type LatestRace = {
  trackSlug: string
  trackName: string
  trackNameShort: string
  state: AustralianState
  date: Date
  distance: number
  dayPhaseLetter: DayPhaseLetter
  raceNumber: number
  clothNumber: number | null
  startTime: Date
  odds?: string
  silk?: SilkObj
}

export type LatestRacesFetchResponse = BaseFetchResponse & {
  races: {
    slug: string
    desktop_display_name: string
    mobile_display_name: string
    state: string
    date: string
    day_night_twilight: DayPhaseLetter
    race_number: number
    distance: number
    scheduled_start_time: string
    saddlecloth_number: number
    woo_average_odds: string | null
    silk_image: null | {
      filename: string
      path: string
    }
  }[]
}

export type LatestRacesResponse = {
  races: LatestRace[]
}

type GetNextToGoProps = {
  limit: number
  useDesignMock?: boolean
}

export async function getNextToGo({ limit = 6 }: GetNextToGoProps): Promise<LatestRacesResponse> {
  const endpoint = `racing/next-to-go?limit=${limit}`
  return fetchData<LatestRacesResponse, LatestRacesFetchResponse>({
    endpoint,
    convertResponse
  })

  function convertResponse(payload: LatestRacesFetchResponse): LatestRacesResponse {
    const { races } = payload
    return {
      races: orderBy(
        races.map((race) => ({
          trackSlug: removeLeadingSlash(race.slug),
          trackName: race.desktop_display_name,
          trackNameShort: race.mobile_display_name,
          state: upperCase(race.state) as AustralianState,
          date: new Date(race.date),
          distance: race.distance,
          dayPhaseLetter: race.day_night_twilight,
          raceNumber: race.race_number,
          clothNumber: race.saddlecloth_number,
          odds: race.woo_average_odds ?? undefined,
          startTime: new Date(race.scheduled_start_time),
          silk: { imagePath: race.silk_image?.path }
        })),
        'startTime',
        'asc'
      )
    }
  }
}

/////// Results ///////

export type LatestResult = {
  slug: string
  name: string
  mobileName: string
  state: AustralianState
  date: string
  distance: number
  dayPhase: string
  raceNumber: number
  startTime: number | string
  results: LatestResultPlace[]
}

export type LatestResultPlace = {
  place: string
  runners: {
    clothNumber: number
    silk?: SilkObj
  }[]
}
export type LatestResultsFetchResponse = BaseFetchResponse & {
  races: {
    slug: string
    desktop_display_name: string
    mobile_display_name: string
    state: string
    date: string
    day_night_twilight: DayPhaseLetter
    race_number: number
    distance: number
    scheduled_start_time: string
    result_places: {
      place: string
      runners: {
        saddlecloth_number: number
        silk_image: null | {
          filename: string
          path: string
        }
      }[]
    }[]
  }[]
}

export type LatestResultsResponse = {
  races: LatestResult[]
}

type GetLatestResults = {
  limit: number
  useDesignMock?: boolean
}

export async function getLatestResults({
  limit = 6
}: GetLatestResults): Promise<LatestResultsResponse> {
  const endpoint = `racing/latest-results?limit=${limit}`
  return fetchData<LatestResultsResponse, LatestResultsFetchResponse>({
    endpoint,
    convertResponse
  })

  function convertResponse(payload: LatestResultsFetchResponse): LatestResultsResponse {
    const { races } = payload
    return {
      races: orderBy(
        races.map((race) => ({
          slug: race.slug,
          name: race.desktop_display_name,
          mobileName: race.mobile_display_name,
          state: upperCase(race.state) as AustralianState,
          date: race.date,
          distance: race.distance,
          dayPhase: race.day_night_twilight,
          raceNumber: race.race_number,
          startTime: race.scheduled_start_time,
          results: race.result_places?.map((result) => ({
            place: result.place,
            runners: result.runners.map((runner) => ({
              clothNumber: runner.saddlecloth_number,
              silk: !runner.silk_image
                ? undefined
                : {
                    imagePath: runner.silk_image?.path
                  }
            }))
          }))
        })),
        'startTime',
        'asc'
      )
    }
  }
}
