/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Fragment, useState } from 'react'
import { View } from 'react-native'

import { format, isSameDay } from 'date-fns'
import { orderBy } from 'lodash'
import {
  LatestRacesResponse,
  getNextToGo
} from '../../../services/data/request-functions/latest-races-request'
import {
  MeetingsBasicResponse,
  getMeetingsBasic
} from '../../../services/data/request-functions/meetings-basic-request'
import {
  NewsListResponse,
  getNewsList
} from '../../../services/data/request-functions/news-list-request'
import {
  PodcastListResponse,
  getPodcasts
} from '../../../services/data/request-functions/podcast-list-requests'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../styles/text-sizes'
import { shortDate } from '../../../utils/date-time'
import { Heading_C } from '../../Base/Heading/Heading'
import { Card_C } from '../../Layout/Card/Card'
import { GapGrid_C } from '../../Layout/Grid/GapGrid'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { LatestRaces_C } from '../../Partials/LatestRaces/LatestRaces'
import { LatestReplays_C } from '../../Partials/LatestReplays/LatestReplays'
import { MeetingListCard_C } from '../../Partials/_meeting-components/MeetingListCard'
import { latestNewsLayoutStyles } from '../NewsIndex/LatestNewsLayout'
import { NewsItemLarge_C } from '../NewsIndex/components/NewsItemLarge'
import { NewsItemSmall_C } from '../NewsIndex/components/NewsItemSmall'
import { PodcastItem_C } from '../Videos/components/PodcastItem'
import { Helmet } from 'react-helmet'
import { Metadata } from '../../Partials/Metadata/Metadata'

export function Home_C(): JSX.Element {
  const outerStyles = useBreakpointStyles({ styles: outerBreakpointStyles })
  return withScreenWrapper<
    'Home',
    [MeetingsBasicResponse, NewsListResponse, LatestRacesResponse, PodcastListResponse]
  >({
    requestsSetup: () => [
      getMeetingsBasic({ date: shortDate(new Date()) }),
      getNewsList({ limit: 8, categorySlug: 'lead-news' }),
      getNextToGo({ limit: 6 }),
      getPodcasts({
        limit: 6,
        sortType: 'newest'
      })
    ],
    aside: ({ responses }) => {
      if (!responses) return
      const nextRacesResponse = responses[2]

      const { races: nextRaces } = nextRacesResponse

      return (
        <View style={outerStyles.asideContainer}>
          <LatestRaces_C nextRaces={nextRaces} />
          <LatestReplays_C />
        </View>
      )
    },
    view: ({ responses }) => {
      const [initialMeetingsResponse, { articles }, _nextRacesResponse, latestPodcastsResponse] =
        responses!
      //notNullAssertion because this screen is expecting a response
      const todaysDate = new Date()

      const [meetingsResponse, setMeetingsResponse] = useState(initialMeetingsResponse)

      const { meetings, maxMeetingDate, date } = meetingsResponse
      const { podcasts } = latestPodcastsResponse

      const orderedPodcasts = orderBy(podcasts, (x) => x.date, ['desc'])

      const styles = useBreakpointStyles({ styles: breakpointStyles })

      function handleDateSelected(date: Date) {
        getMeetingsBasic({ date: shortDate(date) }).then(setMeetingsResponse)
      }

      const [featuredArticle] = articles
      const sidebarArticles = articles.slice(1, 5)

      const heading = (
        <View style={styles.leftContainer}>
          <Heading_C styleType="h1" style={{ marginRight: 'auto' }}>
            {`${isSameDay(date, todaysDate) ? "Today's Racing " : ''} ${format(
              date,
              'dd/MM/yyyy'
            )}`}
          </Heading_C>
        </View>
      )

      const meetingsCard = (
        <MeetingListCard_C
          activeDate={date}
          type="home"
          handleDateSelected={handleDateSelected}
          homeHeading={heading}
          meetings={meetings}
          maxDate={maxMeetingDate}
        />
      )

      const featuredNewsCard = !articles.length ? undefined : (
        <Card_C style={{ gap: 16 }}>
          <Heading_C styleType="h2">Latest News</Heading_C>
          <View style={styles.featuredNewsContainer}>
            {!!featuredArticle && (
              <NewsItemLarge_C
                style={{
                  mainContainer: styles.featuredNews,
                  heading: styles.featureArticleHeading
                }}
                article={featuredArticle}
              />
            )}

            <View style={styles.smallFeaturedNewsContainer}>
              {sidebarArticles.length > 0 &&
                sidebarArticles.map((article, idx) => {
                  return (
                    <Fragment key={idx}>
                      <View style={styles.smallNewsItemWrapper}>
                        <NewsItemSmall_C article={article} />
                      </View>
                    </Fragment>
                  )
                })}
            </View>
          </View>
        </Card_C>
      )

      const featuredPodcastCard = !podcasts.length ? undefined : (
        <Card_C style={{ gap: 16 }}>
          <Heading_C styleType="h2">Latest Podcasts</Heading_C>
          <GapGrid_C
            gridBreakpointConfig={{
              base: { columns: 2 },
              medium: { columns: 3 }
            }}
            columnGap={16}
            rowGap={26}
            cells={orderedPodcasts.map((podcast, idx) => (
              <PodcastItem_C podcast={podcast} key={idx} />
            ))}
          />
        </Card_C>
      )

      return (
        <>
          <Metadata
            title="Home"
            description="The home of Australian Harness Racing where you can view all upcoming races, results, and replays."
          />

          {meetingsCard}
          {featuredNewsCard}
          {featuredPodcastCard}
        </>
      )
    }
  })
}
const outerBreakpointStyles = createStyles({
  asideContainer: {
    base: {
      gap: 24
    },
    large: {
      gap: 26
    }
  }
})

const breakpointStyles = createStyles({
  leftContainer: {
    flex: 1,
    marginRight: 'auto'
  },
  mainAsideContainer: {
    base: {
      gap: 24
    },
    medium: {
      flexDirection: 'row'
    }
  },
  asideContainer: {
    base: {
      rowGap: 24
    }
  },
  mainContainer: {
    base: {
      flex: 1,
      rowGap: 10
    }
  },
  featureArticleHeading: {
    base: {},
    [desktopBreakpoint]: {
      ...textSizes.size6
    }
  },

  ...latestNewsLayoutStyles
})
