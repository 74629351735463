import { getStorageData } from '../../utils/storage'
import { testAuthenticated } from '../data/request-functions/user-request'

const authServiceSingleton = (() => ({
  isAuthenticated: async () => {
    const authToken = await getStorageData('auth-token')

    if (!authToken) {
      throw 'no auth token'
    }

    const { loggedIn } = await testAuthenticated({ authToken })

    if (!loggedIn) {
      throw 'invalid auth token'
    }

    return authToken
  }
}))()

export const authService = authServiceSingleton
