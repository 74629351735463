import { useState } from 'react'

import { concat, orderBy, sortBy } from 'lodash'
import { RunnerBasic } from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import { breakpointBelow } from '../../../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../../../services/styles/dependencies/style-constants'
import { ResultRowsMobile_C } from './ResultRowsMobile'
import { ResultTableDesktop_C } from './ResultTableDesktop'

type Props = {
  runners: RunnerBasic[]
  raceNumber: number
  isTrial: boolean
}

export function ResultsTable_C({ runners, raceNumber, isTrial }: Props) {
  const runnersByPlace = sortByPlaceNumber()

  function sortByPlaceNumber() {
    const runnersWithPlaces = orderBy(
      runners.filter((r) => !!r.place),
      (r) => (isNaN(Number(r.place)) ? Infinity : Number(r.place))
    )

    const scratched = runners.filter((r) => r.scratched)

    const rest = sortBy(
      runners.filter((r) => !r.scratched && !r.place),
      'place'
    )

    return concat(runnersWithPlaces, rest, scratched)
  }

  const firstPlaceRunners = runners.filter((runner) => runner.place == '1')
  const [expandedRunners, setExpandedRunners] = useState<RunnerBasic[]>(firstPlaceRunners)

  const isMobileLayout = breakpointBelow(desktopBreakpoint)

  return isMobileLayout ? (
    <ResultRowsMobile_C
      {...{
        runners: runnersByPlace,
        raceNumber,
        expandedRunners,
        setExpandedRunners,
        isTrial
      }}
    />
  ) : (
    <ResultTableDesktop_C
      {...{
        runners: runnersByPlace,
        raceNumber,
        expandedRunners,
        setExpandedRunners,
        isTrial
      }}
    />
  )
}
