import { View } from 'react-native'
import {
  RunnerBasic,
  Sectional
} from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { TableStyles, Table_C } from '../../../../../Base/Table/Table'
import { TableHeading, TableRow } from '../../../../../Base/Table/table.type'
import { HeadingItem } from './SectionalsTable'
import { SectionalStats, getSectionalDataCell } from './sectionals-utils'

const HEADINGS: HeadingItem[] = [
  { title: '800m', description: 'Margin' },
  { title: 'Q3 Split', description: '(800-400)' },
  { title: '400m', description: 'Margin' },
  { title: 'Q4 Split', description: '(400-Finish)' },
  { title: 'Metres gained', description: '(800-400)' },
  { title: 'Metres gained', description: '(400-Finish)' },
  { title: 'Finish', description: 'Margin' }
]

type SectionalsExpandedRowMobileProps = {
  runner: RunnerBasic
  sectionalStats: SectionalStats
}
export function SectionalsExpandedRowMobile_C({
  runner,
  sectionalStats
}: SectionalsExpandedRowMobileProps) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { sectionalData } = runner

  const headings: TableHeading[] = HEADINGS.map((heading) => ({
    colWidth: heading.width ? { width: heading.width } : { flex: 1 },
    style: {},
    content: `${heading.title}${heading.description ? `\n${heading.description}` : ''}`
  }))

  const rows: TableRow[] = [
    {
      cells: [
        getCell('margin800'),
        getCell('q3Split'),
        getCell('margin400'),
        getCell('q4Split'),
        getCell('metresGained800400'),
        getCell('metresGained400Finish'),
        getCell('marginFinish')
      ]
    }
  ]

  function getCell(dataProp: keyof Sectional) {
    return getSectionalDataCell(sectionalStats, sectionalData, dataProp)
  }

  return (
    <View style={styles.container}>
      <Table_C
        {...{
          styles: tableStyle,
          rows,
          headings,
          styleType: 'lightHeaderBorders'
        }}
      />
    </View>
  )
}

const breakpointStyles = createStyles({
  container: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    backgroundColor: colors.gray50,
    gap: 20,
    borderBottomWidth: 1,
    borderBottomColor: presets.border,
    minWidth: 780,
    width: '100%'
  },
  headingText: {
    base: {
      ...textSizes.size1,
      fontWeight: '600SemiBold',
      textAlign: 'center'
    }
  },
  labelText: {
    textAlign: 'center',
    fontWeight: '600SemiBold',
    marginBottom: 6
  }
})

const tableStyle: TableStyles = {
  tableContainer: { backgroundColor: 'white' },
  headingText: { textAlign: 'center' }
}
