import { useNavigation } from '@react-navigation/native'
import { orderBy } from 'lodash'
import { View } from 'react-native'
import {
  PuntersCornerResponse,
  getPuntersCornerData
} from '../../../services/data/request-functions/punters-corner-request'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { presets } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { humanFriendlyDate, shortDate } from '../../../utils/date-time'
import { Heading_C } from '../../Base/Heading/Heading'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { CalendarPickerIcon_C } from '../../Partials/CalendarPicker/CalendarPickerIcon'
import { PuntersCornerDesktop_C } from './components/PuntersCornerDesktop'
import { PuntersCornerMobile_C } from './components/PuntersCornerMobile'
import { Metadata } from '../../Partials/Metadata/Metadata'

export function PuntersCorner_C(): JSX.Element {
  return withScreenWrapper<'PuntersCorner', [PuntersCornerResponse]>({
    requestsSetup: ({ date }) => [
      getPuntersCornerData({
        date: date ?? shortDate(new Date())
      })
    ],

    view: ({ responses, params }) => {
      const date = params.date ? params.date : shortDate(new Date())
      const [{ meetings, maxMeetingDate }] = responses!
      const styles = useBreakpointStyles({ styles: breakpointStyles })
      const isMobile = breakpointBelow(desktopBreakpoint)
      const navigation = useNavigation()

      const filteredMeetings = meetings?.filter((meeting) => {
        if (meeting.races.length && !meeting.isTrial) {
          return meeting
        }
      })

      const sortedFilteredMeetings = orderBy(filteredMeetings, (m) => m.races[0].startTime, 'asc')

      return (
        <>
          <Metadata
            title="Punters Corner"
            description="Search for upcoming races and past results on harness.org.au"
          />
          <Card_C>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                zIndex: 1
              }}
            >
              <Heading_C styleType="h1" style={styles.heading}>
                Punters Corner
              </Heading_C>
              <CalendarPickerIcon_C
                maxDate={maxMeetingDate}
                minDate={new Date()}
                selectedDate={new Date(date)}
                onDateSelected={(date) => {
                  navigation.navigate('PuntersCorner', { date: shortDate(date) })
                }}
              />
            </View>
            <Text_C style={styles.date}>{humanFriendlyDate(new Date(date))}</Text_C>

            {isMobile ? (
              <PuntersCornerMobile_C meetings={sortedFilteredMeetings} />
            ) : (
              <PuntersCornerDesktop_C meetings={sortedFilteredMeetings} />
            )}
          </Card_C>
        </>
      )
    }
  })
}

const breakpointStyles = createStyles({
  heading: { marginBottom: 20 },
  date: {
    ...textSizes.size3,
    color: presets.primary,
    marginBottom: 20,
    fontWeight: '700Bold'
  }
})
