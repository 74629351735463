import { uniqueId } from 'lodash'
import { environment } from '../services/data/api-environment'
import { Asset } from '../types/assets.type'
import { ResponseAsset } from '../types/fetch-response-base.type'

export const getAssetUrl = (asset?: Asset) => {
  if (!asset) return ''
  return asset.placeholderImageUrl || asset.viewerSources?.[0].url
}

export const getResponseAssetUrl = (asset: ResponseAsset) => {
  return asset.placeholder_image_url || asset.viewer_sources?.[0].url
}

export const convertAsset = (asset: ResponseAsset, isStewardsReplay: boolean = false): Asset => {
  const genId = uniqueId()
  return {
    id: genId,
    assetType: asset?.asset_type ?? undefined,
    contentType: asset?.content_type ?? undefined,
    title: asset?.title ?? undefined,
    description: asset?.description ?? undefined,
    viewerType: asset?.viewer_type ?? undefined,
    placeholderImageUrl: asset?.placeholder_image_url ?? undefined,
    viewerSources: asset?.viewer_sources ?? undefined,
    signedId: asset.signed_id,
    isStewardsReplay
  }
}

export const getPdfUrl = (pdfUrl: string | undefined): string | undefined => {
  return pdfUrl && `${environment.apiUrl}${pdfUrl} + '#toolbar=0&navpanes=0')`
}
