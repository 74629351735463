/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Switch } from 'react-native'
import { colors, presets } from '../../../styles/colors'
import { createStyles } from '../../../services/styles/breakpoint-styles.service'
type Props = {
  isEnabled?: boolean
  onSwitched(isEnabled: boolean): void
}
export function Switch_C({ isEnabled, onSwitched }: Props) {
  return (
    <Switch
      style={styles.switch}
      //RNW different props from RN
      //@ts-ignore
      trackColor={colors.gray400}
      activeTrackColor={colors.gray200}
      thumbColor={colors.gray200}
      activeThumbColor={presets.primary}
      onValueChange={onSwitched}
      value={isEnabled}
    />
  )
}

const styles = createStyles({
  switch: { height: 20, width: 43 }
})
