import { merge } from 'lodash'
import { useContext, useMemo, useState } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../../../global-context'
import { NotificationPreferences } from '../../../../../services/data/data-types/local-data-types/blackbook.type'
import {
  NotificationPreferencesResponse,
  getPreferences,
  setPreferences
} from '../../../../../services/data/request-functions/notification-preferences-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../services/styles/breakpoint-styles.service'
import { Heading_C } from '../../../../Base/Heading/Heading'
import { IconProps } from '../../../../Base/Icon/Icon'
import { ComponentWithFetch_C } from '../../../../HigherOrderComponents/ComponentWithFetch'
import { Card_C } from '../../../../Layout/Card/Card'
import { PreferenceRow_C } from './PreferenceRow'

export function NotificationPreferences_C() {
  const { authToken } = useContext(GlobalContext)
  return useMemo(() => {
    return (
      <ComponentWithFetch_C<[NotificationPreferencesResponse]>
        fetchRequests={[getPreferences({ authToken: authToken! })]}
        showLoading
        view={({ responses }) => {
          const { authToken, addToast } = useContext(GlobalContext)
          const [initialPreferencesResponse] = responses
          const [notificationsResponse, setNotificationsResponse] = useState(
            initialPreferencesResponse
          )

          const { notificationPreferences: preferences } = notificationsResponse

          const styles = useBreakpointStyles({ styles: breakpointStyles })
          const preferenceOptions: { name: string; icon?: IconProps }[] = [
            {
              name: 'Yes',
              icon: {
                name: 'tick',
                style: { width: 14 },
                colorFill: true,
                colorStroke: false
              }
            },
            { name: 'No', icon: { name: 'cross', style: { width: 14 } } }
          ]
          return useMemo(() => {
            return (
              <Card_C>
                <Heading_C styleType="h1" style={styles.heading}>
                  Email Settings
                </Heading_C>
                <View style={styles.rowsContainer}>
                  <PreferenceRow_C
                    label="Receive an email when your Blackbook horses are entered to run"
                    options={preferenceOptions}
                    initial={preferences.isEntered ? 'Yes' : 'No'}
                    onOptionSelected={(val: boolean) => {
                      updatePreferences('isEntered', val)
                    }}
                  />
                  {/* TODO:  */}
                  {/* <PreferenceRow_C
                    label="Horse has been scratched"
                    options={preferenceOptions}
                    initial={preferences.isScratched ? 'Yes' : 'No'}
                    onOptionSelected={(val: boolean) => {
                      updatePreferences('isScratched', val)
                    }}
                  />
                  <PreferenceRow_C
                    label="Horse wins a race"
                    options={preferenceOptions}
                    initial={preferences.hasWon ? 'Yes' : 'No'}
                    onOptionSelected={(val: boolean) => {
                      updatePreferences('hasWon', val)
                    }}
                  />
                  <PreferenceRow_C
                    label="Horse completes a race"
                    options={preferenceOptions}
                    initial={preferences.hasCompleted ? 'Yes' : 'No'}
                    onOptionSelected={(val: boolean) => {
                      updatePreferences('hasCompleted', val)
                    }}
                  /> */}
                </View>
              </Card_C>
            )
            function updatePreferences(preference: keyof NotificationPreferences, val: boolean) {
              setPreferences({
                authToken: authToken!,
                preferences: merge(preferences, { [preference]: val })
              }).then(() => {
                addToast({ message: 'Preferences Updated', status: 'success' })
                getPreferences({ authToken: authToken! }).then((preferencesResponse) => {
                  setNotificationsResponse(preferencesResponse)
                })
              })
            }
          }, [preferences])
        }}
      />
    )
  }, [authToken])
}

const breakpointStyles = createStyles({
  heading: { marginBottom: 20 },
  rowsContainer: {
    gap: 10
  }
})
