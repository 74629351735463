export const nameTitle = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Prof'] as const

export type NameTitle = (typeof nameTitle)[number]

export type User = {
  title: NameTitle
  firstName: string
  lastName: string
  email: string
}
