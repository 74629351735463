/* eslint-disable react/jsx-key */
import { isAfter } from 'date-fns'
import { useContext } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../../global-context'
import { desaturateColor } from '../../../../helper-functions'
import { MeetingPuntersCorner } from '../../../../services/data/request-functions/punters-corner-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { colors, presets, stateColor } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { humanFriendlyTime } from '../../../../utils/date-time'
import { Button_C } from '../../../Base/Button/Button'
import { Link_C } from '../../../Base/Link/Link'
import { PressableStyleExtension } from '../../../Base/Pressable/Pressable'
import { Text_C } from '../../../Base/Text/Text'
import { GapGrid_C } from '../../../Layout/Grid/GapGrid'
import { Analysis_C } from '../../../Partials/_meeting-components/Analysis'
import { GearChanges_C } from '../../../Partials/_meeting-components/GearChanges'
import { Scratchings_C } from '../../../Partials/_meeting-components/Scratchings'
import { Tips_C } from '../../../Partials/_meeting-components/Tips'
import { WeatherIcon_C } from '../../../Partials/_meeting-components/WeatherIcon'

type Props = {
  meetings: MeetingPuntersCorner[]
}
export function PuntersCornerMobile_C({ meetings }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const { openModal } = useContext(GlobalContext)

  const meetingRows = meetings.map((meeting, i) => {
    const { races, tips, analysis, track, date, dayPhaseLetter } = meeting

    const gearChangesCount = races.reduce(
      (count, race) => count + race.runners?.filter((r) => r.gearAdded || r.gearRemoved).length,
      0
    )
    const scratchingsCount = races.reduce(
      (count, race) => count + race.runners?.filter((r) => r.isScratched).length,
      0
    )

    const weatherIcon = meeting.weather && <WeatherIcon_C weather={meeting.weather} />

    const nextRaceTime = races.find((r) => isAfter(r.startTime, new Date()))?.startTime

    return (
      <View style={styles.mobileRow} key={i}>
        <View style={styles.trackCellContainer}>
          <Link_C
            navigateTo={[
              'Meeting',
              {
                date: meeting.date,
                trackSlug: track.slug,
                dayPhaseLetter: meeting.dayPhaseLetter,
                type: meeting.isTrial ? 'trial' : 'meeting'
              }
            ]}
            style={{ elem: styles.trackLinkContainer }}
          >
            <View
              style={[
                styles.stateColor,
                {
                  backgroundColor: track.state ? stateColor[track.state] : 'white'
                }
              ]}
            />
            <View style={styles.trackNameContainer}>
              <Text_C style={styles.trackNameText}>{track.name}</Text_C>
              <Text_C style={styles.trackStateText}>{track.state}</Text_C>
            </View>
          </Link_C>
          {weatherIcon}
          <View style={styles.racesInfo}>
            <View>
              <Text_C style={styles.racesCount}>{races.length} races</Text_C>
            </View>
            {nextRaceTime && (
              <View>
                <Text_C>Next race {humanFriendlyTime(nextRaceTime)}</Text_C>
              </View>
            )}
          </View>
        </View>
        <View style={styles.buttonRow}>
          <GapGrid_C
            gridBreakpointConfig={{
              base: { columns: 2 },
              small: { columns: 3 }
            }}
            rowGap={10}
            columnGap={10}
            cells={[
              <Link_C
                styleType="buttonBase"
                style={infoButton}
                navigateTo={[
                  'Meeting',
                  {
                    date: date,
                    trackSlug: track.slug,
                    dayPhaseLetter: dayPhaseLetter,
                    type: meeting.isTrial ? 'trial' : 'meeting'
                  }
                ]}
              >
                Fields
              </Link_C>,
              <Link_C
                styleType="buttonBase"
                style={infoButton}
                navigateTo={[
                  'Meeting',
                  {
                    date: meeting.date,
                    trackSlug: track.slug,
                    dayPhaseLetter: meeting.dayPhaseLetter,
                    type: meeting.isTrial ? 'trial' : 'meeting'
                  }
                ]}
              >
                Form
              </Link_C>,
              <Button_C
                styleType="buttonBase"
                disabled={!tips}
                style={infoButton}
                numberOfLines={1}
                onPress={() => {
                  openModal(<Tips_C tips={tips!} />)
                }}
              >
                Tips
              </Button_C>,
              <Button_C
                styleType="buttonBase"
                disabled={!analysis}
                style={infoButton}
                numberOfLines={1}
                onPress={() => {
                  openModal(<Analysis_C analysis={analysis!} />)
                }}
              >
                Analysis
              </Button_C>,
              <>
                <Button_C
                  styleType="buttonBase"
                  disabled={!scratchingsCount}
                  style={infoButton}
                  numberOfLines={1}
                  onPress={() => {
                    openModal(<Scratchings_C races={races} />)
                  }}
                >
                  {`Scratchings ${scratchingsCount ? ` (${scratchingsCount})` : ''}`}
                </Button_C>
              </>,
              <>
                <Button_C
                  styleType="buttonBase"
                  disabled={!gearChangesCount}
                  style={infoButton}
                  numberOfLines={1}
                  onPress={() => {
                    openModal(<GearChanges_C races={races} />)
                  }}
                >
                  {`Gear Changes ${gearChangesCount ? `(${gearChangesCount})` : ''}`}
                </Button_C>
              </>
            ]}
          />
        </View>
      </View>
    )
  })
  return <View style={styles.daysMeetingsContainer}>{meetingRows}</View>
}

const breakpointStyles = createStyles({
  daysMeetingsContainer: {
    borderTopWidth: 1,
    borderTopColor: presets.border,
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
    alignItems: 'stretch'
  },
  mobileRow: {
    width: '100%',
    paddingVertical: 20,
    gap: 10,
    borderBottomWidth: 1,
    borderBottomColor: presets.border
  },
  trackCellContainer: {
    flex: 1,
    flexDirection: 'row',
    gap: 24,
    alignItems: 'center'
  },
  trackLinkContainer: {
    flexDirection: 'row',
    marginRight: 'auto'
  },
  trackNameContainer: {
    flex: 1,
    justifyContent: 'center',
    rowGap: 5,
    marginTop: 2,
    marginLeft: 12
  },
  stateColor: { width: 9, height: 35 },
  trackNameText: {
    ...textSizes.size3,
    textTransform: 'capitalize',
    fontFamily: 'OpenSans',
    fontWeight: '700Bold',
    color: colors.gray700
  },
  trackStateText: {
    ...textSizes.size1,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold',
    lineHeight: 12,
    marginTop: 2,
    color: colors.gray700
  },
  scratchingsText: {
    ...textSizes.size3
  },
  racesInfo: {
    gap: 4
  },
  racesCount: {
    ...textSizes.size3,
    fontWeight: '600SemiBold',
    textAlign: 'right'
  },
  expandContainer: {
    borderColor: presets.border,
    borderWidth: 1,
    borderTopWidth: 0
  },
  buttonRow: {
    gap: 6,
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
})

const infoButton: PressableStyleExtension = {
  elem: {
    base: {
      flex: 1,
      backgroundColor: desaturateColor(presets.primary, 0.5),
      borderWidth: 1,
      borderColor: presets.border
    },
    disabled: {
      backgroundColor: presets.disabled
    },

    hovered: { backgroundColor: presets.primary }
  },
  text: {
    base: {
      color: 'white'
    },
    disabled: {
      color: presets.disabledText
    }
  }
}
