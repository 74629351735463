import { orderBy } from 'lodash'

import { View } from 'react-native'
import { RacePuntersCorner } from '../../../services/data/request-functions/punters-corner-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { presets } from '../../../styles/colors'
import { LabelValuePair, LabelValueTable_C } from '../../Base/Table/LabelValueTable'
import { Text_C } from '../../Base/Text/Text'

type Props = { races: RacePuntersCorner[] }

export function GearChanges_C({ races }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const gearTableData = orderBy(races, (r) => r.raceNumber).reduce((acc, race) => {
    const runnersWithGearChange = race.runners.filter(
      (runner) => runner.gearAdded || runner.gearRemoved
    )
    if (runnersWithGearChange.length) {
      return [
        ...acc,
        {
          label: `Race ${race.raceNumber}.`,
          value: (
            <View style={styles.gearChangeContainer}>
              {runnersWithGearChange.map((r, i) => (
                <View key={i} style={styles.runnerGearContainer}>
                  <Text_C style={styles.runnerText}>
                    {r.runnerNumber}. {r.horseName}
                  </Text_C>
                  {!!r.gearRemoved?.length && <Text_C>Gear Removed: {r.gearRemoved}</Text_C>}
                  {!!r.gearAdded?.length && <Text_C>Gear Added: {r.gearAdded}</Text_C>}
                </View>
              ))}
            </View>
          )
        }
      ]
    }
    return acc
  }, [] as LabelValuePair[])

  return (
    <LabelValueTable_C
      data={gearTableData}
      style={{ container: styles.gearChangeTable, label: { width: 60 } }}
    />
  )
}

const breakpointStyles = createStyles({
  container: {
    padding: 30
  },
  contentContainer: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: presets.border,
    padding: 30,
    marginRight: 'auto'
  },
  runnerText: {
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold'
  },
  gearChangeContainer: {
    gap: 20
  },
  runnerGearContainer: {
    gap: 10
  },
  gearChangeTable: {
    base: { rowGap: 26 },
    [tabletBreakpoint]: { rowGap: 32 }
  }
})
