import { DriverResponse, getDriver } from '../../../services/data/request-functions/driver-request'
import {
  DriverRunsResponse,
  getDriverRuns
} from '../../../services/data/request-functions/driver-runs-request'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { BlackTypeRaces_C } from '../../Partials/_trainer-driver-components/BlackTypeRaces'
import { FeatureRaceWins_C } from '../../Partials/_trainer-driver-components/FeatureRaceWins'
import { TrainerDriverDetails_C } from '../../Partials/_trainer-driver-components/TrainerDriverDetails'
import { TrainerDriverLatestResults_C } from '../../Partials/_trainer-driver-components/TrainerDriverLatestResults'
import { TrainerDriverStats_C } from '../../Partials/_trainer-driver-components/TrainerDriverStats'
import { TrainerDriverTopHorseRuns_C } from '../../Partials/_trainer-driver-components/TrainerDriverTopHorseRuns'
import { TrainerDriverUpcomingRuns_C } from '../../Partials/_trainer-driver-components/TrainerDriverUpcomingRuns'
import { Metadata } from '../../Partials/Metadata/Metadata'

export function Driver_C(): JSX.Element {
  return withScreenWrapper<'Driver', [DriverResponse, DriverRunsResponse]>({
    requestsSetup: ({ driverSlug }) => [getDriver({ driverSlug }), getDriverRuns({ driverSlug })],

    view: ({ responses }) => {
      const [{ driver }, { latestResults, topHorsesRuns, upcomingRuns }] = responses!

      const {
        blackTypePerformance,
        featureWins,
        lifetimePerformance,
        lastSeasonPerformance,
        thisSeasonPerformance
      } = driver
      const hasFeatureWins = !!featureWins?.length
      return (
        <>
          <Metadata
            title={driver.displayName}
            description={`View all of ${driver.displayName}'s Driver stats, race records and upcoming races on harness.org.au`}
          />

          <TrainerDriverDetails_C driver={driver} />
          <TrainerDriverStats_C
            {...{
              lifetimePerformance,
              lastSeasonPerformance,
              thisSeasonPerformance
            }}
          />
          {!!blackTypePerformance?.length && (
            <BlackTypeRaces_C blackTypePerformance={blackTypePerformance} />
          )}
          {hasFeatureWins && <FeatureRaceWins_C {...{ featureWins }} />}

          {!!upcomingRuns?.length && (
            <TrainerDriverUpcomingRuns_C {...{ upcomingRuns }} type="driver" />
          )}
          {!!latestResults?.length && <TrainerDriverLatestResults_C {...{ latestResults }} />}
          {!!topHorsesRuns?.length && <TrainerDriverTopHorseRuns_C {...{ topHorsesRuns }} />}
        </>
      )
    }
  })
}
