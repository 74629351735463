// cspell: word Infront
import { PropsWithChildren, useEffect, useState } from 'react'
import { AccessibilityProps, Pressable, PressableProps, View } from 'react-native'

import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { GeneralNamedBreakpointStylesExtenion } from '../../../services/styles/dependencies/breakpoint-style.type'
import { TextStyle_T } from '../../../styles/fonts-map'
import { IconProps, Icon_C } from '../Icon/Icon'
import { Text_C } from '../Text/Text'
import { StyleTypes, pressableStyleTypes } from './pressable-style-types'

type Props = PropsWithChildren<
  Omit<PressableProps, 'style'> &
    AccessibilityProps &
    React.RefAttributes<View> & {
      styleType?: StyleTypes
      disabled?: boolean
      active?: boolean
      style?: PressableStyleExtension
      icon?: IconProps
      iconInfront?: boolean
      numberOfLines?: number
    }
>

export type PressableStyleExtension = GeneralNamedBreakpointStylesExtenion<
  typeof baseStyles,
  StateNames
>

export type PressableCProps = Props

type StateNames = 'hovered' | 'focused' | 'pressed' | 'disabled' | 'active'

const baseStyles = createStyles({
  elem: {},
  text: {}
})

export function Pressable_C(props: Props) {
  const { active = false, disabled = false, icon, iconInfront, ...rest } = props

  const [state, setState] = useState<Record<StateNames, boolean>>({
    active,
    disabled,
    focused: false,
    hovered: false,
    pressed: false
  })

  function updateState<Name extends StateNames>(stateName: Name, value: boolean) {
    setState((prevState) => ({ ...prevState, [stateName]: value }))
  }

  useEffect(() => {
    updateState('active', active)
  }, [active])

  useEffect(() => {
    updateState('disabled', disabled)
  }, [disabled])

  /* flatten to named styles based on state and breakpoint */
  const styles = useBreakpointStyles({
    styles: baseStyles,
    additionalStyles: [props.styleType && pressableStyleTypes[props.styleType], props.style],
    state
  })

  const isStringOrNumber = ['string', 'number'].includes(typeof props.children)

  const iconElem = icon && (
    <Icon_C
      {...icon}
      style={{ height: '100%', ...icon.style }}
      color={icon.useParentTextColor ? (styles.text as TextStyle_T).color : icon.color}
    />
  )

  return (
    <Pressable
      testID="button"
      disabled={disabled}
      onHoverOut={() => updateState('hovered', false)}
      onHoverIn={() => updateState('hovered', true)}
      onPressIn={() => updateState('pressed', true)}
      onPressOut={() => updateState('pressed', false)}
      onFocus={() => updateState('focused', true)}
      onBlur={() => updateState('focused', false)}
      {...rest}
      style={styles.elem}
    >
      {iconInfront && iconElem}
      {isStringOrNumber ? (
        <Text_C numberOfLines={props.numberOfLines} style={styles.text}>
          {props.children}
        </Text_C>
      ) : (
        props.children
      )}
      {/* icon color is based on buttons text color and changes based on state */}

      {!iconInfront && iconElem}
    </Pressable>
  )
}
