/* eslint-disable @typescript-eslint/no-for-in-array */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { mapObject, reduceObject } from '../../../helper-functions'
import {
  BreakpointName,
  BreakpointStyles,
  GeneralNamedStyles,
  NamedBreakpointStylesExtension,
  NamedStatefulBreakpointStylesExtension,
  NamedStyleExtension,
  NamedStyles,
  StatefulBreakpointStyles,
  StyleType
} from './breakpoint-style.type'
import { flattenStyles } from './flatten-styles'
import { breakpointNames } from './style-constants'

export function processStyles<
  T extends GeneralNamedStyles<T, StateNames>,
  StateNames extends string
>(obj: {
  activeBreakpoint: BreakpointName
  styles: T | StyleType | BreakpointStyles
  additionalStyles?: Array<
    | undefined
    | StyleType
    | BreakpointStyles
    | NamedStyleExtension<T>
    | NamedBreakpointStylesExtension<T>
    | NamedStatefulBreakpointStylesExtension<T, StateNames>
  >
  state?: Record<StateNames, boolean>
}) {
  const { activeBreakpoint, styles, additionalStyles, state } = obj

  const filteredAdditionalStyles = additionalStyles?.reduce(
    (filtered, styleType) => (styleType ? [...filtered, styleType] : filtered),
    [] as Array<
      | StyleType
      | BreakpointStyles
      | NamedStyleExtension<T>
      | NamedBreakpointStylesExtension<T>
      | NamedStatefulBreakpointStylesExtension<T, StateNames>
    >
  )

  //typeguard infers type of styles if/else block
  if (isNamedStylesObj(styles, filteredAdditionalStyles)) {
    let returnObj: NamedStyles<T>

    const namedStyleObj: GeneralNamedStyles<T, StateNames> = styles
    const namedAdditionalStyles = filteredAdditionalStyles as
      | undefined
      | Array<
          | NamedStyleExtension<T>
          | NamedBreakpointStylesExtension<T>
          | NamedStatefulBreakpointStylesExtension<T, StateNames>
        >

    returnObj = processNamedStyles(namedStyleObj, namedAdditionalStyles)
    return returnObj
  } else {
    let returnObj: StyleType

    const nonNamedAdditionalStyles = additionalStyles as
      | undefined
      | Array<StyleType | BreakpointStyles>

    returnObj = flattenStyles(activeBreakpoint, [
      styles,
      ...(nonNamedAdditionalStyles ? nonNamedAdditionalStyles : [])
    ])

    return returnObj
  }

  /* Implementation */

  function isNamedStylesObj(
    stylesObj: T | StyleType | BreakpointStyles,
    additionalStyles?: Array<
      | StyleType
      | BreakpointStyles
      | NamedStyleExtension<T>
      | NamedBreakpointStylesExtension<T>
      | NamedStatefulBreakpointStylesExtension<T, StateNames>
    >
  ): stylesObj is T {
    /*
      - logic rules
        ~  if style or any additional style has its key as a bps( base, large etc), then its type can only be a BreakpointStyle, therefore all the other ones must be StyleType/BreakpointStyle and cant be any named style obj
        ~ there is a chance that none of them will have its key as a bps if the typ eis StyleType, so to rule out StyleType, check the object depth, if we already rule out BreakpointStyles, then StyleType will never have grandchildren
          * ie if styleObj:{transform: [{ rotate: "90deg" }]}
            - example of style prop that has 2 deep value
          * ie if styleObj:{myElemName: {fontSize: 2}}
            - example of named style that is only 1 deep
          * however all named styles are at least 1 deep, and most style prop values are 0 deep, so this is the check
    */

    /* if all values are typeof == 'object', then is named style, else styletype/breakpointstyle */

    const stateNames = state && Object.keys(state)

    const allStyles: Array<
      | StyleType
      | BreakpointStyles
      | NamedStyleExtension<T>
      | NamedBreakpointStylesExtension<T>
      | NamedStatefulBreakpointStylesExtension<T, StateNames>
    > = [stylesObj, ...(additionalStyles ? additionalStyles : [])]

    const oneHasBpOrStateAsKey = allStyles.some((styleObj: Record<string, any>) => {
      return reduceObject(
        styleObj,
        (hasBpOrStateAsKey, _key, value) => {
          if (hasBpOrStateAsKey) return true
          if (typeof value == 'object') {
            // is possibly a named style
            return (
              breakpointNames.some((bp) => bp in value) ||
              (!!stateNames && stateNames.some((state) => state in value))
            )
          }
          return false
        },
        false
      )
    })

    if (oneHasBpOrStateAsKey) {
      return true
    } else {
      /* could still be named style, need to rule it out */
      if (isAllValuesObjects(allStyles)) {
        return true
      } else {
        return false
      }
    }

    function isAllValuesObjects(
      allStyles: Array<
        | StyleType
        | Partial<BreakpointStyles>
        | NamedStyleExtension<T>
        | NamedBreakpointStylesExtension<T>
        | NamedStatefulBreakpointStylesExtension<T, StateNames>
      >
    ): allStyles is Array<
      | NamedStyleExtension<T>
      | NamedBreakpointStylesExtension<T>
      | NamedStatefulBreakpointStylesExtension<T, StateNames>
    > {
      return allStyles.every((styleObj) => {
        const vals = Object.values(styleObj)

        for (const val in vals) {
          if (typeof val == 'object') {
            return true
          }
        }
        return false
      })
    }
  }

  function processNamedStyles(
    namedStyleObj: GeneralNamedStyles<T, StateNames>,
    namedAdditionalStyles?: Array<
      | NamedStyleExtension<T>
      | NamedBreakpointStylesExtension<T>
      | NamedStatefulBreakpointStylesExtension<T, StateNames>
    >
  ) {
    return mapObject(
      namedStyleObj,
      (
        styleName: keyof T,
        styleObj: StyleType | BreakpointStyles | StatefulBreakpointStyles<StateNames>
      ) => {
        let returnObj: NamedStyles<T>

        const additionalStylesForName:
          | undefined
          | Array<StyleType | Partial<BreakpointStyles> | StatefulBreakpointStyles<StateNames>> =
          namedAdditionalStyles
            ?.filter((namedStyleObj) => styleName in namedStyleObj && !!namedStyleObj[styleName])
            .map(
              (
                namedStyleObj
              ): StyleType | Partial<BreakpointStyles> | StatefulBreakpointStyles<StateNames> =>
                namedStyleObj[styleName]!
            )

        const allStylesForName = [
          styleObj,
          ...(additionalStylesForName ? additionalStylesForName : [])
        ]

        const flattenedStyles: StyleType = flattenStyles(activeBreakpoint, allStylesForName, state)
        returnObj = { [styleName]: flattenedStyles } as NamedStyles<T>

        return returnObj
      }
    )
  }
}
