import { fetchData } from '../api-fetch-request'
import { VideoSourceFetchResponse } from '../data-types/fetch-response-types/video-source-response.type'
import { VideoSourceResponse } from './videos-latest-replays-request'

type GetVideoSourceProps = {
  trackSlug: string
  date: string
  raceNumber: number
  isTrial: boolean
  videoIndex?: number
  isStewardsReplay?: boolean
}

export function getVideoSource({
  trackSlug,
  date,
  raceNumber,
  isTrial,
  videoIndex = 0,
  isStewardsReplay = false
}: GetVideoSourceProps) {
  const basePath = `videos/watch/replays/${isStewardsReplay ? 'stewards/' : ''}`
  const queryParams = `?trial=${isTrial}`
  const endpoint = `${basePath}${trackSlug}/${date}/${raceNumber}${isStewardsReplay ? `/${videoIndex}` : ''}${queryParams}`

  return fetchData<VideoSourceResponse, VideoSourceFetchResponse>({
    endpoint,
    convertResponse: (payload: VideoSourceFetchResponse): VideoSourceResponse => {
      const { sources } = payload.player
      return {
        playerSources: sources.map((source) => ({
          contentType: source.content_type,
          url: source.url
        }))
      }
    }
  })
}
