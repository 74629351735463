import { capitalize } from 'lodash'
import { View } from 'react-native'
import { NewsArticle } from '../../../../services/data/data-types/local-data-types/news.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { dateByDistance } from '../../../../utils/date-time'
import { Link_C } from '../../../Base/Link/Link'
import { Text_C } from '../../../Base/Text/Text'

export function CategoryAndDate_C({ article }: { article: NewsArticle }) {
  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })
  const publishedDate = dateByDistance(article.published.datetime)
  return (
    <View style={styles.categoryAndDateContainer}>
      {article.categories.map(({ title, slug }) => {
        return (
          <Link_C
            numberOfLines={1}
            navigateTo={['News', { categorySlug: slug }]}
            style={{ elem: styles.categoryLink }}
            key={title}
          >
            {title}
          </Link_C>
        )
      })}
      <Text_C numberOfLines={1} style={styles.date}>
        {capitalize(publishedDate)}
      </Text_C>
    </View>
  )
}

const breakpointStyles = createStyles({
  categoryAndDateContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    gap: 7
  },
  categoryLink: {
    ...textSizes.size1,
    fontWeight: '700Bold',
    textTransform: 'uppercase',
    color: presets.primary
  },
  date: {
    paddingLeft: 5,
    borderLeftWidth: 1,
    borderLeftColor: presets.border,
    ...textSizes.size1
  }
})
