/* eslint-disable @typescript-eslint/no-misused-promises */
import { useContext, useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { formatCurrency, removeLeadingSlash } from '../../../../helper-functions'
import { RaceStatus } from '../../../../services/data/data-types/general-data-types.type'
import { MeetingDetailedRace } from '../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../services/styles/dependencies/style-constants'
import { colors } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { humanFriendlyTime, shortDate } from '../../../../utils/date-time'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Link_C } from '../../../Base/Link/Link'
import { Text_C } from '../../../Base/Text/Text'
import { InfoButton_C } from '../../../Partials/InfoButton/InfoButton'
import { RaceCountdownText_C } from '../../../Partials/RaceCountdownText/RaceCountdownText'
import { VideoItem_C } from '../../../Partials/_video-components/VideoItem'
import { MeetingContext } from '../Meeting'
import { PhotoFinishType, PhotoFinish_C } from '../tab-views/Results/components/PhotoFinish'

type Props = {
  race: MeetingDetailedRace
  photoFinishType?: PhotoFinishType
}

export function RaceHeader_C({ race, photoFinishType }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const { activeTab, activeMeeting } = useContext(MeetingContext)
  const isMobileLayout = breakpointBelow(desktopBreakpoint)
  const [photoFinishSource, setPhotoFinishSource] = useState<string>()

  const {
    raceName,
    startTime,
    raceClass,
    betTypes,
    resultPlaces,
    totalPrizeMoney,
    trackRecord,
    distance,
    startType,
    raceNotes,
    raceNumber,
    shortName,
    hasReplay
  } = race

  const photoFinishUrl = race.photoFinish?.viewerSources?.[0].url

  useEffect(() => {
    if (photoFinishUrl) {
      Image.getSize(
        photoFinishUrl,
        () => setPhotoFinishSource(photoFinishUrl),
        () => setPhotoFinishSource(undefined)
      )
    }
  }, [photoFinishUrl])

  const startTimeString = humanFriendlyTime(startTime)

  const distanceString = `${distance}m`

  const heading = (
    <Heading_C styleType="h4" style={breakpointStyles.heading}>
      {(isMobileLayout && shortName) || raceName}
    </Heading_C>
  )

  const raceNumberElem = (
    <View style={styles.raceNumber}>
      <Text_C style={styles.raceNumberText}>{`R${raceNumber}`}</Text_C>
    </View>
  )

  const raceIsOff = (['Abandoned', 'No Race'] as RaceStatus[]).includes(race.status)

  const countdownAndResult = raceIsOff ? (
    <></>
  ) : resultPlaces ? (
    <Text_C style={styles.resultPlaces}>Result</Text_C>
  ) : (
    <RaceCountdownText_C startTime={startTime} fallbackContent="---" />
  )

  const trackRecordElem = trackRecord && (
    <View style={styles.trackRecordContainer}>
      <Text_C style={styles.trackRecordLabel}>Track Record: </Text_C>
      {trackRecord.horseSlug ? (
        <Link_C
          style={{ text: styles.trackRecord }}
          navigateTo={['Horse', { horseSlug: trackRecord.horseSlug }]}
        >
          {trackRecord.horseName}
        </Link_C>
      ) : (
        <Text_C style={styles.trackRecord}>{trackRecord.horseName}</Text_C>
      )}
      <Text_C style={styles.trackRecord}> {trackRecord.timeMileRate}</Text_C>
    </View>
  )

  const replayVideo =
    hasReplay && race.raceReplay ? (
      <View style={{ aspectRatio: 1.7889, height: '100%' }}>
        <VideoItem_C
          startTime={humanFriendlyTime(race.startTime)}
          video={race.raceReplay}
          nextVideos={race.hasStewardsReplay && race.stewardsReplays}
          date={shortDate(race.startTime)}
          trackSlug={removeLeadingSlash(activeMeeting.track.slug)}
          raceNumber={race.raceNumber}
          trial={activeMeeting.isTrial}
          dayPhaseLetter={activeMeeting.dayPhaseLetter}
          includeMeetingLink={false}
        />
      </View>
    ) : null

  const mobilePhotoAndReplay = (photoFinishSource || hasReplay) && (
    <View style={styles.photoAndReplayButtons}>
      {photoFinishSource && (
        <PhotoFinish_C imageSource={photoFinishSource} type="icon" isFullWidth />
      )}

      {hasReplay && race.raceReplay && (
        <VideoItem_C
          startTime={humanFriendlyTime(race.startTime)}
          video={race.raceReplay}
          date={shortDate(race.startTime)}
          trackSlug={removeLeadingSlash(activeMeeting.track.slug)}
          raceNumber={race.raceNumber}
          trial={activeMeeting.isTrial}
          dayPhaseLetter={activeMeeting.dayPhaseLetter}
          includeMeetingLink={false}
          nextVideos={race.hasStewardsReplay && race.stewardsReplays}
          asButton
        />
      )}
    </View>
  )

  const desktopPhotoAndReplay = (photoFinishSource || (hasReplay && !!race?.raceReplay)) && (
    <View style={styles.photoAndReplayContainer}>
      {photoFinishSource && (
        <PhotoFinish_C
          imageSource={photoFinishSource}
          type="image"
          isFullWidth={!race.raceReplay}
        />
      )}
      {replayVideo}
    </View>
  )

  const mobileLayout = (
    <View style={styles.raceHeader}>
      <View style={styles.headerTop}>
        <View style={styles.headerLeft}>
          {raceNumberElem}
          {raceIsOff ? (
            <Text_C style={styles.raceOffText}>
              {race.status == 'Abandoned' ? 'Abnd' : 'No Race'}
            </Text_C>
          ) : (
            countdownAndResult
          )}
        </View>

        <View style={styles.headerRight}>
          {heading}
          <View style={styles.headerBottom}>
            <View style={styles.info}>
              {[
                startTimeString,
                formatCurrency(totalPrizeMoney),
                raceClass,
                distanceString,
                startType
              ].map((info, i, { length }) => {
                const notLastItem = i != length - 1
                return (
                  <Text_C key={i} style={styles.infoText}>{`${info} ${
                    notLastItem ? ' | ' : ''
                  }`}</Text_C>
                )
              })}
            </View>

            {raceNotes && <InfoButton_C tooltipContent={raceNotes} />}
          </View>
          {trackRecordElem}
        </View>
      </View>
      {mobilePhotoAndReplay}
    </View>
  )

  const desktopLayout = (
    <View style={styles.raceHeader}>
      <View style={styles.headerTop}>
        <View style={styles.headerLeft}>
          {raceNumberElem}
          {raceIsOff ? (
            <Text_C style={styles.raceOffText}>
              {race.status == 'Abandoned' ? 'Abnd' : 'No Race'}
            </Text_C>
          ) : (
            countdownAndResult
          )}
        </View>
        <View style={{ gap: 4 }}>
          <Text_C style={styles.distanceText}>{distanceString}</Text_C>
          <Text_C style={styles.distanceText}>{startType}</Text_C>
        </View>
        <View style={{ flex: 1 }}>
          {heading}
          <View style={styles.info}>
            <Text_C style={styles.infoText}>{`${startTimeString} ${
              totalPrizeMoney ? formatCurrency(totalPrizeMoney) : ''
            } ${raceClass}`}</Text_C>

            {raceNotes && <InfoButton_C tooltipContent={raceNotes} />}
          </View>
          {trackRecordElem}
        </View>
        <View style={styles.rightContainer}>
          {betTypes && activeTab == 'Fields & Form' && (
            <View style={styles.bettingTypes}>
              <Text_C style={styles.bettingTypesText}>{betTypes}</Text_C>
            </View>
          )}
        </View>
      </View>

      {photoFinishType === 'icon' ? mobilePhotoAndReplay : desktopPhotoAndReplay}
    </View>
  )

  return isMobileLayout ? mobileLayout : desktopLayout
}

const breakpointStyles = createStyles({
  raceHeader: {
    gap: 10
  },
  headerTop: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    columnGap: 32
  },
  raceNumber: {
    borderRadius: 100,
    backgroundColor: 'black',
    width: 40,
    height: 40,
    justifyContent: 'center'
  },
  raceNumberText: {
    ...textSizes.size5,
    color: 'white',
    textAlign: 'center',
    alignSelf: 'center'
  },
  redText: {
    color: colors.red
  },
  distanceText: {
    base: {
      ...textSizes.size3
    },
    [desktopBreakpoint]: {
      fontWeight: '600SemiBold'
    }
  },
  headerLeft: { gap: 10, alignItems: 'center' },
  headerRight: { flex: 1 },
  headerBottom: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center'
  },
  info: {
    base: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      flex: 1
    },
    [desktopBreakpoint]: {
      columnGap: 16,
      color: colors.gray600
    }
  },
  infoText: {
    color: colors.gray600
  },
  heading: {
    [desktopBreakpoint]: {
      marginBottom: 6
    }
  },
  rightContainer: {
    marginLeft: 'auto',
    alignItems: 'flex-end',
    gap: 30
  },
  bettingTypes: {
    backgroundColor: colors.gray600,
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 4
  },
  bettingTypesText: {
    color: 'white'
  },

  expandText: {
    alignSelf: 'flex-end',
    ...textSizes.size1,
    fontWeight: '600SemiBold'
  },
  trackRecordContainer: {
    flexDirection: 'row',
    marginVertical: 10
  },
  trackRecordLabel: {
    ...textSizes.size1
  },
  trackRecord: {
    fontWeight: '600SemiBold',
    ...textSizes.size1
  },
  resultPlaces: {
    fontWeight: '700Bold',
    textTransform: 'uppercase'
  },
  photoAndReplayContainer: {
    aspectRatio: 7.2,
    flexDirection: 'row',
    gap: 30,
    maxWidth: '100%'
  },
  photoAndReplayButtons: {
    flexDirection: 'row',
    gap: 30
  },

  replayButtonContainer: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center'
  },
  replayPlayIcon: {
    width: 28,
    height: 28
  },
  raceOffText: {
    fontSize: 10,
    fontStyle: 'italic',
    fontWeight: '600SemiBold'
  }
})
