import { reduceResultPlaces, removeLeadingSlash, toCapitalCase } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { fetchData } from '../api-fetch-request'
import { PuntersCornerFetchResponse } from '../data-types/fetch-response-types/punters-corner-response.type'
import { meetingStatusMap, raceStatusMap } from '../data-types/general-data-types.type'
import { MeetingBasic, RaceBasic } from '../data-types/local-data-types/meeting-basic.type'

export type PuntersCornerResponse = {
  maxMeetingDate: Date
  meetings: MeetingPuntersCorner[]
}
export type MeetingPuntersCorner = Omit<MeetingBasic, 'races'> & {
  races: RacePuntersCorner[]
}
export type RacePuntersCorner = Omit<RaceBasic, 'hasReplay'> & {
  tips?: string
  analysis?: string
  runners: RunnerPuntersCorner[]
}

type RunnerPuntersCorner = {
  horseName: string
  horseSlug?: string
  barrier: string
  runnerNumber: number
  isScratched: boolean
  isLateScratching: boolean
  gearAdded?: string
  gearRemoved?: string
}

type GetPuntersCornerParams = {
  date: string
  useDesignMock?: boolean
}
export async function getPuntersCornerData({
  date,
  useDesignMock
}: GetPuntersCornerParams): Promise<PuntersCornerResponse> {
  if (useDesignMock) {
    throw 'no design mock'
  }

  const endpoint = `racing/list/detailed/${date}`

  return fetchData<PuntersCornerResponse, PuntersCornerFetchResponse>({
    endpoint,
    convertResponse
  })

  function convertResponse(
    puntersCornerPayload: PuntersCornerFetchResponse
  ): PuntersCornerResponse {
    const { max_meeting_date, meetings } = puntersCornerPayload
    const responseObj: PuntersCornerResponse = {
      maxMeetingDate: new Date(max_meeting_date),
      meetings: meetings.map((m) => {
        const {
          meeting_status,
          date,
          track,
          races,
          is_trial,
          day_night_twilight,
          analysis,
          tips,
          track_condition,
          weather
        } = m
        const meetingObj: MeetingPuntersCorner = {
          status: meetingStatusMap[meeting_status],
          date,
          tips: tips ?? undefined,
          analysis: analysis ?? undefined,
          trackCondition: track_condition ?? undefined,
          weather: weather ?? undefined,
          track: {
            name: toCapitalCase(track.desktop_display_name),
            shortName: track.mobile_display_name,
            slug: removeLeadingSlash(track.slug),
            state: !track.state ? undefined : (track.state.toUpperCase() as AustralianState),
            trackMapImage: undefined
          },
          races: races.map(
            ({
              distance,
              race_number,
              scheduled_start_time,
              race_status,
              result_places,
              runners
            }) => {
              return {
                distance,
                raceNumber: race_number,
                status: raceStatusMap[race_status],
                replayUrl: 'stub data',
                resultPlaces: result_places ? reduceResultPlaces(result_places) : undefined,
                startTime: new Date(scheduled_start_time),
                runners: runners.map((r) => {
                  const runner: RunnerPuntersCorner = {
                    horseName: r.horse_name,
                    horseSlug: r.horse_slug ? removeLeadingSlash(r.horse_slug) : undefined,
                    barrier: r.barrier,
                    runnerNumber: r.saddlecloth_number,
                    isScratched: r.is_scratching || r.is_late_scratching,
                    isLateScratching: r.is_late_scratching,
                    gearAdded: r.gear_added ?? undefined,
                    gearRemoved: r.gear_removed ?? undefined
                  }
                  return runner
                })
              }
            }
          ),
          isTrial: is_trial,
          dayPhaseLetter: day_night_twilight
        }
        return meetingObj
      })
    }
    return responseObj
  }
}
