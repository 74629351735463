import { Platform } from 'react-native'

// This method is used to save the current page in the browser history
// It should be called just before we navigate somewhere else, IF we want
// the user to come back to this page when pressing the back button.
//
// The default behaviour of the back button is to go back to the previous screen,
// see (URLMapper). That means for certain pages like IndividualRacecard,
// if we click through a say 3 individual racecards, and then press the back button,
// by default, we'll go back to the previous screen, which could be News for example.
//
// By calling savePageInHistory, pressing back will instead cycle back to the last racecard
// the user looked at.
export const savePageInHistory = () => {
  if (Platform.OS == 'web') history.pushState({}, '', window.location.pathname)
}
