import { Helmet } from 'react-helmet'

type MetaTagProps = {
  title: string
  description: string
}

export function Metadata({ title, description }: MetaTagProps): JSX.Element {
  return (
    <Helmet>
      <title>{title || 'Harness Racing'} | Australian Harness Racing</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}
