import { View } from 'react-native'
import { NewsArticle } from '../../../services/data/data-types/local-data-types/news.type'
import {
  breakpointIs,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { Separator_C } from '../../Base/Separator/Separator'
import { Card_C } from '../../Layout/Card/Card'
import { GapGrid_C } from '../../Layout/Grid/GapGrid'
import { NewsItemLarge_C } from './components/NewsItemLarge'
import { NewsItemMedium_C } from './components/NewsItemMedium'
import { NewsItemSmall_C } from './components/NewsItemSmall'
type Props = { articles: NewsArticle[] }
export function LatestNewsLayout_C({ articles }: Props) {
  const styles = useBreakpointStyles({ styles: latestNewsLayoutStyles })

  const featuredArticle = articles[0]
  const sidebarArticles = articles.slice(1, 5)
  const otherArticles = articles.slice(6)

  const isTabletLayout = breakpointIs(['large'])

  return (
    <>
      <Card_C style={styles.featuredNewsContainer}>
        {!!featuredArticle && (
          <NewsItemLarge_C
            style={{
              mainContainer: styles.featuredNews
            }}
            article={featuredArticle}
          />
        )}

        {sidebarArticles.length > 0 && (
          <View style={styles.smallFeaturedNewsContainer}>
            {sidebarArticles.map((article, i) => {
              const hideSeparator = i == 0 || (isTabletLayout && i == 1)
              return (
                <View key={article.id} style={styles.smallNewsItemWrapper}>
                  {!hideSeparator && <Separator_C style={{ marginTop: 0, marginBottom: 20 }} />}
                  <NewsItemSmall_C
                    article={article}
                    style={{ mainContainer: styles.newsContainer }}
                  />
                </View>
              )
            })}
          </View>
        )}
      </Card_C>

      {otherArticles.length > 0 && (
        <Card_C>
          <GapGrid_C
            gridBreakpointConfig={{
              base: { columns: 2 },
              medium: { columns: 3 },
              xlarge: { columns: 4 }
            }}
            columnGap={16}
            rowGap={32}
            cells={otherArticles.map((article, idx) => (
              <NewsItemMedium_C key={idx} article={article} />
            ))}
          />
        </Card_C>
      )}
    </>
  )
}

export const latestNewsLayoutStyles = createStyles({
  featuredNewsContainer: {
    base: {
      gap: 30
    },
    xlarge: {
      flexDirection: 'row',
      alignItems: 'flex-start'
    }
  },
  featuredNews: {
    base: {
      flex: 1
    },
    xlarge: {
      marginBottom: 0
    }
  },
  smallFeaturedNewsContainer: {
    base: {
      rowGap: 15
    },
    large: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    xlarge: {
      width: 390
    }
  },
  smallNewsItemWrapper: {
    base: {},
    large: {
      width: '50%'
    },
    xlarge: {
      width: '100%'
    }
  },
  newsContainer: {
    base: {}
  }
})
