/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'

import { filterExpiredRaces, getExpiryTime } from '../../../helper-functions'
import {
  LatestRace,
  getNextToGo
} from '../../../services/data/request-functions/latest-races-request'
import { createStyles } from '../../../services/styles/breakpoint-styles.service'
import { colors, presets, stateColor } from '../../../styles/colors'
import { shortDate } from '../../../utils/date-time'
import { Icon_C } from '../../Base/Icon/Icon'
import { Link_C } from '../../Base/Link/Link'
import { PressableStyleExtension } from '../../Base/Pressable/Pressable'
import { Text_C } from '../../Base/Text/Text'
import { RaceCountdownText_C } from '../RaceCountdownText/RaceCountdownText'
import { SilkImage_C } from '../SilkImage/SilkImage'

const maxRaces = 12
const minAmountForRefetch = 6
const expireMinsAfterRaceStart = 5

export function NextRaces_C({ initialRaces }: { initialRaces?: LatestRace[] }) {
  const [nextRaces, setNextRaces] = useState<LatestRace[]>([])

  const raceExpireTimers = useRef<any[]>([])

  useEffect(() => {
    let latestRaces: LatestRace[]

    getAndSetLatestRaces()

    function getAndSetLatestRaces() {
      if (!nextRaces && initialRaces) {
        latestRaces = filterExpiredRaces({
          races: initialRaces,
          extraMins: expireMinsAfterRaceStart
        })
        setNextRaces(latestRaces)
        removeAfterStart(latestRaces)
      } else {
        getNextToGo({ limit: maxRaces }).then(({ races }) => {
          clearAllTimers()
          latestRaces = filterExpiredRaces({
            races,
            extraMins: expireMinsAfterRaceStart
          })
          setNextRaces(latestRaces)
          removeAfterStart(latestRaces)
        })
      }
    }

    function removeAfterStart(races: LatestRace[]) {
      races.forEach((race) => {
        const expireTime = getExpiryTime({
          date: race.startTime,
          extraMins: expireMinsAfterRaceStart
        })

        raceExpireTimers.current.push(setTimeout(onRaceExpire, expireTime))

        function onRaceExpire() {
          if (latestRaces.length > minAmountForRefetch) {
            latestRaces = filterExpiredRaces({
              races: latestRaces,
              extraMins: expireMinsAfterRaceStart
            })
            setNextRaces(latestRaces)
          } else {
            getAndSetLatestRaces()
          }
        }
      })
    }

    function clearAllTimers() {
      raceExpireTimers.current.forEach(clearTimeout)
    }
    return clearAllTimers
  }, [initialRaces])
  return (
    <View style={styles.container}>
      {!nextRaces.length && <Text_C>Loading...</Text_C>}
      {nextRaces.splice(0, minAmountForRefetch)?.map((race) => (
        <Fragment key={race.trackName + race.startTime}>
          <View style={styles.divider} />
          <Link_C
            navigateTo={[
              'Meeting',
              {
                raceNumber: `${race.raceNumber}`,
                trackSlug: race.trackSlug,
                date: shortDate(race.date),
                dayPhaseLetter: race.dayPhaseLetter,
                type: 'meeting'
              }
            ]}
            style={linkStyle}
          >
            <View style={styles.raceContainer}>
              <View
                style={[styles.raceNumberBackground, { backgroundColor: stateColor[race.state] }]}
              >
                <Text_C style={styles.raceNumber}>R{race.raceNumber}</Text_C>
              </View>
              <RaceCountdownText_C startTime={new Date(race.startTime)} />
            </View>

            <View style={styles.trackContainer}>
              <Text_C numberOfLines={1} ellipsizeMode="head" style={styles.trackName}>
                {race.trackNameShort}
              </Text_C>
              <Text_C style={styles.trackState}>{race.state}</Text_C>
              <Text_C style={styles.trackDistrance}>{race.distance}m</Text_C>
            </View>

            <View style={styles.runnerContainer}>
              {!!race.odds && (
                <>
                  <Text_C style={styles.runnerNumber}>{race.clothNumber}</Text_C>

                  <SilkImage_C silk={race.silk} style={{ cropContainer: { width: 28 } }} />

                  <View style={styles.runnerOdds}>
                    <Text_C style={styles.oddsText}>{race.odds}</Text_C>
                  </View>
                </>
              )}

              <Icon_C name="chevron" color={presets.border} style={styles.icon} />
            </View>
          </Link_C>
        </Fragment>
      ))}
    </View>
  )
}

const linkStyle: PressableStyleExtension = {
  elem: {
    base: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10,
      borderTopColor: presets.border,
      padding: 5
    },
    hovered: {
      backgroundColor: colors.gray100
    }
  }
}
const styles = createStyles({
  container: {
    marginTop: 10
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: presets.border
  },
  raceContainer: {
    alignItems: 'center',
    gap: 5,
    width: 50
  },
  raceNumberBackground: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 100
  },
  raceNumber: {
    color: 'white',
    fontWeight: '600SemiBold'
  },
  trackContainer: {
    alignItems: 'flex-start'
  },
  trackName: {
    maxWidth: 120,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold',
    fontSize: 14,
    color: colors.gray700
  },
  trackState: {
    fontSize: 10
  },
  trackDistrance: {
    marginTop: 2,
    fontSize: 12
  },
  icon: {
    width: 25,
    height: 25,
    transform: [{ rotate: `${-90}deg` }]
  },
  runnerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    alignItems: 'center',
    columnGap: 5
  },
  runnerNumber: {
    fontSize: 13
  },
  runnerOdds: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 25,
    backgroundColor: colors.gray100,
    borderRadius: 5
  },
  oddsText: {
    fontSize: 14,
    fontWeight: '600SemiBold'
  }
})
