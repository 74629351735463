/* eslint-disable react/jsx-key */
import { View } from 'react-native'
import { BlackTypePerformance } from '../../../services/data/data-types/general-data-types.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../services/styles/dependencies/style-constants'
import { Heading_C } from '../../Base/Heading/Heading'
import { ProgressBar_C } from '../../Base/ProgressBar/ProgressBar'
import { TableStyles, Table_C } from '../../Base/Table/Table'
import { TableHeading, TableRow } from '../../Base/Table/table.type'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'

type Props = { blackTypePerformance: BlackTypePerformance[] }
export function BlackTypeRaces_C({ blackTypePerformance }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const tableHeadings: TableHeading[] = [
    { content: 'Class', colWidth: { flex: 2 } },
    { content: 'Starts', colWidth: { flex: 1 } },
    { content: 'Wins', colWidth: { flex: 4 } },
    { content: 'Places', colWidth: { flex: 4 } },
    { content: 'Race Stakes', colWidth: { flex: 2 } }
  ]

  const tableRows: TableRow[] = blackTypePerformance.map((group) => {
    const { class: groupClass, starts, wins, winPercent, stakes, places, placePercent } = group

    const groupRow: TableRow = {
      cells: [
        groupClass,
        starts,
        <View
          style={{
            flexDirection: 'row',
            gap: 4,
            justifyContent: 'space-between'
          }}
        >
          <Text_C numberOfLines={1} style={{ fontWeight: '600SemiBold' }}>
            {wins} ({winPercent || 0}%)
          </Text_C>

          <ProgressBar_C
            styles={{
              width: 100,
              flex: 0,
              flexBasis: 'auto'
            }}
            percent={winPercent || 0}
          />
        </View>,
        <View
          style={{
            flexDirection: 'row',
            gap: 4,
            justifyContent: 'space-between'
          }}
        >
          <Text_C style={{ fontWeight: '600SemiBold' }}>
            {places} ({placePercent}%)
          </Text_C>

          <ProgressBar_C
            styles={{ width: 100, flex: 0, flexBasis: 'auto' }}
            percent={placePercent || 0}
          />
        </View>,
        stakes
      ]
    }

    return groupRow
  })

  return (
    <Card_C style={styles.racesCard}>
      <View style={styles.raceGroup}>
        <Heading_C styleType="h4" style={breakpointStyles.raceHeading}>
          Black Type Races
        </Heading_C>
        <Table_C
          styleType="lightHeaderBorders"
          headings={tableHeadings}
          rows={tableRows}
          styles={tableStyle}
        />
      </View>
    </Card_C>
  )
}

const breakpointStyles = createStyles({
  racesCard: {
    base: {
      gap: 40
    }
  },
  raceGroup: {
    base: {}
  },
  raceHeading: {
    base: { marginBottom: 16 },
    [tabletBreakpoint]: { marginBottom: 22 },
    [desktopBreakpoint]: { marginBottom: 30 }
  }
})

const tableStyle: TableStyles = {
  tableContainer: { base: { minWidth: 'auto', flex: 1, maxWidth: 1000 } },
  table: { width: '100%', minWidth: 700 }
}
