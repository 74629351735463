import { BreakpointName } from './breakpoint-style.type'
import { breakpointNames } from './style-constants'
import { useActiveBreakpoint } from './use-active-breakpoint'

/* breakpoint query */

export function breakpointAbove(compareBreakpoint: BreakpointName): boolean {
  const activeBreakpoint = useActiveBreakpoint()
  return breakpointNames.indexOf(activeBreakpoint) >= breakpointNames.indexOf(compareBreakpoint)
}
export function breakpointBelow(compareBreakpoint: BreakpointName): boolean {
  const activeBreakpoint = useActiveBreakpoint()
  return breakpointNames.indexOf(activeBreakpoint) < breakpointNames.indexOf(compareBreakpoint)
}
export function breakpointIs(compareBreakpoints: BreakpointName[]): boolean {
  const activeBreakpoint = useActiveBreakpoint()
  return compareBreakpoints.some((breakpoint) => breakpoint == activeBreakpoint)
}
