import { useMemo, useState } from 'react'
import { Pressable, View } from 'react-native'

import { LatestRace } from '../../../services/data/request-functions/latest-races-request'
import { createStyles } from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { NextRaces_C } from './NextRaces'
import { ResultedRaces } from './ResultedRaces'

type ActiveTab = 'next' | 'results'

export function LatestRaces_C({ nextRaces }: { nextRaces: LatestRace[] }) {
  const [activeTab, setActiveTab] = useState<ActiveTab>('next')
  const { heading } = getElements()

  const nextRacesElem = useMemo(() => {
    return <NextRaces_C initialRaces={nextRaces} />
  }, [])
  const resultRacesElem = useMemo(() => {
    return activeTab == 'results' ? <ResultedRaces /> : <></>
  }, [activeTab])
  return (
    <Card_C>
      {heading}
      {activeTab === 'next' && nextRacesElem}
      {activeTab === 'results' && resultRacesElem}
    </Card_C>
  )

  function getElements() {
    const button = (tab: ActiveTab, title: string) => (
      <Pressable onPress={() => setActiveTab(tab)} style={{ width: 100 }}>
        <Text_C
          style={{
            ...styles.title,
            fontWeight: activeTab === tab ? '700Bold' : '500Medium'
          }}
        >
          {title}
        </Text_C>
      </Pressable>
    )

    const heading = (
      <View style={styles.headerContainer}>
        {button('next', 'next to go')}
        <View style={styles.divider} />
        {button('results', 'results')}
      </View>
    )
    return { heading }
  }
}

const styles = createStyles({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10
  },
  title: {
    ...textSizes.size4,
    color: colors.blue,
    textTransform: 'uppercase'
  },
  divider: {
    width: 2,
    height: 20,
    backgroundColor: presets.border
  }
})
