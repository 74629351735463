import { useContext } from 'react'
import { View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../../../services/styles/dependencies/style-constants'
import { Heading_C } from '../../../../../Base/Heading/Heading'
import { Separator_C } from '../../../../../Base/Separator/Separator'
import { LabelValuePair, LabelValueTable_C } from '../../../../../Base/Table/LabelValueTable'
import { TrackContext } from '../../../Track'

export function TrackDataTable_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { activeTrack } = useContext(TrackContext)

  const {
    fieldLimits,
    circumference,
    straights,
    radiusOnTurns,
    camber,
    sprintLane,
    frontLine,
    mobileDistances,
    standDistances
  } = activeTrack
  const { mobile: mobileLimit, standing: standingLimit } = fieldLimits

  const trackData: LabelValuePair[] = [
    {
      label: 'Circumference:',
      value: circumference ? `${circumference}m` : 'N/A'
    },
    { label: 'Straights:', value: straights ? `${straights}m` : 'N/A' },
    {
      label: 'Radius On Turns:',
      value: radiusOnTurns ? `${radiusOnTurns}m` : 'N/A'
    },
    ...(!camber?.corners || !camber.straights
      ? []
      : [
          {
            label: 'Camber:',
            value: `${camber?.corners && `${camber.corners}% (corners)`}${
              camber?.corners && camber.straights && ', '
            } ${camber?.straights && `${camber.straights}% (straights)`}`
          }
        ]),
    { label: 'Sprint Lane:', value: sprintLane ? 'Yes' : 'No' },

    ...(!mobileLimit && !standingLimit
      ? []
      : [
          {
            label: 'Field Limits:',
            value: mobileLimit
              ? `${mobileLimit} (mobile)`
              : '' + mobileLimit && standingLimit
                ? ', '
                : '' + standingLimit
                  ? `${standingLimit} (standing)`
                  : ''
          }
        ]),

    ...(!frontLine.mobile && !frontLine.standing
      ? []
      : [
          {
            label: 'Front Line:',
            value: frontLine.mobile
              ? `${frontLine.mobile} (mobile)`
              : '' + frontLine.mobile && frontLine.standing
                ? ', '
                : '' + frontLine.standing
                  ? `${frontLine.standing} (stand)`
                  : ''
          }
        ]),
    {
      label: 'Mobile Distances :',
      value: !mobileDistances.length ? 'N/A' : mobileDistances.map((d) => `${d}m`).join(', ')
    },
    {
      label: 'Standing Distances',
      value: !standDistances.length ? 'N/A' : standDistances.map((d) => `${d}m`).join(', ')
    }
  ]
  return (
    <View style={[styles.section, styles.trackContainer]}>
      <Heading_C styleType="h2">Track Data</Heading_C>
      <Separator_C />
      <LabelValueTable_C
        data={trackData}
        style={{ container: { rowGap: 6 }, label: { width: 136 } }}
      />
    </View>
  )
}

const breakpointStyles = createStyles({
  section: {
    base: { marginHorizontal: 14 },
    large: { marginHorizontal: 0 }
  },
  trackContainer: {
    base: {},
    small: {
      flex: 1
    },
    [desktopBreakpoint]: {
      flex: 0,
      flexBasis: 'auto'
    },
    xxlarge: {
      flex: 1,
      flexBasis: '0%'
    }
  }
})
