/* eslint-disable react/jsx-key */
import { View } from 'react-native'
import { formatCurrency } from '../../../helper-functions'
import { TrainerDriverTopHorsesRun } from '../../../services/data/data-types/local-data-types/trainer-driver-runs'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { Heading_C } from '../../Base/Heading/Heading'
import { ProgressBar_C } from '../../Base/ProgressBar/ProgressBar'
import { TableStyles, Table_C } from '../../Base/Table/Table'
import { TableHeading, TableRow } from '../../Base/Table/table.type'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { LinkOrText_C } from '../LinkOrText'

type Props = {
  topHorsesRuns: TrainerDriverTopHorsesRun[]
}

export function TrainerDriverTopHorseRuns_C({ topHorsesRuns }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const upcomingHeadings: TableHeading[] = [
    { content: 'Horse', colWidth: { width: 170 } },
    { content: 'Starts', colWidth: { width: 80 } },
    { content: 'Wins', colWidth: { flex: 2 } },
    { content: 'Places', colWidth: { flex: 2 } },
    'Stakes'
  ]

  const upcomingRows = topHorsesRuns.flatMap((race): TableRow => {
    const { name, places, slug, stakes, starts, wins, winPercent, placePercent } = race
    return {
      cells: [
        <LinkOrText_C
          {...{
            name,
            navigateTo: !slug
              ? undefined
              : [
                  'Horse',
                  {
                    horseSlug: slug
                  }
                ]
          }}
        />,
        starts,
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            gap: 10,
            justifyContent: 'space-between'
          }}
        >
          <Text_C
            numberOfLines={1}
            style={{
              flex: 1
            }}
          >{`${wins} (${winPercent}%)`}</Text_C>
          <ProgressBar_C percent={winPercent} />
        </View>,
        <View style={{ flexDirection: 'row', flex: 1, gap: 10 }}>
          <Text_C
            numberOfLines={1}
            style={{
              flex: 1
            }}
          >{`${places} (${placePercent}%)`}</Text_C>
          <ProgressBar_C percent={placePercent} />
        </View>,
        formatCurrency(stakes)
      ]
    }
  })

  return (
    <Card_C style={styles.racesCard}>
      <View style={styles.raceGroup}>
        <Heading_C styleType="h4" style={breakpointStyles.raceHeading}>
          Top Horses
        </Heading_C>
        <Table_C
          styleType="lightHeaderBorders"
          headings={upcomingHeadings}
          rows={upcomingRows}
          styles={tableStyle}
        />
      </View>
    </Card_C>
  )
}

const breakpointStyles = createStyles({
  racesCard: {
    base: {
      gap: 40
    }
  },
  raceGroup: {
    base: {}
  },
  raceHeading: {
    base: { marginBottom: 16 }
  }
})

const tableStyle: TableStyles = {
  tableContainer: { base: { minWidth: 'auto', flex: 1, maxWidth: 1000 } },
  table: { width: '100%', minWidth: 700 }
}
