import { useContext } from 'react'
import { Card_C } from '../../../Layout/Card/Card'
import { SimpleContainer_C } from '../../../Layout/SimpleContainer/SimpleContainer'
import { GearChanges_C } from '../../../Partials/_meeting-components/GearChanges'
import { MeetingContext } from '../Meeting'

export function GearChangesTab_C() {
  const { activeMeetingPuntersCorner } = useContext(MeetingContext)

  return (
    <Card_C>
      <SimpleContainer_C>
        {/* non-null assertion because tab is hidden otherwise */}
        <GearChanges_C races={activeMeetingPuntersCorner!.races} />
      </SimpleContainer_C>
    </Card_C>
  )
}
