/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image, ImageStyle, StyleProp, Pressable, View } from 'react-native'

import HRA from '../../../../assets/images/state-logos/HRA.png'
import NSW from '../../../../assets/images/state-logos/NSW.png'
import QLD from '../../../../assets/images/state-logos/QLD.png'
import SA from '../../../../assets/images/state-logos/SA.png'
import TAS from '../../../../assets/images/state-logos/TAS.png'
import VIC from '../../../../assets/images/state-logos/VIC.png'
import WA from '../../../../assets/images/state-logos/WA.png'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../../services/styles/dependencies/style-constants'
import { AustralianState } from '../../../../types/map.type'

// NOTE: Each state logo should be an SVG file, but for now we're using this PNG
// By displaying the logos, hopefully it will push them along to deliver their logos to us

type LogoConfig = {
  source: any
  aspectRatio: number
  state: null | AustralianState
  link: string
}
const logosConfig: LogoConfig[] = [
  {
    source: HRA,
    aspectRatio: 2.81,
    state: null,
    link: 'https://www.harness.org.au/hra.cfm'
  },
  {
    source: NSW,
    aspectRatio: 2.8,
    state: 'NSW',
    link: 'https://www.hrnsw.com.au'
  },
  {
    source: QLD,
    aspectRatio: 2.81,
    state: 'QLD',
    link: 'https://www.racingqueensland.com.au/racing'
  },
  {
    source: SA,
    aspectRatio: 2.81,
    state: 'SA',
    link: 'https://satrots.com.au'
  },
  {
    source: TAS,
    aspectRatio: 2.81,
    state: 'TAS',
    link: 'https://tasracing.com.au'
  },
  {
    source: VIC,
    aspectRatio: 2.81,
    state: 'VIC',
    link: 'https://www.thetrots.com.au'
  },
  {
    source: WA,
    aspectRatio: 1.09,
    state: 'WA',
    link: 'https://racingwa.com.au/racing/harness-racing'
  }
]

export function StateLogos_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyle })
  return (
    <View style={styles.stateLogosContainer}>
      {logosConfig.map(({ source, aspectRatio, state, link }, i) => (
        <Pressable onPress={() => handleStateLogoPress(link)} key={i} style={styles.stateLogo}>
          <Image
            source={source}
            style={[{ aspectRatio }, state === 'WA' && styles.stateLogoWA] as StyleProp<ImageStyle>}
          />
        </Pressable>
      ))}
    </View>
  )

  function handleStateLogoPress(link: string) {
    window.open(link, '_blank')
  }
}

// only on desktop so no need for breakpointstyles
const breakpointStyle = createStyles({
  stateLogosContainer: {
    base: {
      flexDirection: 'row',
      width: '90%',
      marginBottom: 26,
      maxWidth: 900
    },
    [tabletBreakpoint]: {
      gap: 4,
      marginBottom: 40
    }
  },
  stateLogo: {
    flex: 1
  },
  stateLogoWA: {
    base: {
      width: '39%',
      left: 15
    },
    [tabletBreakpoint]: {
      left: 30
    }
  }
})
