import { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../../../../global-context'
import { desaturateColor } from '../../../../../../helper-functions'
import { BlackBookHorse } from '../../../../../../services/data/data-types/local-data-types/blackbook.type'
import {
  BlackBookHorsesResponse,
  deleteBlackBookItem,
  updateBlackBookItem
} from '../../../../../../services/data/request-functions/black-book-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { Button_C } from '../../../../../Base/Button/Button'
import { EditField_C } from '../../../../../Base/EditField/EditField'
import { Heading_C } from '../../../../../Base/Heading/Heading'
import { PressableStyleExtension } from '../../../../../Base/Pressable/Pressable'
import { Table_C, TableStyles } from '../../../../../Base/Table/Table'
import { TableHeading, TableRow } from '../../../../../Base/Table/table.type'
import { Text_C } from '../../../../../Base/Text/Text'
import { LinkOrText_C } from '../../../../../Partials/LinkOrText'
import {
  PerPageOption,
  PerPageSelect
} from '../../../../../Partials/Pagination/components/PerPageSelect'
import { Pagination_C } from '../../../../../Partials/Pagination/Pagination'

type Props = {
  horsesResponse: BlackBookHorsesResponse
  onHorsesPageChange: (params: { pageNumber?: number; perPage?: PerPageOption }) => void
  onUpdated: () => void
}

export function BlackbookHorsesTable_C({ horsesResponse, onUpdated, onHorsesPageChange }: Props) {
  const { horses, pagination } = horsesResponse
  const { openModal, closeModal, authToken } = useContext(GlobalContext)
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const [rows, setRows] = useState<TableRow[]>()

  const tableHeadings: TableHeading[] = [
    { content: 'Horse', colWidth: { width: 200 } },
    'Comment',
    { content: 'Delete', colWidth: { width: 80 } }
  ]

  useEffect(() => {
    const rows: TableRow[] = horses.map(makeBlackBookItemRow)
    setRows(rows)
  }, [horsesResponse])

  return (
    <View>
      {rows?.length ? (
        <View style={{ gap: 20 }}>
          <PerPageSelect
            initialSelection={pagination?.perPage}
            onSelect={(perPage) => onHorsesPageChange({ perPage })}
          />
          {pagination && (
            <Pagination_C
              options={{
                infoSide: 'left'
              }}
              {...pagination}
              onPageChange={(pageNumber) =>
                onHorsesPageChange({
                  pageNumber,
                  perPage: pagination.perPage
                })
              }
            />
          )}
          <Table_C
            styleType="lightHeaderBorders"
            headings={tableHeadings}
            rows={rows}
            styles={tableStyle}
          />
          {pagination && (
            <Pagination_C
              options={{
                infoSide: 'left'
              }}
              {...pagination}
              onPageChange={(pageNumber) =>
                onHorsesPageChange({
                  pageNumber,
                  perPage: pagination.perPage
                })
              }
            />
          )}
        </View>
      ) : (
        <Text_C style={{ fontStyle: 'italic' }}>
          There are currently no horses in your Blackbook
        </Text_C>
      )}
    </View>
  )

  function makeBlackBookItemRow(blackbookItem: BlackBookHorse): TableRow {
    const { comment, id, horse, useNotifications } = blackbookItem

    const row: TableRow = {
      cells: [
        {
          style: { justifyContent: 'flex-start' },
          content: (
            <LinkOrText_C
              name={horse.name}
              newTab
              linkUrl={!horse.slug ? undefined : `horse/${horse.slug}`}
              linkStyle={{
                text: {
                  base: styles.runner
                }
              }}
            />
          )
        },

        <View key={id} style={styles.valueContainer}>
          <EditField_C
            numberOfLines={3}
            {...{
              value: comment ?? '',
              onChange: (comment) => onCommentUpdate(blackbookItem, comment)
            }}
          />
        </View>,
        {
          style: { alignItems: 'center', justifyContent: 'flex-start' },
          content: (
            <Button_C
              styleType="iconOnly"
              style={{
                elem: { width: 18 },
                text: {
                  base: { color: desaturateColor(colors.red, 1.1) },
                  hovered: { color: colors.red }
                }
              }}
              icon={{
                name: 'trash',
                style: { height: 20 },
                colorStroke: false,
                colorFill: true,
                useParentTextColor: true
              }}
              onPress={() =>
                openModal(
                  <View style={{ gap: 10 }}>
                    <Heading_C styleType="h1">Confirm Delete</Heading_C>
                    <Text_C>{`Are you sure you want to delete ${blackbookItem.horse.name} from your Blackbook?`}</Text_C>
                    <View style={styles.buttonsContainer}>
                      <Button_C
                        styleType="fillButton"
                        style={confirmDeleteButton}
                        onPress={() => confirmDelete(blackbookItem)}
                      >
                        Delete
                      </Button_C>
                    </View>
                  </View>
                )
              }
            />
          )
        }
      ]
    }
    return row
  }

  function onCommentUpdate(blackbookItem: BlackBookHorse, comment: string) {
    updateBlackBookItem({
      authToken: authToken!,
      blackbookItemId: blackbookItem.id,
      comment,
      useNotifications: blackbookItem.useNotifications
    }).then(onUpdated)
  }
  function confirmDelete(blackbookItem: BlackBookHorse) {
    deleteBlackBookItem({
      authToken: authToken!,
      blackbookItemId: blackbookItem.id
    }).then(onUpdated)
    closeModal()
  }
}

const breakpointStyles = createStyles({
  heading: { marginBottom: 20 },
  iconContainer: { flexDirection: 'row', gap: 10, alignItems: 'center' },
  horizontalDivider: {
    height: 10,
    width: 1,
    backgroundColor: presets.border,
    marginHorizontal: 6
  },
  valueContainer: {
    minWidth: 180,
    columnGap: 20,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  buttonsContainer: {
    marginTop: 20,
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'flex-end'
  },
  runner: {
    base: {
      ...textSizes.size4,
      fontWeight: '600SemiBold',
      marginRight: 4
    }
  }
})

const tableStyle: TableStyles = {
  tableContainer: { width: 'auto', maxWidth: '100%' },
  table: { width: '100%', minWidth: 500 }
}

const confirmDeleteButton: PressableStyleExtension = {
  elem: {
    base: { backgroundColor: desaturateColor(colors.red, 0.8) },
    hovered: { backgroundColor: colors.red }
  }
}
