import { Arc, DefaultArcObject, arc } from 'd3-shape'
import { Fragment } from 'react'
import { View } from 'react-native'
import Svg, { G, Path, Text, TextPath } from 'react-native-svg'
import tinycolor from 'tinycolor2'
import { desaturateColor } from '../../../helper-functions'
import { HexColor } from '../../../helper-types'
import { useActiveBreakpoint } from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { textBaseStyle } from '../../Base/Text/Text'

type GraphDataItem = {
  label: string
  percent: number
}

type Props = {
  dataItems: GraphDataItem[]
}

const rootColor: HexColor = presets.primary

export function RadialGraph_C({ dataItems }: Props) {
  const bp = useActiveBreakpoint()

  const size = bp == 'xxlarge' ? 'large' : bp == 'xlarge' ? 'small' : 'large'

  const arcThickness = 23
  const arcGap = 3
  const radius = size == 'large' ? 40 : 10
  const svgSize = (radius + dataItems.length * (arcThickness + arcGap)) * 2
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const arcGenerator: Arc<any, DefaultArcObject> = arc()

  const arcs = dataItems.map((item, index) => {
    const arcRadius = radius + index * (arcThickness + arcGap) // Increase the radius for each concentric arc
    const minPercent = size == 'large' ? 15 : 25 //so label isn't on grey bg
    const arcPercentage = item.percent > minPercent ? item.percent : minPercent

    const endAngle = (arcPercentage / 100) * 2 * Math.PI
    const endCircle = 2 * Math.PI

    const arcData: DefaultArcObject = {
      innerRadius: arcRadius,
      outerRadius: arcRadius + arcThickness,
      startAngle: 0,
      endAngle
    }

    const restData: DefaultArcObject = {
      innerRadius: arcRadius,
      outerRadius: arcRadius + arcThickness,
      startAngle: endAngle + arcGap / arcRadius,
      endAngle: endCircle - arcGap / arcRadius
    }

    const textPathData: DefaultArcObject = {
      innerRadius: arcRadius + arcThickness * 0.2,
      outerRadius: arcRadius + arcThickness * 0.25,
      startAngle: 0,
      endAngle: endCircle - arcGap / arcRadius
    }

    const arcDPath = arcGenerator(arcData)
    const restOfArcDPath = arcGenerator(restData)
    const textPath = arcGenerator(textPathData)

    return {
      percent: item.percent,
      label: item.label,
      mainArcPath: arcDPath,
      restOfArcDPath,
      textPath
    }
  })

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
      <Svg width={svgSize} height={svgSize}>
        {arcs.map((arc, index) => {
          const arcColor = desaturateColor(rootColor, index)
          const colorInstance = tinycolor(arcColor)
          const lightness = colorInstance.getLuminance()
          const fontColor = lightness < 0.4 ? 'white' : colors.gray700

          return (
            <Fragment key={index}>
              <G transform={`translate(${svgSize / 2}, ${svgSize / 2})`}>
                <Path d={arc.mainArcPath || undefined} fill={arcColor} />
                <Path d={arc.restOfArcDPath || undefined} fill={colors.gray200} />
              </G>
              <G transform={`translate(${svgSize / 2}, ${svgSize / 2})`}>
                <Path id={`textArc${index}`} d={arc.textPath || undefined} fill={'transparent'} />

                <Text
                  fill={fontColor}
                  {...(size == 'large'
                    ? {
                        fontSize: 14,
                        letterSpacing: 1
                      }
                    : {
                        fontSize: 10,
                        letterSpacing: -1
                      })}
                  fontFamily="Arial"
                  textAnchor="start"
                >
                  <TextPath startOffset={size == 'large' ? 6 : 2} href={`#textArc${index}`}>
                    {arc.percent}%
                  </TextPath>
                </Text>

                <Text
                  fill={textBaseStyle.color}
                  {...(size == 'large'
                    ? {
                        fontSize: 14
                      }
                    : {
                        fontSize: 10
                      })}
                  fontFamily="Arial"
                  textAnchor="end"
                >
                  <TextPath startOffset={'49.5%'} href={`#textArc${index}`}>
                    {arc.label}
                  </TextPath>
                </Text>
              </G>
            </Fragment>
          )
        })}
      </Svg>
    </View>
  )
}
