import { mapObject } from '../../../helper-functions'
import {
  BreakpointStyles,
  GeneralNamedStyles,
  GetStyleType,
  NamedBreakpointStyles,
  NamedStyles,
  StyleType
} from './breakpoint-style.type'

export function createStyles<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends GeneralNamedStyles<T> | GeneralNamedStyles<any>,
  Name extends keyof T
>(namedStyleObj: T) {
  const mappedTypes: T = mapObject(
    namedStyleObj,
    (name: Name, styleObj: StyleType | BreakpointStyles) => {
      /* determine if style obj is nsbps or s */
      /* determine style type for each name (text/view/img)*/

      if ('base' in styleObj) {
        const { base } = styleObj
        type ThisType = GetStyleType<typeof base>
        const bps: BreakpointStyles<ThisType> = styleObj
        return { [name]: bps } as NamedBreakpointStyles<T>
      } else {
        type ThisType = GetStyleType<typeof styleObj>
        const s: ThisType = styleObj
        return { [name]: s } as NamedStyles<T>
      }
    }
  )

  return mappedTypes
}
