import {
  getBlackTypePerformance,
  getPlacementPercents,
  mapSilkObj,
  removeLeadingSlash,
  toCapitalized
} from '../../../helper-functions'
import { snakeToCamel } from '../../../helper-types'
import { fetchData } from '../api-fetch-request'
import {
  ResponseTrainerDiverPerformanceStats,
  TrainerFetchResponse
} from '../data-types/fetch-response-types/trainer-response.type'
import { Trainer } from '../data-types/local-data-types/trainer.type'
import { trainerCompare } from '../validation-compare-objects/trainer-compare'

export type TrainerResponse = {
  trainer: Trainer
}

type FetchTrainerParams = {
  trainerSlug: string
  useDesignMock?: boolean
}
export async function getTrainer({
  trainerSlug,
  useDesignMock
}: FetchTrainerParams): Promise<TrainerResponse> {
  if (useDesignMock) {
    throw 'no design mock'
  }

  const endpoint = `racing/trainers/profile/${trainerSlug}`
  return fetchData<TrainerResponse, TrainerFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: trainerCompare
  })

  function convertResponse(trainerPayload: TrainerFetchResponse): TrainerResponse {
    const {
      lifetime_summary,
      current_season_summary,
      last_season_summary,
      feature_wins,
      slug,
      primary_silk,
      secondary_silk,
      tertiary_silk,
      driver
    } = trainerPayload.trainer

    const trainer: Trainer = {
      isDriver: !!driver,
      ...snakeToCamel(trainerPayload.trainer),
      lifetimePerformance: {
        summary: statsToSummary(lifetime_summary),
        ...lifetime_summary,
        ...getPlacementPercents({
          starts: lifetime_summary.starts,
          wins: lifetime_summary.wins,
          places: lifetime_summary.seconds + lifetime_summary.thirds
        })
      },
      thisSeasonPerformance: {
        summary: statsToSummary(current_season_summary),
        ...current_season_summary,
        ...getPlacementPercents({
          starts: current_season_summary.starts,
          wins: current_season_summary.wins,
          places: current_season_summary.seconds + current_season_summary.thirds
        })
      },
      lastSeasonPerformance: {
        summary: statsToSummary(last_season_summary),
        ...last_season_summary,
        ...getPlacementPercents({
          starts: last_season_summary.starts,
          wins: last_season_summary.wins,
          places: last_season_summary.seconds + last_season_summary.thirds
        })
      },
      blackTypePerformance: getBlackTypePerformance(lifetime_summary),
      featureWins: feature_wins
        ? snakeToCamel(feature_wins).map((item) => ({
            ...item,
            horseSlug: removeLeadingSlash(item.horseSlug),
            dayPhaseLetter: item.dayNightTwilight,
            trackSlug: removeLeadingSlash(item.trackSlug),
            track: toCapitalized(item.track),
            blackType: toCapitalized(item.blackType)
          }))
        : undefined,
      slug: removeLeadingSlash(slug),
      primarySilk: mapSilkObj(primary_silk),
      secondarySilk: mapSilkObj(secondary_silk),
      tertiarySilk: mapSilkObj(tertiary_silk)
    }
    return { trainer }

    function statsToSummary(stats: ResponseTrainerDiverPerformanceStats): string {
      const { starts, seconds, thirds, wins } = stats
      return `${starts}-${wins}-${seconds}-${thirds}`
    }
  }
}
