import {
  getPlacementPercents,
  mapSilkObj,
  removeLeadingSlash,
  toCapitalized
} from '../../../helper-functions'
import { fetchData } from '../api-fetch-request'
import { TrainerRunsFetchResponse } from '../data-types/fetch-response-types/trainer-runs-response.type'
import {
  TrainerDriverLatestResult,
  TrainerDriverTopHorsesRun,
  TrainerDriverUpcomingRun
} from '../data-types/local-data-types/trainer-driver-runs'

export type TrainerRunsResponse = {
  trainer: {
    name: string
    shortName: string
    slug: string
  }
  upcomingRuns?: TrainerDriverUpcomingRun[]
  latestResults?: TrainerDriverLatestResult[]
  topHorsesRuns?: TrainerDriverTopHorsesRun[]
}

type GetTrainerRunsParams = {
  trainerSlug: string
  useDesignMock?: boolean
}
export async function getTrainerRuns({
  trainerSlug,
  useDesignMock
}: GetTrainerRunsParams): Promise<TrainerRunsResponse> {
  if (useDesignMock) {
    throw 'no design mock'
  }

  const endpoint = `racing/trainers/runs/${trainerSlug}`

  return fetchData<TrainerRunsResponse, TrainerRunsFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(trainerRunsPayload: TrainerRunsFetchResponse): TrainerRunsResponse {
    const {
      trainer: { display_name, mobile_display_name, slug },
      previous_runs,
      top_horses,
      upcoming_runs
    } = trainerRunsPayload
    const trainerRunsResponse: TrainerRunsResponse = {
      trainer: {
        name: display_name,
        shortName: mobile_display_name,
        slug: removeLeadingSlash(slug)
      },
      upcomingRuns: upcoming_runs?.map((r) => {
        const {
          barrier,
          day_night_twilight,
          horse,
          meeting_date,
          race,
          saddlecloth_number,
          silk,
          track,
          track_slug,
          trainer_starts_on_horse,
          horse_slug,
          is_trial
        } = r
        const { wins, wins_percent, places, places_percent, starts } = trainer_starts_on_horse
        const run: TrainerDriverUpcomingRun = {
          barrier,
          dayPhaseLetter: day_night_twilight,
          isTrial: is_trial,
          statsOnHorse: {
            starts,
            wins,
            places,
            winPercent: wins_percent,
            placePercent: places_percent
          },
          horse: {
            name: horse,
            slug: horse_slug ? removeLeadingSlash(horse_slug) : undefined
          },
          meetingDateString: meeting_date,
          raceNumber: race,
          runnerNumber: saddlecloth_number ?? undefined,
          silk: silk ? mapSilkObj(silk) : undefined,
          track: {
            name: toCapitalized(track),
            slug: removeLeadingSlash(track_slug)
          }
        }
        return run
      }),

      latestResults: previous_runs?.map((r) => {
        const {
          barrier,
          day_night_twilight,
          horse,
          meeting_date,
          race,
          saddlecloth_number,
          silk,
          track,
          track_slug,
          margin_metres,
          place,
          starting_price,
          horse_slug,
          has_replay
        } = r
        const run: TrainerDriverLatestResult = {
          place: place,
          marginMetres: margin_metres ?? undefined,
          startingPrice: starting_price ?? undefined,
          barrier,
          dayPhaseLetter: day_night_twilight,
          isTrial: false, //todo:
          hasReplay: has_replay,
          horse: {
            name: horse,
            slug: horse_slug ? removeLeadingSlash(horse_slug) : undefined
          },
          meetingDateString: meeting_date,
          raceNumber: race,
          runnerNumber: saddlecloth_number ?? undefined,
          silk: silk ? mapSilkObj(silk) : undefined,
          track: {
            name: toCapitalized(track),
            slug: removeLeadingSlash(track_slug)
          }
        }
        return run
      }),

      topHorsesRuns: top_horses?.map((h) => {
        const { name, places, slug, stakes, starts, wins } = h
        return {
          name,
          slug: removeLeadingSlash(slug),
          stakes: Number(stakes),
          ...getPlacementPercents({
            starts,
            wins,
            places
          })
        }
      })
    }

    return trainerRunsResponse
  }
}
