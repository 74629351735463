/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Text_C } from '../Base/Text/Text'

type Props<T extends any[]> = {
  fetchRequests: Promise<any>[]
  showLoading?: boolean
  view: (props: { responses: T }) => JSX.Element
}

export function ComponentWithFetch_C<T extends any[]>({
  fetchRequests,
  showLoading = true,
  view: Component
}: Props<T>) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const [responses, setResponses] = useState<T | null>(null)

  useEffect(() => {
    let isMounted = true
    const fetchDataAsync = async () => {
      setLoading(true)
      try {
        const results = await Promise.allSettled(fetchRequests)

        if (isMounted) {
          const fulfilledResults = results.map((result) => {
            if (result.status === 'fulfilled') {
              return result.value
            }
            throw result.reason
          })

          setResponses(fulfilledResults as T)
        } else throw 'component not mounted' //if not thrown here, finally will set loading to false and try and render response even though there isn't one, throwing error stops it
      } catch (err) {
        setError(err as Error)
      } finally {
        setLoading(false)
      }
    }

    fetchDataAsync()
    return () => {
      isMounted = false
    }
  }, [])

  const viewElem = <Component responses={responses!} />

  return loading || error ? (
    <View>
      {loading ? (
        showLoading && <Text_C>Loading... </Text_C>
      ) : error ? (
        <Text_C>An error has occurred... </Text_C>
      ) : (
        <></>
      )}
    </View>
  ) : (
    viewElem
  )
}
