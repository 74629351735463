/* eslint-disable no-unused-vars */
import { Image, ImageStyle, View } from 'react-native'
import profileIcon from '../../../assets/images/profile-icon.png'
import { formatCurrency } from '../../../helper-functions'
import { Driver } from '../../../services/data/data-types/local-data-types/driver.type'
import { Trainer } from '../../../services/data/data-types/local-data-types/trainer.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../services/styles/dependencies/style-constants'
import { colors } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { Button_C } from '../../Base/Button/Button'
import { Heading_C } from '../../Base/Heading/Heading'
import { Link_C } from '../../Base/Link/Link'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { BorderGrid_C } from '../../Layout/Grid/BorderGrid'
import { SilkImage_C } from '../SilkImage/SilkImage'

export function TrainerDriverDetails_C(props: { trainer: Trainer }): JSX.Element
export function TrainerDriverDetails_C(props: { driver: Driver }): JSX.Element
export function TrainerDriverDetails_C(props: { trainer: Trainer } | { driver: Driver }) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { age, blackTypePerformance, displayName, lifetimePerformance, location, debutSeason } =
    isTrainer(props) ? props.trainer : props.driver

  const { careerGrid, detailsGrid } = getElements()

  return (
    <Card_C>
      <View style={styles.topContainer}>
        <View style={styles.flexRow}>
          <Image
            style={styles.profileIcon as ImageStyle}
            resizeMode={'cover'}
            source={profileIcon}
          />
          <View style={styles.headingContainer}>
            <Heading_C styleType="h1">{displayName}</Heading_C>
            <Text_C style={styles.profileSubTitle}>
              {`${isTrainer(props) ? 'Trainer' : 'Driver'} Profile`}
            </Text_C>
          </View>
          {isTrainer(props) && (
            <SilkImage_C
              silk={props.trainer.primarySilk}
              style={{ cropContainer: { marginLeft: 6 } }}
            />
          )}
        </View>
        {((isTrainer(props) && props.trainer.isDriver) ||
          (!isTrainer(props) && props.driver.isTrainer)) && (
          <Link_C
            styleType="fillButton"
            navigateTo={
              isTrainer(props)
                ? ['Driver', { driverSlug: props.trainer.slug }]
                : ['Trainer', { trainerSlug: props.driver.slug }]
            }
          >
            {`${isTrainer(props) ? 'Driver' : 'Trainer'} Profile`}
          </Link_C>
        )}
      </View>
      {careerGrid}
      {detailsGrid}
    </Card_C>
  )

  function getElements() {
    const blackbookButton = (
      <Button_C
        styleType="fillButton"
        onPress={addRunnerToBlackbook}
        icon={{
          name: 'blackBookRibbon',
          style: { width: 15, position: 'absolute', top: 10, right: 14 },
          color: 'white',
          colorFill: true,
          colorStroke: false
        }}
        style={{
          text: {
            marginRight: 26
          }
        }}
      >
        Add To Blackbook
      </Button_C>
    )

    const careerGrid = (
      <BorderGrid_C
        gridBreakpointConfig={{
          base: { columns: 2 },
          medium: { columns: 4 }
        }}
        style={{ grid: styles.careerGrid }}
        cells={[
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Career</Text_C>
                <Text_C>{lifetimePerformance.summary}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Career Win %</Text_C>
                <Text_C>{lifetimePerformance.winPercent}%</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Career Place %</Text_C>
                <Text_C>{lifetimePerformance.placePercent}%</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Career Prizemoney</Text_C>
                <Text_C>{formatCurrency(lifetimePerformance.stakes)}</Text_C>
              </>
            )
          }
        ]}
      />
    )
    const group1 = blackTypePerformance.find((g) => g.class == 'Group 1')

    const detailsGrid = (
      <BorderGrid_C
        gridBreakpointConfig={{
          base: { columns: 3 }
        }}
        cells={[
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Location:</Text_C>
                <Text_C> {location}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Debut Season:</Text_C>
                <Text_C>{debutSeason ?? '---'}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Age:</Text_C>
                <Text_C>{age}</Text_C>
              </>
            )
          },

          {
            content: (
              <>
                <Text_C style={styles.labelText}>Total Starts:</Text_C>
                <Text_C>{lifetimePerformance.starts}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Total Wins:</Text_C>
                <Text_C>
                  {lifetimePerformance.wins} ({lifetimePerformance.winPercent}% )
                </Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Total Placings:</Text_C>
                <Text_C>
                  {lifetimePerformance.places} ({lifetimePerformance.placePercent}%)
                </Text_C>
              </>
            )
          },

          {
            content: (
              <>
                <Text_C style={styles.labelText}>Group 1 Starts:</Text_C>
                <Text_C>{group1 ? group1.starts : 'N/A'}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Group 1 Wins:</Text_C>
                <Text_C>{group1 ? `${group1.wins} (${group1.winPercent}%)` : 'N/A'}</Text_C>
              </>
            )
          },
          {
            content: (
              <>
                <Text_C style={styles.labelText}>Group 1 Placings:</Text_C>
                <Text_C>{group1 ? `${group1.places} (${group1.placePercent}%)` : 'N/A'}</Text_C>
              </>
            )
          }
        ]}
      ></BorderGrid_C>
    )

    return {
      careerGrid,
      blackbookButton,
      detailsGrid
    }
  }
  function addRunnerToBlackbook(): void {
    throw new Error('Function not implemented.')
  }

  function isTrainer(td: { trainer: Trainer } | { driver: Driver }): td is { trainer: Trainer } {
    return 'trainer' in td
  }
}

const breakpointStyles = createStyles({
  topContainer: {
    base: {
      alignItems: 'center',
      justifyContent: 'space-between',
      alignContent: 'center',
      flexDirection: 'row',
      marginBottom: 16,
      gap: 20
    },
    [tabletBreakpoint]: {
      marginBottom: 26
    },
    [desktopBreakpoint]: {
      marginBottom: 30
    }
  },
  flexRow: { base: { flexDirection: 'row', gap: 10 } },
  labelText: {
    base: { fontFamily: 'OpenSans', fontWeight: '700Bold' }
  },
  careerGrid: {
    base: {
      backgroundColor: colors.paleBlue,
      maxWidth: 800,
      marginBottom: 20
    },
    [tabletBreakpoint]: {
      marginBottom: 26
    },
    [desktopBreakpoint]: {
      marginBottom: 30
    }
  },
  profileIcon: {
    width: 50,
    height: 50
  },
  profileSubTitle: {
    fontWeight: '700Bold',
    ...textSizes.size1,
    color: colors.blue
  },
  headingContainer: {
    gap: 4,
    marginRight: 'auto'
  }
})
