import { removeLeadingSlash } from '../../../helper-functions'
import { convertAsset } from '../../../utils/assets'
import { fetchData } from '../api-fetch-request'
import { NewsArticleFetchResponse } from '../data-types/fetch-response-types/news-article-response.type'
import { NewsArticle } from '../data-types/local-data-types/news.type'

export type NewsArticleResponse = {
  article: NewsArticle
}

type NewsArticleFetchValues = {
  newsId: string
}

export async function getNewsArticle({
  newsId
}: NewsArticleFetchValues): Promise<NewsArticleResponse> {
  const endpoint = `news/articles/${newsId}`
  return fetchData<NewsArticleResponse, NewsArticleFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(payload: NewsArticleFetchResponse): NewsArticleResponse {
    const {
      id,
      published,
      headline,
      author,
      slug,
      content,
      categories,
      hero,
      similar_articles,
      state_logo
    } = payload.article
    return {
      article: {
        id: id,
        headline: headline,
        author: author ?? undefined,
        slug: removeLeadingSlash(slug),
        published: published,
        content: content,
        categories: categories,
        hero: !hero ? undefined : convertAsset(hero),
        pdf: payload.article.pdf,
        trotsUrl: payload.article?.trots_url,
        stateLogo: state_logo ? convertAsset(state_logo) : undefined,
        similarArticles: similar_articles?.map((a) => {
          const { author, categories, content, headline, id, published, slug, hero, state_logo } = a
          const article: NewsArticle = {
            id: id,
            headline: headline.trim(),
            author: author ?? undefined,
            slug: removeLeadingSlash(slug),
            pdf: payload.article.pdf,
            trotsUrl: payload.article.trots_url,
            published: published,
            categories: categories,
            content: content,

            stateLogo: state_logo ? convertAsset(state_logo) : undefined,
            hero: !hero ? undefined : convertAsset(hero)
          }
          return article
        })
      }
    }
  }
}
