import { Fragment } from 'react'
import { View } from 'react-native'

import { orderBy } from 'lodash'
import { reduceLatestResultPlaces, removeLeadingSlash } from '../../../helper-functions'
import {
  LatestResultsResponse,
  getLatestResults
} from '../../../services/data/request-functions/latest-races-request'
import { createStyles } from '../../../services/styles/breakpoint-styles.service'
import { colors, presets, stateColor } from '../../../styles/colors'
import { Icon_C } from '../../Base/Icon/Icon'
import { Link_C } from '../../Base/Link/Link'
import { PressableStyleExtension } from '../../Base/Pressable/Pressable'
import { Text_C } from '../../Base/Text/Text'
import { ComponentWithFetch_C } from '../../HigherOrderComponents/ComponentWithFetch'
import { SilkImage_C } from '../SilkImage/SilkImage'

export function ResultedRaces() {
  return (
    <ComponentWithFetch_C<[LatestResultsResponse]>
      fetchRequests={[getLatestResults({ limit: 6 })]}
      view={({ responses }) => {
        const [{ races }] = responses
        const orderedRaces = orderBy(races, 'startTime', 'desc')
        return (
          <View style={styles.container}>
            {orderedRaces.map((race, idx) => {
              const results = race.results.filter((result) => result.place !== '4')
              return (
                <Fragment key={idx}>
                  <View style={styles.divider} />
                  <Link_C
                    navigateTo={[
                      'Meeting',
                      {
                        raceNumber: `${race.raceNumber}`,
                        trackSlug: removeLeadingSlash(race.slug),
                        dayPhaseLetter: race.dayPhase,
                        date: race.date,
                        type: 'meeting'
                      }
                    ]}
                    style={linkStyle}
                  >
                    <View style={styles.raceContainer}>
                      <View
                        style={[
                          styles.raceNumberBackground,
                          { backgroundColor: stateColor[race.state] }
                        ]}
                      >
                        <Text_C style={styles.raceNumber}>R{race.raceNumber}</Text_C>
                      </View>

                      <View style={{ flexDirection: 'row', columnGap: 3 }}>
                        <Text_C style={{ fontSize: 12 }}>
                          {reduceLatestResultPlaces(results)}
                        </Text_C>
                      </View>
                    </View>

                    <View style={styles.trackContainer}>
                      <Text_C numberOfLines={1} ellipsizeMode="head" style={styles.trackName}>
                        {race.mobileName}
                      </Text_C>
                      <Text_C style={styles.trackState}>{race.state}</Text_C>
                      <Text_C style={styles.trackDistrance}>{race.distance}m</Text_C>
                    </View>

                    <View style={styles.runnerContainer}>
                      {results.map((result) =>
                        result.runners.map((runner, idx) => (
                          <View
                            key={idx}
                            style={{
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              columnGap: 3
                            }}
                          >
                            <Text_C style={styles.runnerNumber}>{runner.clothNumber}</Text_C>

                            <SilkImage_C
                              silk={runner.silk}
                              style={{
                                cropContainer: { width: 26 }
                              }}
                            />
                          </View>
                        ))
                      )}

                      <Icon_C name="chevron" color={presets.border} style={styles.icon} />
                    </View>
                  </Link_C>
                </Fragment>
              )
            })}
          </View>
        )
      }}
    />
  )
}

const linkStyle: PressableStyleExtension = {
  elem: {
    base: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10,
      borderTopColor: presets.border,
      padding: 5
    },
    hovered: {
      backgroundColor: colors.gray100
    }
  }
}

const styles = createStyles({
  container: {
    marginTop: 10
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: presets.border
  },
  raceContainer: {
    alignItems: 'center',
    gap: 5,
    width: 50
  },
  raceNumberBackground: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 100
  },
  raceNumber: {
    color: 'white',
    fontWeight: '600SemiBold'
  },
  trackContainer: {
    alignItems: 'flex-start'
  },
  trackName: {
    fontWeight: '600SemiBold',
    fontSize: 14
  },
  trackState: {
    fontSize: 10
  },
  trackDistrance: {
    marginTop: 2,
    fontSize: 12
  },
  icon: {
    width: 25,
    height: 25,
    transform: [{ rotate: `${-90}deg` }]
  },
  runnerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    alignItems: 'center',
    columnGap: 15,
    maxWidth: 150
  },
  runnerNumber: {
    fontSize: 13
  },
  runnerOdds: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 25,
    backgroundColor: colors.gray100,
    borderRadius: 5
  },
  oddsText: {
    fontSize: 14,
    fontWeight: '600SemiBold'
  }
})
