import { useNavigation } from '@react-navigation/native'
import { format } from 'date-fns'
import { orderBy } from 'lodash'
import { Fragment, ReactElement, useContext, useMemo } from 'react'
import { View } from 'react-native'
import { formatCurrency, isNumeric } from '../../../../../../helper-functions'
import {
  PreviousRun,
  RunnerAdditionalData,
  RunnerBasic
} from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  RunnerAdditionalDataResponse,
  fetchRunnerAdditionalData
} from '../../../../../../services/data/request-functions/runner-additional-data-request'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../../../../services/styles/dependencies/style-constants'
import { colors, presets } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { savePageInHistory } from '../../../../../../utils/save-history'
import { Button_C } from '../../../../../Base/Button/Button'
import { playIcon } from '../../../../../Base/Icon/preset-icon-props'
import { Link_C } from '../../../../../Base/Link/Link'
import { ProgressBar_C } from '../../../../../Base/ProgressBar/ProgressBar'
import { Text_C } from '../../../../../Base/Text/Text'
import { ComponentWithFetch_C } from '../../../../../HigherOrderComponents/ComponentWithFetch'
import { BorderGrid_C } from '../../../../../Layout/Grid/BorderGrid'
import { GridCell } from '../../../../../Layout/Grid/grid.type'
import { BlackBookButton_C } from '../../../../../Partials/BlackBookButton'
import { LinkOrText_C } from '../../../../../Partials/LinkOrText'
import { MeetingContext } from '../../../Meeting'

type Props = {
  meetingRunner: RunnerBasic
  raceNumber: number
}

export function ExpandedRunner_C(props: Props) {
  const meetingContext = useContext(MeetingContext)

  const {
    params: { trackSlug, type },
    date,
    activeMeetingPuntersCorner,
    blackbookHorseIds,
    fetchBlackbookHorseIds
  } = meetingContext
  const { meetingRunner, raceNumber } = props

  return useMemo(() => {
    return (
      <ComponentWithFetch_C<[RunnerAdditionalDataResponse]>
        fetchRequests={[
          fetchRunnerAdditionalData({
            date,
            trackSlug,
            dayPhaseLetter: activeMeetingPuntersCorner!.dayPhaseLetter,
            raceNumber,
            runnerNumber: meetingRunner.runnerNumber,
            trial: type == 'trial'
          })
        ]}
        showLoading={false}
        view={({ responses }) => {
          const [{ runnerAdditionalData }] = responses

          const detailedRunner: RunnerBasic & RunnerAdditionalData = {
            ...meetingRunner,
            ...runnerAdditionalData
          }

          const navigation = useNavigation()

          const {
            age,
            colour,
            sex,
            horse,
            sire,
            dam,
            owner,
            breeder,
            career,
            lifetimeStakes,
            bestMileRate,
            thisSeason,
            lastSeason,
            trainerLocation,
            class: runnerClass,
            last15,
            mobile,
            track,
            distanceRange,
            bmrDistanceRange,
            winPercent,
            placePercent,
            previousRuns,
            broodmareSire,
            lastWin
          } = detailedRunner
          const styles = useBreakpointStyles({ styles: breakpointStyles })

          const sortedPreviousRuns = orderBy(previousRuns, (run) => run.meetingDate, 'asc')

          const ownerContent: ReactElement = (
            <Text_C>
              {breakpointBelow(desktopBreakpoint) && owner.shortName ? owner.shortName : owner.name}
            </Text_C>
          )

          const breederContent: ReactElement = <Text_C>{breeder?.name ?? '---'}</Text_C>

          const gridCells: GridCell[] = [
            {
              breakpointConfig: { base: { colspan: 2 } },
              content: (
                <>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Sire: </Text_C>
                    {sire ? (
                      <LinkOrText_C
                        name={sire.name}
                        newTab
                        linkUrl={!sire.slug ? undefined : `horse/${sire.slug}`}
                      />
                    ) : (
                      <Text_C>N/A</Text_C>
                    )}
                  </View>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Dam:</Text_C>

                    {dam ? (
                      <LinkOrText_C
                        name={dam.name}
                        newTab
                        linkUrl={!dam.slug ? undefined : `horse/${dam.slug}`}
                      />
                    ) : (
                      <Text_C>N/A</Text_C>
                    )}
                  </View>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Broodmare Sire:</Text_C>

                    {broodmareSire ? (
                      <LinkOrText_C
                        name={broodmareSire.name}
                        newTab
                        linkUrl={!broodmareSire.slug ? undefined : `horse/${broodmareSire.slug}`}
                      />
                    ) : (
                      <Text_C>N/A</Text_C>
                    )}
                  </View>
                </>
              )
            },
            {
              breakpointConfig: {
                base: { colspan: 2 },
                [desktopBreakpoint]: { excluded: true }
              },
              content: (
                <>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Owner: </Text_C>
                    {ownerContent}
                  </View>
                  <View style={styles.flexRow}>
                    <Text_C style={styles.labelText}>Breeder: </Text_C>
                    {breederContent}
                  </View>
                </>
              )
            },
            {
              breakpointConfig: { [desktopBreakpoint]: { order: 1 } },
              content: (
                <>
                  <Text_C style={styles.labelText}>Age / Colour / Sex</Text_C>
                  <View style={styles.flexRow}>
                    <Text_C>{age}</Text_C>
                    <Text_C>{colour}</Text_C>
                    <Text_C>{sex}</Text_C>
                  </View>
                </>
              )
            },
            {
              breakpointConfig: {
                base: { excluded: true },
                [desktopBreakpoint]: { excluded: false, colspan: 2 }
              },
              content: (
                <>
                  <Text_C style={styles.labelText}>Owner:</Text_C>
                  {ownerContent}
                </>
              )
            },
            {
              breakpointConfig: {
                base: { excluded: true },
                [desktopBreakpoint]: { excluded: false, colspan: 2 }
              },
              content: (
                <>
                  <Text_C style={styles.labelText}>Breeder:</Text_C>
                  {breederContent}
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Career:</Text_C>
                  <Text_C>{career}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Lifetime Stakes:</Text_C>
                  <Text_C>{lifetimeStakes ? formatCurrency(lifetimeStakes) : 'N/A'}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Best Mile Rate (BMR):</Text_C>
                  <Text_C>{bestMileRate}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>This Season:</Text_C>
                  <Text_C>{thisSeason}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Last Season:</Text_C>
                  <Text_C>{lastSeason}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Trainer Location</Text_C>
                  <Text_C>{trainerLocation}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Class:</Text_C>
                  <Text_C>{runnerClass}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Last 15</Text_C>
                  <Text_C>{last15}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Mobile:</Text_C>
                  <Text_C>{mobile}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Track:</Text_C>
                  <Text_C>{track}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Distance Range:</Text_C>
                  <Text_C>{distanceRange}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>BMR Distance Range:</Text_C>
                  <Text_C>{bmrDistanceRange}</Text_C>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Win %</Text_C>

                  <View style={styles.flexRow}>
                    <Text_C>{winPercent}%</Text_C>
                    <ProgressBar_C percent={winPercent ?? 0} />
                  </View>
                </>
              )
            },
            {
              content: (
                <>
                  <Text_C style={styles.labelText}>Place %</Text_C>
                  <View style={styles.flexRow}>
                    <Text_C>{placePercent}%</Text_C>
                    <ProgressBar_C percent={placePercent ?? 0} />
                  </View>
                </>
              )
            }
          ]

          return (
            <View style={styles.container}>
              <BorderGrid_C
                gridBreakpointConfig={{
                  base: { columns: 2 },
                  [desktopBreakpoint]: { columns: 7 }
                }}
                cells={gridCells}
              />
              {previousRuns && (
                <View style={styles.form}>
                  {sortedPreviousRuns.map((previousRun, raceIndex) => {
                    const isLastRow = !lastWin && raceIndex == previousRuns.length - 1

                    const label = (
                      <Text_C
                        style={{
                          fontFamily: 'OpenSans',
                          fontWeight: '700Bold'
                        }}
                      >
                        {previousRun.place} - {previousRun.totalRunners}
                      </Text_C>
                    )
                    return createFormRow(previousRun, label, isLastRow)
                  })}
                  {lastWin &&
                    createFormRow(
                      lastWin,
                      <Text_C
                        style={{
                          fontFamily: 'OpenSans',
                          fontStyle: 'italic'
                        }}
                      >
                        Last Win
                      </Text_C>,
                      true
                    )}
                </View>
              )}
              <BlackBookButton_C
                onHorseAdded={fetchBlackbookHorseIds}
                horseId={horse.id}
                horseName={horse.name}
                isAlreadyInBlackbook={blackbookHorseIds?.includes(horse.id) ?? false}
              />
            </View>
          )

          function createFormRow(run: PreviousRun, label: ReactElement, isLastRow: boolean) {
            const {
              meetingDate,
              dayPhaseLetter,
              raceDistance,
              raceName,
              totalPrizeMoney,
              trackCondition,
              trackName,
              trackSlug,
              startType,
              raceClass,
              driverName,
              driverSlug,
              marginString,
              barrier,
              otherRunners,
              startingPrice,
              time,
              mileRate,
              stewardsComments,
              hasReplay,
              trackPublished,
              raceNumber
            } = run

            const { first, second, third, fourth, lastHalf } = time

            const meetingText = `${trackName} ${format(new Date(meetingDate), 'dMMMyy')} `
            return (
              <View
                key={meetingDate}
                style={{
                  ...styles.formRow,
                  ...(isLastRow && {
                    borderBottomWidth: 0
                  })
                }}
              >
                <View style={{ width: 52 }}>{label}</View>
                <View style={styles.formDetails}>
                  <Text_C style={styles.formDetailsText}>
                    {trackPublished ? (
                      <Button_C
                        styleType="linkBase"
                        onPress={() => {
                          savePageInHistory()
                          navigation.navigate('Meeting', {
                            date: meetingDate,
                            trackSlug,
                            dayPhaseLetter,
                            raceNumber: raceNumber,
                            type: 'meeting' //these will never be trial
                          })
                        }}
                      >
                        {meetingText}
                      </Button_C>
                    ) : (
                      <Text_C
                        style={{
                          fontWeight: '600SemiBold',
                          ...textSizes.size2
                        }}
                      >
                        {meetingText}
                      </Text_C>
                    )}
                    {`${raceDistance}${startType}${
                      trackCondition ? ` (${trackCondition})` : ''
                    }, ${formatCurrency(totalPrizeMoney)}${
                      raceName ? ` ${raceName}` : ''
                    }${raceClass ? ` (${raceClass})` : ''}, `}
                    <Text_C style={{ fontWeight: '700Bold' }}>{barrier}</Text_C>
                    {driverName &&
                      (driverSlug ? (
                        <Link_C navigateTo={['Driver', { driverSlug }]}>{` ${driverName}`}</Link_C>
                      ) : (
                        <Text_C>{` ${driverName}`}</Text_C>
                      ))}
                    {startingPrice ? ` $${startingPrice}` : ''}
                    {', '}
                    {marginString}
                    {', '}
                    {orderBy(otherRunners, (r) => r.place, ['asc'])?.map((runner, i) => {
                      const placeAsNumber = isNumeric(runner.place)
                        ? Number(runner.place)
                        : undefined

                      const placeText = placeAsNumber
                        ? placeAsNumber == 1
                          ? 'wnr'
                          : placeAsNumber == 2
                            ? '2nd'
                            : placeAsNumber == 3
                              ? '3rd'
                              : placeAsNumber == 4
                                ? '4th'
                                : ''
                        : runner.place

                      return (
                        <Fragment key={i}>
                          {!placeText ? '' : `${placeText} `}
                          {runner.horseSlug ? (
                            <Link_C
                              style={{
                                text: breakpointStyles.otherRunnerText
                              }}
                              navigateTo={['Horse', { horseSlug: runner.horseSlug }]}
                            >
                              {runner.horseName}
                            </Link_C>
                          ) : (
                            <Text_C style={styles.otherRunnerText}>{runner.horseName}</Text_C>
                          )}
                          <Text_C style={{ fontWeight: '700Bold' }}>{` ${runner.barrier}`}</Text_C>
                          {', '}
                        </Fragment>
                      )
                    })}
                    <Text_C style={{ fontWeight: '700Bold' }}>
                      {mileRate}
                      {lastHalf ? `, ${lastHalf}` : ''}
                    </Text_C>
                    {first && second && third && fourth
                      ? ` (${first}, ${second}, ${third}, ${fourth}) `
                      : ' '}
                    {stewardsComments ?? ''}
                  </Text_C>
                </View>
                {hasReplay && (
                  <Button_C
                    style={{
                      text: {
                        color: colors.blue
                      }
                    }}
                    onPress={() => {
                      savePageInHistory()
                      navigation.navigate('Meeting', {
                        date: meetingDate,
                        trackSlug,
                        dayPhaseLetter,
                        raceNumber: `${raceNumber}`,
                        type: 'meeting' // should always be trial
                      })
                    }}
                    icon={playIcon}
                  />
                )}
              </View>
            )
          }
        }}
      />
    )
  }, [blackbookHorseIds])
}

const breakpointStyles = createStyles({
  container: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    backgroundColor: colors.gray50,
    gap: 20,
    borderBottomWidth: 1,
    borderBottomColor: presets.border
  },
  labelText: {
    fontWeight: '700Bold'
  },
  flexRow: { flexDirection: 'row', gap: 6 },

  form: {
    base: {
      marginHorizontal: 10
    },
    [tabletBreakpoint]: {
      marginHorizontal: 20
    }
  },
  formRow: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    gap: 20,
    borderBottomWidth: 1,
    borderBottomColor: presets.border,
    paddingVertical: 16
  },
  formDetails: { flex: 1, maxWidth: '87%' },
  formDetailsText: { lineHeight: 18 },
  otherRunnerText: {
    fontStyle: 'italic'
  }
})
