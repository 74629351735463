/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRoute } from '@react-navigation/native'
import { merge } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import {
  breakpointAbove,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../styles/text-sizes'
import { Button_C } from '../../Base/Button/Button'
import { chevronIcon } from '../../Base/Icon/preset-icon-props'
import { Link_C } from '../../Base/Link/Link'
import { ExpandContainer_C } from '../../Layout/ExpandContainer/ExpandContainer'
import { MenuLink, mainMenuLinksProps } from './main-menu-link-props'

const alwaysMoreLinks: string[] = [
  'Podcasts',
  'Owners & Breeders',
  'Industry',
  'HarnessWeb',
  'Stallion Selector',
  'HRA'
]
const fullScreenMoreLinks: string[] = [...alwaysMoreLinks, 'Punters Corner', 'Premierships']
const desktopMoreLinks: string[] = [...fullScreenMoreLinks, 'Tracks']

export function MainMenu_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const isDesktopLayout = breakpointAbove(desktopBreakpoint)
  const isFullScreen = breakpointAbove('xxlarge')

  const { name: currentScreen } = useRoute()

  const { menuLinks, moreLinks } = (() => {
    const menuLinks: MenuLink[] = []
    const moreLinks: MenuLink[] = []
    if (isFullScreen) {
      menuLinks.push(...mainMenuLinksProps.filter((l) => !alwaysMoreLinks.includes(l.label)))
      moreLinks.push(...mainMenuLinksProps.filter((l) => alwaysMoreLinks.includes(l.label)))
    } else if (isDesktopLayout) {
      menuLinks.push(...mainMenuLinksProps.filter((l) => !fullScreenMoreLinks.includes(l.label)))
      moreLinks.push(...mainMenuLinksProps.filter((l) => fullScreenMoreLinks.includes(l.label)))
    } else {
      menuLinks.push(...mainMenuLinksProps.filter((l) => !desktopMoreLinks.includes(l.label)))
      moreLinks.push(...mainMenuLinksProps.filter((l) => desktopMoreLinks.includes(l.label)))
    }
    return { menuLinks, moreLinks }
  })()

  const [moreExpanded, setMoreExpanded] = useState(false)

  const handleClick = useRef<any>(null)
  const moreContainer = useRef<any>(null)

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  handleClick.current = (e: any) => {
    setMoreExpanded(false)
  }

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      const clickTargetElem = event.target as HTMLDivElement
      const menuContainerElem = moreContainer.current as HTMLDivElement
      const clickIsOutside = !menuContainerElem.contains(clickTargetElem)
      if (clickIsOutside) {
        handleClick.current(event)
      }
    }
    if (moreExpanded) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [moreExpanded])

  const moreButton = moreLinks?.length ? (
    <View ref={moreContainer}>
      <Button_C
        onPress={() => setMoreExpanded((prev) => !prev)}
        style={{ text: { base: breakpointStyles.linkText } }}
        icon={merge(chevronIcon, {
          style: {
            width: 16,
            transform: [{ rotate: moreExpanded ? '-90deg' : '0deg' }]
          }
        })}
      >
        More
      </Button_C>
      <ExpandContainer_C
        animationDuration={100}
        styles={{ expandContainer: styles.moreDropdown }}
        isExpanded={moreExpanded}
      >
        <View style={styles.linksContainer}>
          {moreLinks?.map(({ linkProps, label }, i) =>
            'navigateTo' in linkProps ? (
              <Link_C
                key={i}
                navigateTo={linkProps.navigateTo}
                style={{ text: { base: breakpointStyles.linkText } }}
              >
                {label}
              </Link_C>
            ) : (
              <Link_C
                key={i}
                linkUrl={linkProps.linkUrl}
                externalLink
                newTab
                style={{ text: { base: breakpointStyles.linkText } }}
              >
                {label}
              </Link_C>
            )
          )}
        </View>
      </ExpandContainer_C>
    </View>
  ) : (
    <></>
  )

  return (
    <View style={styles.container}>
      {menuLinks?.map(({ linkProps, label }, i) => {
        return 'navigateTo' in linkProps ? (
          <Link_C
            key={i}
            navigateTo={linkProps.navigateTo}
            style={{
              text: { base: breakpointStyles.linkText },
              elem: {
                base: {
                  borderBottomWidth: 2,
                  borderBottomColor:
                    linkProps.navigateTo[0] == currentScreen ? 'white' : 'transparent'
                }
              }
            }}
          >
            {label}
          </Link_C>
        ) : (
          <Link_C
            key={i}
            linkUrl={linkProps.linkUrl}
            externalLink
            newTab
            style={{
              text: { base: breakpointStyles.linkText },
              elem: {
                base: {
                  borderBottomWidth: 2,
                  borderBottomColor: 'transparent'
                }
              }
            }}
          >
            {label}
          </Link_C>
        )
      })}
      {moreButton}
    </View>
  )
}
const breakpointStyles = createStyles({
  linkText: {
    ...textSizes.size5,
    fontFamily: 'OpenSans',
    fontWeight: '700Bold',
    color: 'white'
  },
  container: {
    base: {
      display: 'none'
    },
    [tabletBreakpoint]: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 40,
      paddingLeft: 80
    }
  },
  linksContainer: {
    padding: 20,
    backgroundColor: 'black',
    gap: 14
  },
  moreDropdown: {
    position: 'absolute',
    top: '100%',
    width: 240,
    zIndex: 1
  }
})
