import { BreakpointZoom, MapCoords, StateBreakpointZoom } from './types/map.type'

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

/* for password */
export const capitalLetterRegex = /(?=.*[A-Z])/
export const numberRegex = /(?=.*\d)/
export const symbolRegex = /(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-])/

export const aToZ = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
] as const

export type AToZ = (typeof aToZ)[number]

export const australianStates = [
  'NSW',
  'VIC',
  'QLD',
  'SA',
  'WA',
  /* "NT", */
  'TAS'
  /* "ACT" */
] as const

export const australiaLatLng: MapCoords = {
  lat: -28.5,
  lng: 134
}

export const australiaZoom: BreakpointZoom = {
  base: {
    zoom: 3.4,
    nativeZoom: 40
  },
  small: {
    zoom: 4.2,
    nativeZoom: 20
  },
  medium: {
    zoom: 3.5,
    nativeZoom: 30
  },
  large: {
    zoom: 3.9,
    nativeZoom: 40
  },
  xlarge: {
    zoom: 4.1,
    nativeZoom: 12
  },
  xxlarge: {
    zoom: 4.5,
    nativeZoom: 12
  }
}

export const stateBreakpointZoom: StateBreakpointZoom = {
  NSW: {
    coords: {
      lat: -33.3,
      lng: 147
    },
    breakpointZoom: {
      base: {
        zoom: 5.4,
        nativeZoom: 12
      },
      small: {
        zoom: 6,
        nativeZoom: 12
      },
      medium: {
        zoom: 5.3,
        nativeZoom: 12
      },
      large: {
        zoom: 5.7,
        nativeZoom: 12
      },
      xlarge: {
        zoom: 5.9,
        nativeZoom: 12
      },
      xxlarge: {
        zoom: 6.3,
        nativeZoom: 12
      }
    }
  },
  VIC: {
    coords: {
      lat: -36,
      lng: 144
    },
    breakpointZoom: {
      base: {
        zoom: 6.5,
        nativeZoom: 8
      },
      small: {
        zoom: 6.8,
        nativeZoom: 8
      },
      medium: {
        zoom: 6,
        nativeZoom: 8
      },
      large: {
        zoom: 6.3,
        nativeZoom: 8
      },
      xlarge: {
        zoom: 6.5,
        nativeZoom: 8
      },
      xxlarge: {
        zoom: 7,
        nativeZoom: 8
      }
    }
  },
  QLD: {
    coords: {
      lat: -25.6,
      lng: 151.8
    },
    breakpointZoom: {
      base: {
        zoom: 6,
        nativeZoom: 18
      },
      small: {
        zoom: 6.6,
        nativeZoom: 18
      },
      medium: {
        zoom: 6,
        nativeZoom: 18
      },
      large: {
        zoom: 6.4,
        nativeZoom: 18
      },
      xlarge: {
        zoom: 6.8,
        nativeZoom: 18
      },
      xxlarge: {
        zoom: 7,
        nativeZoom: 18
      }
    }
  },
  SA: {
    coords: {
      lat: -35,
      lng: 139
    },
    breakpointZoom: {
      base: {
        zoom: 6.0,
        nativeZoom: 13
      },
      small: {
        zoom: 6.6,
        nativeZoom: 13
      },
      medium: {
        zoom: 5.6,
        nativeZoom: 13
      },
      large: {
        zoom: 6,
        nativeZoom: 13
      },
      xlarge: {
        zoom: 6.4,
        nativeZoom: 13
      },
      xxlarge: {
        zoom: 6.7,
        nativeZoom: 13
      }
    }
  },
  WA: {
    coords: {
      lat: -31.5,
      lng: 117
    },
    breakpointZoom: {
      base: {
        zoom: 5.8,
        nativeZoom: 21
      },
      small: {
        zoom: 6.2,
        nativeZoom: 21
      },
      medium: {
        zoom: 5.5,
        nativeZoom: 21
      },
      large: {
        zoom: 5.7,
        nativeZoom: 21
      },
      xlarge: {
        zoom: 6.2,
        nativeZoom: 21
      },
      xxlarge: {
        zoom: 6.6,
        nativeZoom: 21
      }
    }
  },
  /* NT: {
    coords: {
      lat: -19,
      lng: 133.2,
    },
    breakpointZoom: {
      base: {
        zoom: 5.1,
        nativeZoom: 16,
      },
      small: {
        zoom: 5.4,
        nativeZoom: 16,
      },
      medium: {
        zoom: 5.7,
        nativeZoom: 16,
      },
      large: {
        zoom: 5.5,
        nativeZoom: 16,
      },
      xlarge: {
        zoom: 5.7,
        nativeZoom: 16,
      },
      xxlarge: {
        zoom: 5.9,
        nativeZoom: 16,
      },
    },
  }, */
  TAS: {
    coords: {
      lat: -41.6,
      lng: 145.8
    },
    breakpointZoom: {
      base: {
        zoom: 6.5,
        nativeZoom: 5
      },
      small: {
        zoom: 6.9,
        nativeZoom: 5
      },
      medium: {
        zoom: 6.2,
        nativeZoom: 5
      },
      large: {
        zoom: 6.8,
        nativeZoom: 5
      },
      xlarge: {
        zoom: 7,
        nativeZoom: 5
      },
      xxlarge: {
        zoom: 7.2,
        nativeZoom: 5
      }
    }
  }
  /* ACT: {
    coords: {
      lat: -35.5,
      lng: 149,
    },
    breakpointZoom: {
      base: {
        zoom: 9,
        nativeZoom: 1,
      },
      small: {
        zoom: 9.5,
        nativeZoom: 1,
      },
      medium: {
        zoom: 8.9,
        nativeZoom: 1,
      },
      large: {
        zoom: 9.1,
        nativeZoom: 1,
      },
      xlarge: {
        zoom: 9.3,
        nativeZoom: 1,
      },
      xxlarge: {
        zoom: 9.5,
        nativeZoom: 1,
      },
    },
  }, */
}

export const daysOfTheWeek = [
  { shortName: 'Mon', dayIndex: 1, letter: 'M' },
  { shortName: 'Tue', dayIndex: 2, letter: 'T' },
  { shortName: 'Wed', dayIndex: 3, letter: 'W' },
  { shortName: 'Thu', dayIndex: 4, letter: 'T' },
  { shortName: 'Fri', dayIndex: 5, letter: 'F' },
  { shortName: 'Sat', dayIndex: 6, letter: 'S' },
  { shortName: 'Sun', dayIndex: 0, letter: 'S' }
] as const
