import { ReactNode } from 'react'
import { View } from 'react-native'
import { reduceObject } from '../../../helper-functions'
import {
  createStyles,
  useActiveBreakpoint,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import {
  BreakpointName,
  NamedBreakpointStylesExtension
} from '../../../services/styles/dependencies/breakpoint-style.type'
import {
  breakpointNames,
  tabletBreakpoint
} from '../../../services/styles/dependencies/style-constants'
import { presets } from '../../../styles/colors'
import {
  GridBreakpointConfig,
  GridCell,
  GridCellBreakpointConfig,
  GridCellConfig,
  GridConfig
} from './grid.type'

type Props = {
  style?: NamedBreakpointStylesExtension<typeof breakpointStyles>
  gridBreakpointConfig: GridBreakpointConfig
  cells: GridCell[]
}

export function BorderGrid_C(props: Props) {
  const styles = useBreakpointStyles({
    styles: breakpointStyles,
    additionalStyles: [props.style]
  })
  const activeBreakpoint = useActiveBreakpoint()

  const columns = getGridBreakpointColumns(props.gridBreakpointConfig, activeBreakpoint)

  return (
    <View style={styles.grid}>
      {props.cells.sort(sortByOrder).reduce((cells: ReactNode[], cell, index) => {
        const cellConfig =
          cell.breakpointConfig && getCellBreakpointConfig(cell.breakpointConfig, activeBreakpoint)

        if (cellConfig?.excluded) return cells

        const colspan = cellConfig?.colspan ?? 1
        const colFraction = colspan / columns

        return [
          ...cells,
          <View
            key={index}
            style={[
              styles.gridCell,
              {
                width: `${toPercentage(colFraction)}%`
              }
            ]}
          >
            {cell.content}
          </View>
        ]
      }, [])}
    </View>
  )

  function sortByOrder(a: GridCell, b: GridCell): 1 | 0 | -1 {
    const aOrder =
      a.breakpointConfig && getCellBreakpointConfig(a.breakpointConfig, activeBreakpoint)?.order

    const bOrder =
      b.breakpointConfig && getCellBreakpointConfig(b.breakpointConfig, activeBreakpoint)?.order

    if (!aOrder && !bOrder) return 0
    if (!aOrder) return 1
    if (!bOrder) return -1
    if (aOrder < bOrder) return -1
    if (bOrder < aOrder) return 1
    return 0
  }

  function toPercentage(num: number) {
    return Math.floor(num * 100000) / 1000
  }
}

function getGridBreakpointColumns(
  gridBreakpointConfig: GridBreakpointConfig,
  activeBreakpoint: BreakpointName
): number {
  return reduceObject(
    gridBreakpointConfig,
    (columns, breakpoint: BreakpointName, config?: GridConfig) => {
      const breakpointIndex = breakpointNames.indexOf(breakpoint)
      if (breakpointIndex <= breakpointNames.indexOf(activeBreakpoint) && config?.columns) {
        columns = config.columns
      }
      return columns
    },
    0
  )
}
function getCellBreakpointConfig(
  gridCellBreakpointConfig: GridCellBreakpointConfig,
  activeBreakpoint: BreakpointName
): GridCellConfig | undefined {
  return reduceObject(
    gridCellBreakpointConfig,
    (selectedCellConfig, breakpoint: BreakpointName, loopCellConfig?: GridCellConfig) => {
      const breakpointIndex = breakpointNames.indexOf(breakpoint)
      if (loopCellConfig && breakpointIndex <= breakpointNames.indexOf(activeBreakpoint)) {
        selectedCellConfig = loopCellConfig
      }
      return selectedCellConfig
    }
  )
}

const breakpointStyles = createStyles({
  grid: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: presets.border,
    borderLeftWidth: 1,
    borderLeftColor: presets.border,
    backgroundColor: 'white',
    flexWrap: 'wrap'
  },
  gridCell: {
    base: {
      borderRightWidth: 1,
      borderRightColor: presets.border,
      borderBottomWidth: 1,
      borderBottomColor: presets.border,
      padding: 12,
      gap: 2
    },
    [tabletBreakpoint]: {
      gap: 4
    }
  }
})
