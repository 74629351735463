import ordinal from 'ordinal'
import { Dispatch, SetStateAction, useContext } from 'react'
import { Pressable, View } from 'react-native'
import { formatCurrency, isNumeric } from '../../../../../../helper-functions'
import { RunnerBasic } from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { Icon_C } from '../../../../../Base/Icon/Icon'
import { blackbookRibbon, chevronIcon } from '../../../../../Base/Icon/preset-icon-props'
import { Link_C } from '../../../../../Base/Link/Link'
import { Text_C } from '../../../../../Base/Text/Text'
import { ExpandContainer_C } from '../../../../../Layout/ExpandContainer/ExpandContainer'
import { LinkOrText_C } from '../../../../../Partials/LinkOrText'
import { SilkImage_C } from '../../../../../Partials/SilkImage/SilkImage'
import { ResultsExpandedMobile_C } from './ResultsExpandedMobile'
import { MeetingContext } from '../../../Meeting'

type Props = {
  runners: RunnerBasic[]
  raceNumber: number
  expandedRunners: RunnerBasic[]
  isTrial: boolean
  setExpandedRunners: Dispatch<SetStateAction<RunnerBasic[]>>
}
export function ResultRowsMobile_C(props: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { blackbookHorseIds } = useContext(MeetingContext)
  return (
    <View style={styles.rowsContainer}>
      {props.runners
        .filter((r) => !r.scratched)
        .map((runner, runnerIndex) => {
          const hasProtest = props.runners.some((r) => r.pastThePostPlace !== r.place)
          const {
            trainer,
            driver,
            horse,
            place,
            runnerNumber,
            silk,
            pastThePostPlace,
            prizemoney
          } = runner

          const isExpanded = props.expandedRunners.some((r) => r.runnerNumber == runnerNumber)
          const placeNumber = isNumeric(place) ? Number(place) : undefined

          const ordinalPlace = placeNumber ? ordinal(placeNumber) : undefined

          const isTop3 = placeNumber && placeNumber >= 1 && placeNumber <= 3

          const ptpPlaceNumber = isNumeric(pastThePostPlace) ? Number(pastThePostPlace) : undefined

          const ptpOrdinalPlace = ptpPlaceNumber ? ordinal(ptpPlaceNumber) : undefined

          return (
            <Pressable
              onPress={() =>
                props.setExpandedRunners((prev) =>
                  prev.some((r) => r.runnerNumber == runnerNumber)
                    ? prev.filter((r) => r.runnerNumber != runnerNumber)
                    : [...prev, runner]
                )
              }
              key={runnerIndex}
              style={styles.runnerRowContainer}
            >
              <View style={styles.runnerRow}>
                <View
                  style={{
                    ...styles.runnerPlaceStyle,
                    ...(isTop3 && styles.top3Place)
                  }}
                >
                  <Text_C style={{ ...(isTop3 && styles.top3PlaceText) }}>
                    {ordinalPlace ?? place}
                  </Text_C>
                </View>

                {hasProtest && (
                  <View
                    style={{
                      ...styles.runnerPlaceStyle
                    }}
                  >
                    <Text_C>{ptpOrdinalPlace ?? ptpPlaceNumber}</Text_C>
                  </View>
                )}

                <View style={styles.silkContainer}>{<SilkImage_C silk={silk} />}</View>

                <View style={styles.linksContainer}>
                  <LinkOrText_C
                    name={`${runner.runnerNumber}. ${horse.name}`}
                    linkUrl={!horse.slug ? undefined : `horse/${horse.slug}`}
                    newTab
                    linkStyle={{
                      text: styles.runner
                    }}
                  />
                  {driver && (
                    <Text_C>
                      D:{' '}
                      {!driver.slug ? (
                        <Text_C>{driver.name ?? driver.shortName}</Text_C>
                      ) : (
                        <Link_C navigateTo={['Driver', { driverSlug: driver.slug }]}>
                          {driver.name ?? driver.shortName}
                        </Link_C>
                      )}
                    </Text_C>
                  )}
                  {trainer && (
                    <Text_C>
                      T:{' '}
                      {!trainer.slug ? (
                        <Text_C>{trainer.name ?? trainer.shortName}</Text_C>
                      ) : (
                        <Link_C navigateTo={['Trainer', { trainerSlug: trainer.slug }]}>
                          {trainer.name ?? trainer.shortName}
                        </Link_C>
                      )}
                    </Text_C>
                  )}

                  {isRunnerInBlackBook(runner) && <Icon_C {...blackbookRibbon} />}
                </View>
                <Text_C style={{ alignSelf: 'center' }}>
                  {prizemoney && formatCurrency(prizemoney)}
                </Text_C>
                <Icon_C
                  {...chevronIcon}
                  color={colors.gray600}
                  style={{
                    width: 20,
                    transform: [{ rotate: isExpanded ? '0deg' : '-90deg' }]
                  }}
                />
              </View>
              <ExpandContainer_C isExpanded={isExpanded}>
                <ResultsExpandedMobile_C meetingRunner={runner} raceNumber={props.raceNumber} />
              </ExpandContainer_C>
            </Pressable>
          )
        })}
    </View>
  )
  function isRunnerInBlackBook(runner: RunnerBasic): boolean {
    return blackbookHorseIds?.includes(runner.horse.id) ?? false
  }
}

const breakpointStyles = createStyles({
  rowsContainer: {
    borderTopColor: presets.border,
    borderTopWidth: 1
  },
  runnerRowContainer: {},
  runnerRow: {
    base: {
      flexDirection: 'row',
      columnGap: 10,
      alignItems: 'stretch',
      borderBottomWidth: 1,
      borderBottomColor: presets.border
    },
    small: {
      columnGap: 16
    }
  },
  runnerPlaceStyle: {
    base: {
      ...textSizes.size1,
      paddingVertical: 6,
      width: 30,
      alignItems: 'center',
      justifyContent: 'center'
    },
    small: {
      width: 40
    }
  },
  top3Place: {
    backgroundColor: presets.primary
  },
  top3PlaceText: {
    fontWeight: '600SemiBold',
    color: 'white'
  },
  linksContainer: {
    paddingVertical: 10,
    flex: 1,
    gap: 4,
    alignItems: 'flex-start'
  },
  silkContainer: {
    justifyContent: 'center'
  },
  runnerScratched: {
    textDecorationLine: 'line-through'
  },
  runner: {
    base: {
      ...textSizes.size4,
      fontWeight: '600SemiBold',
      marginRight: 4
    }
  }
})
