/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Dimensions, View } from 'react-native'
import { reduceObject } from '../../../helper-functions'
import {
  createStyles,
  useActiveBreakpoint,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { BreakpointName } from '../../../services/styles/dependencies/breakpoint-style.type'
import { breakpointNames } from '../../../services/styles/dependencies/style-constants'
import { GridBreakpointConfig, GridConfig } from './grid.type'

type Props = {
  gridBreakpointConfig: GridBreakpointConfig
  cells: ReactNode[]
  rowGap: number
  columnGap: number
}

export function GapGrid_C(props: Props) {
  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })
  const activeBreakpoint = useActiveBreakpoint()

  const [cellWidth, setCellWidth] = useState<number | null>(null)

  const gridRef = useRef<any>(null)

  const columns = getGridBreakpointColumns(props.gridBreakpointConfig, activeBreakpoint)

  const updateCellWidth = useCallback(
    () => {
      const width = (gridRef.current as HTMLDivElement).getBoundingClientRect().width
      const totalGapSpace = props.columnGap * (columns - 1)
      const cellWidth = Math.floor((width - totalGapSpace) / columns)
      setCellWidth(cellWidth)
    },
    [cellWidth, columns] //ensures latest value of activeBreakpoint
  )

  useEffect(() => {
    updateCellWidth()

    const subscription = Dimensions.addEventListener('change', () => updateCellWidth())

    return () => {
      subscription.remove()
    }
  }, [updateCellWidth])

  return (
    <View ref={gridRef} style={[styles.grid, { columnGap: props.columnGap, rowGap: props.rowGap }]}>
      {props.cells.reduce((cells: ReactNode[], cell, index) => {
        return [
          ...cells,
          <View
            key={index}
            style={[
              styles.gridCell,
              {
                ...(cellWidth && { width: cellWidth })
              }
            ]}
          >
            {cell}
          </View>
        ]
      }, [])}
    </View>
  )

  function getGridBreakpointColumns(
    gridBreakpointConfig: GridBreakpointConfig,
    activeBreakpoint: BreakpointName
  ): number {
    return reduceObject(
      gridBreakpointConfig,
      (columns, breakpoint: BreakpointName, config?: GridConfig) => {
        const breakpointIndex = breakpointNames.indexOf(breakpoint)
        if (breakpointIndex <= breakpointNames.indexOf(activeBreakpoint) && config?.columns) {
          columns = config.columns
        }
        return columns
      },
      0
    )
  }
}

const breakpointStyles = createStyles({
  grid: {
    base: {
      flexDirection: 'row',
      flex: 1,
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    }
  },
  gridCell: {
    base: {}
  }
})
