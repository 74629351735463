import { PropsWithChildren, ReactNode } from 'react'
import { View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { Heading_C } from '../../Base/Heading/Heading'

type Props = { heading?: ReactNode }
export function SimpleContainer_C({ heading, children }: PropsWithChildren<Props>) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  return (
    <View style={styles.container}>
      {!!heading && (
        <Heading_C style={styles.heading} styleType="h3">
          {heading}
        </Heading_C>
      )}
      <View style={styles.contentContainer}>{children}</View>
    </View>
  )
}

const breakpointStyles = createStyles({
  container: {
    padding: 30,
    backgroundColor: colors.gray50
  },
  contentContainer: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: presets.border,
    padding: 30,
    marginRight: 'auto'
  },
  heading: { marginBottom: 20 }
})
