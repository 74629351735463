/* eslint-disable no-unused-vars */
import { mapObject, removeLeadingSlash } from '../../../helper-functions'
import { Asset } from '../../../types/assets.type'
import { BaseFetchResponse, ResponseAsset } from '../../../types/fetch-response-base.type'
import { convertAsset } from '../../../utils/assets'
import { environment } from '../api-environment'
import { fetchData } from '../api-fetch-request'

const RESPONSE_PLACEMENTS = ['skyscraper', 'sidebar', 'header', 'footer'] as const
type ResponseAdType = (typeof RESPONSE_PLACEMENTS)[number]

export type AdType = 'skyscraper' | 'sidebar' | 'header' | 'footer'

type ResponseAd = {
  campaign_id: number
  asset: ResponseAsset
  link_url: string
}

export type Ad = {
  campaignId?: string
  asset?: Asset
  linkUrl: string
} & {
  width?: number
  height?: number
  aspectRatio?: number
  imageUrl?: string
}

export type ResponseAdsObj = {
  [key in ResponseAdType]: ResponseAd | null
}

export type AdsObj = {
  [key in AdType]?: Ad
}

export type AdvertisementFetchResponse = BaseFetchResponse & {
  placements: ResponseAdsObj
}

export type AdvertisementResponse = {
  adsObj: AdsObj
}

export async function getAdvertisements(path: string): Promise<AdvertisementResponse> {
  const pathname = path === '/' ? 'home' : removeLeadingSlash(path)
  const placements = RESPONSE_PLACEMENTS?.map((placement) => `placements[]=${placement}`).join('&')
  const endpoint = `site/advertising/${pathname}?${placements}`
  const response = await fetchData({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  return response

  function convertResponse(payload: AdvertisementFetchResponse): AdvertisementResponse {
    const responseAdsObj: ResponseAdsObj = payload.placements

    const adsObj: AdsObj = mapObject(
      responseAdsObj,
      (responseAdType: ResponseAdType, responseAd) => {
        const ad: Ad | undefined = !responseAd
          ? undefined
          : {
              campaignId: '' + responseAd.campaign_id,
              asset: convertAsset(responseAd.asset),
              linkUrl: environment.apiUrl + responseAd.link_url
            }
        return {
          [responseAdType]: ad
        }
      }
    )

    return { adsObj }
  }
}
