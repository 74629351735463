import { endOfMonth, isAfter, isBefore } from 'date-fns'
import { random } from 'lodash'
import { getDateNow } from '../../../helper-functions'
import { shortDate } from '../../../utils/date-time'
import { CalendarMeeting } from '../data-types/local-data-types/calendar-meeting.type'
import { CalendarResponse } from '../request-functions/calendar-request'
import { DayPhaseLetter } from '../data-types/general-data-types.type'

export const calendarDesignMock: CalendarResponse = {
  meetings: Array.from({ length: endOfMonth(getDateNow()).getDate() }).flatMap((_, i) => {
    let meetings: CalendarMeeting[] = []
    const date = getDateNow()
    date.setDate(i + 1)

    meetings = Array.from({ length: random(3) + 5 }).map((_) => {
      let accepted = true
      const isFutureMeeting = isAfter(date, getDateNow())

      if (isFutureMeeting) {
        const dateDiff = date.getDate() - getDateNow().getDate()
        accepted = isBefore(date, getDateNow()) || random(100) > dateDiff * 10
      }

      const meeting: CalendarMeeting = {
        track: {
          name: [
            'Bankstown',
            'Hamilton',
            'Marburg',
            'Pinjarra',
            'Swan Hill',
            'Bathurst',
            'Leeton',
            'Mildura',
            'Albion Park'
          ][random(8)],
          slug: 'mock-slug',
          state: 'NSW'
        },
        date: shortDate(date),
        dayPhaseLetter: (['D', 'T', 'N'] as DayPhaseLetter[])[random(3)],
        isTab: true,
        isTrial: false,
        status: 'Results'
      }
      return meeting
    })

    return meetings
  })
}
