import { Card_C } from '../../../Layout/Card/Card'
import { SimpleContainer_C } from '../../../Layout/SimpleContainer/SimpleContainer'
import { RenderRichText } from '../../../Partials/RichText/RichText'

type Props = { stewardsReport: string }
export function StewardsReportsTab_C({ stewardsReport }: Props) {
  return (
    <Card_C>
      <SimpleContainer_C>
        <RenderRichText content={stewardsReport} />
      </SimpleContainer_C>
    </Card_C>
  )
}
