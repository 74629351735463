import { DayPhaseLetter } from '../services/data/data-types/general-data-types.type'
import { AustralianState } from '../types/map.type'

// Base Colors
export const colors = {
  black: '#010101',
  white: '#fff',

  blue: '#0274dd',
  darkBlue: '#003396',
  paleBlue: '#d8eafa',
  orange: '#ff9900',
  red: '#da291c',
  yellow: '#b99400', //calendar non-TAB
  green: '#008000', //form green tick

  gray50: '#f9fAfB',
  gray100: '#f4f4f4',
  gray200: '#e9e9e9',
  gray300: '#d3d4d5',
  gray400: '#ccc',
  gray500: '#969696',
  gray600: '#666',
  gray700: '#333',
  gray800: '#222'
} as const
// 'as const' makes variable hover show hex value instead of type string

export const presets = {
  primary: colors.blue,
  secondary: colors.orange,
  headerFooter: colors.black,
  border: colors.gray300,
  background: colors.gray200,
  disabled: colors.gray300,
  disabledText: colors.gray500
}

export const sectionalsColors = {
  green: '#a4dd80',
  greenHighlight: '#a4dd804d',
  red: '#dd070a',
  redHighlight: '#dd070a1a'
}

export const stateColor: Record<AustralianState, string> = {
  NSW: '#0096ff', // Blue
  VIC: '#003396', // Navy
  QLD: '#960033', // Maroon
  SA: '#C90033', // Red
  WA: '#ffC900', // Yellow
  /* NT: "#000" */
  TAS: '#339600' // Green
  /*  ACT: "#000" */
}

export const calendarColors: Record<DayPhaseLetter, string> = {
  D: '#ff6702',
  T: '#349900',
  N: '#0066c9'
}
