import { removeLeadingSlash } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { shortDate } from '../../../utils/date-time'
import { fetchData } from '../api-fetch-request'
import { CalendarFetchResponse } from '../data-types/fetch-response-types/calendar-response.type'
import { meetingStatusMap } from '../data-types/general-data-types.type'
import { CalendarMeeting } from '../data-types/local-data-types/calendar-meeting.type'
import { calendarDesignMock } from '../mock-responses/calendar-design-mock'

export type CalendarResponse = {
  meetings: CalendarMeeting[]
}

type GetCalendarParams = {
  date: Date
  useDesignMock?: boolean
}
export async function getCalendarData({
  date,
  useDesignMock
}: GetCalendarParams): Promise<CalendarResponse> {
  if (useDesignMock) {
    return Promise.resolve(calendarDesignMock)
  }

  const endpoint = `racing/calendar/${shortDate(date)}`
  return fetchData<CalendarResponse, CalendarFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(calendarPayload: CalendarFetchResponse): CalendarResponse {
    const converted: CalendarResponse = {
      meetings: calendarPayload.meetings.map((m) => {
        const { date, day_night_twilight, track_details, meeting_status, is_tab, is_trial } = m
        const { desktop_display_name, mobile_display_name, slug, state } = track_details
        const meeting: CalendarMeeting = {
          date,
          dayPhaseLetter: day_night_twilight,
          isTab: is_tab,
          isTrial: is_trial,
          track: {
            name: desktop_display_name,
            shortName: mobile_display_name,
            state: state?.toUpperCase() as AustralianState,
            slug: removeLeadingSlash(slug)
          },

          status: meetingStatusMap[meeting_status]
        }
        return meeting
      })
    }
    return converted
  }
}
