import { isAfter } from 'date-fns'
import { useContext } from 'react'
import { View } from 'react-native'
import { GlobalContext } from '../../../global-context'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { Toast_C } from './Toast'

export function ToastContainer_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const { toasts } = useContext(GlobalContext)
  return (
    <View style={styles.toastsContainer}>
      {toasts
        .filter((t) => isAfter(t.expiryDate, new Date()))
        .map((t) => (
          <Toast_C key={t.expiryDate.getTime()} {...t} />
        ))}
    </View>
  )
}

const breakpointStyles = createStyles({
  toastsContainer: {
    zIndex: 20, //Above header
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 20,
    rowGap: 20
  }
})
