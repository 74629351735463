import { merge } from 'lodash'
import { Image, ImageSourcePropType } from 'react-native'
import { Ad, AdType } from '../../../services/data/request-functions/advertisement-request'
import { BreakpointStyles } from '../../../services/styles/dependencies/breakpoint-style.type'
import { Link_C } from '../../Base/Link/Link'

export type AdLinkProps = {
  name: AdType
  ad?: Ad
  style?: BreakpointStyles
}

export function AdPlacement({ ad, style }: AdLinkProps) {
  return !ad ? null : (
    <Link_C
      linkUrl={ad.linkUrl}
      externalLink
      newTab
      style={{
        elem: merge(
          {
            base: {
              maxWidth: ad?.width,
              aspectRatio: ad.aspectRatio,
              width: '100%',
              marginHorizontal: 'auto',
              marginBottom: 'auto'
            }
          },
          style
        )
      }}
    >
      <Image
        alt="advert"
        source={ad.imageUrl as ImageSourcePropType}
        style={{ aspectRatio: ad.aspectRatio }}
        resizeMode="stretch"
      />
    </Link_C>
  )
}
