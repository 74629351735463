import { loop } from '../../../helper-functions'
import {
  NewsListFetchResponse,
  ResponseNewsArticle
} from '../data-types/fetch-response-types/news-list-response.type'
export const newsListFetchMock: NewsListFetchResponse = {
  meta: {
    status: 200,
    code: 'OK'
  },
  pagination: {
    limit: 10,
    page: 3,
    total: 87,
    pages: 9
  },
  categories: [
    {
      slug: 'top-stories',
      title: 'Top Stories',
      priority: 1
    },
    {
      slug: 'industry-news',
      title: 'Industry News',
      priority: 2
    },
    {
      slug: 'the-racing-office',
      title: 'The Racing Office',
      priority: 3
    },
    {
      slug: 'news-archive',
      title: 'News Archive',
      priority: 4
    }
  ],
  articles: loop<ResponseNewsArticle>(18).map(() => {
    return {
      id: 2,
      headline: 'This is a new headline for the second news article',
      author: 'Tyson Eketone',
      slug: '/this-is-a-new-headline-for-the-second-news-article',
      state: 'wa',
      published: {
        state: 'on_since',
        datetime: '2023-12-05T12:03:00+11:00'
      },
      hero: {
        asset_type: 'blob',
        content_type: 'image',
        title: 'Tasmanian highlights 16 August',
        description: null,
        placeholder_image_url:
          'https://api.staging.harness.au/onspace/media/assets/placeholder/eyJfcmFpbHMiOnsibWVzc2FnZSI6Ild6RXNNRjA9IiwiZXhwIjpudWxsLCJwdXIiOiJvbnNwYWNlX21lZGlhX2Fzc2V0X2lkIn19--d59df84d715abc20d53d7a3d997bf93efb0ec7ec95eaa4fb8310c2c4b1235835/tasmanian-highlights-16-august.webp',
        viewer_type: 'image',
        viewer_sources: [
          {
            url: 'https://api.staging.harness.au/onspace/media/assets/eyJfcmFpbHMiOnsibWVzc2FnZSI6Ild6RXNNRjA9IiwiZXhwIjpudWxsLCJwdXIiOiJvbnNwYWNlX21lZGlhX2Fzc2V0X2lkIn19--d59df84d715abc20d53d7a3d997bf93efb0ec7ec95eaa4fb8310c2c4b1235835/tasmanian-highlights-16-august.webp'
          }
        ]
      },
      pdf: null,
      trots_url: null,
      categories: [
        {
          slug: 'top-stories',
          title: 'Top Stories',
          priority: 1
        }
      ],
      content: 'This is news content..'
    }
  })
}
