import { toUpper } from 'lodash'
import { removeLeadingSlash } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { convertAsset } from '../../../utils/assets'
import { fetchData } from '../api-fetch-request'
import { TrackDetailedFetchResponse } from '../data-types/fetch-response-types/track-detailed-response.type'
import { meetingStatusMap } from '../data-types/general-data-types.type'
import { TrackDetailed } from '../data-types/local-data-types/track-detailed.type'

export type TrackDetailedResponse = {
  trackDetailed: TrackDetailed
}

type FetchTrackDetailedParams = {
  trackSlug: string
  useDesignMock?: boolean
}

export async function fetchTrackDetailed({
  trackSlug,
  useDesignMock
}: FetchTrackDetailedParams): Promise<TrackDetailedResponse> {
  if (useDesignMock) {
    throw 'no design mock'
  }

  const endpoint = `racing/tracks/${trackSlug}`
  return fetchData<TrackDetailedResponse, TrackDetailedFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(trackPayload: TrackDetailedFetchResponse): TrackDetailedResponse {
    const {
      desktop_display_name,
      map_latitude,
      map_longitude,
      slug,
      address,
      mobile_display_name,
      phone,
      email,
      website_url,
      radio,
      circumference,
      home_straight_length,
      turn_radius,
      mobile_distances,
      abbreviated_name,
      field_limit_mobile,
      field_limit_standing,
      stand_distances,
      sprint_lane,
      property_identification_code,
      track_image,
      max_front_row_mobile,
      max_row_standing,
      usual_season,
      facilities,
      camber_corners,
      camber_straights,
      track_stats,
      postal_address,
      next_5_meetings,
      usual_meeting_days,
      last_5_meetings,
      about,
      events,
      track_map_image
    } = trackPayload.track

    const { barrier_stats, driver_stats, pacers_records, trainer_stats, trotters_records } =
      track_stats

    const trackDetailed: TrackDetailed = {
      name: desktop_display_name,
      shortName: mobile_display_name,
      abbrevName: abbreviated_name,
      next5Meetings: next_5_meetings.map((m) => {
        return {
          date: m.date,
          dayPhaseLetter: m.day_night_twilight,
          racesCount: m.drawn_races_count,
          status: meetingStatusMap[m.meeting_status],
          isTrial: m.is_trial
        }
      }),

      last5Meetings: last_5_meetings.map((m) => {
        return {
          date: m.date,
          dayPhaseLetter: m.day_night_twilight,
          racesCount: m.drawn_races_count,
          status: meetingStatusMap[m.meeting_status],
          isTrial: m.is_trial
        }
      }),
      trackImage: !track_image ? undefined : convertAsset(track_image),
      trackMapImage: !track_map_image ? undefined : convertAsset(track_map_image),
      lat: map_latitude ?? undefined,
      lng: map_longitude ?? undefined,
      slug: removeLeadingSlash(slug),
      address: !address
        ? undefined
        : {
            country: address.country ?? undefined,
            region: address.region ?? undefined,
            postalCode: address.postal_code ?? undefined,
            locality: address.locality ?? undefined,
            lineOne: address.line_one ?? undefined,
            lineTwo: address.line_two ?? undefined,
            formatted: address.formatted ?? undefined
          },
      postalAddress: !postal_address
        ? undefined
        : {
            country: postal_address.country,
            region: postal_address.region,
            postalCode: postal_address.postal_code,
            locality: postal_address.locality,
            lineOne: postal_address.line_one,
            lineTwo: postal_address.line_two ?? undefined,
            formatted: postal_address.formatted
          },
      state: address?.region ? (toUpper(address?.region) as AustralianState) : undefined,
      phone: phone ?? undefined,
      email: email ?? undefined,
      website: website_url ?? undefined,
      radio: radio ?? undefined,
      usualMeetingDays: usual_meeting_days ?? undefined,
      usualSeason: usual_season ?? undefined,
      about: about ?? undefined,
      facilities: facilities ?? undefined,
      events: events ?? undefined,
      circumference: circumference ?? undefined,
      straights: home_straight_length ?? undefined,
      radiusOnTurns: turn_radius ?? undefined,
      camber: {
        corners: camber_corners ?? undefined,
        straights: camber_straights ?? undefined
      },
      sprintLane: sprint_lane,
      propertyIdentificationCode: property_identification_code ?? undefined,
      fieldLimits: {
        standing: field_limit_standing ?? undefined,
        mobile: field_limit_mobile ?? undefined
      },
      frontLine: {
        mobile: max_front_row_mobile ?? undefined,
        standing: max_row_standing ?? undefined
      },
      mobileDistances: mobile_distances,
      standDistances: stand_distances,
      barrierStats: barrier_stats,
      driverStats: driver_stats.map(({ driver_name, ...rest }) => ({
        name: driver_name,
        ...rest
      })),
      trainerStats: trainer_stats.map(({ trainer_name, stakes, ...rest }) => ({
        name: trainer_name,
        stakes: stakes ?? undefined,
        ...rest
      })),
      pacerRecords: pacers_records.map(
        ({
          horse_name: name,
          horse_slug,
          race_number,
          race_date,
          time_mile_rate,
          start_type,
          distance,
          meeting_day_night_twilight
        }) => ({
          horse: { name, slug: removeLeadingSlash(horse_slug) },
          raceNumber: race_number,
          date: race_date,
          timeMileRate: time_mile_rate,
          startType: start_type,
          distance,
          dayPhaseLetter: meeting_day_night_twilight
        })
      ),
      trotterRecords: trotters_records.map(
        ({
          horse_name: name,
          horse_slug,
          race_number,
          race_date,
          time_mile_rate,
          start_type,
          distance,
          meeting_day_night_twilight
        }) => ({
          horse: { name, slug: removeLeadingSlash(horse_slug) },
          raceNumber: race_number,
          date: race_date,
          timeMileRate: time_mile_rate,
          startType: start_type,
          distance,
          dayPhaseLetter: meeting_day_night_twilight
        })
      )
    }
    return {
      trackDetailed
    }
  }
}
