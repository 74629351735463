/* eslint-disable react/jsx-key */
import { View } from 'react-native'
import { TrainerDriverUpcomingRun } from '../../../services/data/data-types/local-data-types/trainer-driver-runs'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { Heading_C } from '../../Base/Heading/Heading'
import { TableStyles, Table_C } from '../../Base/Table/Table'
import { TableHeading, TableRow } from '../../Base/Table/table.type'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { LinkOrText_C } from '../LinkOrText'
import { SilkImage_C } from '../SilkImage/SilkImage'
import { TableCellRaceLink_C } from '../_meeting-components/TableCellRaceLink'

type Props = {
  upcomingRuns: TrainerDriverUpcomingRun[]
  type: 'trainer' | 'driver'
}

export function TrainerDriverUpcomingRuns_C({ upcomingRuns, type }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const upcomingHeadings: TableHeading[] = [
    { content: 'Meeting', colWidth: { width: 160 } },
    { content: 'Race', colWidth: { width: 70 } },
    { content: 'Horse', colWidth: { width: 230 } },
    'Barrier',
    `Starts For ${type == 'trainer' ? 'Trainer' : 'Driver'}`,
    'Wins',
    'Places'
  ]

  const upcomingRows = upcomingRuns.flatMap((race): TableRow => {
    const {
      barrier,
      dayPhaseLetter,
      horse,
      meetingDateString,
      raceNumber,
      silk,
      statsOnHorse,
      track,
      runnerNumber,
      isTrial
    } = race

    return {
      cells: [
        <TableCellRaceLink_C
          {...{
            date: meetingDateString,
            dayPhaseLetter,
            isTrial,
            trackName: track.shortName ?? track.name,
            trackSlug: track.slug,
            hasReplay: false,
            raceNumber: `${raceNumber}`
          }}
        />,
        raceNumber,
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
          <SilkImage_C silk={silk} style={{ cropContainer: { width: 26 } }} />
          <Text_C>{runnerNumber}.</Text_C>

          <LinkOrText_C
            {...{
              name: horse.name,
              navigateTo: !horse.slug ? undefined : ['Horse', { horseSlug: horse.slug }]
            }}
          />
        </View>,
        barrier,
        statsOnHorse.starts,
        `${statsOnHorse.wins} (${statsOnHorse.winPercent}%)`,
        `${statsOnHorse.places} (${statsOnHorse.placePercent}%)`
      ]
    }
  })

  return (
    <Card_C style={styles.racesCard}>
      <View style={styles.raceGroup}>
        <Heading_C styleType="h4" style={breakpointStyles.raceHeading}>
          Upcoming Runs
        </Heading_C>
        <Table_C
          styleType="lightHeaderBorders"
          headings={upcomingHeadings}
          rows={upcomingRows}
          styles={tableStyle}
        />
      </View>
    </Card_C>
  )
}

const breakpointStyles = createStyles({
  racesCard: {
    base: {
      gap: 40
    }
  },
  raceGroup: {
    base: {}
  },
  raceHeading: {
    base: { marginBottom: 16 }
  }
})

const tableStyle: TableStyles = {
  tableContainer: { base: { minWidth: 'auto', flex: 1, maxWidth: 1000 } },
  table: { width: '100%', minWidth: 700 }
}
