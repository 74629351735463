/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { addSeconds, isAfter } from 'date-fns'
import { merge } from 'lodash'
import { ReactNode, createContext, useEffect, useState } from 'react'
import { ModalStyle } from './components/Base/Modal/Modal'
import { Toast } from './components/Base/Toast/toast.type'
import { expirySecondsAfterCreated } from './components/Base/Toast/tost.constants'
import { Tooltip } from './components/Base/Tooltip/Tooltip'
import { CalendarPicker } from './components/Partials/CalendarPicker/CalendarPickerIcon'
import { FeatureToggle, defaultFeaturesToggle } from './features-toggle'
import { AustralianState } from './types/map.type'
import { getStorageData, setStorageData } from './utils/storage'

type ModalOptions = { modalStyle?: ModalStyle }

export type GlobalContext_T = {
  modalVisible: boolean
  modalContent: ReactNode
  openModal: (content: ReactNode, modalOptions?: ModalOptions) => void
  closeModal: () => void
  selectedState?: AustralianState
  setSelectedState: (val: undefined | AustralianState) => void
  activeTooltip?: Tooltip
  setActiveTooltip: (val: undefined | Tooltip) => void
  modalStyle?: ModalStyle // only used by modal to set style
  loadingSpinnerActive: boolean
  setLoadingSpinnerActive: (val: boolean) => void

  authToken?: string | null
  setAuthToken: (val: string | null) => void
  featuresToggle: FeatureToggle | undefined
  setFeaturesToggle: (val: FeatureToggle) => void
  addToast: (toast: Omit<Toast, 'expiryDate'>) => void
  removeToast: (toast: Toast) => void
  toasts: Toast[]

  activeCalendar?: CalendarPicker
  setActiveCalendar: (val: undefined | CalendarPicker) => void
}

export const GlobalContext = createContext<GlobalContext_T>({
  modalVisible: false,
  modalContent: null,
  openModal: () => {},
  closeModal: () => {},
  setSelectedState: (val?: AustralianState) => {},
  setActiveTooltip: (val?: Tooltip) => {},
  modalStyle: undefined,
  loadingSpinnerActive: false,
  setLoadingSpinnerActive: (val?: boolean) => {},
  authToken: undefined,
  setAuthToken: (val: string | null) => {},
  featuresToggle: undefined,
  setFeaturesToggle: (val: FeatureToggle) => {},
  addToast: (toast: Omit<Toast, 'expiryDate'>) => {},
  removeToast: (toast: Toast) => {},
  toasts: [],
  setActiveCalendar: (val?: CalendarPicker) => {}
})

interface GlobalProviderProps {
  children: ReactNode
}

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<ReactNode>()
  const [modalStyle, setModalStyle] = useState<ModalStyle>()
  const [activeTooltip, setActiveTooltip] = useState<Tooltip>()
  const [selectedState, setSelectedState] = useState<AustralianState>()
  const [loadingSpinnerActive, setLoadingSpinnerActive] = useState<boolean>(false)

  const [activeCalendar, setActiveCalendar] = useState<CalendarPicker>()

  const [featuresToggle, setFeaturesToggle] = useState<FeatureToggle>()

  const [toasts, setToasts] = useState<Toast[]>([])

  const [authToken, setAuthToken] = useState<string | null>()

  useEffect(() => {
    getStorageData('auth-token').then((authToken) => {
      setAuthToken(authToken)
    })
  }, [])

  useEffect(() => {
    getStorageData('feature-toggle-list').then((featureToggleList) => {
      if (featureToggleList) {
        setFeaturesToggle(merge(defaultFeaturesToggle, JSON.parse(featureToggleList)))
      } else setFeaturesToggle(defaultFeaturesToggle)
    })
  }, [])

  useEffect(() => {
    if (featuresToggle !== undefined) {
      setStorageData('feature-toggle-list', JSON.stringify(featuresToggle))
    }
  }, [featuresToggle])

  function addToast(toast: Omit<Toast, 'expiryDate'>) {
    const newToast: Toast = {
      ...toast,
      expiryDate: addSeconds(new Date(), expirySecondsAfterCreated)
    }
    setToasts((prev) => [...prev, newToast])
  }

  function removeToast(_toast: Toast) {
    setToasts((prev) =>
      prev.filter((t) => {
        const expired = isAfter(new Date(), t.expiryDate)
        return !expired
      })
    )
  }

  const openModal = (content: ReactNode, options?: ModalOptions) => {
    setModalStyle(options?.modalStyle)
    setModalVisible(true)
    setModalContent(content)
  }

  const closeModal = () => {
    setModalVisible(false)
    setModalContent(undefined)
    setModalStyle(undefined)
  }

  const contextValue: GlobalContext_T = {
    modalVisible,
    modalContent,
    openModal,
    closeModal,
    modalStyle,
    selectedState,
    setSelectedState,
    activeTooltip,
    setActiveTooltip,
    loadingSpinnerActive,
    setLoadingSpinnerActive,
    authToken,
    setAuthToken,
    featuresToggle,
    setFeaturesToggle,
    toasts,
    addToast,
    removeToast,
    activeCalendar,
    setActiveCalendar
  }

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}
