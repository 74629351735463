import { Image, ImageSourcePropType, View } from 'react-native'
import { Podcast } from '../../../../services/data/data-types/local-data-types/podcast-list.type'
import { createStyles } from '../../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../../styles/colors'
import { Asset } from '../../../../types/assets.type'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Pressable_C } from '../../../Base/Pressable/Pressable'
import { Text_C } from '../../../Base/Text/Text'
import { RenderRichText } from '../../../Partials/RichText/RichText'

import { openPlayerDialog } from '@onpace/onspace-media-react/components/player'
import { getSignedVideoAsset } from '../../../../services/data/request-functions/videos-latest-replays-request'

type Props = {
  podcast: Podcast
}
export function PodcastItem_C({ podcast }: Props) {
  const { description, state, title, video } = podcast
  return (
    <Pressable_C
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onPress={() => handleOpenModal(video)}
      style={{ elem: { base: styles.mainContainer } }}
    >
      <Image
        style={styles.image}
        source={(video.placeholderImageUrl ?? '') as ImageSourcePropType}
        resizeMethod="auto"
        resizeMode="cover"
      />
      <Heading_C styleType="h3" style={styles.heading}>
        {title}
      </Heading_C>
      {description && <RenderRichText content={description} />}
      <View style={styles.stateTag}>
        <Text_C style={styles.stateTagText}>{state}</Text_C>
      </View>
    </Pressable_C>
  )

  async function handleOpenModal(video: Asset) {
    let sources
    if (video.signedId) {
      const { playerSources } = await getSignedVideoAsset({
        signedId: video.signedId
      })
      sources = playerSources
    } else {
      sources = [
        {
          content_type: video.contentType,
          url: video.viewerSources?.[0].url
        }
      ]
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await openPlayerDialog(video.viewerType, {
      sources: sources,
      metadata: {
        title: video.title,
        artwork: video.placeholderImageUrl
      }
    })
  }
}

const styles = createStyles({
  mainContainer: {
    gap: 8,
    flex: 1
  },
  heading: {},
  image: {
    aspectRatio: 1.78125,
    borderRadius: 2,
    backgroundColor: presets.background
  },
  stateTag: {
    backgroundColor: colors.gray600,
    marginTop: 'auto',
    marginLeft: 'auto',
    paddingVertical: 2,
    paddingHorizontal: 6
  },
  stateTagText: {
    color: 'white',
    fontWeight: '600SemiBold'
  }
})
