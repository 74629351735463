import { endOfMonth, format, isSameDay, startOfMonth } from 'date-fns'
import { orderBy } from 'lodash'
import { ReactNode } from 'react'
import { View } from 'react-native'
import { daysOfTheWeek } from '../../../../constants'
import { desaturateColor, getDateNow } from '../../../../helper-functions'
import {
  DayPhaseLetter,
  MeetingStatus
} from '../../../../services/data/data-types/general-data-types.type'
import { CalendarMeeting } from '../../../../services/data/data-types/local-data-types/calendar-meeting.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import {
  breakpointSizes,
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../../services/styles/dependencies/style-constants'
import { calendarColors, colors, presets } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { AustralianState } from '../../../../types/map.type'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Link_C } from '../../../Base/Link/Link'
import { Pressable_C } from '../../../Base/Pressable/Pressable'
import { Text_C } from '../../../Base/Text/Text'

const selectedCellColour = desaturateColor(presets.primary, 2.6)

type Props = {
  selectedDate: Date
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
  selectedState?: AustralianState
  meetings?: CalendarMeeting[]
  fixedTrack?: string
}
export function Calendar_C({
  selectedDate,
  setSelectedDate,
  meetings,
  selectedState,
  fixedTrack
}: Props) {
  const isMobileLayout = breakpointBelow(tabletBreakpoint)

  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })

  const daysOfTheWeekHeadings = (
    <View style={styles.daysOfTheWeekContainer}>
      {daysOfTheWeek.map((day) => (
        <Text_C key={day.shortName} style={styles.dayOfTheWeekColumnText}>
          {day.shortName}
        </Text_C>
      ))}
    </View>
  )

  const mobileSelectedDayMeetings = (
    <View style={styles.mobileSelectedDate}>
      <Heading_C styleType="h2" style={styles.mobileSelectedDateHeading}>
        {format(selectedDate, 'EEE dd yyyy')}
      </Heading_C>
      {meetings?.length && (
        <View style={styles.mobileSelectedDateTracks}>
          {dateMeetingLinks(getFilteredSortedMeetings(selectedDate))}
        </View>
      )}
    </View>
  )

  return (
    <>
      {isMobileLayout && selectedDate && mobileSelectedDayMeetings}
      <View style={styles.calendarContainer}>
        {daysOfTheWeekHeadings}
        {makeWeekRows()}
      </View>
    </>
  )
  function makeWeekRows(): ReactNode[] {
    /*
      - uses copy of date to iterate over days,
        ~ sets month date to the first
        ~ will loop render rows of monday to sunday, until last day of the month is reached
        ~ week day cell will be empty until week day loop matches the first day
        ~ will render content and increment date each iteration until the last day of the month, then stop rendering
    */
    const iterateMonth = new Date(selectedDate)
    iterateMonth.setDate(1)
    const lastDateOfMonth = endOfMonth(selectedDate).getDate()
    const weekRows: ReactNode[] = []
    let safetyBreak = 0

    let started = false
    let finished = false

    while (!finished) {
      safetyBreak++
      if (safetyBreak > 10) {
        break
      }

      const weekRow = (
        <View style={styles.row} key={`week ${weekRows.length}`}>
          {daysOfTheWeek.map(({ dayIndex }, i) => {
            /* blank cells until day of the week matches first day of the month, also blank end of the month */

            let dayCell = <View key={weekRows.length + i} style={styles.rowCell} />

            /* if not start, start if is first day */
            if (!started) {
              const firstDayIndex = startOfMonth(iterateMonth).getDay()
              if (firstDayIndex == dayIndex) {
                started = true
              }
            }

            if (started && !finished) {
              const monthDate = iterateMonth.getDate()

              dayCell = createDayCell(iterateMonth)
              //if last day, finished
              if (monthDate == lastDateOfMonth) {
                finished = true
              }
              //if not finished, increment date for next week day iteration
              if (!finished) {
                iterateMonth.setDate(iterateMonth.getDate() + 1)
              }
            }
            //default unless overwritten
            return dayCell
          })}
        </View>
      )
      weekRows.push(weekRow)
      if (finished) {
        break
      }
    }
    return weekRows

    function createDayCell(monthDate: Date): JSX.Element {
      const isToday = isSameDay(monthDate, getDateNow())
      const isSelected = selectedDate && isSameDay(selectedDate, monthDate)

      const sortedFilteredMeetings = getFilteredSortedMeetings(monthDate)

      const thisDate = new Date(monthDate)

      return (
        <Pressable_C
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`date-cell-${monthDate}`}
          style={{
            elem: {
              ...styles.rowCell,
              backgroundColor: isSelected
                ? selectedCellColour
                : isToday
                  ? colors.gray100
                  : 'transparent'
            }
          }}
          onPress={() => setSelectedDate(thisDate)}
        >
          <Text_C numberOfLines={1} style={styles.day}>
            {format(monthDate, 'dd')}
          </Text_C>
          {(!isMobileLayout &&
            sortedFilteredMeetings?.length &&
            dateMeetingLinks(sortedFilteredMeetings)) || <></>}
          {isMobileLayout && !!sortedFilteredMeetings?.length && (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
              {sortedFilteredMeetings.map((m) => (
                <View
                  key={m.track.name + m.dayPhaseLetter + m.isTrial}
                  style={{
                    width: 6,
                    height: 10,
                    backgroundColor: m.isTrial ? colors.gray600 : calendarColors[m.dayPhaseLetter]
                  }}
                />
              ))}
            </View>
          )}
        </Pressable_C>
      )
    }
  }

  function isFilterMatch(m: CalendarMeeting) {
    return !selectedState || selectedState == m.track.state
  }

  function getFilteredSortedMeetings(date: Date) {
    const filteredMeetings = meetings?.filter((m) => !fixedTrack || m.track.name == fixedTrack)
    return orderBy(
      filteredMeetings?.filter((m) => isSameDay(new Date(m.date), date) && isFilterMatch(m)),
      [
        (m) => !isDisabled(m),
        (m) => m.isTrial,
        (m) => !m.isTab,
        (m) => {
          const index = (['D', 'T', 'N'] as DayPhaseLetter[]).indexOf(m.dayPhaseLetter)
          return index !== -1 ? index : 3
        }
      ],
      ['desc', 'asc']
    )
  }

  function isDisabled(m: CalendarMeeting) {
    return (['Abandoned', 'Transferred'] as MeetingStatus[]).includes(m.status)
  }

  function dateMeetingLinks(meetings: CalendarMeeting[]) {
    return meetings.map((m, i) => {
      return (
        <Link_C
          key={i}
          disabled={isDisabled(m)}
          style={{
            text: {
              base: {
                ...styles.link,

                color: m.isTrial
                  ? colors.gray700
                  : !m.isTab
                    ? colors.yellow
                    : calendarColors[m.dayPhaseLetter]
              },
              disabled: {
                color: colors.gray500,
                textDecorationLine: 'line-through'
              }
            }
          }}
          navigateTo={[
            m.status == 'Scheduled' ? 'MeetingProgram' : 'Meeting',
            {
              trackSlug: m.track.slug,
              date: m.date,
              dayPhaseLetter: m.dayPhaseLetter,
              type: m.isTrial ? 'trial' : 'meeting'
            }
          ]}
        >
          {m.track.shortName || m.track.name}
        </Link_C>
      )
    })
  }
}

const breakpointStyles = createStyles({
  calendarContainer: {
    base: { width: '100%', maxWidth: 500, alignSelf: 'center' },
    [tabletBreakpoint]: { maxWidth: breakpointSizes.xlarge }
  },
  daysOfTheWeekContainer: { base: { flexDirection: 'row', gap: 12 } },
  [desktopBreakpoint]: { gap: 20 },
  dayOfTheWeekColumnText: {
    flex: 1,
    textTransform: 'uppercase',
    fontWeight: '700Bold',
    paddingBottom: 24,
    ...textSizes.size5
  },
  day: {
    base: {
      ...textSizes.size4,
      alignItems: 'center',
      fontWeight: '600SemiBold',
      paddingBottom: 8
    },
    [tabletBreakpoint]: {
      fontWeight: '700Bold',
      alignItems: 'start',
      ...textSizes.size7
    }
  },
  row: {
    base: { width: '100%', flexDirection: 'row' },
    [tabletBreakpoint]: { gap: 12 },
    [desktopBreakpoint]: { gap: 20 }
  },
  rowCell: {
    base: {
      paddingVertical: 10,
      paddingHorizontal: 8,
      flex: 1,
      borderTopWidth: 1,
      borderTopColor: presets.border,
      alignItems: 'center',
      userSelect: 'none'
    },
    [tabletBreakpoint]: {
      alignItems: 'start',
      borderTopColor: 'black',
      paddingHorizontal: 4,
      borderTopWidth: 2,
      paddingTop: 6,
      paddingBottom: 24,
      minHeight: 160
    }
  },
  link: {
    base: {
      fontSize: 10,
      lineHeight: 18,
      fontWeight: '600SemiBold'
    },
    xlarge: {
      ...textSizes.size1,
      lineHeight: 20
    }
  },
  mobileSelectedDate: {
    padding: 20,
    maxWidth: 360,

    marginBottom: 30,
    backgroundColor: selectedCellColour,
    borderWidth: 1,
    borderColor: presets.border
  },
  mobileSelectedDateHeading: {
    marginBottom: 10
  },
  mobileSelectedDateTracks: {
    gap: 6
  }
})
