/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { API_ENV } from '@env'
import { useContext } from 'react'
import { Image, ImageSourcePropType, ImageStyle, View } from 'react-native'
import trackMapImageMock from '../../../../../assets/images/track-map-image-mock.jpg'
import { dayPhaseMap } from '../../../../../services/data/data-types/general-data-types.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../../../styles/text-sizes'
import { getAssetUrl } from '../../../../../utils/assets'
import { humanFriendlyDate } from '../../../../../utils/date-time'
import { Heading_C } from '../../../../Base/Heading/Heading'
import { Link_C } from '../../../../Base/Link/Link'
import { Map_C } from '../../../../Base/Map/Map'
import { Separator_C } from '../../../../Base/Separator/Separator'
import { Text_C } from '../../../../Base/Text/Text'
import { Card_C } from '../../../../Layout/Card/Card'
import { RenderRichText } from '../../../../Partials/RichText/RichText'
import { TrackContext } from '../../Track'
import { LatestResults_C } from './components/LatestResults'
import { RecordTables_C } from './components/RecordTables'
import { TrackDataTable_C } from './components/TrackDataTable'
import { TrackInfo_C } from './components/TrackInfo'
/* on mobile, info is to the edge of the card, so card padding is overwritten to 0, and other components need to have their own padding */
export function Overview_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { activeTrack } = useContext(TrackContext)

  const { about, lat, lng, next5Meetings, trackImage, slug, trackMapImage, last5Meetings } =
    activeTrack

  const isMobileLayout = breakpointBelow('medium')
  const isTabletLayout = breakpointBelow(desktopBreakpoint)

  const { image, map, aboutText, meetingsTable, trackMap } = getElements()

  const mobileLayout = (
    <Card_C style={styles.mobileLayoutContainer} noMobileHorizontalPadding>
      {image}
      {map}
      {trackMap}
      <TrackInfo_C />
      <TrackDataTable_C />
      {meetingsTable}
      <RecordTables_C />
    </Card_C>
  )

  const tabletLayout = (
    <Card_C style={styles.mobileLayoutContainer} noMobileHorizontalPadding>
      {image}

      <View style={{ flexDirection: 'row', gap: 24 }}>
        {lat && lng && (
          <Map_C
            defaultMapCoords={{ lat: lat, lng: lng }}
            zoom={15}
            nativeZoom={4}
            style={{ ...styles.section, flex: 1 }}
          />
        )}
        <View style={{ gap: 24, flex: 1 }}>
          {trackMap}
          <TrackInfo_C />
        </View>
      </View>

      <View style={{ flexDirection: 'row', gap: 24 }}>
        <TrackDataTable_C />
        {meetingsTable}
      </View>
      <RecordTables_C />
    </Card_C>
  )
  const desktopLayout = (
    <Card_C style={styles.desktopContainer}>
      <View style={styles.main}>
        {image}
        {aboutText}
        <View style={styles.trackAndMeetingContainer}>
          <TrackDataTable_C />
          {meetingsTable}
        </View>
        <RecordTables_C />
      </View>

      <View style={styles.aside}>
        {map}
        {trackMap}
        <TrackInfo_C />
        {!!last5Meetings.length && <LatestResults_C />}
      </View>
    </Card_C>
  )
  return isMobileLayout ? mobileLayout : isTabletLayout ? tabletLayout : desktopLayout

  function getElements() {
    const imageUrl = getAssetUrl(trackImage)

    const image = !imageUrl ? (
      <></>
    ) : (
      <Image
        style={{ ...styles.trackImage, ...styles.section } as ImageStyle}
        resizeMode={'cover'}
        source={imageUrl as ImageSourcePropType}
      />
    )

    const aboutText = !about ? (
      <></>
    ) : (
      <View style={styles.about}>
        <Heading_C styleType="h3" style={breakpointStyles.aboutHeading}>
          About The Track
        </Heading_C>
        <RenderRichText content={about} />
      </View>
    )

    const map = lat && lng && (
      <Map_C
        defaultMapCoords={{ lat: lat, lng: lng }}
        zoom={15}
        nativeZoom={4}
        style={{ ...styles.section }}
      />
    )

    const trackImageUrl = trackMapImage?.viewerSources?.[0]?.url

    const trackMap =
      !trackImageUrl && API_ENV == 'production' ? (
        <></>
      ) : (
        <View style={styles.trackMapImageContainer}>
          <Image
            source={trackImageUrl ?? trackMapImageMock}
            style={
              {
                ...styles.trackMapImage,
                ...(trackImageUrl ? {} : { aspectRatio: 1.7 })
              } as ImageStyle
            }
            resizeMode="contain"
            track-map-image-mock
          />
          {!trackImageUrl && (
            <Text_C style={styles.noTrackMapImageMessage}>
              {'map not uploaded yet\naspect ratio: 1.7'}
            </Text_C>
          )}
        </View>
      )

    const meetingsTable = !next5Meetings.length ? (
      <></>
    ) : (
      <View style={[styles.section, styles.meetingsContainer]}>
        <Heading_C styleType="h2">Meetings</Heading_C>
        <Separator_C />
        {next5Meetings.map((meeting, i) => {
          const text = `${humanFriendlyDate(new Date(meeting.date))} (${
            dayPhaseMap[meeting.dayPhaseLetter]
          } meeting)`

          return (
            <Link_C
              key={i}
              style={{
                text: breakpointStyles.meetingText
              }}
              navigateTo={[
                meeting.status == 'Scheduled' ? 'MeetingProgram' : 'Meeting',
                {
                  date: meeting.date,
                  trackSlug: slug,
                  dayPhaseLetter: meeting.dayPhaseLetter,
                  type: meeting.isTrial ? 'trial' : 'meeting'
                }
              ]}
            >
              {text}
            </Link_C>
          )
        })}
      </View>
    )
    return { image, map, aboutText, meetingsTable, trackMap }
  }
}

const breakpointStyles = createStyles({
  desktopContainer: {
    base: {
      flexDirection: 'column',
      gap: 24
    },
    [desktopBreakpoint]: {
      flexDirection: 'row',
      gap: 30
    },
    xxlarge: {
      gap: 40
    }
  },
  main: {
    base: {
      flex: 1,
      zIndex: 1,
      rowGap: 24
    },
    small: {
      rowGap: 30
    },
    large: {
      rowGap: 30
    }
  },
  aside: {
    base: {
      width: '100%'
    },
    [desktopBreakpoint]: {
      maxWidth: 350
    },
    xxlarge: {
      maxWidth: 400
    }
  },
  section: {
    base: { marginHorizontal: 14 }
  },
  trackImage: {
    base: {
      aspectRatio: 3.08
    },
    large: {
      height: 278
    }
  },
  about: {},
  aboutHeading: {
    base: {
      marginBottom: 12
    }
  },
  trackAndMeetingContainer: {
    base: {},
    large: {
      gap: 40
    },
    xxlarge: {
      flexDirection: 'row',
      gap: 20
    }
  },
  meetingsContainer: {
    base: {},
    small: {
      flex: 1
    },
    xlarge: {
      flex: 1
    }
  },
  meetingText: {
    base: { lineHeight: 30 }
  },
  mobileLayoutContainer: {
    base: {
      rowGap: 30
    }
  },
  trackMapImageContainer: {
    base: {
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      aspectRatio: 1.5,
      maxWidth: 300,
      width: '100%'
    }
  },
  trackMapImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  },
  noTrackMapImageMessage: {
    base: {
      backgroundColor: '#ffffffbb',
      padding: 10,
      fontWeight: '700Bold',
      textAlign: 'center',
      ...textSizes.size3
    }
  }
})
