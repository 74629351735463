import { shortDate } from '../../../utils/date-time'
import { LinkProps } from '../../Base/Link/Link'
export type MenuLink = {
  label: string
  linkProps: LinkProps
}

export const mainMenuLinksProps: MenuLink[] = [
  {
    label: 'Fields',
    linkProps: { navigateTo: ['Fields', {}] }
  },

  {
    label: 'Results',
    linkProps: { navigateTo: ['Results', {}] }
  },

  {
    label: 'Calendar',
    linkProps: {
      navigateTo: ['CalendarScreen', { date: shortDate(new Date()) }]
    }
  },
  {
    label: 'Videos',
    linkProps: { navigateTo: ['Videos', { categorySlug: 'replays' }] } // Date has intentionally been left blank
  },
  {
    label: 'News',
    linkProps: { navigateTo: ['News', {}] }
  },
  {
    label: 'Tracks',
    linkProps: { navigateTo: ['Tracks'] }
  },
  {
    label: 'Punters Corner',
    linkProps: {
      navigateTo: ['PuntersCorner', { date: shortDate(new Date()) }]
    }
  },
  /* ,
  {
    label: "Premierships",
    navigateTo: ["Premierships"],
  }, */ {
    label: 'Owners & Breeders',
    linkProps: { linkUrl: 'https://www.harness.org.au/owners-breeders.cfm' }
  },
  {
    label: 'Industry',
    linkProps: {
      linkUrl: 'https://www.harness.org.au/ausbreed/contacts-2.htm'
    }
  },
  {
    label: 'HarnessWeb',
    linkProps: {
      linkUrl: 'https://harnessweb.harness.org.au/harnessweb/secure/login.do'
    }
  },
  {
    label: 'Stallion Selector',
    linkProps: {
      linkUrl: 'https://www.harness.org.au/services/stallion-selector/'
    }
  },
  {
    label: 'HRA',
    linkProps: { linkUrl: 'https://hra.harness.org.au/hra.cfm' }
  }
]
