import { View } from 'react-native'
import { formatCurrency } from '../../../helper-functions'
import { PerformanceStats } from '../../../services/data/data-types/general-data-types.type'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { Text_C } from '../../Base/Text/Text'
import { BorderGrid_C } from '../../Layout/Grid/BorderGrid'
import { RadialGraph_C } from '../RadialGraph/RadialGraph'

export function GridAndGraph_C({ stats }: { stats: PerformanceStats }) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const grid = (
    <BorderGrid_C
      key={'grid' + stats.starts}
      style={{ grid: breakpointStyles.statsGrid }}
      gridBreakpointConfig={{
        base: { columns: 1 }
      }}
      cells={[
        {
          content: (
            <>
              <Text_C style={styles.labelText}>Summary:</Text_C>
              <Text_C>{stats.summary}</Text_C>
            </>
          )
        },
        {
          content: (
            <>
              <Text_C style={styles.labelText}>Stakes:</Text_C>
              <Text_C>{formatCurrency(stats.stakes)}</Text_C>
            </>
          )
        },
        ...(!stats.bestWinMileRate
          ? []
          : [
              {
                content: (
                  <>
                    <Text_C style={styles.labelText}>Best WMR:</Text_C>
                    <Text_C>{stats.bestWinMileRate}</Text_C>
                  </>
                )
              }
            ])
      ]}
    />
  )

  const graph = (
    <RadialGraph_C
      key={'graph' + stats.starts}
      dataItems={[
        {
          label: '1st',
          percent: stats.winPercent
        },
        {
          label: '2nd/3rd',
          percent: stats.placePercent
        },
        {
          label: 'Unplaced',
          percent: stats.unplacedPercent
        }
      ]}
    />
  )

  return (
    <View style={styles.gridAndGraphContainer}>
      {grid}
      {graph}
    </View>
  )
}

const breakpointStyles = createStyles({
  statsGrid: { base: { width: 120, flex: 0, flexBasis: 'auto' } },
  labelText: {
    base: { fontFamily: 'OpenSans', fontWeight: '700Bold' }
  },
  gridAndGraphContainer: {
    base: { flexDirection: 'row', gap: 10, alignItems: 'flex-start' }
  }
})
