import {
  TrainerResponse,
  getTrainer
} from '../../../services/data/request-functions/trainer-request'
import {
  TrainerRunsResponse,
  getTrainerRuns
} from '../../../services/data/request-functions/trainer-runs-request'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { BlackTypeRaces_C } from '../../Partials/_trainer-driver-components/BlackTypeRaces'
import { FeatureRaceWins_C } from '../../Partials/_trainer-driver-components/FeatureRaceWins'
import { TrainerDriverDetails_C } from '../../Partials/_trainer-driver-components/TrainerDriverDetails'
import { TrainerDriverLatestResults_C } from '../../Partials/_trainer-driver-components/TrainerDriverLatestResults'
import { TrainerDriverStats_C } from '../../Partials/_trainer-driver-components/TrainerDriverStats'
import { TrainerDriverTopHorseRuns_C } from '../../Partials/_trainer-driver-components/TrainerDriverTopHorseRuns'
import { TrainerDriverUpcomingRuns_C } from '../../Partials/_trainer-driver-components/TrainerDriverUpcomingRuns'
import { Metadata } from '../../Partials/Metadata/Metadata'

export function Trainer_C(): JSX.Element {
  return withScreenWrapper<'Trainer', [TrainerResponse, TrainerRunsResponse]>({
    requestsSetup: ({ trainerSlug }) => [
      getTrainer({ trainerSlug }),
      getTrainerRuns({ trainerSlug })
    ],

    view: ({ responses }) => {
      const [{ trainer }, { topHorsesRuns, upcomingRuns, latestResults }] = responses!

      const {
        blackTypePerformance,
        featureWins,
        lifetimePerformance,
        lastSeasonPerformance,
        thisSeasonPerformance
      } = trainer

      const hasFeatureWins = !!featureWins?.length

      return (
        <>
          <Metadata
            title={trainer.displayName}
            description={`View all of ${trainer.displayName}'s Trainer stats, race records and upcoming races on harness.org.au`}
          />
          <TrainerDriverDetails_C trainer={trainer} />
          <TrainerDriverStats_C
            {...{
              lifetimePerformance,
              lastSeasonPerformance,
              thisSeasonPerformance
            }}
          />
          {!!blackTypePerformance?.length && (
            <BlackTypeRaces_C blackTypePerformance={blackTypePerformance} />
          )}
          {hasFeatureWins && <FeatureRaceWins_C {...{ featureWins }} />}

          {!!upcomingRuns?.length && (
            <TrainerDriverUpcomingRuns_C {...{ upcomingRuns }} type="trainer" />
          )}
          {!!latestResults?.length && <TrainerDriverLatestResults_C {...{ latestResults }} />}
          {!!topHorsesRuns?.length && <TrainerDriverTopHorseRuns_C {...{ topHorsesRuns }} />}
        </>
      )
    }
  })
}
