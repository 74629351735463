/* eslint-disable react/jsx-key */
import { useContext, useMemo, useState } from 'react'
import { GlobalContext } from '../../../../../global-context'
import {
  BlackBookHorsesResponse,
  BlackBookUpcomingResponse,
  getBlackBookHorses,
  getBlackBookUpcoming
} from '../../../../../services/data/request-functions/black-book-request'
import { ComponentWithFetch_C } from '../../../../HigherOrderComponents/ComponentWithFetch'
import { TabConfigItem, TabView_C } from '../../../../Layout/TabView/TabView'
import { PerPageOption } from '../../../../Partials/Pagination/components/PerPageSelect'
import { BlackbookHorsesTable_C } from './components/BlackBookHorseTable'
import { UpcomingTable_C } from './components/UpcomingTable'

export const BlackBook_C = (): JSX.Element => {
  const { authToken } = useContext(GlobalContext)
  return useMemo(() => {
    return (
      <ComponentWithFetch_C<[BlackBookUpcomingResponse, BlackBookHorsesResponse]>
        fetchRequests={[
          getBlackBookUpcoming({ authToken: authToken! }),
          getBlackBookHorses({ authToken: authToken! })
        ]}
        showLoading
        view={({ responses: [initialUpcomingResponse, initialHorsesResponse] }) => {
          const { addToast } = useContext(GlobalContext)

          const [horsesResponse, setHorsesResponse] = useState(initialHorsesResponse)
          const [upcomingResponse, setUpcomingResponse] = useState(initialUpcomingResponse)

          const trackTabs: TabConfigItem[] = [
            [
              'Upcoming',
              <UpcomingTable_C
                upcomingResponse={upcomingResponse}
                onUpcomingPageChange={onUpcomingPageChange}
              />
            ],

            [
              'Horses',
              <BlackbookHorsesTable_C
                horsesResponse={horsesResponse}
                onUpdated={reFetchData}
                onHorsesPageChange={onHorsesPageChange}
              />
            ]
          ]

          return <TabView_C heading="Blackbook" tabs={trackTabs} separateCards={false} />

          function reFetchData() {
            Promise.all([
              getBlackBookUpcoming({
                authToken: authToken!,
                activePageNumber: upcomingResponse.pagination?.activePageNumber
              }),
              getBlackBookHorses({
                authToken: authToken!,
                activePageNumber: horsesResponse.pagination?.activePageNumber
              })
            ]).then(([upcomingResponse, horsesResponse]) => {
              setHorsesResponse(horsesResponse)
              setUpcomingResponse(upcomingResponse)
              addToast({ message: 'Update successful', status: 'success' })
            })
          }

          function onHorsesPageChange(param?: { pageNumber?: number; perPage?: PerPageOption }) {
            const { pageNumber, perPage } = param ?? {}
            getBlackBookHorses({
              authToken: authToken!,
              activePageNumber: pageNumber ?? 1,
              perPage
            }).then((horsesResponse) => {
              setHorsesResponse(horsesResponse)
            })
          }

          function onUpcomingPageChange(param?: { pageNumber?: number; perPage?: PerPageOption }) {
            const { pageNumber, perPage } = param ?? {}
            getBlackBookUpcoming({
              authToken: authToken!,
              activePageNumber: pageNumber ?? 1,
              perPage
            }).then((upcomingResponse) => {
              setUpcomingResponse(upcomingResponse)
            })
          }
        }}
      />
    )
  }, [])
}
