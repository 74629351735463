/* eslint-disable no-unused-vars */
import { loopObject, mapObject } from '../../../../../../helper-functions'
import {
  RunnerBasic,
  Sectional
} from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import { sectionalsColors } from '../../../../../../styles/colors'
import { TableCell } from '../../../../../Base/Table/table.type'

export type SectionalStats = {
  [key in keyof Sectional]: {
    min: number
    max?: number
  }
}

export function getSectionalStats(runners: RunnerBasic[]): SectionalStats {
  const propSpreadArrays: {
    [key in keyof Required<Sectional>]: number[]
  } = {
    last800: [],
    q3Split: [],
    q4Split: [],
    margin800: [],
    margin400: [],
    marginFinish: [],
    metresGained800400: [],
    metresGained400Finish: [],
    metresGained800Finish: []
  }

  runners.forEach((runner) => {
    if (runner.sectionalData) {
      loopObject(runner.sectionalData, (propName, value) => {
        if (value != undefined) {
          let val = value
          if (propName == 'metresGained800Finish') {
            const mar800 = runner.sectionalData!['margin800']!
            const marFin = runner.sectionalData!['marginFinish']!

            const calcVal = mar800 - marFin
            val = calcVal
          }
          propSpreadArrays[propName].push(val)
        }
      })
    }
  })

  const sectionalStats: SectionalStats = mapObject(propSpreadArrays, (propName, spreadArray) => {
    return {
      [propName]: {
        min: Math.min(...spreadArray),
        max: Math.max(...spreadArray)
      }
    } as {
      [key in keyof Sectional]: {
        min: number
        max?: number
      }
    }
  })
  return sectionalStats
}

export const valueWidth = 60
export const horseWidth = 60
export const arrowWidth = 15

export type FinishScaled = {
  name: string
  marginPercent: number
  metresGained: number
  gainPercent: number
  isMax: boolean
  isMin: boolean
}

export function getFinishScaled(runners: RunnerBasic[], elemWidth: number): FinishScaled[] {
  // Step 1: Prepare the scaling objects by extracting necessary information
  const scalingObj = runners
    .filter(
      (r) => r.sectionalData?.margin800 != undefined && r.sectionalData?.marginFinish != undefined
    )
    .map(({ sectionalData, horse: { name } }) => {
      const { marginFinish, margin800 } = sectionalData!
      const metresGained = margin800! - marginFinish!
      const spreadValue = -marginFinish! + metresGained

      return { name, margin800, marginFinish, metresGained, spreadValue }
    })

  // Step 2: Find the minimum and maximum values for scaling
  const spreadArr = scalingObj.map((x) => x.spreadValue)
  const minValue = Math.min(...spreadArr)
  const maxValue = Math.max(...spreadArr)
  const range = maxValue - minValue

  // Step 3: Calculate the fixed and scaling portions
  const valueWidthPercent = valueWidth / elemWidth
  const arrowWidthPercent = arrowWidth / elemWidth
  const fixedPortion = horseWidth / elemWidth + 2 * (valueWidth / elemWidth) + 2 * arrowWidthPercent
  const scalePortion = 1 - fixedPortion

  // Step 4: Process scaling objects to calculate gain and margin percentages
  const scalingProcessedData = scalingObj.map(
    ({ name, metresGained, marginFinish, spreadValue }) => {
      const gainPercentInitial = (metresGained * (metresGained >= 0 ? 1 : -1)) / range
      const gainPercentWithRest = gainPercentInitial * scalePortion

      const val = marginFinish! * -1 + (metresGained >= 0 ? 0 : metresGained)
      const marginLeftInitial = (val - minValue) / range
      let marginLeftWithRest = marginLeftInitial * scalePortion

      // Step 5: Adjust the left margin for positive gain horses
      if (metresGained >= 0) {
        marginLeftWithRest += valueWidthPercent + arrowWidthPercent
      }

      return {
        gainPercent: gainPercentWithRest * 100,
        marginPercent: marginLeftWithRest * 100,
        isMax: spreadValue === maxValue,
        isMin: spreadValue === minValue,
        metresGained,
        name
      }
    }
  )

  return scalingProcessedData
}

export function getSectionalDataCell(
  sectionalStats: SectionalStats,
  sectionalData: Sectional | undefined,
  dataProp: keyof Sectional
): TableCell {
  const value = sectionalData?.[dataProp]

  let isLead, isLast

  const marginCellTypes: (keyof Sectional)[] = ['margin400', 'marginFinish', 'margin800']
  const gainedCellTypes: (keyof Sectional)[] = ['metresGained400Finish', 'metresGained800400']

  if (marginCellTypes.includes(dataProp) || ['q3Split', 'q4Split'].includes(dataProp)) {
    isLead = sectionalStats[dataProp]?.min == value
    isLast = sectionalStats[dataProp]?.max == value
  }
  if (gainedCellTypes.includes(dataProp) || dataProp == 'last800') {
    isLead = sectionalStats[dataProp]?.max == value
    isLast = sectionalStats[dataProp]?.min == value
  }

  const cell: TableCell = {
    style: {
      alignItems: 'center',
      backgroundColor: isLead
        ? sectionalsColors.greenHighlight
        : gainedCellTypes.includes(dataProp) && isLast
          ? sectionalsColors.redHighlight
          : 'transparent'
    },
    content: marginCellTypes.includes(dataProp) && isLead ? 'Lead' : value?.toFixed(2) ?? '--'
  }
  return cell
}
