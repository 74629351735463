import { HorseResponse } from '../request-functions/horse-request'

export const horseDesignMock: HorseResponse = {
  meta: {
    status: 123,
    code: 'asfda'
  },
  runner: {
    runnerNumber: 1,
    horse: {
      name: 'Reginald',
      slug: 'reginald'
    },
    driver: {
      name: 'John Walters',
      shortName: '',
      slug: 'john-walters'
    },
    trainer: {
      name: 'Rodney Ashwood',
      shortName: '',
      slug: 'rodney-ashwood'
    },
    form: '354s8',
    otherEng: [
      {
        name: 'ALBP',
        shortName: 'ALBP',
        date: new Date('12 Apr'),
        raceNumber: 1
      }
    ],
    class: 'Nr47',
    hcp: 'FR1',
    lastRun: {
      date: new Date('12-10-2021'),
      location: 'Albion Park',
      replayRef: 'dummyReplayLink',
      placement: '8th'
    },
    partnerOdds: { name: 'tab', odds: 17 },
    age: '10yo',
    color: 'Brown',
    sex: 'Gelding',
    sire: {
      name: 'Rocknroll Dance',
      slug: ''
    },
    dam: {
      name: 'Eighteencharacters (Alphabet Soup USA)',
      slug: ''
    },
    owners: ['B J Beasley', 'E D Coxon'],
    breeder: 'J McFlannigan',
    career: '101: 46-12-13',
    lifetimeStakes: '$123,456',
    bestMileRate: '1:54:99',
    thisSeason: '26: 5-5-6',
    lastSeason: '24: 4-7-5',
    trainerLocation: 'Logan Village',
    nationalRating: '57 B5',
    last15: '1034568x095638',
    mobile: '99: 45-12-12',
    track: '88: 41-10-10',
    distanceRange: '55: 23-6-7',
    bmrDistanceRange: '1:54:99',
    winPercent: 26,
    placePercent: 39,
    gait: 'Pacer',
    foalDate: new Date(),
    broodmareSire: 'Gotta Go Cullect NZ',
    hwoe: 'L4 (H$6757.5)',
    place: 'string',
    prizeMoney: '$285,000',
    br: 'SR4',
    margin: 'string',
    sp: 'string',
    comments: ['string']
  }
} as any
