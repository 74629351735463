import { View } from 'react-native'
import { ResultsInfo } from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  breakpointBelow,
  createStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../../../services/styles/dependencies/style-constants'
import { Table_C } from '../../../../../Base/Table/Table'
import { Text_C } from '../../../../../Base/Text/Text'
import { BorderGrid_C } from '../../../../../Layout/Grid/BorderGrid'

type Props = {
  resultsInfo: ResultsInfo
}

export function ResultsInfo_C({ resultsInfo: raceInfo }: Props) {
  const isMobileLayout = breakpointBelow(desktopBreakpoint)

  const { sectionalTimes, grossTime, cumulativeMile, leadTime, margins, mileRate } = raceInfo

  const [firstQuarter, secondQuarter, thirdQuarter, fourthQuarter] = sectionalTimes ?? []
  const [cumulative, firstHalf, threeQuarterMile, lastMile] = cumulativeMile ?? []

  const mobileLayout = (
    <BorderGrid_C
      gridBreakpointConfig={{
        base: { columns: 2 }
      }}
      cells={[
        {
          content: (
            <View style={styles.gridCell}>
              <Text_C style={styles.labelText}>Margins</Text_C>
              <Text_C>{margins ?? '---'}</Text_C>
            </View>
          )
        },
        {
          content: (
            <View style={styles.gridCell}>
              <Text_C style={styles.labelText}>Gross Time</Text_C>
              <Text_C>{grossTime}</Text_C>
            </View>
          )
        },
        {
          content: (
            <View style={styles.gridCell}>
              <Text_C style={styles.labelText}>Lead Time</Text_C>
              <Text_C>{leadTime ?? '---'}</Text_C>
            </View>
          )
        },
        {
          content: (
            <View style={styles.gridCell}>
              <Text_C style={styles.labelText}>Mile Rate</Text_C>
              <Text_C>{mileRate}</Text_C>
            </View>
          )
        },
        ...(!sectionalTimes
          ? []
          : (() => {
              return [
                {
                  content: (
                    <View style={styles.gridCell}>
                      <Text_C style={styles.labelText}>1st QTR</Text_C>
                      <Text_C>{firstQuarter}</Text_C>
                    </View>
                  )
                },
                {
                  content: (
                    <View style={styles.gridCell}>
                      <Text_C style={styles.labelText}>2nd QTR</Text_C>
                      <Text_C>{secondQuarter}</Text_C>
                    </View>
                  )
                },
                {
                  content: (
                    <View style={styles.gridCell}>
                      <Text_C style={styles.labelText}>3rd QTR</Text_C>
                      <Text_C>{thirdQuarter}</Text_C>
                    </View>
                  )
                },
                {
                  content: (
                    <View style={styles.gridCell}>
                      <Text_C style={styles.labelText}>4th QTR</Text_C>
                      <Text_C>{fourthQuarter}</Text_C>
                    </View>
                  )
                }
              ]
            })()),
        {
          content: (
            <View style={styles.gridCell}>
              <Text_C style={styles.labelText}>Cumulative Mile</Text_C>
              <Text_C>{cumulative}</Text_C>
            </View>
          )
        },
        {
          content: (
            <View style={styles.gridCell}>
              <Text_C style={styles.labelText}>1st Half</Text_C>
              <Text_C>{firstHalf}</Text_C>
            </View>
          )
        },
        {
          content: (
            <View style={styles.gridCell}>
              <Text_C style={styles.labelText}>3/4 Mile</Text_C>
              <Text_C>{threeQuarterMile}</Text_C>
            </View>
          )
        },
        {
          content: (
            <View style={styles.gridCell}>
              <Text_C style={styles.labelText}>Last Mile</Text_C>
              <Text_C>{lastMile}</Text_C>
            </View>
          )
        }
      ]}
    />
  )
  const desktopLayout = (
    <Table_C
      styleType="lightHeaderBorders"
      headings={[
        'Margins',
        'Gross Time',
        'Lead Time',
        'Mile Rate',
        '1st QTR',
        '2nd QTR',
        '3rd QTR',
        '4th QTR',
        { content: 'Cumulative Mile', colWidth: { flex: 1.1 } },
        '1st Half',
        '3/4 Mile',
        'Last Mile'
      ]}
      rows={[
        {
          cells: [
            margins ?? '---',
            grossTime,
            leadTime ?? '---',
            mileRate,
            firstQuarter,
            secondQuarter,
            thirdQuarter,
            fourthQuarter,
            cumulative,
            firstHalf,
            threeQuarterMile,
            lastMile
          ]
        }
      ]}
    ></Table_C>
  )

  return isMobileLayout ? mobileLayout : desktopLayout
}
const styles = createStyles({
  labelText: {
    fontFamily: 'OpenSans',
    fontWeight: '700Bold'
  },
  gridCell: { gap: 4 }
})
