import { concat, orderBy, sortBy } from 'lodash'

import { Pressable, View } from 'react-native'
import {
  RunnerBasic,
  Sectional
} from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  breakpointAbove,
  breakpointBelow
} from '../../../../../../services/styles/breakpoint-styles.service'

import ordinal from 'ordinal'
import { useState } from 'react'
import { SilkImage_C } from '../../../../../../components/Partials/SilkImage/SilkImage'
import { isNumeric } from '../../../../../../helper-functions'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { colors, presets, sectionalsColors } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { Icon_C } from '../../../../../Base/Icon/Icon'
import { chevronIcon } from '../../../../../Base/Icon/preset-icon-props'
import { ScrollHorizontal_C } from '../../../../../Base/ScrollHorizontal/ScrollHorizontal'
import { Switch_C } from '../../../../../Base/Switch/Switch'
import { TableStyles, Table_C } from '../../../../../Base/Table/Table'
import { TableCell, TableHeading, TableRow } from '../../../../../Base/Table/table.type'
import { Text_C } from '../../../../../Base/Text/Text'
import { ExpandContainer_C } from '../../../../../Layout/ExpandContainer/ExpandContainer'
import { LinkOrText_C } from '../../../../../Partials/LinkOrText'
import { FinishGainedGraphic_C } from './FinishGainedGraphic'
import { SectionalsExpandedRowMobile_C } from './SectionalsExpandedRowMobile'
import { getSectionalDataCell, getSectionalStats } from './sectionals-utils'

export type HeadingItem = {
  title: string
  description?: string
  width?: number
  flex?: number
}
const HEADINGS_DESKTOP: HeadingItem[] = [
  { title: 'Number and Horse', width: 280 },
  { title: 'Last 800', description: 'km/h', width: 80 },
  { title: '800m', description: 'Margin', width: 80 },
  { title: 'Q3 Split', description: '(800-400)', width: 80 },
  { title: '400m', description: 'Margin', width: 70 },
  { title: 'Q4 Split', description: '(400-Finish)', width: 100 },
  { title: 'Metres gained', description: '(800-400)', width: 100 },
  { title: 'Metres gained', description: '(400-Finish)', width: 100 },
  { title: 'Finish', description: 'Margin', width: 70 },
  { title: 'Metres gained', description: '(800-Finish)' }
]

const HEADINGS_MOBILE: HeadingItem[] = [
  { title: 'Number and Horse' },
  { title: 'Last 800', description: 'km/h', width: 80 },
  { title: '', description: '', width: 80 }
]
const HEADINGS_TABLET: HeadingItem[] = [
  { title: 'Number and Horse', width: 280 },
  { title: 'Last 800', description: 'km/h' },
  { title: '800m', description: 'Margin' },
  { title: 'Q3 Split', description: '(800-400)' },
  { title: '400m', description: 'Margin' },
  { title: 'Q4 Split', description: '(400-Finish)' },
  { title: 'Metres gained', description: '(800-400)' },
  { title: 'Metres gained', description: '(400-Finish)' },
  { title: 'Finish', description: 'Margin' }
]
type Props = {
  runners: RunnerBasic[]
}
type State = {
  allRunnersExpanded: boolean
  expandedRunnerNumber: number | undefined
}

export function SectionalsTable_C({ runners: unsortedRunners }: Props) {
  const runners = sortByPlaceNumber()
  const sectionalStats = getSectionalStats(runners)
  const [state, setState] = useState<State>({
    allRunnersExpanded: false,
    expandedRunnerNumber: undefined
  })

  const isDesktopLayout = breakpointAbove('xxlarge')
  const isMobileLayout = breakpointBelow('xlarge')
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { expandAllSwitch, rows, headings, mobileTable } = setup()

  return (
    <View style={{ gap: 10 }}>
      {expandAllSwitch}
      {isMobileLayout ? (
        mobileTable
      ) : (
        <Table_C
          styleType="lightHeaderBorders"
          headings={headings}
          rows={rows}
          styles={tableStyle}
        />
      )}
    </View>
  )

  function setup() {
    const expandAllSwitch = (
      <View style={styles.expandSwitch}>
        <Switch_C
          onSwitched={(val) => {
            setState((prev) => ({
              ...prev,
              allRunnersExpanded: val
            }))
          }}
          isEnabled={state.allRunnersExpanded}
        />
        <Text_C style={styles.switchText}>Expand All</Text_C>
      </View>
    )

    const headings: TableHeading[] = (
      isDesktopLayout ? HEADINGS_DESKTOP : isMobileLayout ? HEADINGS_MOBILE : HEADINGS_TABLET
    ).map((heading) => ({
      colWidth: heading.width ? { width: heading.width } : { flex: 1 },
      style: {},
      content: `${heading.title}${heading.description ? `\n${heading.description}` : ''}`
    }))

    const rows: TableRow[] = runners.map((runner) => {
      const { sectionalData } = runner

      const { horse, driver, trainer, runnerNumber, place } = runner
      const placeVal = !place ? '--' : isNumeric(place) ? ordinal(Number(place)) : place

      const horseCell: TableCell = {
        content: (
          <View style={{ flexDirection: 'row', gap: 16, alignItems: 'center' }}>
            <View>
              <Text_C style={{ textAlign: 'center' }}>{placeVal}</Text_C>
              <SilkImage_C silk={runner.silk} />
            </View>
            <View style={styles.linksContainer}>
              <LinkOrText_C
                name={`${runnerNumber}. ${horse.name}`}
                newTab
                linkUrl={!horse.slug ? undefined : `horse/${horse.slug}`}
                linkStyle={{
                  text: {
                    base: styles.runner
                  }
                }}
              />
              {driver && (
                <Text_C>
                  D:{' '}
                  <LinkOrText_C
                    newTab
                    name={driver.name}
                    linkUrl={!driver.slug ? undefined : `driver/${driver.slug}`}
                  />
                </Text_C>
              )}
              {trainer && (
                <Text_C>
                  T:{' '}
                  <LinkOrText_C
                    newTab
                    name={trainer.name}
                    linkUrl={!trainer.slug ? undefined : `trainer/${trainer.slug}`}
                  />
                </Text_C>
              )}
            </View>
          </View>
        )
      }

      const finishGainedGraphicCell: TableCell = {
        content: <FinishGainedGraphic_C {...{ runner, runners, sectionalStats }} />
      }

      function getCell(dataProp: keyof Sectional): TableCell {
        if (sectionalData) {
          return getSectionalDataCell(sectionalStats, sectionalData, dataProp)
        }
        return { content: '--', textStyle: { textAlign: 'center' } }
      }

      if (isDesktopLayout) {
        return {
          cells: [
            horseCell,
            getCell('last800'),
            getCell('margin800'),
            getCell('q3Split'),
            getCell('margin400'),
            getCell('q4Split'),
            getCell('metresGained800400'),
            getCell('metresGained400Finish'),
            getCell('marginFinish'),
            finishGainedGraphicCell
          ]
        }
      } else {
        //tablet
        return {
          cells: [
            horseCell,
            getCell('last800'),
            getCell('margin800'),
            getCell('q3Split'),
            getCell('margin400'),
            getCell('q4Split'),
            getCell('metresGained800400'),
            getCell('metresGained400Finish'),
            getCell('marginFinish')
          ]
        }
      }
    })

    const mobileTable = !isMobileLayout ? (
      <></>
    ) : (
      runners.map((runner) => {
        const { sectionalData } = runner

        const { horse, driver, trainer, runnerNumber, place } = runner
        const isExpanded =
          state.allRunnersExpanded ||
          (!!state.expandedRunnerNumber && state.expandedRunnerNumber === runnerNumber)

        const isLead = sectionalStats.last800?.max == sectionalData?.last800

        const placeVal = !place ? '--' : isNumeric(place) ? ordinal(Number(place)) : place

        return (
          <Pressable onPress={() => expandRunnerRow(runner)} key={runner.runnerNumber}>
            <View style={styles.runnerRow}>
              <View style={{ flexDirection: 'row', gap: 16, alignItems: 'center' }}>
                <View>
                  <Text_C style={{ textAlign: 'center' }}>{placeVal}</Text_C>
                  <SilkImage_C silk={runner.silk} />
                </View>
                <View style={styles.linksContainer}>
                  <LinkOrText_C
                    name={`${runnerNumber}. ${horse.name}`}
                    newTab
                    linkUrl={!horse.slug ? undefined : `horse/${horse.slug}`}
                    linkStyle={{
                      text: {
                        base: styles.runner
                      }
                    }}
                  />
                  {driver && (
                    <Text_C>
                      D:{' '}
                      <LinkOrText_C
                        newTab
                        name={driver.name}
                        linkUrl={!driver.slug ? undefined : `driver/${driver.slug}`}
                      />
                    </Text_C>
                  )}
                  {trainer && (
                    <Text_C>
                      T:{' '}
                      <LinkOrText_C
                        newTab
                        name={trainer.name}
                        linkUrl={!trainer.slug ? undefined : `trainer/${trainer.slug}`}
                      />
                    </Text_C>
                  )}
                </View>
              </View>

              <View
                style={{
                  justifyContent: 'center',
                  marginLeft: 'auto',
                  backgroundColor: isLead ? sectionalsColors.greenHighlight : 'transparent',
                  paddingHorizontal: 10,
                  paddingVertical: 8
                }}
              >
                <Text_C style={styles.headingText}>{'Last 800\n(km/h)'}</Text_C>
                <Text_C
                  style={{
                    ...styles.valueText,
                    color: colors.gray600
                  }}
                >
                  {sectionalData?.last800?.toFixed(2) || '--'}
                </Text_C>
              </View>

              <Icon_C
                {...chevronIcon}
                color={colors.gray600}
                style={{
                  width: 20,
                  transform: [{ rotate: isExpanded ? '0deg' : '-90deg' }]
                }}
              />
            </View>
            <ExpandContainer_C isExpanded={isExpanded}>
              <ScrollHorizontal_C contentContainerStyle={{ width: '100%' }}>
                <SectionalsExpandedRowMobile_C {...{ runner, sectionalStats }} />
              </ScrollHorizontal_C>
            </ExpandContainer_C>
          </Pressable>
        )
      })
    )

    return { expandAllSwitch, rows, headings, mobileTable }
  }

  function sortByPlaceNumber() {
    const runnersWithPlaces = orderBy(
      unsortedRunners.filter((r) => !!r.place),
      (r) => (isNumeric(r.place) ? Number(r.place) : Infinity)
    )

    const scratched = unsortedRunners.filter((r) => r.scratched)

    const rest = sortBy(
      unsortedRunners.filter((r) => !r.scratched && !r.place),
      'place'
    )

    return concat(runnersWithPlaces, rest, scratched)
  }
  function expandRunnerRow(runner: RunnerBasic) {
    setState((prevState) => ({
      ...prevState,
      expandedRunnerNumber:
        runner.runnerNumber === prevState.expandedRunnerNumber ? undefined : runner.runnerNumber
    }))
  }
}

const breakpointStyles = createStyles({
  linksContainer: {
    alignItems: 'flex-start',
    gap: 4
  },
  runner: {
    base: {
      ...textSizes.size4,
      fontWeight: '600SemiBold',
      marginRight: 4
    }
  },
  expandSwitch: {
    base: {
      flexDirection: 'row',
      gap: 10,
      marginTop: 8,
      alignItems: 'center'
    }
  },
  switchText: {
    ...textSizes.size1,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold'
  },
  runnerRow: {
    base: {
      flexDirection: 'row',
      columnGap: 5,
      alignItems: 'stretch',
      borderBottomWidth: 1,
      borderBottomColor: presets.border,
      paddingHorizontal: 10,
      paddingVertical: 8,
      justifyContent: 'space-between'
    },
    small: {
      columnGap: 16
    }
  },
  runnerPlaceStyle: {
    base: {
      ...textSizes.size1,
      paddingVertical: 6,
      width: 30,
      alignItems: 'center',
      justifyContent: 'center'
    },
    small: {
      width: 40
    }
  },
  headingText: {
    ...textSizes.size1,
    fontWeight: '700Bold'
  },
  valueText: {
    ...textSizes.size1,
    marginTop: 5,
    fontWeight: '600SemiBold'
  }
})

const tableStyle: TableStyles = {
  tableContainer: { width: '100%' },
  table: { width: '100%' },
  heading: { textAlign: 'center' }
}
