/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { isAfter } from 'date-fns'
import { useState } from 'react'
import { View } from 'react-native'
import { MeetingPuntersCorner } from '../../../../services/data/request-functions/punters-corner-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { colors, presets, stateColor } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { humanFriendlyTime } from '../../../../utils/date-time'
import { Button_C } from '../../../Base/Button/Button'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Icon_C } from '../../../Base/Icon/Icon'
import { dayIcon, eyeIcon, nightIcon, twilightIcon } from '../../../Base/Icon/preset-icon-props'
import { Link_C } from '../../../Base/Link/Link'
import { TableStyles, Table_C } from '../../../Base/Table/Table'
import { Text_C } from '../../../Base/Text/Text'
import { ExpandContainer_C } from '../../../Layout/ExpandContainer/ExpandContainer'
import { SimpleContainer_C } from '../../../Layout/SimpleContainer/SimpleContainer'
import { PrintButton_C } from '../../../Partials/PrintButton'
import { Analysis_C } from '../../../Partials/_meeting-components/Analysis'
import { GearChanges_C } from '../../../Partials/_meeting-components/GearChanges'
import { Scratchings_C } from '../../../Partials/_meeting-components/Scratchings'
import { Tips_C } from '../../../Partials/_meeting-components/Tips'
import { WeatherIcon_C } from '../../../Partials/_meeting-components/WeatherIcon'
import { ExpandedContentTypes } from '../punters-corner-ui.type'

type ExpandedRow = {
  trackName: string
  contentType: ExpandedContentTypes
}

type Props = {
  meetings: MeetingPuntersCorner[]
}
export function PuntersCornerDesktop_C({ meetings }: Props) {
  const [expandedRow, setExpandedRow] = useState<ExpandedRow | undefined>()
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  return (
    <Table_C
      styles={tableStyles}
      styleType="lightHeaderBorders"
      headings={[
        {
          content: 'Track',
          colWidth: { width: 420 }
        },
        {
          content: 'Fields',
          colWidth: { flex: 2 }
        },
        {
          content: 'Form',
          colWidth: { flex: 2 }
        },
        'Tips',
        'Analysis',
        {
          content: 'Scratchings',
          colWidth: { width: 100 }
        },
        'Gear'
      ]}
      rows={meetings.map((meeting) => {
        const { races, tips, analysis, track, date, dayPhaseLetter } = meeting

        const scratchingsCount = races.reduce(
          (count, race) => count + race.runners?.filter((r) => r.isScratched).length,
          0
        )
        const gearChangesCount = races.reduce(
          (count, race) => count + race.runners?.filter((r) => r.gearAdded || r.gearRemoved).length,
          0
        )

        const weatherIcon = meeting.weather && <WeatherIcon_C weather={meeting.weather} />

        const tipsExpanded =
          expandedRow?.contentType == 'tips' && expandedRow.trackName == track.name

        const analysisExpanded =
          expandedRow?.contentType == 'analysis' && expandedRow.trackName == track.name

        const scratchingsExpanded =
          expandedRow?.contentType == 'scratchings' && expandedRow.trackName == track.name

        const gearChangeExpanded =
          expandedRow?.contentType == 'gear' && expandedRow.trackName == track.name

        const nextRaceTime = races.find((r) => isAfter(r.startTime, new Date()))?.startTime

        return {
          underRowContent: (
            <ExpandContainer_C
              isExpanded={expandedRow?.trackName == track.name}
              styles={{ innerContainer: styles.innerContainer }}
            >
              {expandedRow?.contentType == 'gear' && (
                <SimpleContainer_C>
                  <Heading_C style={styles.heading} styleType="h3">
                    Gear Changes
                  </Heading_C>
                  <GearChanges_C races={meeting.races} />
                </SimpleContainer_C>
              )}
              {expandedRow?.contentType == 'scratchings' && (
                <SimpleContainer_C>
                  <Heading_C style={styles.heading} styleType="h3">
                    Scratchings
                  </Heading_C>
                  <Scratchings_C races={meeting.races} />
                </SimpleContainer_C>
              )}
              {expandedRow?.contentType == 'tips' && (
                <SimpleContainer_C>
                  <Heading_C style={styles.heading} styleType="h3">
                    Tips
                  </Heading_C>
                  <Tips_C tips={meeting.tips!} />
                </SimpleContainer_C>
              )}
              {expandedRow?.contentType == 'analysis' && (
                <SimpleContainer_C>
                  <Heading_C style={styles.heading} styleType="h3">
                    Analysis
                  </Heading_C>
                  <Analysis_C analysis={meeting.analysis!} />
                </SimpleContainer_C>
              )}
            </ExpandContainer_C>
          ),
          cells: [
            {
              content: (
                <View style={styles.trackCellContainer}>
                  <Link_C
                    navigateTo={[
                      'Meeting',
                      {
                        date: meeting.date,
                        trackSlug: track.slug,
                        dayPhaseLetter: meeting.dayPhaseLetter,
                        type: meeting.isTrial ? 'trial' : 'meeting'
                      }
                    ]}
                    style={{ elem: styles.trackLinkContainer }}
                  >
                    <View
                      style={[
                        styles.stateColor,
                        {
                          backgroundColor: track.state ? stateColor[track.state] : colors.gray500
                        }
                      ]}
                    />
                    <View style={styles.trackNameContainer}>
                      <View style={styles.nameAndIconContainer}>
                        <Text_C style={styles.trackNameText}>
                          {track.shortName || track.name}
                        </Text_C>
                        <Icon_C
                          {...(meeting.dayPhaseLetter == 'T'
                            ? twilightIcon
                            : meeting.dayPhaseLetter == 'N'
                              ? nightIcon
                              : dayIcon)}
                          style={{
                            width: meeting.dayPhaseLetter == 'N' ? 10 : 14
                          }}
                        />
                      </View>
                      <Text_C style={styles.trackStateText}>{track.state}</Text_C>
                    </View>
                  </Link_C>
                  {weatherIcon}
                  <View style={styles.racesInfo}>
                    <View>
                      <Text_C style={styles.racesCount}>{meeting.races.length} races</Text_C>
                    </View>
                    {nextRaceTime && (
                      <View>
                        <Text_C>Next race {humanFriendlyTime(nextRaceTime)}</Text_C>
                      </View>
                    )}
                  </View>
                </View>
              )
            },
            {
              //field
              content: (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <Link_C
                    styleType="iconOnly"
                    icon={eyeIcon}
                    navigateTo={[
                      'Meeting',
                      {
                        date: meeting.date,
                        trackSlug: track.slug,
                        dayPhaseLetter: meeting.dayPhaseLetter,
                        type: meeting.isTrial ? 'trial' : 'meeting'
                      }
                    ]}
                  />
                  <PrintButton_C meeting={meeting} printType="fields" />
                </View>
              )
            },
            {
              //form
              content: (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <Link_C
                    styleType="iconOnly"
                    icon={eyeIcon}
                    navigateTo={[
                      'Meeting',
                      {
                        date,
                        trackSlug: track.slug,
                        dayPhaseLetter,
                        type: meeting.isTrial ? 'trial' : 'meeting'
                      }
                    ]}
                  />
                  <PrintButton_C meeting={meeting} printType="form" />
                </View>
              )
            },
            <Button_C
              styleType="iconOnly"
              icon={{
                ...eyeIcon,
                ...(tipsExpanded && { color: presets.primary })
              }}
              active={tipsExpanded}
              accessibilityState={{ selected: tipsExpanded }}
              disabled={!tips}
              onPress={() =>
                setExpandedRow(
                  tipsExpanded
                    ? undefined
                    : {
                        trackName: track.name,
                        contentType: 'tips'
                      }
                )
              }
            />,
            <Button_C
              styleType="iconOnly"
              icon={{
                ...eyeIcon,
                ...(analysisExpanded && { color: presets.primary })
              }}
              active={analysisExpanded}
              accessibilityState={{ selected: analysisExpanded }}
              disabled={!analysis}
              onPress={() =>
                setExpandedRow(
                  analysisExpanded
                    ? undefined
                    : {
                        trackName: track.name,
                        contentType: 'analysis'
                      }
                )
              }
            />,
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 10
              }}
            >
              <Button_C
                styleType="iconOnly"
                active={scratchingsExpanded}
                accessibilityState={{ selected: scratchingsExpanded }}
                disabled={!scratchingsCount}
                icon={{
                  ...eyeIcon,
                  ...(scratchingsExpanded && {
                    color: presets.primary
                  })
                }}
                onPress={() =>
                  setExpandedRow(
                    scratchingsExpanded
                      ? undefined
                      : {
                          trackName: track.name,
                          contentType: 'scratchings'
                        }
                  )
                }
              />
              {!!scratchingsCount && <Text_C>{`(${scratchingsCount})`}</Text_C>}
            </View>,
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 10
              }}
            >
              <Button_C
                styleType="iconOnly"
                active={gearChangeExpanded}
                accessibilityState={{ selected: gearChangeExpanded }}
                disabled={!gearChangesCount}
                icon={{
                  ...eyeIcon,
                  ...(gearChangeExpanded && {
                    color: presets.primary
                  })
                }}
                onPress={() =>
                  setExpandedRow(
                    gearChangeExpanded
                      ? undefined
                      : {
                          trackName: track.name,
                          contentType: 'gear'
                        }
                  )
                }
              />
              {!!gearChangesCount && <Text_C>{`(${gearChangesCount})`}</Text_C>}
            </View>
          ]
        }
      })}
    />
  )
}
const tableStyles: TableStyles = {
  tableContainer: { width: '100%' },
  table: { width: '100%' }
}

const breakpointStyles = createStyles({
  trackCellContainer: {
    flex: 1,
    flexDirection: 'row',
    gap: 24,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  trackLinkContainer: {
    flexDirection: 'row',
    width: 200,
    marginRight: 'auto'
  },
  trackNameContainer: {
    flex: 1,
    justifyContent: 'center',
    rowGap: 5,
    marginTop: 2,
    marginLeft: 12
  },
  stateColor: { width: 9 },
  nameAndIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 6
  },
  trackNameText: {
    ...textSizes.size3,
    textTransform: 'capitalize',
    fontFamily: 'OpenSans',
    fontWeight: '700Bold',
    color: colors.gray700
  },
  trackStateText: {
    ...textSizes.size1,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold',
    lineHeight: 12,
    marginTop: 2,
    color: colors.gray700
  },
  scratchingsText: {
    ...textSizes.size3
  },
  racesInfo: {
    gap: 4
  },
  racesCount: {
    ...textSizes.size3,
    fontWeight: '600SemiBold'
  },
  container: {
    padding: 30,
    backgroundColor: colors.gray50
  },
  innerContainer: {
    borderColor: presets.border,
    borderWidth: 1,
    borderTopWidth: 0
  },
  contentContainer: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: presets.border,
    padding: 30,
    marginRight: 'auto',
    gap: 20
  },
  heading: { marginBottom: 20 }
})
