/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { AToZ, aToZ } from '../../../../constants'
import { wordStartsWithLetter } from '../../../../helper-functions'

import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { colors } from '../../../../styles/colors'
import { textSizes } from '../../../../styles/text-sizes'
import { Button_C } from '../../../Base/Button/Button'
import { PressableStyleExtension } from '../../../Base/Pressable/Pressable'

type Props = {
  onLetterSelected: (letter: AToZ | null) => void
  wordList: string[]
}

export function AToZFilter_C(props: Props) {
  const [selectedLetter, setSelectedLetter] = useState<AToZ | null>(null)

  const styles = useBreakpointStyles({ styles: breakpointStyles })
  useEffect(() => {
    props.onLetterSelected(selectedLetter)
  }, [selectedLetter])

  function handleLetterSelected(letter: AToZ) {
    setSelectedLetter(letter == selectedLetter ? null : letter)
  }

  function isLetterDisabled(letter: string): boolean {
    return !props.wordList.some((word) => wordStartsWithLetter(word, letter))
  }

  return (
    <View style={styles.lettersContainer}>
      {aToZ.map((letter) => (
        <Button_C
          key={letter}
          styleType="iconOnly"
          active={letter == selectedLetter}
          accessibilityState={{ selected: letter == selectedLetter }}
          disabled={isLetterDisabled(letter)}
          onPress={() => handleLetterSelected(letter)}
          style={aToZButton}
        >
          {letter}
        </Button_C>
      ))}
    </View>
  )
}
const breakpointStyles = createStyles({
  lettersContainer: {
    base: {},
    medium: {
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: colors.gray400,
      borderRadius: 4,
      paddingHorizontal: 8
    },
    xxlarge: {
      paddingHorizontal: 10
    }
  }
})

const aToZButton: PressableStyleExtension = {
  elem: {
    base: { padding: 0, borderRadius: 0 },
    active: {
      backgroundColor: colors.blue
    }
  },
  text: {
    base: {
      ...textSizes.size1,
      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      color: colors.gray600
    },
    medium: {
      paddingVertical: 4,
      paddingHorizontal: 3
    },
    xlarge: {
      paddingHorizontal: 4
    },
    xxlarge: {
      paddingHorizontal: 5
    },
    disabled: {
      color: colors.gray400
    },
    active: {
      color: 'white'
    }
  }
}
