import { useEffect, useRef } from 'react'
import { IframeProps } from './types'

export const IFrame_C = ({ uri, style, onLoadEnd }: IframeProps) => {
  const ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const iframe = ref.current
    if (!onLoadEnd || !iframe) return

    iframe.addEventListener('load', onLoadEnd)

    return () => {
      iframe.removeEventListener('load', onLoadEnd)
    }
  }, [ref, onLoadEnd])

  return <iframe ref={ref} src={uri} style={style} />
}
