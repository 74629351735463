/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { AToZ, australianStates } from '../../../constants'
import {
  TracksBasicResponse,
  getTracksBasicData
} from '../../../services/data/request-functions/tracks-basic-request'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { AustralianState } from '../../../types/map.type'
import { Heading_C } from '../../Base/Heading/Heading'
import { Select_C } from '../../Base/Select/Select'
import { Card_C } from '../../Layout/Card/Card'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'

import { OptionToggle_C } from '../../Partials/OptionToggle/OptionToggle'
import { AToZFilter_C } from './components/AToZFilter'
import { TrackList_C } from './components/TrackList'
import { TrackMap_C } from './components/TrackMap'
import { Metadata } from '../../Partials/Metadata/Metadata'

type State = {
  showMap: boolean
  selectedState: AustralianState | null
  aToZFilter: AToZ | null
}

export function TrackIndex_C(): JSX.Element {
  return withScreenWrapper<'Tracks', [TracksBasicResponse]>({
    requestsSetup: () => [getTracksBasicData({})],

    view: ({ responses }) => {
      const [{ tracksBasic }] = responses!

      const [state, setState] = useState<State>({
        showMap: true,
        aToZFilter: null,
        selectedState: null
      })

      const stateFilteredTracks = tracksBasic.filter(
        (track) => state.selectedState == null || track.state == state.selectedState
      )

      const styles = useBreakpointStyles({ styles: breakpointStyles })
      const mLayout = useBreakpointStyles({ styles: mobileLayoutStyles })
      const dLayout = useBreakpointStyles({
        styles: desktopLayoutStyles
      })

      function handleMapListOptionSelect(option: 'Map' | 'List') {
        setState({
          ...state,
          showMap: option == 'Map',
          ...(option == 'List' ? { aToZFilter: null } : {})
        })
      }

      const isMobileLayout = breakpointBelow('medium')
      const isTabletLayout = breakpointBelow(tabletBreakpoint)
      const { heading, stateSelect, map, aToZFilter, trackList, showMapToggle } = getElements()

      const mobileLayout = (
        <View>
          {heading}
          <View style={mLayout.stateAndMapToggle}>
            {stateSelect}
            {showMapToggle}
          </View>
          {map}
          <View style={mLayout.listAndFilter}>
            <ScrollView nestedScrollEnabled>{trackList}</ScrollView>
            {aToZFilter}
          </View>
        </View>
      )
      const tabletLayout = (
        <>
          <View style={dLayout.topContainer}>
            {heading}
            {stateSelect}
            {showMapToggle}
          </View>
          <View style={{ alignItems: 'flex-end' }}>{aToZFilter}</View>
          <View style={dLayout.bottomContainer}>
            {map}
            {trackList}
          </View>
        </>
      )
      const desktopLayout = (
        <>
          <View style={dLayout.topContainer}>
            {heading}
            {stateSelect}
            {showMapToggle}
            {aToZFilter}
          </View>

          <View style={dLayout.bottomContainer}>
            {map}
            {trackList}
          </View>
        </>
      )

      return (
        <>
          <Metadata
            title="Harness Racing Tracks"
            description="View all harness racing track from across Australia on harness.org.au"
          />

          <Card_C style={styles.cardContainer}>
            {isMobileLayout ? mobileLayout : isTabletLayout ? tabletLayout : desktopLayout}
          </Card_C>
        </>
      )

      function getElements() {
        const heading = (
          <Heading_C styleType="h1" style={breakpointStyles.heading}>
            Tracks
          </Heading_C>
        )
        const selectOptions = [
          { label: 'All States', value: null },
          ...australianStates
            .filter((s) => tracksBasic.some((t) => t.state == s))
            .map((state) => ({ label: state, value: state }))
        ]
        const stateSelect = (
          <Select_C<AustralianState | null>
            styleType="greyChevron"
            initialSelection={
              state.selectedState
                ? selectOptions.find((option) => option.value == state.selectedState)?.label
                : selectOptions[0].label
            }
            options={selectOptions}
            onSelect={(selectedOption) =>
              setState({
                ...state,
                selectedState: selectedOption.value
              })
            }
          />
        )
        const map = !state.showMap ? (
          <></>
        ) : (
          <TrackMap_C
            selectedState={state.selectedState}
            aToZFilter={state.aToZFilter}
            markerLocations={tracksBasic.map(({ lat, lng, name, state }) => ({
              locationName: name,
              state,
              mapCoords: {
                lat,
                lng
              }
            }))}
            style={styles.map}
          />
        )

        const showMapToggle = (
          <OptionToggle_C
            options={[
              {
                name: 'List',
                icon: {
                  name: 'list',
                  style: { height: 11 }
                }
              },
              {
                name: 'Map',
                icon: {
                  name: 'map',
                  colorFill: true,
                  style: { height: 16 }
                }
              }
            ]}
            initialSelectedOption={state.showMap ? 'Map' : 'List'}
            onOptionSelected={handleMapListOptionSelect}
          />
        )

        const aToZFilter = (
          <AToZFilter_C
            onLetterSelected={(letter) => setState({ ...state, aToZFilter: letter })}
            wordList={stateFilteredTracks.map((track) => track.name)}
          />
        )

        const trackList = (
          <TrackList_C
            stateFilteredTracks={stateFilteredTracks}
            selectedState={state.selectedState}
            aToZFilter={state.aToZFilter}
            isListView={!state.showMap}
          />
        )
        return {
          heading,
          stateSelect,
          map,
          aToZFilter,
          trackList,
          showMapToggle
        }
      }
    }
  })
}

const breakpointStyles = createStyles({
  cardContainer: {
    base: {
      paddingHorizontal: 0,
      gap: 10
    },
    medium: {
      paddingHorizontal: 20,
      gap: 20
    }
  },
  heading: {
    base: { marginBottom: 20, paddingHorizontal: 14, marginRight: 'auto' },
    medium: {
      marginBottom: 0,
      paddingHorizontal: 0
    }
  },
  map: {
    base: {},
    large: { flex: 1 }
  }
})

const mobileLayoutStyles = createStyles({
  stateAndMapToggle: {
    base: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginHorizontal: 12,
      marginBottom: 18
    }
  },
  listAndFilter: {
    base: {
      flexDirection: 'row',
      height: 494,
      marginTop: 16,
      gap: 33,
      paddingHorizontal: 14
    }
  }
})
const desktopLayoutStyles = createStyles({
  topContainer: {
    base: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20
    }
  },
  bottomContainer: {
    base: { flexDirection: 'row', gap: 36 }
  },
  trackListContainer: {
    base: {
      width: 500
    }
  }
})
