import { useEffect, useState } from 'react'
import { getAdSizes } from '../../../helper-functions'
import {
  AdsObj,
  getAdvertisements
} from '../../../services/data/request-functions/advertisement-request'

// Note: isRendered is used to refetch when the screens are re-rendered

export function useAds(pathname?: string, isRendered: boolean = false): AdsObj | undefined {
  const [adsObj, setAdsObj] = useState<AdsObj | undefined>()

  useEffect(() => {
    if (!pathname) return

    getAdvertisements(pathname).then(({ adsObj }) => {
      if (isRendered) {
        getAdSizes(adsObj)
          .then((adsObj) => {
            setAdsObj(adsObj)
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.warn(err)
          })
      }
    })
  }, [isRendered])

  return adsObj
}
