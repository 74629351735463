/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { PerPageOption } from '../../../components/Partials/Pagination/components/PerPageSelect'
import { mapSilkObj, removeLeadingSlash, toCapitalized } from '../../../helper-functions'
import { fetchData } from '../api-fetch-request'
import {
  BlackBookHorsesFetchResponse,
  BlackBookUpcomingFetchResponse,
  DeleteBlackBookItemFetchResponse,
  UpdateBlackBookItemFetchResponse
} from '../data-types/fetch-response-types/black-book-response.type'
import { meetingStatusMap, Pagination } from '../data-types/general-data-types.type'
import { BlackBookHorse, BlackbookItem } from '../data-types/local-data-types/blackbook.type'

// upcoming
export type BlackBookUpcomingResponse = {
  upcoming: BlackbookItem[]
  pagination: Pagination
}
type BlackBookUpcomingProps = {
  authToken: string
  activePageNumber?: number
  perPage?: PerPageOption
}

export async function getBlackBookUpcoming({
  authToken,
  activePageNumber,
  perPage
}: BlackBookUpcomingProps): Promise<BlackBookUpcomingResponse> {
  return fetchData<BlackBookUpcomingResponse, BlackBookUpcomingFetchResponse>({
    endpoint:
      'member/blackbook/upcoming?' +
      new URLSearchParams({
        'pagination[limit]': `${perPage ?? 10}`,
        'pagination[page]': `${activePageNumber ?? 1}`
      }),
    convertResponse,
    validationCompareObject: undefined,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })

  function convertResponse(payload: BlackBookUpcomingFetchResponse): BlackBookUpcomingResponse {
    const { runners, pagination } = payload
    const response: BlackBookUpcomingResponse = {
      pagination: {
        activePageNumber: pagination.page,
        totalPages: pagination.pages,
        perPage: pagination.limit as PerPageOption,
        totalRecords: pagination.total
      },
      upcoming: runners.map(({ blackbook_horse, driver, horse, race, silk, trainer }) => {
        const { comment, notifications } = blackbook_horse
        const blackBookItem: BlackbookItem = {
          id: horse.id,
          comment: comment ?? '',
          useNotifications: notifications,
          nextRace: {
            meeting: {
              date: new Date(race.meeting.date),
              isTrial: race.meeting.is_trial,
              dayPhaseLetter: race.meeting.day_night_twilight,
              status: meetingStatusMap[race.meeting.meeting_status]
            },
            race: {
              startTime: new Date(race.scheduled_start_time),
              raceNumber: race.race_number,
              distance: race.distance
            },
            track: {
              name: toCapitalized(race.meeting.track.name),
              slug: removeLeadingSlash(race.meeting.track.slug)
            },
            horse: {
              name: toCapitalized(horse.name),
              slug: removeLeadingSlash(horse.slug),
              silk: silk ? mapSilkObj(silk) : undefined
            },
            trainer: {
              name: trainer.display_name ?? undefined,
              shortName: trainer.mobile_display_name ?? undefined,
              slug: trainer.slug ? removeLeadingSlash(trainer.slug) : undefined
            },
            driver: {
              name: driver.display_name ?? undefined,
              shortName: driver.mobile_display_name ?? undefined,
              slug: driver.slug ? removeLeadingSlash(driver.slug) : undefined
            }
          }
        }
        return blackBookItem
      })
    }

    return response
  }
}

// horses

export type BlackBookHorsesResponse = {
  horses: BlackBookHorse[]
  pagination?: Pagination
}
type BlackBookHorsesProps = {
  authToken: string
  activePageNumber?: number
  perPage?: PerPageOption

  horseIds?: (string | number)[]
}

export async function getBlackBookHorses({
  authToken,
  activePageNumber,
  perPage,
  horseIds
}: BlackBookHorsesProps): Promise<BlackBookHorsesResponse> {
  let url =
    'member/blackbook/horses?' +
    new URLSearchParams({
      'pagination[limit]': `${perPage ?? 10}`,
      'pagination[page]': `${activePageNumber ?? 1}`
    })
  if (horseIds?.length) {
    url += `&${horseIds?.reduce(
      (idsString, id, i) => idsString + `ids[]=${id}${i != horseIds.length ? '&' : ''}`,
      ''
    )}`
  }

  return fetchData<BlackBookHorsesResponse, BlackBookHorsesFetchResponse>({
    endpoint: url,
    convertResponse,
    validationCompareObject: undefined,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })

  function convertResponse(payload: BlackBookHorsesFetchResponse): BlackBookHorsesResponse {
    const { horses, pagination } = payload
    const response: BlackBookHorsesResponse = {
      pagination: !pagination
        ? undefined
        : {
            activePageNumber: pagination.page,
            totalPages: pagination.pages,
            perPage: pagination.limit as PerPageOption,
            totalRecords: pagination.total
          },
      horses: horses.map(({ blackbook_horse, id, name, slug }) => {
        const { comment, notifications } = blackbook_horse
        const horse: BlackBookHorse = {
          id,
          comment,
          useNotifications: notifications,
          horse: {
            name: toCapitalized(name),
            slug: removeLeadingSlash(slug)
          }
        }
        return horse
      })
    }

    return response
  }
}

// update blackbook item

export type UpdateBlackBookItemResponse = true

type UpdateBlackbookItemProps = {
  authToken: string
  blackbookItemId: number
  comment: string
  useNotifications: boolean
}

export async function updateBlackBookItem({
  authToken,
  blackbookItemId,
  comment,
  useNotifications
}: UpdateBlackbookItemProps): Promise<UpdateBlackBookItemResponse> {
  const body = {
    horse: {
      notifications: useNotifications,
      comment: comment
    }
  }
  return fetchData<UpdateBlackBookItemResponse, UpdateBlackBookItemFetchResponse>({
    endpoint: `member/blackbook/horses/${blackbookItemId}`,
    convertResponse,
    validationCompareObject: undefined,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  function convertResponse(payload: UpdateBlackBookItemFetchResponse): UpdateBlackBookItemResponse {
    return true
  }
}

// delete blackbook item

export type DeleteBlackBookItemResponse = true

type DeleteBlackbookItemProps = {
  authToken: string
  blackbookItemId: number
}

export async function deleteBlackBookItem({
  authToken,
  blackbookItemId
}: DeleteBlackbookItemProps): Promise<DeleteBlackBookItemResponse> {
  return fetchData<DeleteBlackBookItemResponse, DeleteBlackBookItemFetchResponse>({
    endpoint: `member/blackbook/horses/${blackbookItemId}`,
    convertResponse,
    validationCompareObject: undefined,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })

  function convertResponse(payload: DeleteBlackBookItemFetchResponse): DeleteBlackBookItemResponse {
    return true
  }
}
