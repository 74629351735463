/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { fetchData } from '../api-fetch-request'
import {
  ForgotPasswordFetchResponse,
  LoginFetchResponse,
  ResetPasswordFetchResponse,
  SignupFetchResponse,
  TestAuthenticatedFetchResponse,
  UpdateUserFetchResponse,
  UserFetchResponse
} from '../data-types/fetch-response-types/user-response.type'
import { NameTitle, User } from '../data-types/local-data-types/user.type'

/* login */
type LoginUserParams = {
  email: string
  password: string
}

export type LoginResponse = {
  authToken: string
}

export async function loginUser({ email, password }: LoginUserParams): Promise<LoginResponse> {
  const endpoint = 'member/authentication/login'

  const body: BodyInit = JSON.stringify({
    email,
    password
  })
  return fetchData<LoginResponse, LoginFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined,
    body,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  function convertResponse(userPayload: LoginFetchResponse): LoginResponse {
    return {
      authToken: userPayload.jwt_token
    }
  }
}

/* signup */
type SignupUserParams = {
  title: NameTitle
  email: string
  password: string
  firstName: string
  lastName: string
}

export type SignupResponse = {
  authToken: string
}

export async function signupUser({
  title,
  email,
  password,
  firstName,
  lastName
}: SignupUserParams): Promise<SignupResponse> {
  const endpoint = 'member/authentication/sign-up'

  const body: BodyInit = JSON.stringify({
    member: {
      name_prefix: title,
      name_given: firstName,
      name_family: lastName,
      email,
      password
    }
  })

  return fetchData<SignupResponse, SignupFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined,
    body,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  function convertResponse(userPayload: SignupFetchResponse): SignupResponse {
    return {
      authToken: userPayload.jwt_token
    }
  }
}

// logout
type LogoutParams = {
  authToken: string
}
export type LogoutResponse = true
export async function logoutUser({ authToken }: LogoutParams): Promise<LogoutResponse> {
  const endpoint = 'member/authentication/logout/'

  return fetchData<LogoutResponse, UserFetchResponse>({
    endpoint,
    convertResponse,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })

  function convertResponse(userPayload: UserFetchResponse): LogoutResponse {
    return true
  }
}

// test authenticated
type TestAuthenticatedParams = {
  authToken: string
}
export type TestAuthenticatedResponse = {
  loggedIn: boolean
}
export async function testAuthenticated({
  authToken
}: TestAuthenticatedParams): Promise<TestAuthenticatedResponse> {
  const endpoint = 'member/current'

  return fetchData<TestAuthenticatedResponse, TestAuthenticatedFetchResponse>({
    endpoint,
    convertResponse,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })

  function convertResponse(userPayload: TestAuthenticatedFetchResponse): TestAuthenticatedResponse {
    return {
      loggedIn: userPayload.logged_in
    }
  }
}

export type UserInfoResponse = {
  user: User
}

type UserInfoParams = {
  authToken: string
}
export async function getUserInfo({ authToken }: UserInfoParams): Promise<UserInfoResponse> {
  const endpoint = 'member/accounts'

  return fetchData<UserInfoResponse, UserFetchResponse>({
    endpoint,
    convertResponse,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })

  function convertResponse(userPayload: UserFetchResponse): UserInfoResponse {
    const { email, name_family, name_given, name_prefix } = userPayload.member
    return {
      user: {
        email,
        firstName: name_given,
        lastName: name_family,
        title: name_prefix as NameTitle
      }
    }
  }
}

//update
type UpdateUserParams = {
  user: User
  authToken: string
}
type UpdateUserResponse = true
export async function updateUserInfo({
  user,
  authToken
}: UpdateUserParams): Promise<UpdateUserResponse> {
  const endpoint = 'member/accounts'
  const { title, firstName, lastName, email } = user

  const body = JSON.stringify({
    member: {
      name_prefix: title,
      name_given: firstName,
      name_family: lastName,
      email
    }
  })

  return fetchData<UpdateUserResponse, UpdateUserFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },

    body
  })

  function convertResponse(userPayload: UpdateUserFetchResponse): UpdateUserResponse {
    return true
  }
}

//forgot password
type ForgotPasswordUserParams = {
  email: string
}
type ForgotPasswordResponse = true
export async function forgotUserPassword({
  email
}: ForgotPasswordUserParams): Promise<ForgotPasswordResponse> {
  const endpoint = 'member/authentication/forgot-password'

  const body = JSON.stringify({
    email
  })

  return fetchData<ForgotPasswordResponse, ForgotPasswordFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })

  function convertResponse(userPayload: ForgotPasswordFetchResponse): ForgotPasswordResponse {
    return true
  }
}

type ChangePasswordParams = {
  currentPassword: string
  newPassword: string
  authToken: string
}

type ChangePasswordResponse = true

export async function changePassword({
  currentPassword,
  newPassword,
  authToken
}: ChangePasswordParams): Promise<ChangePasswordResponse> {
  const endpoint = 'member/passwords'

  const body = JSON.stringify({
    password: {
      password: currentPassword,
      new_password: newPassword,
      password_confirmation: newPassword
    }
  })
  return fetchData<ChangePasswordResponse, any>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    },
    body
  })

  function convertResponse(userPayload: any): ChangePasswordResponse {
    return true
  }
}

//change password
type ResetPasswordParams = {
  password: string
  resetToken: string
}
type ResetPasswordResponse = {
  authToken: string
}
export async function resetPassword({
  password,
  resetToken
}: ResetPasswordParams): Promise<ResetPasswordResponse> {
  const endpoint = `member/authentication/reset-password/${resetToken}`

  const body = JSON.stringify({
    member: {
      password
    }
  })
  return fetchData<ResetPasswordResponse, ResetPasswordFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  })

  function convertResponse(userPayload: ResetPasswordFetchResponse): ResetPasswordResponse {
    return { authToken: userPayload.jwt_token }
  }
}
