import { PerPageOption } from '../../../components/Partials/Pagination/components/PerPageSelect'
import { removeLeadingSlash } from '../../../helper-functions'
import { AustralianState } from '../../../types/map.type'
import { convertAsset } from '../../../utils/assets'
import { shortDate } from '../../../utils/date-time'
import { fetchData } from '../api-fetch-request'
import { NewsListFetchResponse } from '../data-types/fetch-response-types/news-list-response.type'
import { Pagination } from '../data-types/general-data-types.type'
import { NewsArticle, NewsCategory } from '../data-types/local-data-types/news.type'
import { newsListFetchMock } from '../mock-responses/news-list-mock'

export type NewsListResponse = {
  pagination: Pagination
  articles: NewsArticle[]
  categories: Array<NewsCategory & { priority?: number }>
  activeCategory?: string
}

type Params = {
  categorySlug?: string
  limit: number
  date?: Date
  state?: AustralianState | 'Others'
  page?: number
  useMock?: boolean
}
export async function getNewsList({
  categorySlug,
  page,
  limit,
  date,
  state,
  useMock
}: Params): Promise<NewsListResponse> {
  const endpoint = `news/articles/list?pagination[limit]=${limit}&pagination[page]=${
    page ?? 1
  }${!categorySlug ? '' : `&category_slug=${categorySlug}`}${
    !date ? '' : `&date=${shortDate(date)}`
  }${!state ? '' : `&state=${state.toLocaleLowerCase()}`}`
  if (useMock) {
    return convertResponse(newsListFetchMock)
  }
  return fetchData<NewsListResponse, NewsListFetchResponse>({
    endpoint,
    convertResponse
  })

  function convertResponse(payload: NewsListFetchResponse): NewsListResponse {
    const { articles, pagination, categories } = payload
    return {
      pagination: {
        activePageNumber: pagination.page,
        totalPages: pagination.pages,
        perPage: pagination.limit as PerPageOption,
        totalRecords: pagination.total
      },
      articles: articles.map((a) => {
        const {
          author,
          categories,
          headline,
          slug,
          published,
          content,
          hero,
          id,
          trots_url,
          pdf,
          state,
          state_logo
        } = a
        return <NewsArticle>{
          id,
          headline: headline.trim(),
          author: author,
          slug: removeLeadingSlash(slug),
          published: published,
          categories: categories.map((c) => {
            return {
              ...c,
              priority: c.priority ?? undefined,
              slug: removeLeadingSlash(c.slug)
            }
          }),
          content: content,
          hero: !hero ? undefined : convertAsset(hero),
          pdf: pdf,
          trotsUrl: trots_url,
          state: state?.toUpperCase() as AustralianState | 'Others',
          stateLogo: !state_logo ? undefined : convertAsset(state_logo)
        }
      }),
      categories: categories.map((c) => {
        return {
          ...c,
          priority: c.priority ?? undefined,
          slug: removeLeadingSlash(c.slug)
        }
      })
    }
  }
}
