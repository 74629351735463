import AsyncStorage from '@react-native-async-storage/async-storage'
import { isNull } from 'lodash'

type StorageOption = {
  'ad-popup-dismissed': boolean | null
  'auth-token': string | null
  'feature-toggle-list': string | null
}

type StorageKey = keyof StorageOption

//// Get storage data
export const getStorageData = async <T extends StorageKey>(key: T): Promise<StorageOption[T]> => {
  return AsyncStorage.getItem(key).then((val) => {
    return !isNull(val) ? (JSON.parse(val) as StorageOption[T]) : null
  })
}

export const setStorageData = async <T extends StorageKey>(key: T, value: StorageOption[T]) => {
  await AsyncStorage.setItem(key, JSON.stringify(value))
}

export const unsetStorageData = async (key: StorageKey) => await AsyncStorage.removeItem(key)
