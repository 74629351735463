/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { ParamList, ScreenName } from './services/routes/screens'

export type NamedObj<Obj, Names extends any = any> = {
  [name in keyof Names]: Obj
}

export type ToOptionalWithRequired<Type, Keys extends keyof Type> = Pick<Type, Keys> & {
  [optionalKey in keyof Omit<Type, Keys>]?: Type[optionalKey]
}

export type HexColor = `#${string}`

export type LeftOrRight = 'left' | 'right'

export type NavigateTo<RouteName extends ScreenName = ScreenName> = RouteName extends unknown
  ? HasNavParams<RouteName> extends true
    ? [RouteName, NavToParams<RouteName>]
    : [RouteName]
  : never

export type NavToParams<RouteName extends ScreenName = ScreenName> = Omit<
  ParamList[RouteName],
  'basePath'
>

type HasNavParams<RouteName extends ScreenName> =
  MapIfNotEmpty<Omit<ParamList[RouteName], 'basePath'>> extends undefined ? false : true

/* when create navto this way, the screen prop forces the params prop type */
export function makeNavTo<RouteName extends ScreenName, T extends HasNavParams<RouteName>>(
  screen: T extends false ? RouteName : never
): [RouteName]

export function makeNavTo<RouteName extends ScreenName, T extends HasNavParams<RouteName>>(
  screen: RouteName,
  params: T extends true ? NavToParams<RouteName> : never
): T extends true ? [RouteName, NavToParams<RouteName>] : never

export function makeNavTo<RouteName extends ScreenName, T extends HasNavParams<RouteName>>(
  screen: RouteName,
  params?: T extends true ? NavToParams<RouteName> : never
) {
  if (params) {
    return [screen, params]
  } else {
    return [screen]
  }
}

type MapIfNotEmpty<T> = keyof T extends never ? undefined : T
type CamelCase<S extends string> = S extends `${infer Prefix}_${infer Rest}`
  ? `${Uncapitalize<Prefix>}${Capitalize<CamelCase<Rest>>}`
  : Uncapitalize<S>

type Uncapitalize<S extends string> = S extends `${infer First}${infer Rest}`
  ? `${Lowercase<First>}${Rest}`
  : S

export type SnakeToCamel<T> = T extends object
  ? T extends Array<infer U>
    ? Array<SnakeToCamel<U>>
    : { [K in keyof T as CamelCase<Extract<K, string>>]: SnakeToCamel<T[K]> }
  : T

const z = {
  meta: {
    status: 0,
    code: ''
  },
  trainer: {
    display_name: '',
    primary_silks: [
      {
        description: null,
        silk_image: null
      },
      {
        description: 'null',
        silk_image: null
      }
    ]
  }
}

type Z = typeof z

type X = SnakeToCamel<Z>

const x: X = snakeToCamel(z)

export function snakeToCamel<T>(input: T): SnakeToCamel<T> {
  if (Array.isArray(input)) {
    return input.map((item) => snakeToCamel(item)) as any
  } else if (typeof input === 'object' && input !== null) {
    const result: any = {}

    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase()) as keyof T
        result[camelKey] = snakeToCamel(input[key])
      }
    }

    return result
  } else {
    return input as any
  }
}
