import { lazy, Suspense } from 'react'
import { Platform, Text, View, ViewStyle } from 'react-native'
import { MapCoords, MapPin } from '../../../types/map.type'

export type MapProps = {
  mapCoords?: MapCoords
  defaultMapCoords: MapCoords
  zoom: number
  nativeZoom: number
  markerLocations?: MapPin[]
  style?: ViewStyle
}

const PlatformMap =
  Platform.OS == 'web'
    ? lazy(() => import('./WebMap/WebMap'))
    : lazy(() => import('./NativeMap/NativeMap'))

export function Map_C(props: MapProps) {
  return (
    <View
      style={{
        aspectRatio: 1,
        ...props.style
      }}
    >
      <Suspense fallback={<Text>Loading...</Text>}>
        {Platform.OS == 'web' ? (
          <PlatformMap
            mapCoords={props.mapCoords}
            defaultMapCoords={props.defaultMapCoords}
            zoom={props.zoom}
            markerLocations={props.markerLocations}
          />
        ) : (
          <PlatformMap
            mapCoords={props.mapCoords}
            defaultMapCoords={props.defaultMapCoords}
            zoom={props.nativeZoom}
            markerLocations={props.markerLocations}
          />
        )}
      </Suspense>
    </View>
  )
}
