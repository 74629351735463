import {
  getBlackTypePerformance,
  getPlacementPercents,
  removeLeadingSlash,
  toCapitalized
} from '../../../helper-functions'
import { snakeToCamel } from '../../../helper-types'
import { fetchData } from '../api-fetch-request'
import { DriverFetchResponse } from '../data-types/fetch-response-types/driver-response.type'
import { ResponseTrainerDiverPerformanceStats } from '../data-types/fetch-response-types/trainer-response.type'
import { Driver } from '../data-types/local-data-types/driver.type'

export type DriverResponse = {
  driver: Driver
}

type FetchDriverParams = {
  driverSlug: string
  useDesignMock?: boolean
}
export async function getDriver({
  driverSlug,
  useDesignMock
}: FetchDriverParams): Promise<DriverResponse> {
  if (useDesignMock) {
    throw 'no design mock'
  }

  const endpoint = `racing/drivers/profile/${driverSlug}`
  return fetchData<DriverResponse, DriverFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(driverPayload: DriverFetchResponse): DriverResponse {
    const {
      lifetime_summary,
      current_season_summary,
      last_season_summary,
      feature_wins,
      slug,
      debut_season,
      trainer
    } = driverPayload.driver

    const debutSeason = snakeToCamel(debut_season)

    const driver: Driver = {
      isTrainer: !!trainer,
      ...snakeToCamel(driverPayload.driver),
      lifetimePerformance: {
        summary: statsToSummary(lifetime_summary),
        ...lifetime_summary,
        ...getPlacementPercents({
          starts: lifetime_summary.starts,
          wins: lifetime_summary.wins,
          places: lifetime_summary.seconds + lifetime_summary.thirds
        })
      },
      thisSeasonPerformance: {
        summary: statsToSummary(current_season_summary),
        ...current_season_summary,
        ...getPlacementPercents({
          starts: current_season_summary.starts,
          wins: current_season_summary.wins,
          places: current_season_summary.seconds + current_season_summary.thirds
        })
      },
      lastSeasonPerformance: {
        summary: statsToSummary(last_season_summary),
        ...last_season_summary,
        ...getPlacementPercents({
          starts: last_season_summary.starts,
          wins: last_season_summary.wins,
          places: last_season_summary.seconds + last_season_summary.thirds
        })
      },
      blackTypePerformance: getBlackTypePerformance(lifetime_summary),
      featureWins: feature_wins
        ? snakeToCamel(feature_wins).map((item) => ({
            ...item,
            horseSlug: removeLeadingSlash(item.horseSlug),
            dayPhaseLetter: item.dayNightTwilight,
            trackSlug: removeLeadingSlash(item.trackSlug),
            track: toCapitalized(item.track),
            blackType: toCapitalized(item.blackType)
          }))
        : undefined,
      slug: removeLeadingSlash(slug),
      debutSeason: !debutSeason ? undefined : debutSeason
    }
    return { driver }

    function statsToSummary(stats: ResponseTrainerDiverPerformanceStats): string {
      const { starts, seconds, thirds, wins } = stats
      return `${starts}-${wins}-${seconds}-${thirds}`
    }
  }
}
