import account from '../../../assets/svgs/account.svg'
import article from '../../../assets/svgs/article.svg'
import blackBookRibbon from '../../../assets/svgs/black-book-ribbon.svg'
import calendar from '../../../assets/svgs/calendar.svg'
import calendar2 from '../../../assets/svgs/calendar2.svg'
import camera from '../../../assets/svgs/camera.svg'
import caret from '../../../assets/svgs/caret.svg'
import chevron from '../../../assets/svgs/chevron.svg'
import cross from '../../../assets/svgs/cross.svg'
import day from '../../../assets/svgs/day.svg'
import ellipsis from '../../../assets/svgs/ellipsis.svg'
import eye from '../../../assets/svgs/eye.svg'
import filter from '../../../assets/svgs/filter.svg'
import harness from '../../../assets/svgs/harness.svg'
import info from '../../../assets/svgs/info.svg'
import lightShowers from '../../../assets/svgs/light-showers.svg'
import list from '../../../assets/svgs/list.svg'
import live from '../../../assets/svgs/live.svg'
import logo from '../../../assets/svgs/logo.svg'
import map from '../../../assets/svgs/map.svg'
import menu from '../../../assets/svgs/menu.svg'
import night from '../../../assets/svgs/night.svg'
import partlyCloudy from '../../../assets/svgs/partly-cloudy.svg'
import play from '../../../assets/svgs/play.svg'
import print from '../../../assets/svgs/print.svg'
import rain from '../../../assets/svgs/rain.svg'
import search from '../../../assets/svgs/search.svg'
import sunny from '../../../assets/svgs/sunny.svg'
import twilight from '../../../assets/svgs/twilight.svg'
import weather from '../../../assets/svgs/weather.svg'
import wind from '../../../assets/svgs/wind.svg'

import bell from '../../../assets/svgs/bell.svg'
import comment from '../../../assets/svgs/comment.svg'
import location from '../../../assets/svgs/location.svg'
import tick from '../../../assets/svgs/tick.svg'
import trash from '../../../assets/svgs/trash.svg'

import edit from '../../../assets/svgs/edit.svg'
import ellipsisLarge from '../../../assets/svgs/ellipsis-large.svg'
import horizontalDots from '../../../assets/svgs/horizontal-dots.svg'
import infoLetter from '../../../assets/svgs/info-letter.svg'

export default {
  search,
  calendar,
  calendar2,
  article,
  harness,
  account,
  map,
  list,
  chevron,
  play,
  menu,
  weather,
  wind,
  info,
  caret,
  print,
  blackBookRibbon,
  cross,
  logo,
  ellipsis,
  live,
  camera,
  eye,
  sunny,
  rain,
  partlyCloudy,
  lightShowers,
  day,
  night,
  twilight,
  filter,
  bell,
  comment,
  trash,
  tick,
  location,
  ellipsisLarge,
  edit,
  infoLetter,
  horizontalDots
}
