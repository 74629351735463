import { addMonths, format, isSameMonth, subMonths } from 'date-fns'
import { merge } from 'lodash'
import { useMemo } from 'react'
import { View } from 'react-native'
import { australianStates } from '../../../../constants'
import { CalendarMeeting } from '../../../../services/data/data-types/local-data-types/calendar-meeting.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../../styles/text-sizes'
import { AustralianState } from '../../../../types/map.type'
import { Button_C } from '../../../Base/Button/Button'
import { chevronIcon } from '../../../Base/Icon/preset-icon-props'
import { Link_C } from '../../../Base/Link/Link'
import { Select_C } from '../../../Base/Select/Select'
import { Text_C } from '../../../Base/Text/Text'
import { CalendarPickerIcon_C } from '../../CalendarPicker/CalendarPickerIcon'

type Props = {
  selectedDate: Date
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
  meetings?: CalendarMeeting[]
  selectedState?: AustralianState

  setSelectedState: React.Dispatch<React.SetStateAction<AustralianState | undefined>>
  fixedTrack?: string
  // eslint-disable-next-line no-unused-vars
  onDateChanged(date: Date): void
}
export function CalendarHeader_C({
  selectedDate,
  setSelectedDate,
  meetings,
  selectedState,
  setSelectedState,
  fixedTrack,
  onDateChanged
}: Props) {
  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })
  const isMobileLayout = breakpointBelow(tabletBreakpoint)

  const { monthPicker, stateSelect, programSearchButton, calendarPicker } = getElements()

  return (
    <View style={styles.calendarHeaderContainer}>
      <View style={styles.topRowContainer}>
        {!isMobileLayout && monthPicker}
        {stateSelect}
        {calendarPicker}
        {programSearchButton}
      </View>

      {isMobileLayout && monthPicker}
    </View>
  )

  function getElements() {
    const calendarPicker = (
      <CalendarPickerIcon_C
        selectedDate={selectedDate}
        onDateSelected={(date) => {
          if (isSameMonth(date, selectedDate)) {
            setSelectedDate(date)
          } else {
            onDateChanged(date)
          }
        }}
      />
    )

    const monthText = <Text_C style={styles.monthText}>{format(selectedDate, 'MMMM yyyy')}</Text_C>
    const monthPicker = (
      <View style={styles.monthPicker}>
        {monthText}
        <Button_C
          styleType="iconOnly"
          icon={merge({}, chevronIcon, {
            style: { ...styles.monthChevron, transform: [{ rotate: '90deg' }] }
          })}
          onPress={() => {
            onDateChanged(subMonths(selectedDate, 1))
          }}
        />
        <Button_C
          styleType="iconOnly"
          icon={merge({}, chevronIcon, {
            style: {
              ...styles.monthChevron,
              transform: [{ rotate: '-90deg' }]
            }
          })}
          onPress={() => {
            onDateChanged(addMonths(selectedDate, 1))
          }}
        />
      </View>
    )

    const stateSelect = useMemo(
      () =>
        fixedTrack ? (
          <></>
        ) : (
          <Select_C
            styleType="greyChevron"
            options={[
              { label: 'All States', value: undefined },
              ...australianStates.map((s) => ({ label: s, value: s }))
            ]}
            initialSelection={selectedState}
            onSelect={({ value: state }) => setSelectedState(state)}
          />
        ),
      [fixedTrack, selectedState]
    )

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const statusSelectOptions = useMemo(() => {
      return [
        { label: 'Any Status', value: undefined },
        ...Array.from(new Set(meetings?.flatMap((m) => m.status)))
          .filter((status) => status != undefined)
          .map((status) => ({ label: status, value: status }))
      ]
    }, [meetings?.length])

    const programSearchButton = (
      <Link_C styleType="fillButton" navigateTo={['ProgramSearch']}>
        Program Search
      </Link_C>
    )

    return {
      calendarPicker,
      monthPicker,
      stateSelect,
      programSearchButton
    }
  }
}

const breakpointStyles = createStyles({
  calendarHeaderContainer: {
    base: {
      marginBottom: 30,
      zIndex: 1,
      gap: 20
    },
    [tabletBreakpoint]: {
      marginBottom: 40
    }
  },
  topRowContainer: {
    base: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 18,
      zIndex: 1
    },
    [tabletBreakpoint]: {
      gap: 20
    }
  },
  monthPicker: {
    base: {
      flexDirection: 'row',
      gap: 16,
      marginRight: 'auto'
    },
    [tabletBreakpoint]: {
      gap: 18
    },
    [desktopBreakpoint]: {
      gap: 20
    }
  },
  monthText: {
    base: {
      fontWeight: '700Bold',
      ...textSizes.size8
    },
    [desktopBreakpoint]: {
      ...textSizes.size9
    }
  },
  monthChevron: {
    base: { width: 22 },
    [desktopBreakpoint]: { width: 26 }
  }
})
