import { SvgProps } from 'react-native-svg'
import svgs from '../../../assets/svgs/sectional-silks'

export type HorseSilkSvgName = keyof typeof svgs

export interface HorseSilkIconProps extends SvgProps {
  name: HorseSilkSvgName
}

export function HorseSilkIcon_C({ name, style, ...rest }: HorseSilkIconProps) {
  const DefaultSvg = svgs['silk15Desktop']
  const SVG = svgs[name]

  if (!SVG || SVG === null) {
    return <DefaultSvg style={style} {...rest} />
  }

  return <SVG style={style} {...rest} />
}
