import { View, ViewStyle } from 'react-native'
import { presets } from '../../../styles/colors'
type Props = {
  style?: ViewStyle
}
export function Separator_C(props: Props) {
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderBottomColor: presets.border,
        marginVertical: 8,
        ...props.style
      }}
    />
  )
}
