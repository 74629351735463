/* eslint-disable @typescript-eslint/no-misused-promises */
import { useNavigation, useRoute } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import { Image, ImageSourcePropType, ImageStyle, View } from 'react-native'
import { decodeHtmlEntities } from '../../../../helper-functions'
import { NewsArticle } from '../../../../services/data/data-types/local-data-types/news.type'
import { ScreenName } from '../../../../services/routes/screens'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../../services/styles/dependencies/breakpoint-style.type'
import { colors } from '../../../../styles/colors'
import { ImageDimensions } from '../../../../types/miscellaneous-types.type'
import { getAssetUrl } from '../../../../utils/assets'
import { savePageInHistory } from '../../../../utils/save-history'
import { Button_C } from '../../../Base/Button/Button'
import { Text_C } from '../../../Base/Text/Text'
import { CategoryAndDate_C } from './CategoryAndDate'
import { baseNewsStyles } from './news-styles'

type NewsItemSmallProps = {
  article: NewsArticle
  style?: NamedBreakpointStylesExtension<typeof breakpointStyles>
}

export function NewsItemSmall_C({ article }: NewsItemSmallProps) {
  const heroImage = getAssetUrl(article.hero)
  const { navigate } = useNavigation()
  const { name } = useRoute()
  const screenName = name as ScreenName
  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })
  const [logoDimensions, setLogoDimensions] = useState<ImageDimensions>()

  const stateLogoUrl = getAssetUrl(article.stateLogo)

  useEffect(() => {
    if (stateLogoUrl) {
      Image.getSize(stateLogoUrl, (width, height) => {
        setLogoDimensions({
          width,
          height,
          apsectRatio: width / height
        })
      })
    }
  }, [])

  return (
    <Button_C
      styleType="linkBase"
      onPress={() => {
        if (screenName == 'NewsArticle') {
          savePageInHistory()
        }

        navigate('NewsArticle', {
          newsId: article.id.toString(),
          newsSlug: article.slug
        })
      }}
      style={{ elem: styles.mainContainer }}
    >
      <View style={styles.imageContainer as ImageStyle}>
        {heroImage ? (
          <Image
            style={styles.image as ImageStyle}
            source={(heroImage ?? '') as ImageSourcePropType}
            resizeMethod="auto"
            resizeMode="contain"
          />
        ) : (
          !!stateLogoUrl &&
          !!logoDimensions && (
            <Image
              resizeMethod="auto"
              resizeMode="contain"
              style={{
                ...(styles.fallbackImage as ImageStyle),
                maxWidth: '90%',
                maxHeight: '90%',
                height: logoDimensions.height,
                width: logoDimensions.width,
                aspectRatio: logoDimensions.apsectRatio
              }}
              source={stateLogoUrl as ImageSourcePropType}
            />
          )
        )}
      </View>

      <View style={styles.rightContainer}>
        <CategoryAndDate_C article={article} />
        <Text_C style={styles.heading} numberOfLines={2} ellipsizeMode="head">
          {decodeHtmlEntities(article.headline)}
        </Text_C>
        <Text_C style={styles.content} numberOfLines={2} ellipsizeMode="head">
          {article.content}
        </Text_C>
      </View>
    </Button_C>
  )
}

const breakpointStyles = createStyles({
  mainContainer: {
    base: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '100%',
      columnGap: 10,
      flex: 1
    }
  },
  rightContainer: {
    base: {
      flex: 1,
      justifyContent: 'flex-start',
      gap: 8
    },
    large: {
      paddingRight: 20
    },
    xlarge: {
      paddingRight: 'unset'
    }
  },
  heading: {
    fontSize: 14,
    lineHeight: 18,
    color: 'black',
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold'
  },
  content: {
    marginTop: 3,
    fontSize: 12,
    fontWeight: '500Medium',
    color: colors.gray600
  },
  ...baseNewsStyles
})
