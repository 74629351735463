type TextSize = { fontSize: number; lineHeight?: number }

type TextSizeNames =
  | 'size1'
  | 'size2'
  | 'size3'
  | 'size4'
  | 'size5'
  | 'size6'
  | 'size7'
  | 'size8'
  | 'size9'

export const textSizes: Record<TextSizeNames, TextSize> = {
  size1: {
    // aToZ letter, table cell, allRaces, field form
    fontSize: 12,
    lineHeight: 14
  },
  size2: {
    //standard text, select,
    // (lots of components use this style via Text_C, eg optionToggle, h3, tabButton,)
    //desktop: table heading
    fontSize: 13,
    lineHeight: 16
  },
  size3: {
    //drawerNav, heading4, race card, base button, meetingIndex track name, meeting row runner number, heading ausState, h2
    //desktop: tabButton
    fontSize: 14,
    lineHeight: 18
  },
  size4: {
    //drawerNav, heading4, race card, base button, meetingIndex track name, meeting row runner number, heading ausState, h2
    //desktop: tabButton
    fontSize: 16,
    lineHeight: 20
  },
  size5: {
    // h1, header nav
    // desktop: meeting header date
    fontSize: 18,
    lineHeight: 22
  },
  size6: {
    // h1, header nav
    // desktop: meeting header date
    fontSize: 20,
    lineHeight: 26
  },
  size7: {
    //desktop: h1
    fontSize: 24,
    lineHeight: 36
  },
  size8: {
    //date days
    fontSize: 30,
    lineHeight: 50
  },
  size9: {
    //date days
    fontSize: 36,
    lineHeight: 50
  }
} as const
