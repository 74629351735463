import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { DayPhase, dayPhaseMap } from '../../../services/data/data-types/general-data-types.type'
import { CalendarMeeting } from '../../../services/data/data-types/local-data-types/calendar-meeting.type'
import { getCalendarData } from '../../../services/data/request-functions/calendar-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { calendarColors, colors } from '../../../styles/colors'
import { AustralianState } from '../../../types/map.type'
import { Icon_C } from '../../Base/Icon/Icon'
import { dayIcon, nightIcon, twilightIcon } from '../../Base/Icon/preset-icon-props'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { Calendar_C } from './components/Calendar'
import { CalendarHeader_C } from './components/CalendarHeader'

type Props = {
  date?: Date
  fixedTrack?: string
  // eslint-disable-next-line no-unused-vars
  onDateChange?(date: Date): void
}

export function MeetingsCalendar_C(props: Props) {
  const { fixedTrack } = props

  const styles = useBreakpointStyles({
    styles: breakpointStyles
  })

  const [selectedState, setSelectedState] = useState<AustralianState>()
  const [selectedDate, setSelectedDate] = useState<Date>(props.date ?? new Date())
  const [meetings, setMeetings] = useState<CalendarMeeting[]>()

  useEffect(() => {
    if (selectedDate) {
      getCalendarData({
        date: selectedDate,
        useDesignMock: false
      }).then(({ meetings }) => {
        setMeetings(meetings)
      })
    }
  }, [selectedDate])

  const colorLegend = (
    <View style={styles.colorLegend}>
      {(['Day', 'Twilight', 'Night'] as DayPhase[]).map((dayPhase) => {
        const color = calendarColors[dayPhaseMap[dayPhase]]
        return (
          <View key={dayPhase} style={{ flexDirection: 'row', gap: 10 }}>
            <View
              style={{
                width: 40,
                height: 20,
                backgroundColor: color
              }}
            />
            <Text_C style={{ color, fontWeight: '600SemiBold' }}>{`${dayPhase} Meeting`}</Text_C>
            <Icon_C
              {...(dayPhase == 'Twilight'
                ? twilightIcon
                : dayPhase == 'Night'
                  ? nightIcon
                  : dayIcon)}
              style={{ width: dayPhase == 'Night' ? 11 : 14 }}
              color={color}
            />
          </View>
        )
      })}
      <View style={{ flexDirection: 'row', gap: 10 }}>
        <View
          style={{
            width: 40,
            height: 20,
            backgroundColor: colors.gray700
          }}
        />
        <Text_C style={{ color: colors.gray700, fontWeight: '600SemiBold' }}>Trial Meeting</Text_C>
      </View>
      <View style={{ flexDirection: 'row', gap: 10 }}>
        <View
          style={{
            width: 40,
            height: 20,
            backgroundColor: colors.yellow
          }}
        />
        <Text_C style={{ color: colors.yellow, fontWeight: '600SemiBold' }}>
          {'Non-TAB Meeting'}
        </Text_C>
      </View>
    </View>
  )

  return (
    <Card_C>
      <CalendarHeader_C
        {...{
          selectedState,
          setSelectedState,
          selectedDate,
          setSelectedDate,
          meetings,
          fixedTrack,
          onDateChanged: (date) => {
            if (props.onDateChange) {
              props.onDateChange(date)
            } else {
              setSelectedDate(date)
            }
          }
        }}
      />
      <Calendar_C
        {...{
          setSelectedDate,
          selectedState,
          selectedDate,
          meetings,
          fixedTrack
        }}
      />
      {colorLegend}
    </Card_C>
  )
}

const breakpointStyles = createStyles({
  colorLegend: { gap: 10, marginTop: 50 }
})
