// Footer.tsx

import { View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { presets } from '../../../styles/colors'
import { CopyrightText_C } from './components/CopyrightText'
import { FooterLinks_C } from './components/FooterLinks'
import { FooterTitle_C } from './components/FooterTitle'
import { StateLogos_C } from './components/StateLogos'

export function Footer_C() {
  const style = useBreakpointStyles({ styles: breakpointStyle })

  return (
    <View style={style.footer}>
      <FooterTitle_C />
      <StateLogos_C />
      <FooterLinks_C />
      <CopyrightText_C />
    </View>
  )
}

const breakpointStyle = createStyles({
  footer: {
    base: {
      marginTop: 'auto',
      paddingHorizontal: 20,
      paddingVertical: 40,
      backgroundColor: presets.headerFooter,
      alignItems: 'center'
    },
    [desktopBreakpoint]: {
      paddingHorizontal: '11.25%',
      paddingVertical: 62
    }
  }
})
