/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { LayoutChangeEvent, SafeAreaView, ScrollView, StatusBar, View } from 'react-native'

import {
  RouteProp,
  useIsFocused,
  useLinkBuilder,
  useNavigation,
  useRoute
} from '@react-navigation/native'
import { isNull } from 'lodash'
import { GlobalContext } from '../../../global-context'
import { authService } from '../../../services/auth/auth.service'
import { ParamList, ScreenName } from '../../../services/routes/screens'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import {
  contentMaxWidth,
  desktopBreakpoint
} from '../../../services/styles/dependencies/style-constants'
import { colors } from '../../../styles/colors'
import { Heading_C } from '../../Base/Heading/Heading'
import { Link_C } from '../../Base/Link/Link'
import { Modal_C } from '../../Base/Modal/Modal'
import { Text_C } from '../../Base/Text/Text'
import { ToastContainer_C } from '../../Base/Toast/ToastContainer'
import { Tooltip_C } from '../../Base/Tooltip/Tooltip'
import { AdPlacement } from '../../Partials/Advertisement/AdPlacement'
import { CalendarPicker_C } from '../../Partials/CalendarPicker/CalendarPicker'
import { Footer_C } from '../../Partials/Footer/Footer'
import { Header_C } from '../../Partials/Header/Header'
import { Card_C } from '../Card/Card'
import { useAds } from './useAds'

const skyScraperAdWidth = 150

export function withScreenWrapper<S extends ScreenName, D extends Array<DataObj>>({
  requestsSetup,
  aside,
  view
}: ScreenWrapperParams<S, D>): JSX.Element

export function withScreenWrapper<S extends ScreenName>({
  aside,
  view
}: ScreenWrapperParams<S>): JSX.Element

export function withScreenWrapper<S extends ScreenName, D extends Array<DataObj> | undefined>(
  props: ScreenWrapperParams<S, D>
): JSX.Element {
  const {
    view: screenComponent,
    aside: sideComponent,
    requestsSetup,
    requiresAuthentication
  } = props

  type RouteProps = RouteProp<ParamList, S>

  const route = useRoute<RouteProps>()
  const { params, name: screenName } = route
  const { authToken, setAuthToken } = useContext(GlobalContext)

  const isFocused = useIsFocused()
  const { navigate } = useNavigation()

  const initialLoadingState = getLoading()

  const [loading, setLoading] = useState(initialLoadingState)
  const [error, setError] = useState<Error | null>(null)
  const [responses, setResponses] = useState<D | null>(null)
  const [rerenderMain, triggerRerenderMain] = useState(false)

  const buildLink = useLinkBuilder()
  const pathname = buildLink(screenName, params)
  const adsObj = useAds(pathname, rerenderMain)
  const [displaySkyAd, setDisplaySkyAd] = useState(false)

  const ScreenComponent = screenComponent

  const isMobileLayout = breakpointBelow(desktopBreakpoint)

  const header = useMemo(() => <Header_C />, [isMobileLayout])
  const footer = useMemo(() => <Footer_C />, [isMobileLayout])

  const scrollViewRef = useRef<any>(null)

  const styles = useBreakpointStyles({ styles: breakpointStyles }, { screenName })

  useEffect(() => {
    if (authToken === undefined) {
      authService
        .isAuthenticated()
        .then(setAuthToken)
        .catch(() => setAuthToken(null))
    }
    if (authToken === null && requiresAuthentication) {
      navigate('Home')
    }
  }, [authToken])

  /* stops old data showing when revisiting a page */
  useEffect(() => {
    if (!isFocused && !!requestsSetup) {
      setResponses(null)
    }
  }, [isFocused])

  useEffect(() => {
    triggerRerenderMain((x) => !x)
  }, [responses])

  useEffect(() => {
    let isMounted = true
    setResponses(null)
    if (!requestsSetup) {
      triggerRerenderMain((x) => !x)
    }
    if (isFocused && !!requestsSetup && authToken !== undefined) {
      if (requiresAuthentication && isNull(authToken)) return
      const fetchDataAsync = async () => {
        setResponses(null)
        const fetchRequests = requestsSetup(params, authToken)
        setLoading(true)
        try {
          if (isMounted) {
            await Promise.all(fetchRequests).then((responses) => {
              setResponses(responses as D)
            })
          }
        } catch (err) {
          setError(err as Error)
          console.error(err)
        } finally {
          setLoading(false)
        }
      }
      fetchDataAsync()
    }
    return () => {
      isMounted = false
    }
  }, [params, isFocused, authToken])

  const skyscraper = adsObj?.skyscraper

  const noLoadingOrError = !(loading || error)

  const {
    skyscraperElemLeft,
    skyscraperElemRight,
    loadingAndErrorElem,
    headerAd,
    footerAd,
    asideAd
  } = getElements()

  const main = useMemo(() => {
    if ((requestsSetup && !responses) || authToken === undefined) {
      return <></>
    } else {
      return <ScreenComponent responses={responses} params={params} authToken={authToken} />
    }
  }, [rerenderMain, authToken])

  const side: JSX.Element | undefined = (() => {
    if (!sideComponent) return
    if ((requestsSetup && !responses) || authToken === undefined) return
    const side = sideComponent({
      params,
      responses: responses,
      authToken
    })
    return side ? (
      <View style={styles.aside}>
        {side}
        {asideAd}
      </View>
    ) : undefined
  })()

  function checkIfSpaceForSkyscraperAds({ nativeEvent: { layout } }: LayoutChangeEvent) {
    const hasSpaceForSkyscraperAds: boolean = layout.width > contentMaxWidth + skyScraperAdWidth * 2
    setDisplaySkyAd(hasSpaceForSkyscraperAds)
  }

  return (
    <SafeAreaView style={{ height: '100%', flex: 1 }}>
      <ScrollView contentContainerStyle={styles.appContainer} ref={scrollViewRef}>
        <StatusBar hidden />
        {header}
        <View style={styles.outerContainer} onLayout={checkIfSpaceForSkyscraperAds}>
          {displaySkyAd && skyscraperElemLeft}
          {noLoadingOrError ? (
            <View style={styles.mainAndAsideContainer}>
              <View style={styles.main}>
                {headerAd}
                {isFocused && (!requestsSetup || responses) && (
                  <>
                    {main}

                    {isMobileLayout && side}
                  </>
                )}
                {footerAd}
              </View>

              {!isMobileLayout && side}
            </View>
          ) : (
            loadingAndErrorElem
          )}
          {displaySkyAd && skyscraperElemRight}
        </View>
        {footer}
        <Tooltip_C scrollViewRef={scrollViewRef} />
        <CalendarPicker_C screenName={route.name} />
      </ScrollView>
      <Modal_C />
      <ToastContainer_C />
    </SafeAreaView>
  )

  function getElements() {
    const skyscraperElemLeft = (
      <AdPlacement style={{ base: { marginRight: 'unset' } }} name="skyscraper" ad={skyscraper} />
    )
    const skyscraperElemRight = (
      <AdPlacement style={{ base: { marginLeft: 'unset' } }} name="skyscraper" ad={skyscraper} />
    )

    const loadingAndErrorElem = (
      <View style={styles.loadingContainer}>
        <Card_C>
          {loading ? (
            <Text_C>Loading... </Text_C>
          ) : error ? (
            <>
              <Heading_C styleType="h1" style={{ base: { marginBottom: 20 } }}>
                Page Error
              </Heading_C>
              <Link_C navigateTo={['Home']}>Return to Home</Link_C>
            </>
          ) : (
            <></>
          )}
        </Card_C>
      </View>
    )

    const headerAd = <AdPlacement name="header" ad={adsObj?.header} />
    const footerAd = <AdPlacement name="footer" ad={adsObj?.footer} />
    const asideAd = <AdPlacement name="sidebar" ad={adsObj?.sidebar} />
    return {
      skyscraperElemLeft,
      skyscraperElemRight,
      loadingAndErrorElem,
      headerAd,
      footerAd,
      asideAd
    }
  }

  function getLoading() {
    return (
      (Boolean(requestsSetup) && responses == null) ||
      (requiresAuthentication && authToken == undefined)
    )
  }
}

const breakpointStyles = createStyles({
  appContainer: {
    base: {
      minHeight: '100%',
      backgroundColor: colors.gray200,
      rowGap: 18
    },
    small: {
      rowGap: 22
    },
    [desktopBreakpoint]: {
      rowGap: 26
    }
  },
  outerContainer: {
    base: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center'
    }
  },
  loadingContainer: {
    base: {
      width: '100%',
      maxWidth: contentMaxWidth
    },
    small: {
      paddingHorizontal: 20
    },
    [desktopBreakpoint]: {
      paddingHorizontal: 32
    }
  },
  mainAndAsideContainer: {
    base: {
      alignItems: 'flex-start',
      width: '100%',
      maxWidth: contentMaxWidth,
      columnGap: 24,
      zIndex: 1
    },
    small: {
      paddingHorizontal: 20
    },
    [desktopBreakpoint]: {
      flexDirection: 'row',
      columnGap: 26,
      paddingHorizontal: 32
    }
  },
  main: {
    base: {
      width: '100%',
      flex: 1,
      zIndex: 1,
      rowGap: 18
    },
    small: {
      rowGap: 22
    },
    [desktopBreakpoint]: {
      rowGap: 26
    }
  },
  aside: {
    base: {
      zIndex: 1,
      rowGap: 18
    },
    small: {
      rowGap: 22
    },
    [desktopBreakpoint]: {
      width: '100%',
      maxWidth: 390,
      rowGap: 26
    }
  }
})

export type DataObj = Record<string, any>

export type ScreenWrapperParams<
  S extends ScreenName,
  D extends Array<DataObj> | undefined = undefined
> =
  D extends Array<DataObj>
    ? {
        requiresAuthentication?: boolean
        requestsSetup?: (
          params: RouteProp<ParamList, S>['params'],
          authToken: string | null
        ) => {
          [K in keyof D]: Promise<D[K]>
        }
        aside?: (props: {
          responses: D | null | undefined
          params: RouteProp<ParamList, S>['params']
          authToken: string | null
        }) => JSX.Element | undefined
        view: (props: {
          responses: D | null | undefined
          params: RouteProp<ParamList, S>['params']
          authToken: string | null
        }) => JSX.Element
      }
    : {
        requiresAuthentication?: boolean
        requestsSetup?: undefined
        aside?: (props: {
          params: RouteProp<ParamList, S>['params']
          authToken: string | null
        }) => JSX.Element | undefined
        view: (props: {
          params: RouteProp<ParamList, S>['params']
          authToken: string | null
        }) => JSX.Element
      }
