//////// Title Case

const CAPITALIZE_WORDS = ['tv']

export const titleCase = (str: string) => {
  return str
    .split(' ')
    .map((word) => {
      // Checks if the word is a URL
      if (word.startsWith('http://') || word.startsWith('https://')) {
        return word
      }

      // Checks if the word is an abbreviation
      if (CAPITALIZE_WORDS.includes(word.toLowerCase())) {
        return word.toUpperCase()
      }

      // Capitalize the first letter of the word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(' ')
}
