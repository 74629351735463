import { ProgramSearchFetchResponse } from '../data-types/fetch-response-types/program-search-response.type'

export const programSearchCompare: ProgramSearchFetchResponse = {
  meta: {
    status: 200,
    code: 'OK'
  },
  races: [
    {
      meeting: {
        is_trial: false,
        date: '2023-11-24',
        day_night_twilight: 'N',
        nominations_close_time: '2023-11-22T09:30:00+11:00',
        acceptances_time: '2023-11-22T10:00:00+11:00',
        fields_available_time: '2023-11-22T17:00:00+11:00',
        meeting_status: 'fields_drawn'
      },
      track: {
        desktop_display_name: 'Dubbo',
        mobile_display_name: 'Dubbo',
        slug: '/dubbo'
      },
      name: 'Download the TAB App Today Pace',
      start_type: 'MS',
      rise_race_code: 'DUC24112301',
      short_name: 'Download the TAB App Today',
      total_prize_money: 6936.0,
      race_class: 'No NR. PBD/LTW. Pacers',
      gait: 'PACERS',
      distance: 1720,
      race_barrier_draw_type: 'PBD/LTW',
      race_notes:
        'For Pacers with no more than 2 lifetime wins, trained within 135km (straight line) from the Dubbo track.',
      race_status: 'fields_drawn'
    }
  ],
  pagination: {
    limit: 100,
    page: 1,
    total: 333,
    pages: 4
  }
}
