import { useContext } from 'react'
import { Card_C } from '../../../Layout/Card/Card'
import { SimpleContainer_C } from '../../../Layout/SimpleContainer/SimpleContainer'
import { Analysis_C } from '../../../Partials/_meeting-components/Analysis'
import { MeetingContext } from '../Meeting'

export function AnalysisTab_C() {
  const { activeMeetingPuntersCorner } = useContext(MeetingContext)
  return (
    <Card_C>
      <SimpleContainer_C>
        {/* non-null assertion because tab is hidden otherwise */}
        <Analysis_C analysis={activeMeetingPuntersCorner!.analysis!} />
      </SimpleContainer_C>
    </Card_C>
  )
}
