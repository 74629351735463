/* eslint-disable @typescript-eslint/no-explicit-any */
import { View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'

import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'
import { Text_C } from '../Text/Text'

type Props = {
  data: LabelValuePair[]
  style?: NamedBreakpointStylesExtension<typeof breakpointStyles>
}

export type LabelValuePair<T extends string = any> = {
  label?: string
  value: T
}

export function LabelValueTable_C(props: Props) {
  const styles = useBreakpointStyles({
    styles: breakpointStyles,
    additionalStyles: [props.style]
  })

  return (
    <View style={styles.container}>
      {props.data.map(({ label, value }, i) => (
        <View key={i} style={styles.tableRow}>
          <View style={styles.label}>
            {typeof label == 'string' ? <Text_C style={styles.labelText}>{label}</Text_C> : label}
          </View>
          <View style={styles.value}>
            {typeof label == 'string' ? <Text_C style={styles.valueText}>{value}</Text_C> : label}
          </View>
        </View>
      ))}
    </View>
  )
}
const breakpointStyles = createStyles({
  container: { rowGap: 20 },
  tableRow: { flexDirection: 'row', gap: 20 },
  label: {
    width: 100
  },
  labelText: {
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold'
  },
  value: { flex: 1, gap: 18 },
  valueText: {
    fontFamily: 'OpenSans',
    fontWeight: '500Medium'
  }
})
