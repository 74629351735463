/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { PerPageOption } from '../../../components/Partials/Pagination/components/PerPageSelect'
import { removeLeadingSlash } from '../../../helper-functions'
import { shortDate } from '../../../utils/date-time'
import { fetchData } from '../api-fetch-request'
import { HorsePerformanceFetchResponse } from '../data-types/fetch-response-types/horse-response.type'
import { Pagination } from '../data-types/general-data-types.type'
import { LatestResult } from '../data-types/local-data-types/horse.type'
import { horsePerformanceCompare } from '../validation-compare-objects/horse-performance-compare'
export type HorsePerformanceResponse = {
  horseLatestResults: LatestResult[]
  pagination?: Pagination
}

type GetHorsePerformanceParams = {
  horseSlug: string
  activePageNumber?: number
  perPage?: PerPageOption
}
export async function getHorsePerformanceData({
  horseSlug,
  activePageNumber,
  perPage
}: GetHorsePerformanceParams): Promise<HorsePerformanceResponse> {
  const endpoint =
    `racing/horses/${horseSlug}/performance-records?` +
    new URLSearchParams({
      'pagination[limit]': `${perPage ?? 10}`,
      'pagination[page]': `${activePageNumber ?? 1}`
    })

  return fetchData<HorsePerformanceResponse, HorsePerformanceFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: horsePerformanceCompare
  })
  function convertResponse(
    horsePerformancePayload: HorsePerformanceFetchResponse
  ): HorsePerformanceResponse {
    const { performance_records, pagination } = horsePerformancePayload
    const horsePerformanceRecords: LatestResult[] = performance_records.map((performance) => {
      const {
        meeting,
        track,
        race,
        trainer,
        driver,
        barrier,
        starting_price,
        place,
        prizemoney,
        margin
      } = performance
      const horsePerformance: LatestResult = {
        place: place,
        handicap: barrier,
        startingPrice: starting_price ?? undefined,
        prizemoney: prizemoney ?? undefined,
        meetingDate: shortDate(new Date(meeting.date)),
        meetingDayPhase: meeting.day_night_twilight,
        isTrial: meeting.is_trial,
        margin,
        race: {
          ...race,
          number: race.number ?? undefined,
          mileRate: race.time_mile_rate,
          startType: race.start_type,
          hasReplay: race.has_replay
        },
        track: {
          name: track.desktop_display_name,
          shortName: track.mobile_display_name,
          slug: removeLeadingSlash(track.slug),
          published: track.published
        },
        trainer: !trainer
          ? undefined
          : {
              shortName: trainer.mobile_display_name,
              name: trainer.display_name,
              slug: removeLeadingSlash(trainer.slug)
            },
        driver: !driver
          ? undefined
          : {
              shortName: driver.mobile_display_name,
              name: driver.display_name,
              slug: removeLeadingSlash(driver.slug)
            }
      }
      return horsePerformance
    })
    return {
      horseLatestResults: horsePerformanceRecords,
      pagination: pagination
        ? {
            activePageNumber: pagination.page,
            totalPages: pagination.pages,
            perPage: pagination.limit as PerPageOption,
            totalRecords: pagination.total
          }
        : undefined
    }
  }
}
