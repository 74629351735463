import { MeetingProgramFetchResponse } from '../data-types/fetch-response-types/meeting-program-response.type'

export const meetingProgramCompare: MeetingProgramFetchResponse = {
  meta: {
    status: 200,
    code: 'OK'
  },
  meeting: {
    date: '2023-11-26',
    day_night_twilight: 'D',
    nominations_close_time: '2023-11-24T10:30:00+11:00',
    acceptances_time: '2023-11-24T10:30:00+11:00',
    fields_available_time: '2023-11-24T12:00:00+11:00',
    meeting_status: 'scheduled'
  },
  track: {
    desktop_display_name: 'Coolamon',
    mobile_display_name: 'Coolamon',
    slug: '/coolamon'
  },
  races: [
    {
      name: 'Green Trial',
      start_type: 'MS',
      rise_race_code: 'CLQ26112302',
      short_name: 'Green Trial',
      race_class: 'No NR. RBD. Pacers',
      distance: 1760,
      race_barrier_draw_type: 'RBD',
      race_notes: null,
      total_prize_money: 0,
      race_status: 'scheduled'
    },
    {
      name: 'Trial',
      start_type: 'MS',
      rise_race_code: 'CLQ26112301',
      short_name: 'Trial',
      race_class: 'No NR. RBD. Pacers',
      distance: 1760,
      race_barrier_draw_type: 'RBD',
      race_notes: null,
      total_prize_money: 0,
      race_status: 'scheduled'
    }
  ]
}
