/* eslint-disable no-unused-vars */
import { NavigateTo } from '../../helper-types'
import { useBreakpointStyles } from '../../services/styles/breakpoint-styles.service'
import { BreakpointStyles } from '../../services/styles/dependencies/breakpoint-style.type'
import { Link_C } from '../Base/Link/Link'
import { PressableStyleExtension } from '../Base/Pressable/Pressable'
import { Text_C } from '../Base/Text/Text'

/* internal navigate with TS */
type Props1 = {
  name: string
  navigateTo?: NavigateTo
  linkStyle?: PressableStyleExtension
}
/* or string url with  new tab option, no TS*/
type Props2 = {
  name: string
  newTab?: boolean
  linkUrl?: string
  externalLink?: boolean // if internal, no need for base path
  linkStyle?: PressableStyleExtension
}

export function LinkOrText_C({ name, navigateTo, linkStyle }: Props1): JSX.Element

export function LinkOrText_C({ name, linkStyle, linkUrl }: Props2): JSX.Element
export function LinkOrText_C(props: Props1 & Props2) {
  const { name, externalLink, linkStyle, linkUrl, navigateTo, newTab } = props
  const styles = useBreakpointStyles({
    styles: { linkText: linkStyle?.text as BreakpointStyles }
  })

  return linkUrl ? (
    <Link_C newTab={newTab} externalLink={externalLink} linkUrl={linkUrl} style={linkStyle}>
      {name}
    </Link_C>
  ) : navigateTo ? (
    <Link_C styleType="linkBase" navigateTo={navigateTo} style={linkStyle}>
      {name}
    </Link_C>
  ) : (
    <Text_C style={{ ...styles.linkText }}>{name}</Text_C>
  )
}
