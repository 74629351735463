import { useContext, useState } from 'react'
import { View } from 'react-native'
import { emailRegex } from '../../../../constants'
import { GlobalContext } from '../../../../global-context'
import { forgotUserPassword } from '../../../../services/data/request-functions/user-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { Button_C } from '../../../Base/Button/Button'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Text_C } from '../../../Base/Text/Text'
import { FormField, FormField_C } from '../../_user-components/FormField'

export function ForgotPassword_C() {
  const { openModal, closeModal, addToast } = useContext(GlobalContext)
  const [emailField, setEmailField] = useState<FormField>({
    label: 'Email',
    value: '',
    invalidMessage: 'Email is required'
  })

  const [submitAttempted, setSubmitAttempted] = useState(false)
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  return (
    <View style={styles.mainContainer}>
      <Heading_C styleType="h3">Forgot Password</Heading_C>
      <Text_C>
        Please enter the email associated with your account, and we&apos;ll help you reset your
        password.
      </Text_C>
      <FormField_C
        {...{
          inputMode: 'email',
          textContentType: 'emailAddress',
          formField: emailField,
          setFormField: (field) => {
            const invalidMessage =
              field.value == ''
                ? 'Email is required'
                : field.value && !emailRegex.test(field.value)
                  ? 'Invalid email format '
                  : undefined
            setEmailField({ ...field, invalidMessage })
          },
          submitAttempted,
          onEnterPress: submitForgotPassword
        }}
      />
      <View style={styles.buttonsContainer}>
        <Button_C styleType="fillButton" onPress={submitForgotPassword}>
          Submit
        </Button_C>
      </View>
    </View>
  )

  function submitForgotPassword() {
    if (emailField.invalidMessage) {
      setSubmitAttempted(true)
      return
    }

    forgotUserPassword({ email: emailField.value! })
      .then(() => {
        addToast({ message: 'Reset Password Email Sent!', status: 'success' })
        openModal(
          <View style={{ gap: 30 }}>
            <Heading_C styleType="h3">Email sent</Heading_C>
            <Text_C>
              We&apos;ve sent you an email with instructions to reset your password. Please check
              your inbox (and spam folder, just in case).
            </Text_C>

            <View style={styles.buttonsContainer}>
              <Button_C styleType="fillButton" onPress={closeModal}>
                OK
              </Button_C>
            </View>
          </View>
        )
      })
      .catch(() => {
        addToast({
          message: 'Failed to send reset password email',
          status: 'fail'
        })
      })
  }
}
const breakpointStyles = createStyles({
  mainContainer: {
    base: {
      gap: 30
    }
  },
  buttonsContainer: {
    base: {
      gap: 10,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  }
})
