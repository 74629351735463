import { ReactNode } from 'react'
import { useBreakpointStyles } from '../../../services/styles/breakpoint-styles.service'
import {
  BreakpointStyles,
  StyleType
} from '../../../services/styles/dependencies/breakpoint-style.type'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { colors } from '../../../styles/colors'
import { TextStyle_T } from '../../../styles/fonts-map'
import { textSizes } from '../../../styles/text-sizes'
import { Text_C } from '../Text/Text'

type StyleTypes = 'h1' | 'h2' | 'h3' | 'h4'

const styleTypes: Record<StyleTypes, BreakpointStyles<TextStyle_T>> = {
  h1: {
    base: {
      color: colors.gray700,
      fontFamily: 'OpenSans',
      fontWeight: '700Bold',
      ...textSizes.size6
    },
    [desktopBreakpoint]: {
      ...textSizes.size7
    }
  },
  h2: {
    base: {
      ...textSizes.size3,
      color: colors.blue,
      fontFamily: 'OpenSans',
      fontWeight: '700Bold',
      textTransform: 'uppercase'
    },
    [desktopBreakpoint]: {
      ...textSizes.size5
    }
  },
  h3: {
    base: {
      ...textSizes.size4,
      color: 'black',
      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold'
    }
  },
  h4: {
    base: {
      ...textSizes.size4,
      color: colors.gray700,
      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold'
    }
  }
}

const baseHeadingStyle: TextStyle_T = {}

export const headingStyleTypes = styleTypes

type Props = {
  styleType: StyleTypes // no default, force dev to specify a header size
  style?: StyleType | BreakpointStyles
  children: string | string[] | ReactNode
}

export function Heading_C(props: Props) {
  const style = useBreakpointStyles({
    styles: baseHeadingStyle,
    additionalStyles: [styleTypes[props.styleType], props.style]
  })

  return <Text_C style={style}>{props.children}</Text_C>
}
