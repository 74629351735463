import { colors, presets } from '../../../styles/colors'
import { IconProps } from './Icon'

export const eyeIcon: IconProps = {
  name: 'eye',
  style: { width: 32, height: 16 },
  colorFill: true,
  colorStroke: false,
  useParentTextColor: true
}

export const printIcon: IconProps = {
  name: 'print',
  style: { width: 24.5, height: 24.5 },
  color: presets.primary,
  colorFill: true,
  colorStroke: false,
  useParentTextColor: true
}

export const playIcon: IconProps = {
  name: 'play',
  style: { width: 22, height: 22 },
  colorFill: true,
  colorStroke: false,
  color: colors.blue,
  useParentTextColor: true
}

export const mapMarkerIcon: IconProps = {
  name: 'map',
  style: {
    width: '100%'
  },
  colorFill: true,
  colorStroke: false,
  color: presets.primary,
  useParentTextColor: true,
  strokeWidth: 0.5
}
export const cameraIcon: IconProps = {
  name: 'camera',
  style: {
    width: 30,
    height: 24
  },
  colorFill: true,
  colorStroke: false,
  color: colors.blue,
  useParentTextColor: true
}
export const crossIcon: IconProps = {
  name: 'cross',
  style: {
    aspectRatio: 1
  },
  colorFill: false,
  colorStroke: true,
  color: 'black',
  useParentTextColor: true
}

export const tickIcon: IconProps = {
  name: 'tick',
  colorStroke: true,
  colorFill: false,
  useParentTextColor: true
}

export const sunnyIcon: IconProps = {
  name: 'sunny',
  style: { width: 29.4, height: 29.4 },
  colorFill: true,
  colorStroke: false
}
export const rainIcon: IconProps = {
  name: 'rain',
  style: { width: 32.3, height: 31.7 },
  colorFill: true,
  colorStroke: false
}
export const partlyCloudyIcon: IconProps = {
  name: 'partlyCloudy',
  style: { width: 31.6, height: 27.6 },
  colorFill: true,
  colorStroke: false
}
export const lightShowersIcon: IconProps = {
  name: 'lightShowers',
  style: { width: 32, height: 31.4 },
  colorFill: true,
  colorStroke: false
}

export const filterIcon: IconProps = {
  name: 'filter',
  style: { width: 23.4, height: 17.3 },
  colorFill: true,
  colorStroke: false
}

export const chevronIcon: IconProps = {
  name: 'chevron',
  style: {
    width: 20,
    alignSelf: 'center'
  },
  color: colors.gray600,
  useParentTextColor: true
}

export const ellipsisLargeIcon: IconProps = {
  name: 'ellipsisLarge',
  style: { width: 20 },
  color: colors.gray800,
  colorFill: true,
  colorStroke: false
}

export const blackbookRibbon: IconProps = {
  name: 'blackBookRibbon',
  style: {
    position: 'absolute',
    top: 0,
    height: 26.7,
    right: 40
  },
  color: presets.primary,
  colorFill: true,
  colorStroke: false
}

export const dayIcon: IconProps = {
  name: 'day',
  colorFill: true,
  colorStroke: false,
  style: { width: 8, aspectRatio: 1 },
  useParentTextColor: true
}
export const twilightIcon: IconProps = {
  name: 'twilight',
  colorFill: true,
  colorStroke: false,
  style: { width: 8, aspectRatio: 1.287 },
  useParentTextColor: true
}
export const nightIcon: IconProps = {
  name: 'night',
  colorFill: true,
  colorStroke: false,
  style: { width: 7, aspectRatio: 1 },
  useParentTextColor: true
}

export const commentIcon: IconProps = {
  name: 'comment',
  style: { height: 30 },
  colorStroke: false,
  colorFill: true
}

export const editIcon: IconProps = {
  name: 'edit',
  color: 'black',
  style: { height: 18 },
  colorStroke: true,
  colorFill: false
}

export const accountIcon: IconProps = {
  name: 'account',
  style: { height: 28 },
  colorStroke: true,
  colorFill: false,
  useParentTextColor: true
}

export const searchIcon: IconProps = {
  name: 'search',
  style: { height: 24 },
  colorStroke: true,
  colorFill: false,
  useParentTextColor: true
}
export const menuIcon: IconProps = {
  name: 'menu',
  style: { height: 24 },
  colorStroke: true,
  colorFill: false,
  useParentTextColor: true
}

export const infoLetterIcon: IconProps = {
  name: 'infoLetter',
  style: { width: 18 },
  color: 'black'
}
