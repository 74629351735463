import { createStyles } from '../../../../services/styles/breakpoint-styles.service'
import { presets } from '../../../../styles/colors'

export const baseNewsStyles = createStyles({
  imageContainer: {
    borderRadius: 2,
    flex: 1,
    backgroundColor: presets.background,
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    flex: 1,
    aspectRatio: 1.7889,
    height: 0,
    paddingTop: '56.25%'
  },
  fallbackImage: {
    flex: 1
  }
})
