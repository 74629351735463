/* eslint-disable react/jsx-key */
import { View } from 'react-native'
import { UpcomingRace } from '../../../../services/data/data-types/local-data-types/horse.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../../services/styles/dependencies/style-constants'
import { shortDate } from '../../../../utils/date-time'
import { Heading_C } from '../../../Base/Heading/Heading'
import { TableStyles, Table_C } from '../../../Base/Table/Table'
import { TableHeading, TableRow } from '../../../Base/Table/table.type'
import { Card_C } from '../../../Layout/Card/Card'
import { LinkOrText_C } from '../../../Partials/LinkOrText'
import { TableCellRaceLink_C } from '../../../Partials/_meeting-components/TableCellRaceLink'

type Props = { upcomingRaces: UpcomingRace[] }

export function UpcomingRaces_C({ upcomingRaces }: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const isMobileLayout = breakpointBelow(desktopBreakpoint)
  const upcomingHeadings: TableHeading[] = [
    { content: 'Meeting', colWidth: { width: 160 } },
    { content: 'Race', colWidth: { width: 70 } },
    'Distance',
    'Br',
    'Driver',
    'Trainer',
    'Odds'
  ]

  const upcomingRows = upcomingRaces.flatMap((r): TableRow => {
    const { barrier, track, isTrial, meetingDate, meetingDayPhase, race, driver, odds, trainer } = r

    return {
      cells: [
        <TableCellRaceLink_C
          {...{
            date: shortDate(meetingDate),
            dayPhaseLetter: meetingDayPhase,
            isTrial,
            trackName: track.shortName ?? track.name,
            trackSlug: track.slug,
            hasReplay: false,
            raceNumber: `${race.number}`
          }}
        />,
        race.number,
        `${race.distance}${race.startType}`,
        barrier,
        !driver ? (
          '---'
        ) : (
          <LinkOrText_C
            {...{
              name: (isMobileLayout && driver.shortName) || driver.name,
              navigateTo: !driver.slug ? undefined : ['Driver', { driverSlug: driver.slug }]
            }}
          />
        ),
        <LinkOrText_C
          {...{
            name: (isMobileLayout && trainer.shortName) || trainer.name,
            navigateTo: !trainer.slug ? undefined : ['Trainer', { trainerSlug: trainer.slug }]
          }}
        />,
        odds ? odds : ''
      ]
    }
  })

  return (
    <Card_C style={styles.racesCard}>
      <View style={styles.raceGroup}>
        <Heading_C styleType="h4" style={breakpointStyles.raceHeading}>
          Upcoming Races
        </Heading_C>
        <Table_C
          styleType="lightHeaderBorders"
          headings={upcomingHeadings}
          rows={upcomingRows}
          styles={tableStyle}
        />
      </View>
    </Card_C>
  )
}

const breakpointStyles = createStyles({
  racesCard: {
    base: {
      gap: 40
    }
  },
  raceGroup: {
    base: {}
  },
  raceHeading: {
    base: { marginBottom: 16 }
  }
})

const tableStyle: TableStyles = {
  tableContainer: { base: { minWidth: 'auto', flex: 1, maxWidth: 1000 } },
  table: { width: '100%', minWidth: 700 }
}
