/* eslint-disable react/jsx-key */
import { ReactNode, createContext } from 'react'
import { TrackDetailed } from '../../../services/data/data-types/local-data-types/track-detailed.type'
import {
  TrackDetailedResponse,
  fetchTrackDetailed
} from '../../../services/data/request-functions/track-detailed-request'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { desktopBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { colors } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { Heading_C } from '../../Base/Heading/Heading'
import { IconProps } from '../../Base/Icon/Icon'
import { Text_C } from '../../Base/Text/Text'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { TabView_C } from '../../Layout/TabView/TabView'
import { TrackCalendar_C } from './tab-views/Calendar/TrackCalendar'
import { Overview_C } from './tab-views/Overview/Overview'
import { Metadata } from '../../Partials/Metadata/Metadata'

type TrackTabName = 'Overview' | 'Calendar'

export function Track_C(): JSX.Element {
  return withScreenWrapper<'Track', [TrackDetailedResponse]>({
    requestsSetup: ({ trackSlug }) => [
      fetchTrackDetailed({
        trackSlug: trackSlug,
        useDesignMock: false
      })
    ],
    view: ({ responses }) => {
      const [{ trackDetailed }] = responses!

      const styles = useBreakpointStyles({ styles: breakpointStyles })

      const context: TrackContext = {
        activeTrack: trackDetailed
      }

      return (
        <>
          <Metadata
            title={`${trackDetailed.name} Track`}
            description={`View all of ${trackDetailed.name}'s track overview, racing history and upcoming races on harness.org.au`}
          />

          <TrackContextProvider_C context={context}>
            <TabView_C<TrackTabName>
              heading={
                <>
                  <Heading_C styleType="h1">{trackDetailed.name}</Heading_C>
                  <Text_C style={styles.headingState}>{trackDetailed.state}</Text_C>
                </>
              }
              separateCards
              style={{ leftContainer: styles.headingContainer }}
              tabs={[
                ['Overview', <Overview_C />],
                [
                  'Calendar',
                  <TrackCalendar_C />,
                  {
                    buttonIcon: {
                      name: 'calendar2',
                      style: { height: 25 },
                      useParentTextColor: true
                    } as IconProps
                  }
                ]
              ]}
              initialActiveTab={'Overview'}
            />
          </TrackContextProvider_C>
        </>
      )
    }
  })
}
const breakpointStyles = createStyles({
  headingContainer: {
    flexDirection: 'row',
    alignItems: 'baseline'
  },

  headingState: {
    marginLeft: 10,
    color: colors.gray500,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold',
    ...textSizes.size3
  },
  separator: {
    marginBottom: 13
  },
  container: {
    base: { marginBottom: 20, marginHorizontal: 14 },

    [desktopBreakpoint]: { marginHorizontal: 0 }
  },
  scrollContainer: {
    flexDirection: 'row',
    gap: 10
  }
})

type TrackContext = {
  activeTrack: TrackDetailed
}

export const TrackContext = createContext<TrackContext>({} as TrackContext)

type TrackContextProviderProps = {
  context: TrackContext
  children: ReactNode
}

function TrackContextProvider_C({ context, children }: TrackContextProviderProps) {
  return <TrackContext.Provider value={context}>{children}</TrackContext.Provider>
}
