// cspell:word SHWRY

import { PerPageOption } from '../../../components/Partials/Pagination/components/PerPageSelect'
import { SnakeToCamel } from '../../../helper-types'
import { MeetingStatusResponse, RaceStatusResponse } from '../../../types/fetch-response-base.type'
import { ResponseFeatureWin } from './fetch-response-types/trainer-response.type'

export type Pagination = {
  perPage: PerPageOption
  totalPages: number
  activePageNumber: number
  totalRecords: number
}

export type DayPhase = 'Night' | 'Day' | 'Twilight'
export type DayPhaseLetter = 'N' | 'D' | 'T'
export const dayPhaseMap: Record<DayPhase, DayPhaseLetter> & Record<DayPhaseLetter, DayPhase> = {
  Day: 'D',
  Twilight: 'T',
  Night: 'N',
  D: 'Day',
  T: 'Twilight',
  N: 'Night'
}

export type StartTypeShort = 'MS' | 'SS'
export type StartType = 'Mobile Start' | 'Standing Start'

export const startTypeMap: Record<StartTypeShort, StartType> & Record<StartType, StartTypeShort> = {
  MS: 'Mobile Start',
  SS: 'Standing Start',
  'Mobile Start': 'MS',
  'Standing Start': 'SS'
}

export type ResultPlaces = {
  '1'?: number[]
  '2'?: number[]
  '3'?: number[]
  '4'?: number[]
}

export type RaceStatus =
  | 'Interim Results'
  | 'Final Results'
  | 'Fields Drawn'
  | 'Scheduled'
  | 'Abandoned'
  | 'No Race'

export type MeetingStatus = 'Results' | 'Fields Drawn' | 'Scheduled' | 'Abandoned' | 'Transferred'

export const raceStatusMap: Record<RaceStatusResponse, RaceStatus> &
  Record<RaceStatus, RaceStatusResponse> = {
  interim_results: 'Interim Results',
  final_results: 'Final Results',
  fields_drawn: 'Fields Drawn',
  scheduled: 'Scheduled',
  abandoned: 'Abandoned',
  no_race: 'No Race',

  'Interim Results': 'interim_results',
  'Final Results': 'final_results',
  'Fields Drawn': 'fields_drawn',
  Scheduled: 'scheduled',
  Abandoned: 'abandoned',
  'No Race': 'no_race'
}

export const meetingStatusMap: Record<MeetingStatusResponse, MeetingStatus> &
  Record<MeetingStatus, MeetingStatusResponse> = {
  results: 'Results',
  fields_drawn: 'Fields Drawn',
  scheduled: 'Scheduled',
  abandoned: 'Abandoned',
  transferred: 'Transferred',

  Results: 'results',
  'Fields Drawn': 'fields_drawn',
  Scheduled: 'scheduled',
  Abandoned: 'abandoned',
  Transferred: 'transferred'
}

export type BasePlaceStats = {
  starts: number
  wins: number
  places?: number
  seconds?: number
  thirds?: number
}

export type PlaceStats = BasePlaceStats & {
  winPercent: number
  placePercent: number
  unplacedPercent: number
}

export type PerformanceStats = PlaceStats & {
  summary: string //'starts-wins-seconds-thirds'
  starts: number
  bestWinMileRate?: string
  stakes: number
}

export type BlackTypePerformance = {
  class: 'Group 1' | 'Group 2' | 'Group 3' | 'Listed'
  starts: number
  wins: number
  places: number
  winPercent: number
  placePercent: number
  unplacedPercent: number
  stakes: string
}

export type FeatureWin = SnakeToCamel<Omit<ResponseFeatureWin, 'day_night_twilight'>> & {
  dayPhaseLetter: DayPhaseLetter
}

export type SilkObj = {
  description?: string
  imagePath?: string
}

//current type
export type ResponseWeather = 'FINE' | 'SHWRY' | 'OCAST' | 'HOT' | 'COLD' | 'WINDY' | 'RAIN'

export type Weather = ResponseWeather //same until map is needed

//expected future type
export type X = 'FINE' | 'SHOWERY' | 'OVERCAST' | 'HOT' | 'COLD' | 'WINDY' | 'RAIN'

export type ResponseTrackCondition = 'GOOD' | 'SLOW' | 'FAST'
export type TrackCondition = ResponseTrackCondition //same until map is needed
