import { HorseResponse, getHorseData } from '../../../services/data/request-functions/horse-request'
import { withScreenWrapper } from '../../Layout/ScreenWrapper/ScreenWrapper'
import { Metadata } from '../../Partials/Metadata/Metadata'
import { HorseDetails_C } from './components/HorseDetails'
import { HorseStats_C } from './components/HorseStats'
import { LatestResults_C } from './components/LatestResults'
import { UpcomingRaces_C } from './components/UpcomingRaces'

export function Horse_C(): JSX.Element {
  return withScreenWrapper<'Horse', [HorseResponse]>({
    requestsSetup: ({ horseSlug }) => [getHorseData({ horseSlug })],

    view: ({ responses }) => {
      const [{ horse }] = responses!
      const {
        upcomingRaces,
        latestResults: performanceRecords,
        lifetimePerformance,
        lastSeasonPerformance,
        thisSeasonPerformance,
        slug
      } = horse
      return (
        <>
          <Metadata
            title={horse.name}
            description={`View all of ${horse.name}'s racing results, stats, and upcoming races on harness.org.au`}
          />

          <HorseDetails_C horse={horse} />
          <HorseStats_C
            {...{
              lifetimePerformance,
              lastSeasonPerformance,
              thisSeasonPerformance
            }}
          />
          {!!upcomingRaces.length && <UpcomingRaces_C upcomingRaces={horse.upcomingRaces} />}
          {!!performanceRecords.length && (
            <LatestResults_C {...{ latestResults: performanceRecords, slug }} />
          )}
        </>
      )
    }
  })
}
