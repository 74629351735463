import { View } from 'react-native'
import { LatestReplayRace } from '../../../services/data/data-types/local-data-types/videos-latest-races.type'
import { createStyles } from '../../../services/styles/breakpoint-styles.service'
import { colors, presets, stateColor } from '../../../styles/colors'
import { textSizes } from '../../../styles/text-sizes'
import { shortDate } from '../../../utils/date-time'
import { Icon_C } from '../../Base/Icon/Icon'
import { playIcon } from '../../Base/Icon/preset-icon-props'
import { Link_C } from '../../Base/Link/Link'
import { Text_C } from '../../Base/Text/Text'
import { SilkImage_C } from '../SilkImage/SilkImage'

type Props = {
  race: LatestReplayRace
}

export function ReplayRow_C({ race }: Props) {
  const { raceNumber, distance, meeting, resultPlaces } = race

  const textDivider = <View style={styles.textDivider} />
  const results = resultPlaces?.filter((result) => result.place !== '4')

  return (
    <Link_C
      navigateTo={[
        'Meeting',
        {
          raceNumber: `${raceNumber}`,
          trackSlug: meeting.track.slug,
          dayPhaseLetter: meeting.dayPhaseLetter,
          date: shortDate(meeting.date),
          type: 'meeting'
        }
      ]}
      style={{
        elem: {
          base: styles.replayRow,
          hovered: { backgroundColor: colors.gray100 }
        }
      }}
    >
      <View
        style={{
          ...styles.stateColor,
          backgroundColor: stateColor[meeting.track.state]
        }}
      />
      <Icon_C
        {...{
          ...playIcon,
          style: { width: 24 }
        }}
      />
      <View style={styles.infoContainer}>
        <Text_C style={styles.trackName}>{meeting.track.name}</Text_C>

        <View style={styles.raceDetails}>
          <Text_C>R{raceNumber}</Text_C>
          {textDivider}

          {!!distance && <Text_C>{distance}m</Text_C>}
        </View>
      </View>
      <View style={styles.runnerContainer}>
        {results?.map((result) =>
          result.runners.map((runner, idx) => (
            <View
              key={idx}
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                columnGap: 3
              }}
            >
              <Text_C style={styles.runnerNumber}>{runner.runnerNumber}</Text_C>

              <SilkImage_C
                silk={runner.silk}
                style={{
                  cropContainer: { width: 26 }
                }}
              />
            </View>
          ))
        )}
      </View>
      <Icon_C name="chevron" color={presets.border} style={styles.icon} />
    </Link_C>
  )
}

const styles = createStyles({
  replayRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingVertical: 10,
    justifyContent: 'flex-start'
  },
  stateColor: {
    width: 9,
    height: 35
  },
  infoContainer: { gap: 4, marginRight: 'auto' },
  trackName: {
    ...textSizes.size1,
    fontWeight: '700Bold'
  },
  raceDetails: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  textDivider: {
    marginHorizontal: 8,
    width: 1,
    backgroundColor: colors.gray700,
    height: 12
  },
  runnerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    alignItems: 'center',
    columnGap: 15,
    maxWidth: 150
  },
  runnerNumber: {
    fontSize: 13
  },
  icon: {
    width: 25,
    height: 25,
    transform: [{ rotate: `${-90}deg` }]
  }
})
