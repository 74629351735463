import { fetchData } from '../api-fetch-request'
import { StewardsReportFetchResponse } from '../data-types/fetch-response-types/stewards-report-response.type'
import { DayPhaseLetter } from '../data-types/general-data-types.type'

export type StewardsReportResponse = { stewardsReport: string | undefined }

type GetStewardsReportParams = {
  trackSlug: string
  date: string
  dayPhaseLetter: DayPhaseLetter
  trial?: boolean
}
export async function getStewardsReportData({
  trackSlug,
  date,
  dayPhaseLetter,
  trial
}: GetStewardsReportParams): Promise<StewardsReportResponse> {
  const endpoint = `racing/stewards-reports/${trackSlug}/${date}/${dayPhaseLetter}${trial ? `?trial=true` : ''}`

  return fetchData<StewardsReportResponse, StewardsReportFetchResponse>({
    endpoint,
    convertResponse
  })

  function convertResponse(
    stewardsReportPayload: StewardsReportFetchResponse
  ): StewardsReportResponse {
    const { stewards_report } = stewardsReportPayload

    const stewardsReportResponse: StewardsReportResponse = {
      stewardsReport: stewards_report ?? undefined
    }

    return stewardsReportResponse
  }
}
