import { useContext } from 'react'
import { View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import { colors } from '../../../../../../styles/colors'
import { Heading_C } from '../../../../../Base/Heading/Heading'
import { Link_C } from '../../../../../Base/Link/Link'
import { Separator_C } from '../../../../../Base/Separator/Separator'
import { LabelValueTable_C } from '../../../../../Base/Table/LabelValueTable'
import { RenderRichText } from '../../../../../Partials/RichText/RichText'
import { TrackContext } from '../../../Track'

export function TrackInfo_C() {
  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const { activeTrack } = useContext(TrackContext)

  const {
    address,
    postalAddress,
    phone,
    email,
    website,
    radio,
    usualMeetingDays,
    usualSeason,
    facilities,
    events,
    propertyIdentificationCode
  } = activeTrack

  if (
    [
      address,
      postalAddress,
      phone,
      email,
      website,
      radio,
      usualMeetingDays,
      usualSeason,
      facilities,
      events,
      propertyIdentificationCode
    ].every((prop) => prop == undefined)
  ) {
    return <></>
  }

  const emailLink = email && (
    <Link_C linkUrl={`mailto:${email}`} newTab externalLink>
      {email}
    </Link_C>
  )
  const websiteLink = website && (
    <Link_C linkUrl={website} newTab externalLink>
      {website}
    </Link_C>
  )

  return (
    <View style={styles.trackInfo}>
      {!!address && (
        <>
          <Heading_C styleType="h3">Track Address</Heading_C>
          <Separator_C />
          <LabelValueTable_C
            style={{ container: styles.addressTable, label: { width: 60 } }}
            data={[
              {
                label: '',
                value: address.formatted
              }
            ]}
          />
        </>
      )}
      {!!postalAddress && (
        <>
          <Heading_C styleType="h3">Postal Address</Heading_C>
          <Separator_C />
          <LabelValueTable_C
            style={{ container: styles.addressTable, label: { width: 60 } }}
            data={[
              {
                label: '',
                value: postalAddress.formatted
              }
            ]}
          />
        </>
      )}
      {(!!phone?.formatted || !!emailLink || !!websiteLink) && (
        <LabelValueTable_C
          style={{ container: styles.addressTable, label: { width: 60 } }}
          data={[
            ...(phone?.formatted
              ? [
                  {
                    label: 'Phone:',
                    value: phone.formatted
                  }
                ]
              : []),
            ...(emailLink
              ? [
                  {
                    label: 'Email:',
                    value: emailLink
                  }
                ]
              : []),
            ...(websiteLink
              ? [
                  {
                    label: 'Website:',
                    value: websiteLink ?? ''
                  }
                ]
              : [])
          ]}
        />
      )}

      {(!!radio ||
        !!usualMeetingDays ||
        !!usualSeason ||
        !!facilities ||
        !!events ||
        !!propertyIdentificationCode) && (
        <>
          <Separator_C style={{ marginBottom: 20 }} />
          <LabelValueTable_C
            style={{ container: styles.addressTable }}
            data={[
              ...(!radio
                ? []
                : [
                    {
                      label: 'Radio:',
                      value: radio ?? ''
                    }
                  ]),
              ...(!usualMeetingDays
                ? []
                : [
                    {
                      label: 'Usual Meeting Days:',
                      value: <RenderRichText content={usualMeetingDays} /> ?? ''
                    }
                  ]),
              ...(!usualSeason
                ? []
                : [
                    {
                      label: 'Usual Season:',
                      value: <RenderRichText content={usualSeason} /> ?? ''
                    }
                  ]),
              ...(!events
                ? []
                : [
                    {
                      label: 'Special Events:',
                      value: <RenderRichText content={events} /> ?? ''
                    }
                  ]),
              ...(!facilities
                ? []
                : [
                    {
                      label: 'Track Facilities:',
                      value: <RenderRichText content={facilities} /> ?? ''
                    }
                  ]),
              ...(!propertyIdentificationCode
                ? []
                : [
                    {
                      label: 'PIC Number:',
                      value: propertyIdentificationCode
                    }
                  ])
            ].filter((prop) => ({ prop }))}
          />
        </>
      )}
    </View>
  )
}

const breakpointStyles = createStyles({
  trackInfo: {
    base: {
      backgroundColor: colors.gray100,
      padding: 14
    },
    large: {
      paddingVertical: 34,
      paddingHorizontal: 20
    }
  },
  addressTable: {
    base: {
      marginBottom: 16
    },
    large: {
      marginBottom: 20
    }
  }
})
