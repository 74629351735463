import { toLower, toUpper } from 'lodash'
import { PerPageOption } from '../../../components/Partials/Pagination/components/PerPageSelect'
import { AustralianState } from '../../../types/map.type'
import { convertAsset } from '../../../utils/assets'
import { fetchData } from '../api-fetch-request'
import { PodcastListFetchResponse } from '../data-types/fetch-response-types/podcast-list-response.type'
import { Pagination } from '../data-types/general-data-types.type'
import { Podcast } from '../data-types/local-data-types/podcast-list.type'

export type PodcastListResponse = {
  podcasts: Podcast[]
  pagination: Pagination
}
type Props = {
  state?: AustralianState | 'National'
  sortType: 'newest' | 'oldest'

  limit: number
  page?: number
}
export function getPodcasts({ state, sortType, limit, page }: Props): Promise<PodcastListResponse> {
  const queryParams = new URLSearchParams({
    ...(state ? { state: toLower(state) } : {}),
    order: sortType == 'newest' ? 'desc' : 'asc',
    'pagination[limit]': `${limit}`,
    'pagination[page]': `${page ?? 1}`
  })
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const endpoint = `videos/featured-video?${queryParams}`

  return fetchData<PodcastListResponse, PodcastListFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(payload: PodcastListFetchResponse): PodcastListResponse {
    const { featured_videos, pagination } = payload

    const podcastListResponse: PodcastListResponse = {
      podcasts: featured_videos.map(({ title, date, state, description, feature_video }) => {
        const podcast: Podcast = {
          title,
          date: new Date(date),
          state: toUpper(state) as AustralianState | 'National',
          description,
          video: convertAsset(feature_video)
        }
        return podcast
      }),
      pagination: {
        activePageNumber: pagination.page,
        totalPages: pagination.pages,
        perPage: pagination.limit as PerPageOption,
        totalRecords: pagination.total
      }
    }
    return podcastListResponse
  }
}
